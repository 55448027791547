export const DIRECTION_FORWARD  = "forward";
export const DIRECTION_BACKWARD = "backward";
export const DIRECTION_NONE     = "";

export const MODE_STEP_NONE = "none";
export const MODE_STEP_VIEW = "view";
export const MODE_STEP_EDIT = "edit";

export const TMP_STEP_ID     = "tmp";
export const TMP_QUESTION_ID = "tmp";

export const CURRENT_STEP = "CURRENT_STEP"
