import "mithril";
import Component from "_/lib/Component";
import {translate} from "_/localizations";
import Button from "_/components/plugins/Button";
import ChooseSimple from "_/components/common/ChooseSimple";

const LANG = "documentTextBlock.list";

class AddEditDocumentTextBlock extends Component {
    oninit() {
        this.item = { ...this.attrs.item };
    }

    view() {
        const
            item       = this.item,
            classModel = this.attrs.classModal,
            types      = this.attrs.types;

        return (
            <div>
                <div className="modal__header bg-blue color-white">
                    <div className="font-icon cancel modal-close"/>
                    <span>{translate(`${LANG}.${item.id ? "titleEdit" : "titleAdd"}`)}</span>
                </div>
                <div className="modal__body">
                    <label className="form-item">
                        <span className="pb10">{translate(`${LANG}.labelNameGroup`)}</span>
                        <input
                            type="text"
                            value={item.name}
                            oninput={(e) => this.changeField("name", e.target.value)}
                        />
                    </label>
                    <label className="form-item pt15">
                        <span className="pb10">{translate(`${LANG}.labelTypes`)}</span>
                        <ChooseSimple
                            data-popup-key="add__edit_text-block"
                            data={types}
                            modal={`.${classModel}`}
                            onChange={item => this.changeField("textBlockTypeId", item.id)}
                            isCanEmpty={false}
                            value={item.textBlockTypeId}
                        />
                    </label>
                    <label className="form-item pt15">
                        <span className="pb10">{translate(`${LANG}.labelCommentGroup`)}</span>
                        <textarea
                            value={item.comment}
                            oninput={(e) => this.changeField("comment", e.target.value)}
                        />
                    </label>
                </div>
                <div className="modal__footer bg-grey text-right">
                    <Button className="turbo-button__size_md dotted-link mr20 fs14 p0 modal-close color-blue">
                        {translate("buttons.cancel")}
                    </Button>
                    <Button
                        className="turbo-button__size_md turbo-button__bg_orange turbo-button__text_white"
                        disabled={!item.name}
                        onclick={() => this.save()}
                        title={item.name ? "" : translate(`${LANG}.warningSave`)}
                    >
                        {translate("buttons.save")}
                    </Button>
                </div>
            </div>
        );
    }

    changeField(fieldName, value) {
        this.item[fieldName] = value;
    }

    save() {
        const onSave = this.attrs.onSave;

        if (typeof onSave === "function") {
            if (onSave(this.item) === false) {
                return;
            }
        }

        turbo.plugins.modal.close();
    }
}

export default AddEditDocumentTextBlock;
