import {
    INIT_DATA_CONTENTS_AND_REVISIONS, SET_CONTENT_VIEWS,
    SET_DATA_CONTENTS_AND_REVISIONS
} from "types/document-section/contentsAndRevisions";
import {getContentForView} from "_/service/document-section/contentsAndRevisions";

const getLoadDataContentsAndRevisionsAsync = (documentId, isLoad = true) => (
    mt.s.get({
        url        : Routing.generate(
            "turbo_front_contract_trigger_sync_action",
            {
                documentId,
                // eslint-disable-next-line camelcase
                model_id: "punkt",
                action  : "get-all"
            }
        ),
        loadConfig : { isShow: isLoad, name: "ContentsAndRevisions: getLoadDataContentsAndRevisionsAsync" },
        errorConfig: { isShow: false, isExecute: true }
    })
);

const processData = (itemsModel, itemsVersionModel) => {
    let arrayItemsModel        = Object.values(itemsModel),
        arrayItemsVersionModel = Object.values(itemsVersionModel),
        itemsFirstLevel;

    arrayItemsModel.sort((item1, item2) => {
        let id1 = parseInt(item1.lft),
            id2 = parseInt(item2.lft);

        if (id1 > id2) {
            return 1;
        }

        if (id1 < id2) {
            return -1;
        }

        return 0;
    });

    itemsFirstLevel = arrayItemsModel.filter(item => parseInt(item.level) === 1);

    return getSortArrayItemsModel(itemsFirstLevel, arrayItemsModel, arrayItemsVersionModel);
};

const getSortArrayItemsModel = (items, arrayItemsModel, arrayItemsVersionModel) => {
    let sortArrayItemsModel = [];

    items.forEach(item => {
        let children;

        sortArrayItemsModel.push({
            id           : item.id,
            level        : item.level,
            number       : item.number,
            lastVersionId: item.last_version_id,
            type         : item.type_of,
            ...getItemsVersionData(item.id, item.last_version_id, arrayItemsVersionModel)
        });

        children = arrayItemsModel.filter(child => parseInt(child.parent_id) === parseInt(item.id));

        sortArrayItemsModel = sortArrayItemsModel.concat(getSortArrayItemsModel(
            children,
            arrayItemsModel,
            arrayItemsVersionModel
        ));
    });

    return sortArrayItemsModel;
};

const getItemsVersionData = (itemId, lastVersionId, arrayItemsVersionModel) => {
    let versionItems = arrayItemsVersionModel.filter(version => {
            let itemIdOfVersion = parseInt(version.matching_punkt_id);

            return itemIdOfVersion === parseInt(itemId);
        }),
        versionData  = { countAllVersions: 0, countNewVersions: 0 };

    versionItems.forEach(version => {
        if (parseInt(version.id) === parseInt(lastVersionId)) {
            versionData.content = version.editorial_name || version.editorial_content;
            versionData.model   = version.model;
        } else {
            versionData.countAllVersions++;
        }

        if (parseInt(version.isRead) === 0) {
            versionData.countNewVersions++;
        }
    });

    if (versionData.countNewVersions > versionData.countAllVersions) {
        versionData.countNewVersions = versionData.countAllVersions;
    }

    return versionData;
};

export const initDataContentsAndRevisions = () => ({
    type: INIT_DATA_CONTENTS_AND_REVISIONS
});

export const setDataContentsAndRevisions = data => ({
    type: SET_DATA_CONTENTS_AND_REVISIONS,
    data
});

export const loadDataContentsAndRevisionsAsync = (documentId, isLoad) => dispatch => {
    return getLoadDataContentsAndRevisionsAsync(documentId, isLoad)
        .then(result => {
            let data         = processData(result.part.PunktModel, result.part.PunktVersionModel),
                contentViews = {};

            //TODO для компонентов которые ещё не переведены на midux
            turbo.globalState.set({ documentItems: data });

            dispatch(setDataContentsAndRevisions(data));

            data.forEach(item => {
                contentViews[item.id] = getContentForView(item.content, item.type, item.model);
            });

            dispatch(setContentViews(contentViews));
        });
};

export const setContentViews = payload => ({
    type: SET_CONTENT_VIEWS,
    payload
});
