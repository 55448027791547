import m from 'mithril';
import Level from './Level';
import MainLevel from './MainLevel';
import MainLevelLeft from './MainLevelLeft';
import MainLevelRight from './MainLevelRight';

class TopPanel {
    view({ children }) {
        return (
            <div className="toolbar__top-panel">
                { children }
            </div>
        )
    }
}

TopPanel.Level          = Level;
TopPanel.MainLevel      = MainLevel;
TopPanel.MainLevelLeft  = MainLevelLeft;
TopPanel.MainLevelRight = MainLevelRight;

export default TopPanel;
