export const TABLE = "table";

export const HORIZONTAL_ALIGN_LEFT   = "left";
export const HORIZONTAL_ALIGN_RIGHT  = "right";
export const HORIZONTAL_ALIGN_CENTER = "center";

export const VERTICAL_ALIGN_TOP    = "top";
export const VERTICAL_ALIGN_MIDDLE = "center";
export const VERTICAL_ALIGN_BOTTOM = "bottom";

export const DEFAULT_COLORS = {
    "editor-bg-light-gray-0": "#f2f2f2",
    "editor-bg-light-gray-1": "#d8d8d8",
    "editor-bg-light-gray-2": "#bfbfbf",
    "editor-bg-light-gray-3": "#a5a5a5",
    "editor-bg-light-gray-4": "#7f7f7f",
    "editor-bg-gray-0": "#777777",
    "editor-bg-gray-1": "#595959",
    "editor-bg-gray-2": "#3f3f3f",
    "editor-bg-gray-3": "#262626",
    "editor-bg-gray-4": "#0c0c0c",
    "editor-bg-brown-0": "#ddd9c3",
    "editor-bg-brown-1": "#c4bd97",
    "editor-bg-brown-2": "#938953",
    "editor-bg-brown-3": "#494429",
    "editor-bg-brown-4": "#1d1b10",
    "editor-bg-blue-0": "#c6d9f0",
    "editor-bg-blue-1": "#8db3e2",
    "editor-bg-blue-2": "#548dd4",
    "editor-bg-blue-3": "#17365d",
    "editor-bg-blue-4": "#0f243e",
    "editor-bg-light-blue-0": "#dbe5f1",
    "editor-bg-light-blue-1": "#b8cce4",
    "editor-bg-light-blue-2": "#95b3d7",
    "editor-bg-light-blue-3": "#366092",
    "editor-bg-light-blue-4": "#244061",
    "editor-bg-dark-red-0": "#f2dcdb",
    "editor-bg-dark-red-1": "#e5b9b7",
    "editor-bg-dark-red-2": "#d99694",
    "editor-bg-dark-red-3": "#953734",
    "editor-bg-dark-red-4": "#632423",
    "editor-bg-red-0": "#ffbaba",
    "editor-bg-red-1": "#ff9e9e",
    "editor-bg-red-2": "#ff7a7a",
    "editor-bg-red-3": "#fc5858",
    "editor-bg-red-4": "#ff3232",
    "editor-bg-green-0": "#ebf1dd",
    "editor-bg-green-1": "#d7e3bc",
    "editor-bg-green-2": "#c3d69b",
    "editor-bg-green-3": "#76923c",
    "editor-bg-green-4": "#4f6128",
    "editor-bg-purple-0": "#e5e0ec",
    "editor-bg-purple-1": "#ccc1d9",
    "editor-bg-purple-2": "#b2a2c7",
    "editor-bg-purple-3": "#5f497a",
    "editor-bg-purple-4": "#3f3151",
    "editor-bg-cyan-0": "#dbeef3",
    "editor-bg-cyan-1": "#b7dde8",
    "editor-bg-cyan-2": "#92cddc",
    "editor-bg-cyan-3": "#31859b",
    "editor-bg-cyan-4": "#205867",
    "editor-bg-orange-0": "#fdeada",
    "editor-bg-orange-1": "#fbd5b5",
    "editor-bg-orange-2": "#fac08f",
    "editor-bg-orange-3": "#e36c09",
    "editor-bg-orange-4": "#974806",
    "editor-bg-yellow-0": "#fffeed",
    "editor-bg-yellow-1": "#fffaaa",
    "editor-bg-yellow-2": "#fff784",
    "editor-bg-yellow-3": "#fcf25d",
    "editor-bg-yellow-4": "#ffef0a",
    "editor-bg-multi-0": "#ffffff",
    "editor-bg-multi-1": "#ff0000",
    "editor-bg-multi-2": "#ffff00",
    "editor-bg-multi-3": "#92d050",
    "editor-bg-multi-4": "#00b0f0",
};
