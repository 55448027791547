const LEFT_PANEL_STORAGE_NAME = "toolbar_left_panels";

export const getActiveLeftPanel = function(items, prefix) {
    let nameStorage = `${prefix}${LEFT_PANEL_STORAGE_NAME}`,
        active      = turbo.loadJsonFromLocalStorage(nameStorage).active;

    return items.find(item => item.name === active);
};

export const checkLeftPanelOnActive = function(name, prefix) {
    let nameStorage = `${prefix}${LEFT_PANEL_STORAGE_NAME}`,
        active      = turbo.loadJsonFromLocalStorage(nameStorage).active;

    return active === name;
};

export const getDefaultOpenLeftPanel = function(defaultActive, prefix) {
    let nameStorage  = `${prefix}${LEFT_PANEL_STORAGE_NAME}`,
        cachedActive = turbo.loadJsonFromLocalStorage(nameStorage).active;

    if (cachedActive) {
        return cachedActive === "NONE" ? false : cachedActive;
    } else {
        turbo.saveJsonToLocalStorage(nameStorage, { active: defaultActive });

        return defaultActive;
    }
};

export const setLeftPanelStateToLocalStorage = function(name, value, prefix) {
    let nameStorage = `${prefix}${LEFT_PANEL_STORAGE_NAME}`,
        active      = value ? name : "NONE";

    turbo.saveJsonToLocalStorage(nameStorage, { active });
};

export const getNameActiveLeftPanel = function(prefix) {
    let nameStorage = `${prefix}${LEFT_PANEL_STORAGE_NAME}`,
        active;

    active = turbo.loadJsonFromLocalStorage(nameStorage).active;

    return active;
};
