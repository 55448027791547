import {
    REMOVE_RELATED_DATA,
    REQUEST_RELATED_DATA,
    SET_CURRENT_DATA,
    SET_CURRENT_TYPE,
    SET_RELATED_DATA,
    REQUEST_BINDINGS,
    SET_BINDINGS
} from "_/types/relatedData";

export const requestRelatedData = payload => ({
    type: REQUEST_RELATED_DATA,
    payload
});

export const setRelatedData = payload => ({
    type: SET_RELATED_DATA,
    payload
});

export const setCurrentType = payload => ({
    type: SET_CURRENT_TYPE,
    payload
});

export const setCurrentData = payload => ({
    type   : SET_CURRENT_DATA,
    payload: payload || {}
});

export const removeRelatedData = () => ({
    type: REMOVE_RELATED_DATA
});

export const requestBindingsData = payload => ({
    type: REQUEST_BINDINGS,
    payload
});

export const setBindingsData = payload => ({
    type: SET_BINDINGS,
    payload
});

