import "mithril";
import classNames from "classnames";
import {translate} from "localizations";
import Component from "lib/Component";
import Button from "components/plugins/Button";
import {Container} from "_/components/plugins/ScrollBar";
import TextEllipsis from "components/plugins/TextEllipsis";
import * as typesOfScheme from "_/constants/typesOfScheme";
import Scheme from "./Scheme";

//Корень для локализации
const ROOT = "listObjectOfRegistryByLink";

class Schemes extends Component {
    oninit() {
        this.groups = [
            {
                id    : typesOfScheme.SIMPLE_LIST,
                name  : translate(`${ROOT}.typesOfScheme.simpleList`),
                isOpen: false
            },
            {
                id    : typesOfScheme.LIST_TEXT_BLOCK_OF_REGISTRY,
                name  : translate(`${ROOT}.typesOfScheme.listTextBlockOfRegistry`),
                isOpen: false
            }
        ];
    }

    view() {
        const { data = [], validate, update, onDelete, onOpenWindowScheme } = this.attrs;

        let listClasses;

        listClasses = classNames(
            "tile-list",
            "tile-list_flex",
            "tile-list_striped",
            "tile-list_bordered",
            "tile-list_hovered",
            "mt5"
        );

        return (
            <Container style="max-height: calc(100vh - 244px);">
                <Choose>
                    <When condition={data.length === 0}>
                        <div className="fs16 color-grey">
                            {translate(`${ROOT}.emptyList`)}
                        </div>
                    </When>
                    <Otherwise>
                        {
                            this.groups.map(group => {
                                let groupClasses, dataGroup;

                                groupClasses = classNames(
                                    "group-tile-list mt10 cursor-pointer",
                                    { "group-tile-list__open": group.isOpen }
                                );

                                dataGroup = data.filter(item => +item.type === +group.id);

                                return (
                                    <Choose>
                                        <When condition={dataGroup.length > 0}>
                                            <div>
                                                <div
                                                    className={groupClasses}
                                                    onclick={() => this.onToggleGroup(group)}
                                                >
                                                    <TextEllipsis className="dashed-link color-blue text-bold">
                                                        {`${group.name} (${dataGroup.length})`}
                                                    </TextEllipsis>
                                                    <Button className="ml5 trigger">
                                                        <Button.Icon className="drop-down-arrow color-blue fs8 block"/>
                                                    </Button>
                                                </div>
                                                <Choose>
                                                    <When condition={group.isOpen}>
                                                        <div className={listClasses}>
                                                            {
                                                                dataGroup.map(item => {
                                                                    return (
                                                                        <Scheme
                                                                            scheme={item}
                                                                            validate={validate}
                                                                            onUpdate={update}
                                                                            onDelete={onDelete}
                                                                            onOpenWindowScheme={onOpenWindowScheme}
                                                                        />
                                                                    );
                                                                })
                                                            }
                                                        </div>
                                                    </When>
                                                </Choose>
                                            </div>
                                        </When>
                                    </Choose>
                                );
                            })
                        }
                    </Otherwise>
                </Choose>
            </Container>
        );
    }

    onToggleGroup(group) {
        group.isOpen = !group.isOpen;
    }
}

export default Schemes;
