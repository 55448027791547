import "mithril";
import Component from "_/lib/Component";
import { translate } from "_/localizations";
import DateInput from "_/components/plugins/DateInput";
import ChooseSimple from "_/components/common/ChooseSimple";
import Button from "components/plugins/Button";
import { Container } from "_/components/plugins/ScrollBar";
import ChooseMany from "_/components/common/ChooseMany";


class Filters extends Component {
    oninit({ attrs }) {
        this.isPeriod = true;
    }

    view({ attrs }) {
        let filters = this.attrs.filters || [];

        return (
            <div className={`turbo-search row`}>
                <div class="row">
                    <h5 class="col-8 p10 color-blue bold">{translate("inputSearch.additionalFilters")}</h5>
                </div>
                {filters.map(filter => {
                    const values = typeof filter.values === "function" ? filter.values() : filter.values;

                    let fieldName = filter.fieldName;

                    if (!this.turboTree && Array.isArray(values) && values.length > 0 && (fieldName === "tags"
                        || fieldName === "date")) {
                        this.turboTree = new turbo.plugins.TurboTree({
                            storage: { data: values },
                            settings: { isHideHeader: true, markedParent: true },
                            listeners: {
                                // eslint-disable-next-line camelcase
                                check_node: () => {
                                    this.attrs.onChangeTagValue(this.turboTree.getChecked(), this)
                                }
                            }
                        });

                        TurboTree = this.turboTree.getElement();
                    }

                    return (
                        <Choose>
                            <When condition={fieldName !== "tags" && fieldName !== "created_at"}>
                                <div class="col-4">
                                    <div class="p15">
                                        <label class="pr5">
                                            <span class="fs14">{filter.label}</span>
                                            <Choose>
                                                <When condition={filter.isMulti}>
                                                    <ChooseMany
                                                        data-popup-key={fieldName}
                                                        data={values}
                                                        onChange={item =>
                                                            this.attrs.onChangeFilterMultiValue(fieldName, item)}
                                                        isCanEmpty={false}
                                                        values={this.attrs.strictFilters[fieldName]
                                                            ? this.attrs.strictFilters[fieldName]
                                                            : (values || [])}
                                                    />
                                                </When>
                                                <Otherwise>
                                                    <ChooseSimple
                                                        data-popup-key={fieldName}
                                                        data={values}
                                                        onChange={item =>
                                                            this.attrs.onChangeFilterValue(fieldName, item)}
                                                        countShowItems={15}
                                                        isCanEmpty={false}
                                                        value={this.attrs.strictFilters[fieldName]
                                                            ? this.attrs.strictFilters[fieldName].id
                                                            : (values[0].id || "")}
                                                    />
                                                </Otherwise>
                                            </Choose>
                                        </label>
                                    </div>
                                </div>
                            </When>
                            <When condition={fieldName === "tags"}>
                                <div class="col-4">
                                    <div class="p15">
                                        <div class="pr5">
                                            <Button
                                                className="btn color-blue turbo-link"
                                                onclick={() => this.attrs.isShowTags(!this.attrs.showTags)}
                                            >
                                                <Button.Text className="dashed-link fs15">
                                                    {filter.label}
                                                </Button.Text>
                                            </Button>
                                            <Choose>
                                                <When condition={this.attrs.showTags}>
                                                    <Container className="modal__body h250">
                                                        {TurboTree ? <TurboTree /> : null}
                                                    </Container>
                                                </When>
                                            </Choose>

                                        </div>
                                    </div>
                                </div>
                            </When>
                            <When condition={fieldName === "created_at"}>
                                <div class={"col-10"}>
                                    <div class="p15">
                                        <div class="pr5">
                                            <Button
                                                className="btn color-blue turbo-link"
                                                onclick={() => this.periodToggle()}
                                            >
                                                <Button.Text className="dashed-link fs15">
                                                    {this.isPeriod ? translate("inputSearch.period")
                                                        : translate("inputSearch.singleDate")}
                                                </Button.Text>
                                            </Button>
                                            <Choose>
                                                <When condition={this.isPeriod}>
                                                    <div class="row">
                                                        <div class="col-4 pr5">
                                                            <label class="pr5">
                                                                <span class="fs14">
                                                                    {translate("inputSearch.dateFrom")}
                                                                </span>
                                                                <DateInput
                                                                    value={
                                                                        this.attrs.strictFilters[fieldName]
                                                                            // eslint-disable-next-line
                                                                            ? this.attrs.strictFilters[fieldName].dateFrom
                                                                            : ""
                                                                    }
                                                                    data-popup-key={`filter-date-from`}
                                                                    modal=".body-with-date"
                                                                    onChange={
                                                                        value =>
                                                                            this.attrs.onChangeDateValue(fieldName, value, "dateFrom", this.isPeriod)}
                                                                />
                                                            </label>
                                                        </div>
                                                        <div class="col-4">
                                                            <label class="pr5">
                                                                <span class="fs14">
                                                                    {translate("inputSearch.dateTo")}
                                                                </span>
                                                                <DateInput
                                                                    value={this.attrs.strictFilters[fieldName]
                                                                        ? this.attrs.strictFilters[fieldName].dateTo
                                                                        : ""}
                                                                    data-popup-key={`filter-date-to`}
                                                                    modal=".body-with-date"
                                                                    onChange={
                                                                        value =>
                                                                            this.attrs.onChangeDateValue(fieldName, value, "dateTo", this.isPeriod)}
                                                                />
                                                            </label>
                                                        </div>
                                                    </div>
                                                </When>
                                                <Otherwise>
                                                    <div class="col-4">
                                                        <label class="pr5">
                                                            <DateInput
                                                                value={this.attrs.strictFilters[fieldName]
                                                                    ? this.attrs.strictFilters[fieldName].date
                                                                    : ""}
                                                                data-popup-key={`filter-date`}
                                                                modal=".body-with-date"
                                                                onChange={value =>
                                                                    this.attrs.onChangeDateValue(fieldName, value, "date", this.isPeriod)}
                                                            />
                                                        </label>
                                                    </div>
                                                </Otherwise>
                                            </Choose>

                                        </div>

                                    </div>
                                </div>
                            </When>
                        </Choose>
                    );
                })}
            </div>
        );
    }

    periodToggle() {
        let fieldName = "created_at",
            dateFrom  = this.attrs.strictFilters[fieldName] ? this.attrs.strictFilters[fieldName].dateFrom : '',
            date      = this.attrs.strictFilters[fieldName] ? this.attrs.strictFilters[fieldName].date : '';

        this.isPeriod = !this.isPeriod;

        if (this.isPeriod) {
            this.attrs.onChangeDateValue(fieldName, date, "dateFrom", this.isPeriod)
        } else {
            this.attrs.onChangeDateValue(fieldName, dateFrom, "date", this.isPeriod)
        }
    }
}

export default Filters;
