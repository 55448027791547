import turbo from "_/lib/turbo";
import {
    TABLE,
    HORIZONTAL_ALIGN_LEFT,
    HORIZONTAL_ALIGN_RIGHT,
    HORIZONTAL_ALIGN_CENTER,
    VERTICAL_ALIGN_BOTTOM,
    VERTICAL_ALIGN_MIDDLE,
    VERTICAL_ALIGN_TOP,
    DEFAULT_COLORS
} from "./constants";

class EditorItemModel {
    constructor(html = "", prefix = "table") {
        this.html        = html;
        this.prefix      = prefix;
        this.counter     = 0;
        this.modelTables = {};
    }

    getModel() {
        let elements = turbo.stringToHtmlElements(this.html);

        if (elements.length === 1 && this.isContainer(elements[0])) {
            return { tables: this.modelTables, container: this.createContainerElement(elements[0]) };
        } else {
            return { tables: this.modelTables, content: this.createModelElements(elements) };
        }
    }

    isContainer(element) {
        return element.nodeType === 1 && element.hasAttribute("data-type-container");
    }

    createContainerElement(element) {
        const typeContainer = element.getAttribute("data-type-container");

        if (typeContainer === "cells") {
            return this.createTableModel(element);
        }
    }

    createModelElements(elements) {
        let mockTables = [],
            nodes      = [];

        [].forEach.call(elements, (element, index) => {
            if (element.nodeType === 1) {
                if (element.nodeName === "TABLE") {
                    let identifier = `${this.prefix}_${++this.counter}`,
                        mockTable  = document.createElement("span");

                    this.modelTables[identifier] = this.createTableModel(element);

                    mockTable.setAttribute("data-element", "table");
                    mockTable.setAttribute("data-id", identifier);

                    mockTables[index] = mockTable;
                } else {
                    element.innerHTML = this.createModelElements(element.childNodes);
                }
            }

            nodes.push(element);
        });

        Object.keys(mockTables).forEach(key => {
            nodes.splice(+key, 1, mockTables[key]);
        });

        return turbo.htmlElementsToString(nodes);
    }

    createTableModel(tableElement) {
        let model = {},
            colsElements, rowsElements, tmpElement, tmpContainer, currentDate;

        model.element = TABLE;
        model.cols    = [];
        model.rows    = [];

        currentDate = Date.now();

        tmpElement      = document.createElement("div");
        tmpContainer    = document.createElement("div");
        tmpContainer.id = `tmp_${currentDate}`;
        tmpContainer.appendChild(tableElement.cloneNode(true));
        tmpElement.appendChild(tmpContainer);

        colsElements = tmpElement.querySelectorAll(`#${tmpContainer.id} > table > colgroup > col`);

        [].forEach.call(colsElements, col => {
            let mapDataAttributes = turbo.component.turboEditor.nodeUtils.getMapDataAttributes(col, true),
                width             = col.style.width;

            if (mapDataAttributes.colSource !== "add") {
                if (mapDataAttributes.colBaseWidth) {
                    width = mapDataAttributes.colBaseWidth;
                }

                model.cols.push({ width });
            }
        });

        rowsElements = tableElement.rows;

        [].forEach.call(rowsElements, row => {
            let cellsElements = row.cells,
                cells         = [],
                tmp;

            [].forEach.call(cellsElements, cell => {
                let cellModel = this.createCellModel(cell);

                if (cellModel) {
                    cells.push(cellModel);
                }
            });

            if (cells.length > 0) {
                tmp = {}
                if (row.style && row.style.height) {
                    tmp.height = row.style.height
                }
                tmp.cells = cells
                model.rows.push({ ...tmp });
            }
        });

        return model;
    }

    createCellModel(cellElement) {
        let model   = {},
            classes = cellElement.classList,
            backgroundColorClass, mapDataAttributes;

        mapDataAttributes = turbo.component.turboEditor.nodeUtils.getMapDataAttributes(cellElement, true);

        model.colSpan = cellElement.colSpan;
        model.rowSpan = cellElement.rowSpan;

        if (mapDataAttributes.cellColSpanBase) {
            model.colSpan = mapDataAttributes.cellColSpanBase;

            delete mapDataAttributes.cellColSpanBase;
        }

        if (mapDataAttributes.cellRowSpanBase) {
            model.rowSpan = mapDataAttributes.cellRowSpanBase;

            delete mapDataAttributes.cellRowSpanBase;
        }

        if (mapDataAttributes.cellSource === "add") {
            return null;
        }

        delete mapDataAttributes.cellSource;

        backgroundColorClass = this.getBackgroundColorClass(classes);

        if (DEFAULT_COLORS[backgroundColorClass]) {
            model.backgroundColor = DEFAULT_COLORS[backgroundColorClass];
        }

        if (cellElement.hasAttribute("data-backgroundcolor")) {
            model.backgroundColor = cellElement.getAttribute("data-backgroundcolor");
        }

        model.isDarkText  = classes.contains("editor-dark-text");
        model.isLightText = classes.contains("editor-light-text");

        if (classes.contains("text-right")) {
            model.horizontalAlignText = HORIZONTAL_ALIGN_RIGHT;
        } else if (classes.contains("text-center")) {
            model.horizontalAlignText = HORIZONTAL_ALIGN_CENTER;
        } else if (classes.contains("text-left")) {
            model.horizontalAlignText = HORIZONTAL_ALIGN_LEFT;
        }

        if (classes.contains("v-align-top")) {
            model.verticalAlignText = VERTICAL_ALIGN_TOP;
        } else if (classes.contains("v-align-middle")) {
            model.verticalAlignText = VERTICAL_ALIGN_MIDDLE;
        } else if (classes.contains("v-align-bottom")) {
            model.verticalAlignText = VERTICAL_ALIGN_BOTTOM;
        }

        if (classes.contains("cell-parametric")) {
            model.type = "parametric";

            model.parametric = {
                placeholder: cellElement.innerText,
                value      : null,
                parameters : mapDataAttributes
            };
        }

        model.isHideTopBorder    = classes.contains("hide-top-border");
        model.isHideBottomBorder = classes.contains("hide-bottom-border");
        model.isHideLeftBorder   = classes.contains("hide-left-border");
        model.isHideRightBorder  = classes.contains("hide-right-border");

        model.content = this.createModelElements(cellElement.childNodes);

        return model;
    }

    getBackgroundColorClass(classList) {
        let index, className;

        for (index = 0; index < classList.length; index++) {
            className = classList[index];

            if (className.indexOf("editor-bg-") === 0) {
                return className;
            }
        }

        return "";
    }
}

export default EditorItemModel;
