import "mithril";
import {connect} from "_/lib/midux";
import Component from "_/lib/Component";
import {translate} from "_/localizations";
import Button from "_/components/plugins/Button";
import {
    setCurrentQuestion,
    addTmpQuestion,
    removeCurrentQuestion,
    deleteTmpQuestion,
    requestDeleteQuestionnaire
} from "_/actions/questionnaire";
import {MODE_STEP_EDIT, TMP_QUESTION_ID} from "_/containers/questionnaire/constants";
import {FIELD_OF_OBJECT_OR_OWN_REGISTRY as OWN_FIELD} from "_/constants/questionsOfRelatedData";
import {FIELD_TEXT} from "_/constants/typesOfRegistryFIelds";
import turbo from "_/lib/turbo";
import {saveQuestion} from "_/service/questionnaire";

const mapStateToProps = state => ({
    step           : state.questionnaire.currentStep,
    steps          : state.questionnaire.steps,
    ownRegistry    : state.registry.ownRegistry,
    currentQuestion: state.questionnaire.currentQuestion,
    info           : state.currentInfo
});

const mapActionsToProps = {
    setCurrentQuestion,
    addTmpQuestion,
    removeCurrentQuestion,
    deleteTmpQuestion,
    requestDeleteQuestionnaire
};

class Footer extends Component {
    view() {
        const { isEdit = true } = this.attrs.info;
        const isEditQuestion    = !!this.attrs.currentQuestion.id;
        const isEditStep        = this.attrs.step.mode === MODE_STEP_EDIT;
        const isNoCurrentStep   = !this.attrs.step.id;

        return (
            <div className="turbo-questionnaire__footer">
                <div className="turbo-questionnaire__footer-inner">
                    {
                        isEditQuestion
                            ? [
                                <Button
                                    className="turbo-link color-red fs14"
                                    onclick={() => this.onCancel()}
                                >
                                    <Button.Icon className="icon-trash fs18"/>
                                    <Button.Text className="dashed-link">
                                        {translate("buttons.cancel")}
                                    </Button.Text>
                                </Button>,

                                <Button
                                    className="btn_size_sm btn_bg_blue btn_text_white turbo-link"
                                    onclick={() => saveQuestion()}
                                >
                                    <Button.Icon className="circle-plus fs18"/>
                                    <Button.Text>
                                        {translate("questionnaire.saveQuestion")}
                                    </Button.Text>
                                </Button>
                            ]
                            : [
                                <Button
                                    className="turbo-link color-red fs14"
                                    onclick={() => this.onDelete()}
                                    disabled={isEditStep || isNoCurrentStep || !isEdit}
                                >
                                    <Button.Icon className="icon-trash fs18"/>
                                    <Button.Text className="dashed-link">
                                        {translate("questionnaire.deleteQuestionnaire")}
                                    </Button.Text>
                                </Button>,

                                <Button
                                    className="btn_size_sm btn_bg_blue btn_text_white turbo-link"
                                    onclick={() => this.addNewQuestion()}
                                    disabled={isEditStep || isNoCurrentStep || !isEdit}
                                >
                                    <Button.Icon className="circle-plus fs18"/>
                                    <Button.Text>
                                        {translate("questionnaire.addQuestion")}
                                    </Button.Text>
                                </Button>
                            ]
                    }
                </div>
            </div>
        );
    }

    addNewQuestion() {
        const { step, ownRegistry } = this.attrs;
        const countQuestions        = this.getCountQuestions();
        const tmpQuestion           = {
            id        : TMP_QUESTION_ID,
            comments  : null,
            expression: null,
            stepId    : step.id,
            required  : true,
            name      : `${translate("questionnaire.prefixNewNameQuestion")} ${countQuestions + 1}`,
            type      : OWN_FIELD,
            value     : {
                bindingId   : ownRegistry.idBinding,
                registryId  : ownRegistry.id,
                typeOfField : FIELD_TEXT,
                defaultValue: "",
                view        : "700"
            }
        };


        this.attrs.actions.addTmpQuestion(tmpQuestion);
        this.attrs.actions.setCurrentQuestion(tmpQuestion);
    }

    getCountQuestions() {
        const { steps } = this.attrs;

        return steps.reduce((accumulator, step) => {
            return accumulator + (step.questions || []).length;
        }, 0);
    }

    onCancel() {
        this.attrs.actions.removeCurrentQuestion();
        this.attrs.actions.deleteTmpQuestion();
    }

    onDelete() {
        const { documentType, documentId } = this.attrs.info;

        turbo.plugins.modal.confirm(
            {
                title        : translate("questionnaire.warningMessage.titleDeleteQuestionnaire"),
                btnCancelText: translate("buttons.cancel"),
                btnAcceptText: translate("buttons.yes"),
                text         : translate("questionnaire.warningMessage.textDeleteQuestionnaire"),
                iconClasses  : "font-icon warning fs24 color-orange mr5"
            },
            () => {
                this.attrs.actions.requestDeleteQuestionnaire({ documentId, documentType });
            }
        );
    }
}

export default connect(mapStateToProps, mapActionsToProps)(Footer);
