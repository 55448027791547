import m from 'mithril';
import template from './template';

const CSS_CLASSES = {};

CSS_CLASSES.MENU         = 'turbo-component-menu';
CSS_CLASSES.MENU_OPEN    = 'turbo-component-menu_open';
CSS_CLASSES.MENU_WRAPPER = 'turbo-component-menu__wrapper';
CSS_CLASSES.MENU_LIST    = 'turbo-component-menu__list';
CSS_CLASSES.ITEM         = 'turbo-component-menu__item';
CSS_CLASSES.ROW          = 'turbo-component-menu__row';
CSS_CLASSES.ITEM_EXPAND  = 'turbo-component-menu__item_expand';
CSS_CLASSES.BUTTONS      = 'turbo-component-menu__buttons';

export default class TurboMenu {
    oncreate({ attrs, dom }) {
        this._element = dom;
        this._wrapper = dom.querySelector(`.${CSS_CLASSES.MENU_WRAPPER}`);
        this._list    = dom.querySelector(`.${CSS_CLASSES.MENU_LIST}`);

        this._subtractTop = +attrs.subtractTop || 0;

        this._clickOnDocument = (event) => {
            let parent = event.target.closest(`.${CSS_CLASSES.MENU}`);

            if (!parent || parent !== this._element) {
                this.hide();
            }
        }

        if (attrs.sticky) {
            this._initRelativePositioning()
        }
    }

    /**
     * Инициализация позиционирования меню относительно
     * своего offsetParent(родитель с position: relative;).
     */
    _initRelativePositioning() {
        let relative       = this._element.offsetParent,
            menuHeight     = this._element.getBoundingClientRect().height,
            startOffsetTop = this._element.offsetTop;

        document.addEventListener('scroll', () => {
            if (this._isElementInViewport(relative, this._subtractTop)) {
                this._relativePositioning({ relative, menuHeight, startOffsetTop });
            }

            if (this._isOpen()) {
                this._setItemsPosition();
            }
        });
    }

    /**
     * Реализует прилипание к родительскому элементу.
     *
     * При скролле позиционирует меню, чтобы меню было видно на экране
     * если видно родительский элемент(offsetParent).
     *
     * @param {object} options - параметры
     */
    _relativePositioning({ relative, menuHeight, startOffsetTop }) {
        let relativeRect = relative.getBoundingClientRect(),
            rectTop      = relativeRect.top - this._subtractTop,
            positiveTop  = Math.abs(rectTop),
            maxPos       = relativeRect.height - (menuHeight + startOffsetTop * 2),
            value        = positiveTop > maxPos ? maxPos : positiveTop;

        if (rectTop < 0) {
            this._element.style.transform = `translateY(${value}px)`;
        } else {
            this._element.style.transform = '';
        }
    }

    /**
     * Возвращает пункты меню в обычное состояние.
     * Если переданы элементы работает только с ними. Если не переданы учитывает
     * все существующие пункты в меню.
     *
     * @param {object} elements - HTML элементы
     */
    _resetItems(elements) {
        let parent   = this._element,
            selector = `.${CSS_CLASSES.ITEM_EXPAND}`,
            items    = elements || parent.querySelectorAll(selector);

        [].map.call(items, (item) => {
            this._collapseItem(item);
        });
    }

    /**
     * Сдвигает пунк меню, делая дополнительные кнопки видимыми.
     *
     * @param {object} item - Сдвигаемый элемент
     */
    _expandItem(item) {
        let row     = item.querySelector(`.${CSS_CLASSES.ROW}`),
            buttons = item.querySelector(`.${CSS_CLASSES.BUTTONS}`);

        item.classList.add(CSS_CLASSES.ITEM_EXPAND);

        row.style.transform = `translateX(-${buttons.offsetWidth}px)`;
    }

    /**
     * Задвигает пункт меню, делая дополнительные кнопки невидимыми.
     *
     * @param {object} item - Сдвигаемый элемент
     */
    _collapseItem(item) {
        let row = item.querySelector(`.${CSS_CLASSES.ROW}`);

        item.classList.remove(CSS_CLASSES.ITEM_EXPAND);

        row.style.transform = '';
    }

    /**
     * Отображает или скрывает кнопки пункта меню.
     * Скрывает остальные раскрытые пункты меню если такие имеются.
     *
     * @param {object} item - HTML элемент
     */
    _toggleItem(item) {
        let parent    = this._element,
            isExpand  = item.classList.contains(CSS_CLASSES.ITEM_EXPAND),
            items     = parent.querySelectorAll(`.${CSS_CLASSES.ITEM_EXPAND}`),
            restItems = [].filter.call(items, (entry) => entry !== item);

        if (restItems) {
            this._resetItems(restItems);
        }

        if (isExpand) {
            this._collapseItem(item);
        } else {
            this._expandItem(item);
        }
    }

    /**
     * Возвращает высоту обёртки пунктов меню.
     *
     * @returns {number}
     */
    _getWrapperHeight() {
        let height;

        this._wrapper.style.height = 'auto';
        height                     = this._wrapper.offsetHeight;
        this._wrapper.style.height = '';

        return height;
    }

    /**
     * Возвращает объект с ключами top и bottom, значения ключей означают
     * на сколько нужно сдвинуть пункты меню.
     *
     * @returns {object}
     */
    _getWrapperShift() {
        let elementRect = this._element.getBoundingClientRect(),
            spaceBottom = window.innerHeight - elementRect.bottom,
            spaceTop    = elementRect.top - this._subtractTop,
            top         = spaceTop > 0 ? spaceTop : 0,
            bottom      = spaceBottom > 0 ? spaceBottom : 0,
            halfHeight  = (this._getWrapperHeight() - elementRect.height) / 2;

        return {
            top:    top < halfHeight ? halfHeight - top : 0,
            bottom: bottom < halfHeight ? halfHeight - bottom : 0
        }
    }

    /**
     * Позиционирует список пунктов меню таким образом
     * чтобы их было видно на экране вне зависимости от того в какой части находится кнопка меню.
     * Однако если кнопку на экране не видно сбрасывает пизиционирование.
     */
    _setItemsPosition() {
        if (this._isElementInViewport(this._element, this._subtractTop)) {
            let { top, bottom } = this._getWrapperShift();

            if (top) {
                this._list.style.transform = `translateY(${Math.round(top)}px)`;
            } else if (bottom) {
                this._list.style.transform = `translateY(-${Math.round(bottom)}px)`;
            }
        } else {
            this._list.style.transform = '';
        }
    }

    _resetItemsPosition() {
        this._list.style.transform = '';
    }

    _isOpen() {
        return this._element.classList.contains(CSS_CLASSES.MENU_OPEN);
    }

    /**
     * Проверяет видно ли элементе на экране.
     * TRUE - Если элемент видно.
     *
     * @param {object} element - HTML элемент
     * @param {number} subtractTop - учитывается при вычислениях
     *
     * @returns {boolean}
     */
    _isElementInViewport(element, subtractTop) {
        let pageYOffset    = window.pageYOffset,
            rect, absoluteTop, absoluteBottom, absoluteHeight;

        if (!element || !element.parentNode) {
            return false;
        }

        rect           = element.getBoundingClientRect();
        absoluteTop    = rect.top + pageYOffset;
        absoluteBottom = rect.bottom + pageYOffset;
        absoluteHeight = pageYOffset + window.innerHeight;

        return (
            absoluteHeight > absoluteTop
            &&
            pageYOffset + (subtractTop || 0) < absoluteBottom
        );
    }

    /**
     * Отображает меню.
     */
    show() {
        this._element.classList.add(CSS_CLASSES.MENU_OPEN);

        this._setItemsPosition();

        document.addEventListener('click', this._clickOnDocument);
    }

    /**
     * Скрывает меню.
     */
    hide() {
        this._element.classList.remove(CSS_CLASSES.MENU_OPEN);

        this._resetItems();
        this._resetItemsPosition();

        document.removeEventListener('click', this._clickOnDocument);
    }

    /**
     * Скрывает или отображает меню.
     */
    toggleAction() {
        if (this._isOpen()) {
            this.hide();
        } else {
            this.show();
        }
    }

    /**
     * Срабатывает при нажатии на пункт меню.
     *
     * @param {object} event - событие
     * @param {object} vnode - элемент меню
     */
    itemAction(event, vnode) {
        let callback    = vnode.attrs.onclick,
            hasChildren = vnode.children && vnode.children.length;

        if (hasChildren) {
            this._toggleItem(event.target.closest(`.${CSS_CLASSES.ITEM}`));
        } else {
            this.hide();
        }

        if (callback) {
            callback();
        }
    }

    view({ attrs, children }) {
        return template({
            attrs,
            children,
            toggleAction: () => this.toggleAction(),
            itemAction:   (e, vnode) => this.itemAction(e, vnode)
        });
    }
}
