import m from "mithril";
import {translate} from 'localizations';
import Button from "components/plugins/Button";
import {getListPartner, cloneDocument} from "./promises";
import {connect} from "_/lib/midux";
import DropDownList from "_/components/plugins/DropDownList";
import { showProcess, hideProcess } from 'lib/progressbar';

const mapStateToProps = state => ({});

const mapActionsToProps = {};

class CloneDocument {
    oninit({ attrs }) {
        this.id               = attrs.documentId;
        this.basedOn          = attrs.basedOn;
        this.isCurrentPartner = true;
    }

    view() {
        return m.fragment({}, [
            <div className="modal__header bg-blue color-white">
                <div className="font-icon cancel modal-close"/>
                <span>
                    {
                        this.basedOn
                            ? translate("documentSection.basedOnDocumentTitle")
                            : translate("documentSection.cloneDocumentTitle")
                    }
                </span>
            </div>,
            <div className="modal__body">
                <div className='row mb15'>
                    <label className="checkbox-radio">
                        <input
                            type="radio"
                            checked={this.isCurrentPartner}
                            onchange={event => this.setCurrent(event)}
                        />
                        <i className="mr5"/>
                        <span>
                            {
                                this.basedOn
                                    ? translate("documentSection.basedOnWithCurrentPartner")
                                    : translate("documentSection.cloneWithCurrentPartner")
                            }
                        </span>
                    </label>
                    <label className="checkbox-radio">
                        <input
                            type="radio"
                            checked={!this.isCurrentPartner}
                            onchange={event => this.setCurrent(event)}
                        />
                        <i className="mr5"/>
                        <span>
                            {
                                this.basedOn
                                    ? translate("documentSection.basedOnWithOtherPartner")
                                    : translate("documentSection.cloneWithOtherPartner")
                            }
                        </span>
                    </label>
                </div>
                <Choose>
                    <When condition={!this.isCurrentPartner}>
                        <div className='row'>
                            <DropDownList
                                data-popup-key={"clone-document"}
                                promise= {getListPartner}
                                isServerSearch={true}
                                fieldView="title"
                                fieldsSearch={["title", "inn", "ogrn", "delegate", "shortcode"]}
                                fieldKey={'id'}
                                countShowItem={5}
                                onChange={item => this.setPartner(item.id)}
                                isCanEmpty={true}
                                value={''}
                            />
                        </div>
                    </When>
                </Choose>
            </div>,

            <div className="modal__footer bg-grey text-right">
                <Button className="turbo-button__size_md dotted-link mr20 fs14 p0 modal-close color-blue">
                    {translate("changeDocumentStatus.textButtonCancel")}
                </Button>
                <Button
                    className="turbo-button__size_md turbo-button__bg_orange turbo-button__text_white"
                    onclick={() => this._save()}
                    disabled={!this.partner && !this.isCurrentPartner}
                >
                    {translate("buttons.continue")}
                </Button>
            </div>
        ]);
    }

    setSearchData(data) {
        this.searchData = data
    }

    setCurrent(e) {
        e.preventDefault();
        this.isCurrentPartner = !this.isCurrentPartner;
        this.partner = null
    }

    setPartner(id) {
        this.partner = id
    }

    _save() {
        let data = {
                partnerId: this.partner
            },
            nameProcess = 'document:clone';

        showProcess(nameProcess);

        cloneDocument(this.id, data, this.basedOn).then((res) => {
            hideProcess(nameProcess);

            if (res.success) {
                turbo.plugins.modal.confirm(
                    {
                        width:         400,
                        title:         translate("documentSection.documentCloneSuccess"),
                        text:          translate("documentSection.continueToNewDocument"),
                        iconClasses:   'success color-green fs20 mr5',
                        btnAcceptText: 'Перейти',
                        btnCancelText: 'Отмена',
                        afterLoading() {
                            turbo.plugins.modal.close(document.querySelector('.cloneDocument'));
                        }
                    },
                    function() {
                        window.location.href = res.url;
                    }
                );
            } else {
                turbo.plugins.modal.alert({
                    width:        400,
                    title:        translate("documentSection.documentCloneError"),
                    iconClasses:  'warning color-red fs20 mr5',
                    closeTimeout: 2000
                });
            }
        })
    }

    open() {
        turbo.plugins.modal.open({
            closeOnOverlayClick: false,
            vnode              : this,
            class              : "cloneDocument",
            width              : 500
        });
    }
}

export default connect(mapStateToProps, mapActionsToProps)(CloneDocument);
