import m from "mithril";
import classNames from "classnames";
import Component from 'lib/Component';
import {translate} from 'localizations';
import Button from "components/plugins/Button";

class ValuesListBody extends Component {
    view() {
        const { data, editMode } = this.attrs;

        let DnD = turbo.plugins.TurboDnD;

        return (
            <div className="values-list__body">
                {
                    data.map((item, index) => (
                        <DnD.DragAndDropItem
                            className="turbo-draggable-indicator"
                            with="valueOfList"
                            name="valueOfList"
                            ondragstart={ (event, dataTransfer) => this.onDragStart(dataTransfer, index) }
                            ondroptop={ (event, dataTransfer) => this.onDropTop(dataTransfer, index) }
                            ondropbottom={ (event, dataTransfer) => this.onDropBottom(dataTransfer, index) }
                        >
                            <div className="values-list__record m5">
                                <span className="values-list__value-text ml15">{item}</span>
                                <Button
                                    onclick={() => this.onEditMode(index)}
                                    disabled={editMode}
                                    title={translate("buttons.edit")}
                                >
                                    <Button.Icon className="block pencil fs16 color-blue"/>
                                </Button>
                                <Button
                                    disabled={editMode}
                                    title={translate("buttons.remove")}
                                    onclick={() => this.onDelete(index)}
                                >
                                    <Button.Icon className="block icon-trash fs16 color-red"/>
                                </Button>
                            </div>
                        </DnD.DragAndDropItem>
                    ))
                }
            </div>
        );
    }

    onEditMode(index) {
        const { onEditMode } = this.attrs;

        if (typeof onEditMode === "function") {
            onEditMode(index);
        }
    }

    onDragStart(dataTransfer, index) {
        dataTransfer.setData("index", index);
    }

    onDropTop(dataTransfer, index) {
        const {onMoveBefore} = this.attrs;

        if (typeof onMoveBefore === "function") {
            onMoveBefore(dataTransfer.getData("index"), index);
        }
    }

    onDropBottom(dataTransfer, index) {
        const {onMoveAfter} = this.attrs;

        if (typeof onMoveAfter === "function") {
            onMoveAfter(dataTransfer.getData("index"), index);
        }
    }

    onDelete(index) {
        const {onDelete} = this.attrs;

        if (typeof onDelete === "function") {
            onDelete(index);
        }
    }
}

export default ValuesListBody;
