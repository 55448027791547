import "mithril";
import {translate} from "localizations";
import Component from "lib/Component";
import Button from "components/plugins/Button";
import TextEllipsis from "components/plugins/TextEllipsis";

//Корень для локализации
const ROOT = "listObjectOfRegistryByLink";

class Scheme extends Component {
    view() {
        const { scheme = {} } = this.attrs;

        return (
            <div className="tile-list__item-flex tile-list__item p0">
                <div className="content-flex">
                    <TextEllipsis>{scheme.name}</TextEllipsis>
                    <div className="content-flex__description">
                        {scheme.description}
                    </div>
                </div>
                <div className="buttons-flex">
                    <Button
                        className="turbo-button__size_md color-blue fs20"
                        onclick={() => this.openWindowEditScheme()}
                    >
                        <Button.Icon className="pencil block"/>
                    </Button>
                    <Button
                        className="turbo-button__size_md color-red fs20"
                        onclick={() => this.deleteScheme(scheme)}
                    >
                        <Button.Icon className="icon-trash block"/>
                    </Button>
                </div>
            </div>
        );
    }

    openWindowEditScheme() {
        const { scheme = {}, onOpenWindowScheme } = this.attrs;

        if (typeof onOpenWindowScheme === "function") {
            onOpenWindowScheme(scheme);
        }
    }

    deleteScheme(scheme) {
        const onDelete = this.attrs.onDelete;

        let text = `${translate(`${ROOT}.warning.deleteSchemeText`)} "${scheme.name}" ?`;

        turbo.plugins.modal.confirm(
            {
                title        : translate(`${ROOT}.warning.deleteSchemeTitle`),
                btnCancelText: translate("buttons.cancel"),
                btnAcceptText: translate("buttons.yes"),
                text,
                iconClasses  : "font-icon warning fs24 color-orange mr5"
            },
            () => {
                if (typeof onDelete === "function") {
                    onDelete(scheme.id);
                }
            }
        );
    }
}

export default Scheme;
