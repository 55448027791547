import m from 'mithril';
const MIN_WIDTH = 350;

export default class Panel {
    oncreate(vnode) {
        let element = vnode.dom,
            self    = this;

        if (element && element.nodeType === 1) {
            let resizeTrigger = element.querySelector(".toolbar__left-panel-resize-trigger");

            this.setResizeSettings(element, resizeTrigger);
        }

        window.addEventListener('resize', function() {
            if (element && element.nodeType === 1) {
                let resizeTrigger = element.querySelector(".toolbar__left-panel-resize-trigger");

                self.setResizeSettings(element, resizeTrigger);
            }
        })
    }

    onupdate() {
        const
            openLeftPanel = document.querySelector(".toolbar__left-panel_open"),
            widthElement  = openLeftPanel ? getComputedStyle(openLeftPanel).width : "";

        this.resizePageWrapper(widthElement);
    }

    isRender(item) {
        return item.hasOwnProperty('isRender') ? item.isRender : true;
    }

    view({ attrs, children }) {
        let { isActive } = attrs;

        this.scale = window.devicePixelRatio;
        return (
            <If condition={this.isRender(attrs)}>
                <div className={`toolbar__left-panel ${isActive ? "toolbar__left-panel_open" : ""}`}
                    style={{width:localStorage.getItem("toolbar__left-panel_open-width")}}>
                    <div className="toolbar__left-panel-resize-trigger"/>
                    <div className="toolbar__left-panel-body">
                        {children}
                    </div>
                </div>
            </If>
        )
    }

    setResizeSettings(element, trigger) {
        const
            minWidthLeftPanel       = MIN_WIDTH,
            widthBody               = parseInt(getComputedStyle(document.body).width, 10),
            pageWrapperElement      = document.querySelector(".page-layer__wrapper"),
            pageContentElement      = pageWrapperElement.querySelector(".page-layer__block"),
            pageContentBox          = getComputedStyle(pageContentElement),
            pageWrapperBox          = getComputedStyle(pageWrapperElement),
            widthPageContent        = parseInt(pageContentBox.width, 10),
            paddingLeftPageWrapper  = parseInt(pageWrapperBox.paddingLeft, 10),
            paddingRightPageWrapper = parseInt(pageWrapperBox.paddingRight, 10),
            maxWidthLeftPanel       = widthBody - (widthPageContent + paddingLeftPageWrapper + paddingRightPageWrapper);

        turbo.resize({
            resizableEl: element,
            resizingEl : trigger,
            direction  : "EW",
            maxWidth   : maxWidthLeftPanel,
            minWidth   : minWidthLeftPanel,
            callback   : this.resizePageWrapper
        });
    }

    resizePageWrapper(width) {
        const pageWrapperElement = document.querySelector(".page-layer__wrapper");

        if (pageWrapperElement.style.marginLeft !== width) {
            setTimeout(() => m.redraw(), 0);
        }

        localStorage.setItem("toolbar__left-panel_open-width", parseInt(width) >= MIN_WIDTH ? width : MIN_WIDTH);
        pageWrapperElement.style.marginLeft = width;
    }
}
