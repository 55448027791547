import "mithril";
import Component from "_/lib/Component";
import turbo from "_/lib/turbo";
import ChooseSimple from "_/components/common/ChooseSimple";
import {connect} from "_/lib/midux";
import {setCurrentData, setCurrentType} from "_/actions/relatedData";

const MODAL_CLASS = "related-data-modal";

const mapStateToProps = state => ({
    questions  : state.relatedData.questions,
    currentType: state.relatedData.currentType
});

const mapActionsToProps = {
    setCurrentType,
    setCurrentData
};

class Label extends Component {
    oncreate() {
        const { localStorageName, type } = this.attrs;

        let saveSettings, saveType;

        if (type) {
            this.attrs.actions.setCurrentType(type);
        } else if (localStorageName) {
            saveSettings = turbo.loadJsonFromLocalStorage(localStorageName);
            saveType     = saveSettings.type;

            if (saveType) {
                this.attrs.actions.setCurrentType(saveType);
            }
        }
    }

    view() {
        const { currentType } = this.attrs;

        return (
            <div className="modal__label modal__label_bordered bg-grey p15">
                <div className="form-item">
                    <ChooseSimple
                        data-popup-key="list-related-data"
                        data={this.attrs.questions}
                        modal={`.${MODAL_CLASS}`}
                        onChange={item => this.changeTypeOfData(item)}
                        isCanEmpty={false}
                        countShowItems={7}
                        value={currentType}
                    />
                </div>
            </div>
        );
    }

    changeTypeOfData(item) {
        const { listeners } = this.attrs;

        this.attrs.actions.setCurrentType(item.id);
        this.attrs.actions.setCurrentData({});

        //TODO костыль пока не перепишем компонент дерева
        if (listeners && typeof listeners.changeTypeRelatedData === "function") {
            listeners.changeTypeRelatedData(item.id);
        }
    }
}

export default connect(mapStateToProps, mapActionsToProps)(Label);
