import m from "mithril";
import {translate} from 'localizations';
import Button from "components/plugins/Button";
import ChooseSimple from "_/components/common/ChooseSimple";
import {getDocumentInfo, setDocumentMain, saveDocumentCategory} from "./promises";

const COUNT_SHOW_ITEM = 6;

class DocumentCategory {
    constructor(data) {
        this._data = data;
        this.selectedCategory = this._data.document.categoryId;
        this.selectItems = this.getSelectItems()
    }

    view() {
        let isDisableSave =  true;

        return m.fragment({}, [
            <div className="modal__header bg-blue color-white">
                <div className="font-icon cancel modal-close"/>
                <span>
                    { translate("documentCategory.title") }
                </span>
            </div>,
            <div className="modal__body">

                <table>
                    <thead>
                        <tr>
                            <th>{translate("documentCategory.documentName")}</th>
                            <th>{translate("documentCategory.categoryName")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><div className="form-item form-item_with-controls">
                                <input
                                    type="text"
                                    value={this._data.document.title}
                                    oninput={event => this.onChange(event.target.value, "title")}
                                />
                            </div>
                            </td>
                            <td><ChooseSimple
                                data-popup-key="add_scheme"
                                data={this.selectItems}
                                onChange={item => this.changeCategory(item)}
                                countShowItems={COUNT_SHOW_ITEM}
                                isCanEmpty={false}
                                value={this.selectedCategory}
                            /></td>
                        </tr>
                    </tbody>
                    <thead>
                        <tr>
                            <th>{translate("documentCategory.documentPurpose")}</th>
                            <th>{translate("documentCategory.documentStatus")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{this._data.purpose}</td>
                            <td>{this._data.statusLabel}</td>
                        </tr>
                    </tbody>
                    <thead>
                        <tr>
                            <th>{translate("documentCategory.thisDocument")}</th>
                            <th>{translate("documentCategory.documentType")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{this._data.document.status > 0 ? translate("documentCategory.documentVisible") : translate("documentCategory.documentInvisible")}</td>
                            <td>
                                {this._data.document.main ? translate("documentCategory.basic") : translate("documentCategory.addon")}
                                {this._data.document.main ? null
                                    : <div>
                                        {translate("documentCategory.basic")}:

                                        <a href={
                                            // eslint-disable-next-line camelcase
                                            Routing.generate('turbo_document_matching_item', {documentId: this._data.mainDocument.id, agency_id: this._data.agencyId })}>
                                            {this._data.mainDocument.title}
                                        </a>
                                        {this._data.availableChangeFormation
                                            ? <div className="fs12">
                                                <a href="javascript:void(0)" onclick={() => this.setMain()}>{ translate("documentCategory.setMain")}</a>
                                            </div>
                                            : null }
                                    </div>}
                            </td>
                        </tr>
                    </tbody>
                    <thead>
                        <tr>
                            <th>{translate("documentCategory.documentNumber")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><div className="form-item form-item_with-controls">
                                <input
                                    type="text"
                                    value={this._data.document.number}
                                    oninput={event => this.onChange(event.target.value, "number")}
                                />
                            </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>,

            <div className="modal__footer bg-grey text-right">
                <Button className="turbo-button__size_md dotted-link mr20 fs14 p0 modal-close color-blue">
                    {translate("documentCategory.textButtonCancel")}
                </Button>
                <Button
                    className="turbo-button__size_md turbo-button__bg_orange turbo-button__text_white"
                    onclick={() => this.setCategory()}
                    title={isDisableSave ? translate("documentCategory.warningSave") : ""}
                >
                    {translate("documentCategory.textButtonSave")}
                </Button>
            </div>
        ]);
    }

    setCategory() {
        let id       = this._data.document.id,
            agencyId = this._data.agencyId,
            title    = this._data.document.title,
            number   = this._data.document.number;

        saveDocumentCategory(id, agencyId, this.selectedCategory, title, number)
            .then(result => {
                turbo.plugins.modal.close(document.querySelector('.addEditDocumentFragmentModel'))
            });
    }

    getInfo(id, currentAgencyId) {
        getDocumentInfo(id, currentAgencyId)
            .then(result => {
                this._data = result.data;
            });
    }

    getSelectItems() {
        let dta = this._data.documentCategories;

        return Object.keys(dta).map((key) => ({id: dta[key].id, name: dta[key].nominative}));
    }

    changeCategory(item) {
        this.selectedCategory = item.id
    }

    setMain() {
        setDocumentMain(this._data.document.id, this._data.agencyId)
            .then(result => {
                this.getInfo(this._data.document.id, this._data.agencyId)
            });
    }

    onChange(val, type) {
        this._data.document[type] = val;
    }

    open() {
        turbo.plugins.modal.open({
            closeOnOverlayClick: false,
            vnode              : this,
            class              : "addEditDocumentFragmentModel"
        });
    }
}

export default DocumentCategory;
