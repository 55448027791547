import "mithril";
import Component from "_/lib/Component";
import {translate} from "_/localizations";
import NumberInput from "_/components/dialogs/printProperties/components/NumberInput";

class PaperSettings extends Component {
    view() {
        const { controlsSettings, printSettings }                  = this.attrs;
        const { marginStep = 0.1, marginMin = 0.5, marginMax = 5 } = controlsSettings;

        let statusApproved = turbo.lib.checkApprovedDocumentTemplate();

        return [
            <div className="print-properties__body-row">
                <div className="print-properties__body-item">
                    <div className="text-bold fs18">
                        {translate("printProperties.pageOrientation")}
                    </div>
                </div>
            </div>,

            <div className="print-properties__body-row">
                <div className="print-properties__body-item">
                    <label className="checkbox-radio_button print-properties__interval-item">
                        <input
                            type="radio"
                            name="landscapeOrientation"
                            checked={!printSettings.landscapeOrientation}
                            onchange={() => this.onChangeRadioDefaultValue("landscapeOrientation")}
                            disabled={statusApproved}
                        />
                        <div className="bg-blue color-blue">
                            <i className="font-icon file v-align-middle fs30 mr5"/>
                            <span className="v-align-middle fs12">
                                {translate("printProperties.portait")}
                            </span>
                        </div>
                    </label>
                </div>

                <div className="print-properties__body-item">
                    <label className="checkbox-radio_button print-properties__interval-item">
                        <input
                            type="radio"
                            name="landscapeOrientation"
                            checked={printSettings.landscapeOrientation}
                            onchange={() => this.onChangeRadioDefaultValue("landscapeOrientation")}
                            disabled={statusApproved}
                        />
                        <div className="bg-blue color-blue">
                            <i className="font-icon v-file v-align-middle fs30 mr5"/>
                            <span className="v-align-middle fs12">
                                {translate("printProperties.landscape")}
                            </span>
                        </div>
                    </label>
                </div>
            </div>,

            <div className="print-properties__body-row">
                <div className="print-properties__body-item">
                    <div className="text-bold fs18">
                        {translate("printProperties.pageMargin")}
                    </div>
                </div>
            </div>,

            <div className="print-properties__body-row">
                <div className="print-properties__body-item">
                    <label className="print-properties__interval-item">
                        <span className="pb5 fs14">
                            {translate("printProperties.marginTop")}
                        </span>

                        <NumberInput
                            step={marginStep}
                            min={marginMin}
                            max={marginMax}
                            prefix={'см'}
                            value={printSettings.marginTop}
                            onChange={value => this.onChangeDefaultValue(value, "marginTop")}
                            disabled={statusApproved}
                        />
                    </label>
                </div>

                <div className="print-properties__body-item">
                    <label className="print-properties__interval-item">
                        <span className="pb5 fs14">
                            {translate("printProperties.marginBottom")}
                        </span>

                        <NumberInput
                            step={marginStep}
                            min={marginMin}
                            max={marginMax}
                            prefix={'см'}
                            value={printSettings.marginBottom}
                            onChange={value => this.onChangeDefaultValue(value, 'marginBottom')}
                            disabled={statusApproved}
                        />
                    </label>
                </div>
            </div>,

            <div className="print-properties__body-row">
                <div className="print-properties__body-item">
                    <label className="print-properties__interval-item">
                        <span className="pb5 fs14">
                            {translate("printProperties.marginLeft")}
                        </span>

                        <NumberInput
                            step={marginStep}
                            min={marginMin}
                            max={marginMax}
                            prefix={'см'}
                            value={printSettings.marginLeft}
                            onChange={value => this.onChangeDefaultValue(value, "marginLeft")}
                            disabled={statusApproved}
                        />
                    </label>
                </div>

                <div className="print-properties__body-item">
                    <label className="print-properties__interval-item">
                        <span className="pb5 fs14">
                            {translate("printProperties.marginRight")}
                        </span>

                        <NumberInput
                            step={marginStep}
                            min={marginMin}
                            max={marginMax}
                            prefix={'см'}
                            value={printSettings.marginRight}
                            onChange={value => this.onChangeDefaultValue(value, "marginRight")}
                            disabled={statusApproved}
                        />
                    </label>
                </div>
            </div>
        ];
    }

    onChangeDefaultValue(val, type) {
        const { onChangeValue } = this.attrs;

        if (typeof onChangeValue === "function") {
            onChangeValue(val, type);
        }
    }

    onChangeRadioDefaultValue(type) {
        const { onChangeRadioValue } = this.attrs;

        if (typeof onChangeRadioValue === "function") {
            onChangeRadioValue(type);
        }
    }
}

export default PaperSettings;
