import m from "mithril";
import classNames from "classnames";
import Component from 'lib/Component';
import {translate} from 'localizations';
import {ENTER, S} from "constants/keysCode";
import Button from "components/plugins/Button";
import {SINGLE_MODE, MULTI_MODE} from "../constants";

class ValuesListTop extends Component {
    oninit() {
        this.value          = "";
        this.isGetEditValue = false;
        this.saveAction     = this.onKeyDownSave.bind(this);

        document.addEventListener("keydown", this.saveAction);
    }

    onremove() {
        document.removeEventListener("keydown", this.saveAction);
    }

    view() {
        const { addMode, editMode, editValue } = this.attrs;

        let classesButtonModeIcon, classesButtonModifyIcon, buttonModifyTitle,
            formItemClasses;

        if (editMode && !this.isGetEditValue) {
            this.value          = editValue;
            this.isGetEditValue = true;
        }

        classesButtonModeIcon = classNames(
            "fs16 v-align-middle",
            {
                "single-value": addMode === MULTI_MODE,
                "multi-values": addMode === SINGLE_MODE
            }
        );

        classesButtonModifyIcon = classNames(
            "fs16 v-align-middle",
            { save: editMode },
            { "circle-plus": !editMode }
        );

        if (editMode) {
            buttonModifyTitle = translate("settingValuesList.saveValue");
        } else if (addMode === SINGLE_MODE) {
            buttonModifyTitle = translate("settingValuesList.addValue");
        } else {
            buttonModifyTitle = translate("settingValuesList.addValues");
        }

        formItemClasses = classNames(
            "form-item form-item_with-controls",
            { "no-border": addMode === MULTI_MODE }
        );

        setTimeout(
            () => { 
                if (document && document.getElementById("settingValuesList")) {
                    document.getElementById("settingValuesList").focus() 
                }
            },
            500
        )

        return (
            <div className="values-list__top">
                <Button
                    className="turbo-button__size_md turbo-button__bg_blue turbo-button__text_white"
                    title={
                        addMode === SINGLE_MODE
                            ? translate("settingValuesList.changeModeMulti")
                            : translate("settingValuesList.changeModeSingle")
                    }
                    onclick={() => this.changeAddMode()}
                    disabled={editMode}
                >
                    <Button.Icon className={classesButtonModeIcon}/>
                </Button>
                <div className={formItemClasses}>
                    {
                        addMode === SINGLE_MODE
                            ? (
                                <input
                                    oncreate={vnode => this.setFocus(vnode.dom)}
                                    onupdate={vnode => this.setFocus(vnode.dom)}
                                    placeholder={translate("settingValuesList.inputPlaceholder")}
                                    value={this.value}
                                    oninput={e => this.onChangeValue(e)}
                                    onkeydown={e => this.onKeyDownInput(e)}
                                    id={'settingValuesList'}
                                />
                            )
                            : (
                                <textarea
                                    oncreate={vnode => this.setFocus(vnode.dom)}
                                    onupdate={vnode => this.setFocus(vnode.dom)}
                                    placeholder={translate("settingValuesList.textAreaPlaceholder")}
                                    value={this.value}
                                    oninput={e => this.onChangeValue(e)}
                                    onkeydown={e => this.onKeyDownTextArea(e)}
                                    id={'settingValuesList'}
                                />
                            )
                    }
                    {
                        this.value
                            ? (
                                <Button
                                    className="item-controls"
                                    title={translate("buttons.clear")}
                                    onclick={() => this.clearText()}
                                >
                                    <Button.Icon className="block icon-trash fs16 color-red"/>
                                </Button>
                            )
                            : ""
                    }
                </div>
                <Button
                    className="turbo-button__size_md turbo-button__bg_blue turbo-button__text_white"
                    title={buttonModifyTitle}
                    onclick={() => editMode ? this.editValue() : this.addValues()}
                >
                    <Button.Icon className={classesButtonModifyIcon}/>
                </Button>
                {
                    editMode
                        ? (
                            <Button
                                className="turbo-button__size_md turbo-button_border color-red ml5"
                                title={translate("buttons.cancel")}
                                onclick={() => this.cancelEdiValue()}
                            >
                                <Button.Icon className="circle-close fs16 v-align-middle"/>
                            </Button>
                        )
                        : ""
                }
            </div>
        );
    }

    setFocus(element) {
        element.focus();
    }

    onChangeValue(e) {
        this.value = e.target.value;
    }

    changeAddMode() {
        const { onChangeAddMode } = this.attrs;

        if (typeof onChangeAddMode === "function") {
            onChangeAddMode();
        }
    }

    addValues() {
        const { onAdd } = this.attrs;

        let isSuccess = true;

        if (typeof onAdd === "function") {
            isSuccess = onAdd(this.value);
        }

        if (isSuccess) {
            this.value = "";
        }
    }

    editValue() {
        const { onEdit, editMode } = this.attrs;

        let isSuccess = true;

        if (!editMode || turbo.isEmpty(this.value)) {
            return true;
        }

        if (typeof onEdit === "function") {
            isSuccess = onEdit(this.value);
        }

        if (isSuccess) {
            this.value          = "";
            this.isGetEditValue = false;
        }
    }

    cancelEdiValue() {
        const { onCancelEdit } = this.attrs;

        if (typeof onCancelEdit === "function") {
            onCancelEdit();
        }

        this.value          = "";
        this.isGetEditValue = false;
    }

    onKeyDownInput(e) {
        const { editMode } = this.attrs;

        let keyCode = e.which || e.keyCode;

        if (keyCode === ENTER) {
            e.preventDefault();

            if (editMode) {
                this.editValue();
            } else {
                this.addValues()
            }
        }

        return true;
    }

    onKeyDownTextArea(e) {
        let keyCode = e.which || e.keyCode;

        if (keyCode === ENTER && e.ctrlKey) {
            e.preventDefault();

            this.addValues()
        }

        return true;
    }

    onKeyDownSave(e) {
        let keyCode = e.which || e.keyCode;

        if (keyCode === S && e.ctrlKey) {
            e.preventDefault();

            this.editValue();
            m.redraw();
        }

        return true;
    }

    clearText() {
        this.value = "";
    }
}

export default ValuesListTop;
