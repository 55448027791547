import HandlerQueue from "lib/listeners/handlerQueue";

export default class EventListener {
    constructor(scope) {
        this._scope = scope || w;

        this.clear();
    }

    register() {
        let queues          = this._queues,
            argumentsLength = arguments.length,
            index, argument;

        for (index = 0; index < argumentsLength; index++) {
            argument         = arguments[index];
            queues[argument] = null;
        }

        return this;
    }

    unregister() {
        let queues          = this._queues,
            argumentsLength = arguments.length,
            index, argument;

        for (index = 0; index < argumentsLength; index++) {
            argument = arguments[index];

            delete queues[argument];
        }

        return this;
    }

    isRegistered(event) {
        return event in this._queues;
    }

    throwIfUnregistered(event) {
        if (!this.isRegistered(event)) {
            throw new Error('Event "' + event + '" is not registered');
        }
    }

    on(event, handler) {
        let queue = this._queues[event];

        this.throwIfUnregistered(event);

        if (!queue) {
            queue = new HandlerQueue(this._scope);

            this._queues[event] = queue;
        }

        queue.add(handler);

        return this;
    }

    off(event, handler) {
        let queue = this._queues[event];

        this.throwIfUnregistered(event);

        if (queue) {
            if (handler) {
                queue.remove(handler);
            } else {
                queue.clear();
            }
        }

        return this;
    }

    clear() {
        this._queues = {};
    }

    dispatch() {
        let args  = [].slice.call(arguments, 0),
            event = args.shift(),
            queue = this._queues[event];

        this.throwIfUnregistered(event);

        if (queue) {
            queue.dispatch.apply(queue, args);
        }

        return this;
    }
}
