import m from 'mithril';

export default class TargetTrigger {
    isRender(item) {
        return item.hasOwnProperty('isRender') ? item.isRender : true;
    }

    view({ attrs }) {
        let { item } = attrs;

        return (
            <If condition={ !!item && this.isRender(item) }>
                <div
                    className={ `toolbar__trigger toolbar__trigger_target ${item.className || ""}` }
                    onclick={ item.onclick }
                    title={item.title}
                >
                    <i className={ `font-icon ${item.icon || ""}` }/>
                    { item.label }
                </div>
            </If>
        )
    }
}
