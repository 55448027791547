import "mithril";
import {connect} from "_/lib/midux";
import Component from "_/lib/Component";
import {translate} from "_/localizations";
import {setCurrentQuestion} from "_/actions/questionnaire";

const mapStateToProps = state => ({
    question: state.questionnaire.currentQuestion
});

const mapActionsToProps = {
    setCurrentQuestion
};

class QuestionName extends Component {
    view() {
        const { question } = this.attrs;

        return (
            <div className="turbo-question__item-name">
                <div className="turbo-question__part-title">
                    {translate("questionnaire.nameQuestionTitle")}
                </div>
                <input
                    oncreate={({ dom }) => dom.select()}
                    type="text"
                    value={question.name}
                    oninput={event => this.onChangeName(event.target.value)}
                />
            </div>
        );
    }

    onChangeName(name) {
        this.attrs.actions.setCurrentQuestion({ name });
    }
}

export default connect(mapStateToProps, mapActionsToProps)(QuestionName);
