import {
    CHANGE_SEARCH_VALUE,
    SYS_CHANGE_SEARCH_VALUE,
    CLEAR_SEARCH_VALUE,
    SYS_CLEAR_SEARCH_VALUE
} from "types/common/searchInput";

export const changeSearchValue = value => ({
    type: CHANGE_SEARCH_VALUE,
    value
});

export const changeSearchSysValue = value => ({
    type: SYS_CHANGE_SEARCH_VALUE,
    value
});

export const clearSearchValue = () => ({
    type: CLEAR_SEARCH_VALUE
});

export const clearSearchSysValue = () => ({
    type: SYS_CLEAR_SEARCH_VALUE
});
