import m from 'mithril';
import createStream from 'mithril/stream';
import {setLocale} from 'localizations';
import registerPolyfills from 'lib/polyfills';
import turbo from 'lib/turbo';
import globalState from 'lib/globalState';
import {connectWampClient} from "lib/ws/ws";

const CURRENT_SCRIPT = document.currentScript || document.getElementById('common-entry');
const TWIG_STATE     = CURRENT_SCRIPT.innerText.trim() || "{}";

export default function() {
    // Инициализация js-полифилов.
    registerPolyfills();

    // Установка локализации.
    setLocale("ru");

    // Установка глобальных переменных, полученных из TWIG'а.
    globalState.set(JSON.parse(TWIG_STATE));

    try {
        connectWampClient();
    } catch (e) {
        console.error(e.message, e);
    }

    // Объявление глобальных переменных для поддержки кода, который не обрабатывается webpack'ом.
    window.m                 = m;
    window.m.stream          = createStream;
    window.turbo             = turbo;
    window.turbo.globalState = globalState;
}
