import "mithril";
import Component from "lib/Component";
import Button from "components/plugins/Button";
import ChooseSimple from "_/components/common/ChooseSimple";
import {translate} from "localizations";
import {getListLinks, getListFields } from "_/components/dialogs/listObjectsOfRegistryByLink/promises";

//Корень для локализации
const ROOT = "listObjectOfRegistryByLink";

class AddLink extends Component {
    oninit() {
        this.linkList = this.attrs.linkList || [];

        this.data = {
            name: ""
        };
    }

    view() {
        let bindings   = this.attrs.bindings || [],
            linkList   = this.linkList,
            type       = this.attrs.type,
            modalClass = this.attrs.modalClass;

        linkList = this.prepareField(linkList);

        return (
            <div>
                <div className="modal__header bg-blue color-white">
                    <div className="modal-close font-icon cancel"/>
                    <span>{translate(`${ROOT}.addLink.title`)}</span>
                </div>
                <div className="modal__body">
                    <div className="form-item">
                        <span>{translate(`${ROOT}.addLink.nameLink`)}</span>
                        <input
                            type="text"
                            oninput={e => this.changeValue("name", e.target.value)}
                            value={this.data.name}
                        />
                    </div>
                    <Choose>
                        <When condition={type !== "fromRegistry"}>
                            <div className="form-item mt10">
                                <span>{translate(`${ROOT}.addLink.bindings`)}</span>
                                <ChooseSimple
                                    data-popup-key="add_binding"
                                    data={bindings}
                                    modal={`.${modalClass}`}
                                    onChange={item => {
                                        this.changeValue("bindingId", item.id);
                                        this.changeValue("bindingName", item.name);
                                        this.loadLinkList(item.id);
                                    }}
                                    isCanEmpty={false}
                                />
                            </div>
                        </When>
                    </Choose>

                    <div className="form-item mt10">
                        <span>{translate(`${ROOT}.addLink.fieldLink`)}</span>
                        <ChooseSimple
                            data-popup-key="add_link"
                            data={linkList}
                            modal={`.${modalClass}`}
                            onChange={item => {
                                this.changeValue("fieldId", item.id);
                                this.changeValue("description", item.name);
                            }}
                            isCanEmpty={false}
                        />
                    </div>
                </div>
                <div className="modal__footer bg-grey text-right">
                    <Button className="turbo-button__size_md dashed-link color-blue p0 mr10 modal-close">
                        {translate("buttons.cancel")}
                    </Button>
                    <Button
                        className="turbo-button__size_md turbo-button__bg_orange turbo-button__text_white"
                        onclick={() => this.save()}
                    >
                        {translate("buttons.create")}
                    </Button>
                </div>
            </div>
        );
    }

    loadLinkList(bindingId) {
        getListLinks(bindingId)
            .then(result => {
                this.linkList = result.data;
            });
    }

    prepareField(fields = []) {
        return fields.map(field => {
            return {
                id  : field.fieldId || field.fieldLinkId,
                name: `${field.registryName} (${field.fieldName || field.fieldLinkName})`
            }
        });
    }

    changeValue(field, value) {
        this.data[field] = value;
    }

    save() {
        const { onSave, onValidate, modalClass } = this.attrs;

        let name = this.data.name.trim();

        this.data.name = name;

        if (typeof onValidate === "function") {
            if (!onValidate(name)) {
                return;
            }
        }

        this.data.description = `${this.data.bindingName}/${this.data.description}`;

        delete this.data.bindingName;

        if (typeof onSave === "function") {
            onSave(this.data);
        }

        turbo.plugins.modal.close(document.querySelector(`.${modalClass}`));
    }
}

export default AddLink;
