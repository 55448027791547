import "mithril";
import Component from "lib/Component";

class NumberInput extends Component {
    oninit() {
        this.options       = {};
        this.options.step  = this.attrs.step;
        this.options.min   = this.attrs.min;
        this.options.max   = this.attrs.max;
        this.options.small = this.attrs.small || false
    }

    view() {
        const { prefix, value, disabled = false } = this.attrs;

        return (
            <div className={this.options.small ? "form-item" : "form-item input-number"}>
                <div className="input-number__prefix">{prefix}</div>

                <input
                    type="text"
                    value={value}
                    oninput={(e) => this.onChangeValue(e.target.value)}
                    onkeydown={(e) => this.keydown(e, value)}
                    onmousewheel={(e) => this.mousewheel(e, value)}
                    onDOMMouseScroll={(e) => this.mousewheel(e, value)}
                    disabled={disabled}
                />

                <span
                    className="input-number__spin input-number__spin_plus"
                    onclick={() => disabled ? null : this.operation(value, '+')}
                />

                <span
                    className="input-number__spin input-number__spin_minus"
                    onclick={() => disabled ? null : this.operation(value, '-')}
                />
                <div className="input-number__reset hidden" data-action="reset" title="Очистить">
                    <i className="font-icon circle-close"/>
                </div>
            </div>
        );
    }


    keydown(e, value) {
        e.preventDefault();
        e.stopPropagation();

        if (e.keyCode === 38) {
            this.operation(value, '+');
        }

        if (e.keyCode === 40) {
            this.operation(value, '-');
        }
    }

    mousewheel(e, val) {
        let detail = e.detail,
            value  = val,
            delta  = e.wheelDelta;

        e.preventDefault();
        e.stopPropagation();

        if (typeof detail == 'number' && detail !== 0) {
            if (detail > 0) {
                this.operation(value, '-');
            } else if (detail < 0) {
                this.operation(value, '+');
            }
        } else if (typeof delta == 'number') {
            if (delta < 0) {
                this.operation(value, '-');
            } else if (delta > 0) {
                this.operation(value, '+');
            }
        }
    }

    operation(val, type) {
        let value     = +val || +this.attrs.min,
            step      = +this.attrs.step || 1,
            nextValue = type === '+' ? value + step : value - step;

        nextValue = nextValue.toFixed(1);

        if (this.validate(nextValue)) {
            value = this.corrector(nextValue);
        }
        this.changeValue(value)
    }

    onChangeValue(val) {
        let value = val;

        if (this.validate(value)) {
            value = this.corrector(value);
        }

        this.changeValue(value)
    }

    validate(value) {
        let isNum = !isNaN(value),
            isMin = value >= this.attrs.min,
            isMax = value <= this.attrs.max;

        return isNum && isMin && isMax;
    }

    corrector(val) {
        let value = val + "",
            array;

        value = value.replace(',', '.');
        array = value.split('.');

        array[0] = Number(array[0]).toFixed();

        value = +array[1] ? array.join('.') : array[0];

        return +value;
    }


    changeValue(val) {
        let { onChange } = this.attrs;

        if (typeof onChange === "function") {
            onChange(val);
        }
    }
}

export default NumberInput;
