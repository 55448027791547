import "mithril";
import {connect} from "_/lib/midux";
import turbo from "_/lib/turbo";
import Component from "_/lib/Component";
import {translate} from "_/localizations";
import Button from "_/components/plugins/Button";
import {
    requestDeleteQuestion,
    setCurrentQuestion,
    requestAddEditQuestion,
    removeParametricData,
    requestMoveQuestion
} from "_/actions/questionnaire";
import {MODE_STEP_EDIT} from "_/containers/questionnaire/constants";
import {FIELD_OF_OBJECT_OR_OWN_REGISTRY as OWN_FIELD} from "_/constants/questionsOfRelatedData";
import ContextMenu from "_/components/widgets/ContextMenu";
import classNames from "classnames";
import TextAreaForm from "_/components/dialogs/textAreaForm";
import {insertParameter, insertParameterElement, processParametricData} from "_/service/questionnaire";
import PopUpTooltip from "_/components/plugins/PopUpTooltip";
import RadioButtonGroup from "_/components/plugins/RadioButtonGroup";
import {Container} from "_/components/plugins/ScrollBar";

const TurboDnD = turbo.plugins.TurboDnD;

const mapStateToProps = state => ({
    typesOfFields  : state.referenceBooks.typesOfRegistryFieldsForQuestionnaire,
    currentQuestion: state.questionnaire.currentQuestion,
    currentStep    : state.questionnaire.currentStep,
    info           : state.currentInfo,
    parametric     : state.questionnaire.parametric
});

const mapActionsToProps = {
    setCurrentQuestion,
    requestDeleteQuestion,
    requestAddEditQuestion,
    removeParametricData,
    requestMoveQuestion
};

class QuestionView extends Component {
    oninit() {
        this.idExpressionMenu = null;
        this.position         = null;

        document.addEventListener("click", () => this.hideExpressionMenu());
        document.body.addEventListener("mousedown", this.mouseDownAction.bind(this));
    }

    view() {
        const { isEdit = true }    = this.attrs.info;
        const { data, parametric } = this.attrs;
        const { type }             = data;
        const nameTypeOfQuestion   = this.getNameTypeOfQuestion();
        const nameTypeOfField      = this.getNameTypeOfField();
        const nameRelatedData      = this.getNameRelatedData();
        const isEditQuestion       = !!this.attrs.currentQuestion.id;
        const isEditStep           = this.attrs.currentStep.mode === MODE_STEP_EDIT;
        const isEditMode           = isEditStep || isEditQuestion;
        const logicalIconClasses   = classNames(
            "block fs18",
            { settings: !data.expression },
            { "icon-settings-filled": !!data.expression },
            { "color-grey": !data.expression },
            { "color-purple": !!data.expression }
        );
        const commentsIconClasses  = classNames(
            "block icon-chat fs18",
            { "color-blue": !!data.comments },
            { "color-grey": !data.comments }
        );
        const titleComments        = data.comments
            ? data.comments
            : `${translate("questionnaire.commentQuestion")} «${data.name}»`;

        data.questionStepId = this.attrs.currentStep.id;

        return (
            <TurboDnD.DragAndDropItem
                className={`turbo-question__item tile-list__item ${isEditMode ? "disabled" : ""}`}
                with="question"
                name="question"
                ondragstart={(event, dataTransfer) => dataTransfer.setData("question", data.id)}
                ondroptop={(event, dataTransfer) => this.onQuestionDrop(dataTransfer, data.id, 0)}
                ondropbottom={(event, dataTransfer) => this.onQuestionDrop(dataTransfer, data.id, 1)}
            >
                <div className="turbo-question__item-title">
                    <span
                        className={(isEditMode || !isEdit) ? "" : "cursor-pointer"}
                        ondblclick={(isEditMode || !isEdit) ? '' : () => this.onEdit(data)}
                        onmouseenter={event => this.showExpressionMenu(data, event, "copyQuestion")}
                        onmouseleave={() => this.hideExpressionMenu()}
                    >
                        {
                            this.idExpressionMenu === `${data.id}-copyQuestion` && isEdit
                                ? (
                                    <ContextMenu
                                        step={data}
                                        onHideMenu={() => this.hideExpressionMenu()}
                                        position={this.position}
                                    />
                                )
                                : null
                        }
                        {data.name}
                        <If condition={data.required}>
                            <i
                                className={" font-icon v-align-top fs8 ml5 color-orange required-icon"}
                                title="Обязательный вопрос, может влиять на работу с документом"
                            />
                        </If>
                    </span>
                    <div className="group-h-button">
                        <Button
                            title={`${translate("questionnaire.editQuestion")} «${data.name}»`}
                            onclick={() => this.onEdit(data)}
                            disabled={isEditMode || !isEdit}
                        >
                            <Button.Icon className="block pencil fs18 color-blue"/>
                        </Button>

                        <Button
                            title={`${translate("questionnaire.deleteQuestion")} «${data.name}»`}
                            onclick={() => this.onDelete(data)}
                            disabled={isEditMode || !isEdit}
                        >
                            <Button.Icon className="block icon-trash fs18 color-red"/>
                        </Button>

                        <Button
                            title={this.logicalExpressionTitle(data)}
                            oncontextmenu={event => this.showExpressionMenu(data, event)}
                            onclick={() => this.openLogicalExpression(data)}
                            disabled={!isEdit}
                        >
                            <Button.Icon className={logicalIconClasses}/>
                        </Button>

                        <Button
                            title={titleComments}
                            onclick={() => this.openCommentModal(data)}
                            disabled={!isEdit}
                        >
                            <Button.Icon className={commentsIconClasses}/>
                        </Button>

                        <Button
                            data-popup-parent-key={`parameter_formats_${data.id}`}
                            title={translate("questionnaire.insertParametricByQuestion")}
                            onclick={() => insertParameter(data)}
                            disabled={isEditMode || !isEdit}
                        >
                            <Button.Icon className="block icon-parameter fs18 color-blue"/>
                        </Button>
                    </div>
                </div>
                <div className="turbo-question__item-description">
                    {`${nameTypeOfQuestion} (${+type === OWN_FIELD ? nameTypeOfField : nameRelatedData})`}
                </div>
                {
                    this.idExpressionMenu === `${data.id}-question`
                        ? (
                            <ContextMenu
                                expression={data.expression}
                                onHideMenu={() => this.hideExpressionMenu()}
                                onOpenExpression={(expression) => this.openLogicalExpression(data, expression)}
                                position={this.position}
                            />
                        )
                        : null
                }
                {
                    parametric.isShowOptions && parametric.questionId === data.id
                        ? (
                            <PopUpTooltip
                                data-popup-key={`parameter_formats_${data.id}`}
                            >
                                <Container>
                                    <div style="max-height: 150px;" className="pr15">
                                        <RadioButtonGroup
                                            data={parametric.options}
                                            name="parametric"
                                            onSelect={this.onSelectParametric.bind(this)}
                                            template={this.getTemplateFormatParametric()}
                                        />
                                    </div>
                                </Container>
                            </PopUpTooltip>
                        )
                        : null
                }
            </TurboDnD.DragAndDropItem>
        );
    }

    onEdit(question) {
        this.attrs.actions.setCurrentQuestion(turbo.cloneObject(question));
    }

    logicalExpressionTitle(data) {
        let title = data && data.expression
            ? translate("questionnaire.regularExpressionQuestionSet") + ": "
            : translate("questionnaire.regularExpressionQuestionNotSet");

        if (data && data.expression) {
            title += "\n" + this.getLogicalExpressionText(data.expression.conditions).join("\n");
        }
        return title
    }

    getLogicalExpressionText(conditions = []) {
        let text = [];

        for (let prop in conditions) {
            if (conditions.hasOwnProperty(prop)) {
                let condition = conditions[prop];

                if (condition.isGroup) {
                    text.push("[");
                    text = [...text, ...this.getLogicalExpressionText(condition.conditions)];
                    text.push("]");
                } else {
                    let expression = condition.expression;

                    text.push(expression.parameter.name + " " + expression.operand + " " + expression.value);
                }

                if (condition.type === 0) {
                    text.push("И");
                } else if (condition.type === 1) {
                    text.push("ИЛИ")
                }
            }
        }

        return text;
    }

    onDelete(question) {
        const { documentType, documentId } = this.attrs.info;
        const { id, name }                 = question;

        turbo.plugins.modal.confirm(
            {
                title        : translate("questionnaire.warningMessage.titleDeleteQuestion"),
                btnCancelText: translate("buttons.cancel"),
                btnAcceptText: translate("buttons.yes"),
                text         : `${translate("questionnaire.warningMessage.textDeleteQuestion")} «${name}» ?`,
                iconClasses  : "font-icon warning fs24 color-orange mr5"
            },
            () => {
                this.attrs.actions.requestDeleteQuestion({ documentId, documentType, questionId: id });
            }
        );
    }

    openLogicalExpression(question, newExpression) {
        const { documentType, documentId } = this.attrs.info;
        const { id, expression }           = question;
        const logicalExpression            = new turbo.components.dialogs.logicalExpression.QuestionnaireItem({
            expression  : newExpression || expression,
            type        : "question",
            templateId  : documentId,
            typeDocument: documentType
        });

        logicalExpression.on("save", data => {
            const expression = data.conditions && Object.keys(data.conditions).length ? data : null;
            const saveData   = { id, expression };

            this.attrs.actions.requestAddEditQuestion({ documentId, documentType, data: saveData });
        });

        logicalExpression.open();
    }

    showExpressionMenu(question, event, type = "question") {
        const { id } = question;

        event.preventDefault();

        this.idExpressionMenu = `${id}-${type}`;
        if (type === "question") {
            this.position = {
                rule: {
                    x     : "right",
                    xCount: "10px",
                    y     : "top",
                    yCount: event.layerY + 10 + "px"
                }
            };
        } else {
            this.position = {
                rule: {
                    x     : "left",
                    xCount: "10px",
                    y     : "top",
                    yCount: "16px"
                }
            };
        }
    }

    hideExpressionMenu() {
        if (this.idExpressionMenu) {
            this.idExpressionMenu = null;
            this.position         = null;
            setTimeout(() => m.redraw(), 0);
        }
    }

    openCommentModal(question) {
        const { documentType, documentId } = this.attrs.info;
        const { id, comments }             = question;
        const onSave                       = value => this.attrs.actions.requestAddEditQuestion({
            documentId,
            documentType,
            data: { id, comments: value }
        });

        turbo.plugins.modal.open({
            closeOnOverlayClick: false,
            dnd                : true,
            vnode              : {
                view: () => (
                    <TextAreaForm
                        value={comments}
                        title={translate("questionnaire.changeComments")}
                        onSave={onSave}
                    />
                )
            }
        });
    }

    getNameTypeOfQuestion() {
        const { data: { type } } = this.attrs;

        if (+type === OWN_FIELD) {
            return translate("questionnaire.ownFiledType");
        } else {
            return translate("questionnaire.relatedFiledType");
        }
    }

    getNameTypeOfField() {
        const { data: { value = {} } } = this.attrs;
        const { typeOfField = 0 }      = value;
        const typeOfFields             = this.attrs.typesOfFields.find(type => +type.id === typeOfField);

        return (typeOfFields || {}).name || "";
    }

    getNameRelatedData() {
        const { data: { value = {} } } = this.attrs;

        return value.name || "";
    }

    getTemplateFormatParametric() {
        const { parametric: { typeOptions } } = this.attrs;

        if (typeOptions === "parametric") {
            return;
        }

        return item => (
            <div className="format-template">
                <span className="format-template__name">{item.name}</span>
                <span className="format-template__example pl5">
                    {`${translate("questionnaire.example")}: ${item.example}`}
                </span>
            </div>
        );
    }

    onSelectParametric(option) {
        const { parametric: { typeOptions, questionId } } = this.attrs;

        if (typeOptions === "parametric") {
            processParametricData(option, questionId);
        } else {
            insertParameterElement(option);
        }
    }

    mouseDownAction(event) {
        let target         = event.target,
            selectorPopUp  = "[data-popup-key]",
            selectorParent = "[data-popup-parent-key]",
            elementPopUp   = document.querySelector(selectorPopUp);

        if (!elementPopUp || target.closest(selectorPopUp) || target.closest(selectorParent)) {
            return;
        }

        if (elementPopUp.getAttribute("data-popup-key").indexOf("parameter_formats_") !== 0) {
            return;
        }

        this.attrs.actions.removeParametricData();
    }

    onQuestionDrop(dataTransfer, questionId, shift) {
        const { documentType, documentId } = this.attrs.info;
        const dragQuestionId               = dataTransfer.getData("question");

        let nextQuestionId = questionId;

        if (shift === 1) {
            const currentStep       = this.attrs.currentStep;
            const indexDropQuestion = currentStep.questions.findIndex(question => question.id === questionId);

            nextQuestionId = (currentStep.questions[indexDropQuestion + shift] || {}).id;
        }

        this.attrs.actions.requestMoveQuestion({
            documentType,
            documentId,
            data: { id: dragQuestionId, nextId: nextQuestionId }
        });
    }
}

export default connect(mapStateToProps, mapActionsToProps)(QuestionView);
