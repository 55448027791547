import "mithril";
import Component from "_/lib/Component";
import {translate} from "_/localizations";
import Button from "_/components/plugins/Button";
import {connect} from "_/lib/midux";
import {
    FIELD_OF_OBJECT_OF_REGISTRY,
    OBJECT_OF_REGISTRY,
    OTHER_REQUISITES,
    OTHER_ROLE,
    OTHER_SIDE,
    OWN_REQUISITES,
    OWN_ROLE,
    OWN_SIDE,
    PROPERTY_OF_DOCUMENT,
    SIGNER_OF_OWN_SIDE,
    SIGNER_OF_OTHER_SIDE,
    OBJECT_TREE_OF_REGISTRY
} from "_/constants/questionsOfRelatedData";
import {OTHER_BANK_REQUISITES, OWN_BANK_REQUISITES} from "../../../../constants/questionsOfRelatedData";

const mapStateToProps = state => ({
    currentData: state.relatedData.currentData,
    currentType: state.relatedData.currentType,
    questions  : state.relatedData.questions
});

const mapActionsToProps = {};

class Footer extends Component {
    view() {
        const currentData  = this.attrs.currentData;
        const currentType  = +this.attrs.currentType;
        const isNoTreeData = [
            OWN_SIDE,
            OTHER_SIDE,
            OWN_ROLE,
            OTHER_ROLE,
            SIGNER_OF_OTHER_SIDE,
            SIGNER_OF_OWN_SIDE,
            OWN_BANK_REQUISITES,
            OTHER_BANK_REQUISITES
        ].includes(currentType);
        const isSelected   = Object.keys(currentData).length > 0 || isNoTreeData;

        return (
            <div className="modal__footer bg-grey text-right">
                <Button className="btn_size_md dashed-link p0 mr10 modal-close">
                    {translate("buttons.cancel")}
                </Button>

                <Button
                    className="btn_size_md btn_bg_orange btn_text_white modal-close"
                    onclick={() => this.onChoice()}
                    disabled={!isSelected}
                >
                    {translate("buttons.choice")}
                </Button>
            </div>
        );
    }

    onChoice() {
        const { currentType, onSelect, questions } = this.attrs;

        const currentQuestions = questions.find(question => +question.id === +currentType);

        let data = {};

        switch (+currentType) {
            case OBJECT_OF_REGISTRY:
                data = this.getRegistryData();
                break;
            case FIELD_OF_OBJECT_OF_REGISTRY:
                data = this.getFieldRegistryData();
                break;
            case OWN_REQUISITES:
            case OTHER_REQUISITES:
                data = this.getRequisitesData();
                break;
            case PROPERTY_OF_DOCUMENT:
                data = this.getPropertyDocumentData();
                break;
            case OBJECT_TREE_OF_REGISTRY:
                data = this.getBindingsData();
                break
        }

        if (data.name) {
            data.name = `${currentQuestions ? currentQuestions.name : ""} (${data.name})`;
        } else {
            data.name = currentQuestions ? currentQuestions.name : "";
        }

        if (typeof onSelect === "function") {
            onSelect(+currentType, data);
        }
    }

    getRegistryData() {
        const { currentData } = this.attrs;
        const data            = {};
        const node            = currentData.node;
        const options         = node.options;

        data.nodeId     = node.id;
        data.registryId = options.registryId;
        data.bindingId  = options.isBinding ? options.idBinding : null;
        data.name       = options.isBinding ? options.nameBinding : options.nameRegistry;

        return data;
    }

    getFieldRegistryData() {
        const { currentData } = this.attrs;
        const data            = {};
        const node            = currentData.node;
        const options         = node.options;
        const parentNode      = currentData.parentNode;
        const parentOptions   = parentNode.options;

        data.nodeId     = node.id;
        data.fieldId    = options.fieldId;
        data.registryId = parentOptions.registryId;
        data.bindingId  = parentOptions.isBinding ? parentOptions.idBinding : null;
        data.parentId   = parent.id;
        data.name       = parentOptions.isBinding ? parentOptions.nameBinding : parentOptions.nameRegistry;
        data.name       = `${data.name} (${node.name})`;

        return data;
    }

    getRequisitesData() {
        const { currentData } = this.attrs;
        const node            = currentData.node;

        return { requisiteId: node.id, nodeId: node.id, name: node.name };
    }

    getBindingsData() {
        const { currentData } = this.attrs;
        const node            = currentData.node;

        return { bindingId: node.options.bindingId};
    }

    getPropertyDocumentData() {
        const { currentData } = this.attrs;
        const data            = {};
        const node            = currentData.node;
        const options         = node.options;
        const parentNode      = currentData.parentNode;

        data.nodeId             = node.id;
        data.documentPropertyId = options.propertyId;
        data.templateId         = parentNode.id;
        data.parentId           = parentNode.id;
        data.name               = `${parentNode.name} (${node.name})`;

        return data;
    }
}

export default connect(mapStateToProps, mapActionsToProps)(Footer);
