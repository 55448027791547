export const createItem = isRedraw => ({ groupId, registryId, data }) => {
    return (isRedraw ? mt : mt.s).post({
        url        : Routing.generate("text_block_create", { id: groupId, registryId }),
        data,
        loadConfig : { isShow: true, name: "DocumentTextBlock: createItem" },
        errorConfig: { isShow: true }
    });
};

export const updateItem = isRedraw => data => {
    return (isRedraw ? mt : mt.s).post({
        url        : Routing.generate("text_block_update", { id: data.id }),
        data,
        loadConfig : { isShow: true, name: "DocumentTextBlock: updateItem" },
        errorConfig: { isShow: true }
    });
};

export const cloneItem = isRedraw => data => {
    return (isRedraw ? mt : mt.s).post({
        url        : Routing.generate("text_block_clone", { id: data.id }),
        data,
        loadConfig : { isShow: true, name: "DocumentTextBlock: cloneItem" },
        errorConfig: { isShow: true }
    });
};
