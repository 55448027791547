import {store} from '_/lib/midux';

import noSaveContentConfirm from "../NoSaveContentConfirm";

const checkNoSaveSteps = () => {
    const data = store().getState().questionnaire.currentStep;

    if (data.id && data.mode === 'edit') {
        return true;
    }

    return false;
}

const checkNoSaveData = () => {
    const data = store().getState().questionnaire.currentQuestion;

    if (data.id) {
        return true;
    }

    return false;
}

const checkNoSaveContent = editor => {
    let state   = editor.state,
        element = state.element;

    state.currentContent      = editor.delegates.formatContent(element, element.innerHTML);
    state.comparedSaveContent = editor.delegates.formatContent(element, state.savedContent);

    return state.currentContent !== state.comparedSaveContent || state.currentContent === "";
}

const noSaveQuestion = (redirectFunction) => {
    noSaveContentConfirm({
        accept: () => {
            document.dispatchEvent(new CustomEvent("customSaveQuestion"));
            if (typeof redirectFunction === "function") {
                redirectFunction()
            }
        },
        extra : () => {
            if (typeof redirectFunction === "function") {
                redirectFunction()
            }
        }
    });
}

const noSaveStep = (redirectFunction) => {
    noSaveContentConfirm({
        accept: () => {
            document.dispatchEvent(new CustomEvent("customCurrentStep"));

            if (typeof redirectFunction === "function") {
                redirectFunction()
            }
        },
        extra : () => {
            if (typeof redirectFunction === "function") {
                redirectFunction()
            }
        }
    });
}


const NoSaveContentConfirmAll = (url, callback) => {
    let { editorInDocument, editorInDocumentFragment, editorInPattern, editorInTextBlock } = turbo;

    let setUrlInWindow = () => {
        window.location.href = url
    }

    let redirectFunction = url ? setUrlInWindow : callback;

    let editor, method;

    if (editorInDocument) {
        editor = editorInDocument.editor;
        method = 'saveDocument';
    } else if (editorInDocumentFragment) {
        editor = editorInDocumentFragment.editor;
        method = 'saveDocumentFragment';
    } else if (editorInPattern) {
        editor = editorInPattern.editor;
        method = 'savePattern';
    } else if (editorInTextBlock) {
        editor = editorInTextBlock.editor;
        method = 'saveTextBlock';
    }

    if (!editor) {
        redirectFunction();
        return;
    }

    if (checkNoSaveData()) {
        noSaveQuestion(redirectFunction);
    } else if (checkNoSaveSteps()) {
        noSaveStep(redirectFunction);
    } else if (editor.state && checkNoSaveContent(editor)) {
        noSaveContentConfirm({
            accept: () => {
                editor.endEdit();

                document.addEventListener(`${method}`, function() {
                    redirectFunction();
                });
            },
            extra : () => {
                editor.state.element.innerHTML = editor.state.savedContent;
                redirectFunction();
                editor.isNoSave = true;
            }

        });
    } else {
        redirectFunction();
        editor.isNoSave = true;
    }
}

export default NoSaveContentConfirmAll;
