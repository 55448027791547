import "mithril";
import {connect} from "_/lib/midux";
import Component from "_/lib/Component";
import {translate} from "_/localizations";
import MultiDropDownList from "_/components/plugins/MultiDropDownList";
import NumberInput from "_/components/plugins/NumberInput";
import FIO from "_/components/plugins/FIO";
import DateInput from "_/components/plugins/DateInput";
import {
    FIELD_BOOLEAN,
    FIELD_DATE,
    FIELD_FINANCE,
    FIELD_MULTI_SELECT,
    FIELD_MULTI_SELECT_EDIT,
    FIELD_NUMBER,
    FIELD_PERCENTAGE,
    FIELD_SELECT,
    FIELD_SELECT_EDIT,
    FIELD_TEXT,
    FIELD_TEXT_AREA,
    FIELD_TEXT_BLOCK,
    FIELD_FIO,
    FIELD_CALC,
    FIELD_TEXT_BLOCK_EDIT
} from "_/constants/typesOfRegistryFIelds";
import {requestListGroupTextBlock, setValueCurrentQuestion} from "_/actions/questionnaire";
import {FIELD_OF_OBJECT_OR_OWN_REGISTRY, OBJECT_OF_REGISTRY} from "_/constants/questionsOfRelatedData";

const mapStateToProps = state => ({
    question         : state.questionnaire.currentQuestion,
    currentTextBlocks: state.questionnaire.currentTextBlocks,
    listObjects      : state.registry.currentRegistry.objects
});

const mapActionsToProps = {
    setValueCurrentQuestion,
    requestListGroupTextBlock
};

class QuestionDefaultValue extends Component {
    view() {
        const { question }        = this.attrs;
        const { typeOfField = 0 } = question.value || {};

        return (
            <div className="turbo-question__item-value">
                <div className="turbo-question__part-title">
                    {
                        +typeOfField === FIELD_CALC
                            ? translate("questionnaire.defaultValueCalcTitle")
                            : translate("questionnaire.defaultValueQuestionTitle")
                    }
                </div>
                <div className="turbo-question__item-value-elements">
                    <div className="turbo-question__item-value-element">
                        {
                            +question.type === FIELD_OF_OBJECT_OR_OWN_REGISTRY
                                ? this.renderFieldOfRegistry()
                                : this.renderFieldForRelatedData()
                        }
                    </div>
                </div>
            </div>
        );
    }

    renderFieldOfRegistry() {
        const { question: { id, value = {} }, currentTextBlocks }   = this.attrs;
        const { typeOfField = 0, defaultValue = "", settings = {} } = value;
        const valuesOfList                                          = settings.values || [];

        const textBlocks = valuesOfList.length > 0 ? valuesOfList : currentTextBlocks;

        switch (+typeOfField) {
            case FIELD_TEXT:
                return <input
                    type="text"
                    value={defaultValue}
                    oninput={event => this.onChangeDefaultValue(event.target.value)}
                />;
            case FIELD_FINANCE:
            case FIELD_NUMBER:
            case FIELD_PERCENTAGE:
                return <NumberInput
                    value={defaultValue}
                    onChange={value => this.onChangeDefaultValue(value)}
                />;
            case FIELD_TEXT_AREA:
                return <textarea
                    className="input-style"
                    value={defaultValue}
                    oninput={event => this.onChangeDefaultValue(event.target.value)}
                />;
            case FIELD_DATE:
                return <DateInput
                    value={defaultValue}
                    data-popup-key={`date-question-${id}`}
                    modal=".body-with-date"
                    onChange={value => this.onChangeDefaultValue(value)}
                />
            case FIELD_BOOLEAN:
                return (
                    <select
                        value={defaultValue}
                        onchange={event => this.onChangeDefaultValue(event.target.value)}
                    >
                        <option/>
                        <option value="1">{translate("common.yes")}</option>
                        <option value="0">{translate("common.no")}</option>
                    </select>
                );
            case FIELD_SELECT:
            case FIELD_SELECT_EDIT:
                return (
                    <select
                        value={defaultValue}
                        onchange={event => this.onChangeDefaultValue(event.target.value)}
                    >
                        <option/>
                        {valuesOfList.map(item => (<option value={item}>{item}</option>))}
                    </select>
                );
            case FIELD_MULTI_SELECT:
            case FIELD_MULTI_SELECT_EDIT:
                return <MultiDropDownList
                    data={valuesOfList}
                    values={defaultValue}
                    onchange={values => this.onChangeDefaultValue(values)}
                />;
            case FIELD_TEXT_BLOCK:
            case FIELD_TEXT_BLOCK_EDIT:
                return (
                    <select
                        value={defaultValue}
                        onchange={event => this.onChangeDefaultValue(event.target.value)}
                    >
                        <option/>
                        {textBlocks.map(block => (<option value={block.id}>{block.name}</option>))}
                    </select>
                );
            case FIELD_FIO:
                return <FIO
                    value={defaultValue}
                    onChange={value => this.onChangeDefaultValue(value)}
                />;
            case FIELD_CALC:
                return <textarea
                    className="input-style"
                    value={defaultValue}
                    oninput={event => this.onChangeDefaultValue(event.target.value)}
                />
        }
    }

    renderFieldForRelatedData() {
        const { question: { type, value = {} } }   = this.attrs;
        const { defaultValue = "", settings = {} } = value;
        const valuesOfList                         = settings.values || [];

        if (+type === OBJECT_OF_REGISTRY) {
            const { listObjects } = this.attrs;
            const objects         = valuesOfList.length > 0 ? valuesOfList : listObjects;

            return (
                <select
                    onchange={event => this.onChangeDefaultValue(event.target.value)}
                    value={defaultValue}
                >
                    <option/>
                    {
                        objects.map(item => (
                            <option value={item.id}>{item.name}</option>
                        ))
                    }
                </select>
            );
        }

        return null;
    }

    onChangeDefaultValue(value) {
        this.attrs.actions.setValueCurrentQuestion({ defaultValue: value });
    }
}

export default connect(mapStateToProps, mapActionsToProps)(QuestionDefaultValue);
