import m from 'mithril';
import Component from "lib/Component";
import {translate} from 'localizations';

class Buttons extends Component {
    view() {
        let { setTodayAction, clearDate } = this.attrs;

        return (
            <div className="t-datepicker-buttons">
                <span
                    className="t-datepicker-button"
                    onclick={() => setTodayAction()}
                >
                    {translate("date.today")}
                </span>
                <span
                    className="t-datepicker-button"
                    onclick={() => clearDate()}
                >
                    {translate("date.clear")}
                </span>
            </div>
        );
    }
}

export default Buttons;
