export default {
    storage: {},

    get(name) {
        return this.storage[name];
    },

    set(obj) {
        this.storage = Object.assign(this.storage, obj);
    }
}
