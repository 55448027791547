import m from "mithril";
import classNames from "classnames";
import {
    TABLE,
    HORIZONTAL_ALIGN_LEFT,
    HORIZONTAL_ALIGN_RIGHT,
    HORIZONTAL_ALIGN_CENTER,
    VERTICAL_ALIGN_BOTTOM,
    VERTICAL_ALIGN_MIDDLE,
    VERTICAL_ALIGN_TOP
} from "./constants";
import turbo from "_/lib/turbo";
import {injectParametric} from "_/lib/turboeditor/table/parametric";

class EditorItemHtml {
    constructor(content, models, container) {
        this.content   = content || "";
        this.models    = models || {};
        this.container = container || {};
    }

    getHtml() {
        let fragment;

        this.processModels();

        if (this.container.element === "table") {
            let tableVnode = this.getTableVnode(this.container);

            tableVnode.attrs = { "contentEditable": true, "data-type-container": "cells" };
            fragment         = document.createDocumentFragment();

            m.render(fragment, tableVnode);
        } else {
            if (Object.keys(this.models).length === 0) {
                return this.content;
            }

            fragment = this.replaceMockTables(this.content);

            return turbo.htmlElementsToString(fragment.childNodes);
        }

        return turbo.htmlElementsToString(fragment.childNodes);
    }

    processModels() {
        const keys = Object.keys(this.models);

        keys.forEach(key => {
            const model                   = this.models[key];
            const { updateModel, models } = injectParametric(model);

            this.models[key] = updateModel;
            this.models      = { ...this.models, ...models };
        });
    }

    replaceMockTables(content) {
        let fragment = document.createDocumentFragment(),
            elements = turbo.stringToHtmlElements(content),
            mockTables, index;

        while (elements[0]) {
            fragment.appendChild(elements[0]);
        }

        mockTables = fragment.querySelectorAll("span[data-element=\"table\"]");

        for (index = 0; index < mockTables.length; index++) {
            let mockTable   = mockTables[index],
                idMockTable = mockTable.getAttribute("data-id"),
                model       = this.models[idMockTable],
                tableVnode, container;

            if (!model) {
                continue;
            }

            tableVnode = this.getTableVnode(model);
            container  = document.createDocumentFragment();

            m.render(container, tableVnode);

            mockTable.parentNode.replaceChild(container, mockTable);
        }

        return fragment;
    }

    getTableVnode(tableModel) {
        let colgroupVnode = this.getColGroupVnode(tableModel.cols),
            rowsVnode     = [],
            bodyVnode;

        tableModel.rows.forEach(row => {
            let cellsVnode = [];

            row.cells.forEach(cell => {
                cellsVnode.push(this.getCellVnode(cell));
            });

            let tmp   = [];

            if (row.height) {
                tmp.style = `height:${row.height};`;
            }

            rowsVnode.push(m("tr", {...tmp}, cellsVnode));
        });

        bodyVnode = m("tbody", rowsVnode);

        return m(TABLE, [colgroupVnode, bodyVnode]);
    }

    getColGroupVnode(colGroupModel = []) {
        let colsVnode = [];

        colGroupModel.forEach(col => {
            let attrs = {};

            attrs.style = `width:${col.width};`;

            if (col.source) {
                attrs["data-col-source"] = col.source;
            }

            if (col.baseWidth) {
                attrs["data-col-base-width"] = col.baseWidth;
            }

            colsVnode.push(m("col", attrs));
        });

        return m("colgroup", colsVnode);
    }

    getCellVnode(cellModel) {
        let attrs = {},
            children, classes, fragment, content;

        attrs = { ...cellModel.attrs };

        attrs.colspan = cellModel.colSpan;
        attrs.rowspan = cellModel.rowSpan;

        if (cellModel.colSpanBase) {
            attrs["data-cell-col-span-base"] = cellModel.colSpanBase;
        }

        if (cellModel.rowSpanBase) {
            attrs["data-cell-row-span-base"] = cellModel.rowSpanBase;
        }

        if (cellModel.source) {
            attrs["data-cell-source"] = cellModel.source;
        }

        if (cellModel.backgroundColor) {
            let stylesLoad = turbo.utils.styles.load,
                color      = cellModel.backgroundColor;

            attrs["data-backgroundcolor"] = color;
            stylesLoad.setStyle("backgroundcolor", color, "td");
        }

        classes = classNames({
            "editor-dark-text"  : this.isTrue(cellModel.isDarkText),
            "editor-light-text" : this.isTrue(cellModel.isLightText),
            "text-right"        : cellModel.horizontalAlignText === HORIZONTAL_ALIGN_RIGHT,
            "text-center"       : cellModel.horizontalAlignText === HORIZONTAL_ALIGN_CENTER,
            "text-left"         : cellModel.horizontalAlignText === HORIZONTAL_ALIGN_LEFT,
            "v-align-top"       : cellModel.verticalAlignText === VERTICAL_ALIGN_TOP,
            "v-align-middle"    : cellModel.verticalAlignText === VERTICAL_ALIGN_MIDDLE,
            "v-align-bottom"    : cellModel.verticalAlignText === VERTICAL_ALIGN_BOTTOM,
            "hide-top-border"   : this.isTrue(cellModel.isHideTopBorder),
            "hide-bottom-border": this.isTrue(cellModel.isHideBottomBorder),
            "hide-left-border"  : this.isTrue(cellModel.isHideLeftBorder),
            "hide-right-border" : this.isTrue(cellModel.isHideRightBorder),
            "cell-parametric"   : cellModel.type === "parametric"
        });

        if (classes.length > 0) {
            attrs.className = classes;
        }

        fragment = this.replaceMockTables(cellModel.content);

        this.setColorStylesForText(fragment.childNodes);

        content  = turbo.htmlElementsToString(fragment.childNodes);
        children = m.trust(content);

        return m("td", attrs, children === undefined ? "" : children);
    }

    isTrue(value) {
        return value === true || value === "true";
    }

    setColorStylesForText(elements = []) {
        let stylesLoad = turbo.utils.styles.load;

        elements.forEach(element => {
            if (element.nodeName === "SPAN") {
                if (element.hasAttribute("data-backgroundcolor")) {
                    let color = element.getAttribute("data-backgroundcolor");

                    stylesLoad.setStyle("backgroundcolor", color, "span");
                    stylesLoad.setStyle("backgroundcolor", color, "span.parameter");
                    stylesLoad.setStyle("backgroundcolor", color, "span.parameter_light");
                }

                if (element.hasAttribute("data-fontcolor")) {
                    let color = element.getAttribute("data-fontcolor");

                    stylesLoad.setStyle("fontcolor", color, "span");
                    stylesLoad.setStyle("fontcolor", color, "span.parameter");
                    stylesLoad.setStyle("fontcolor", color, "span.parameter_light");
                }
            }
        });
    }
}

export default EditorItemHtml;
