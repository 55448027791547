import m from "mithril";
import {translate} from 'localizations';
import Button from "components/plugins/Button";
import ChooseSimple from "_/components/common/ChooseSimple";
import {updateRole, getRoles} from "./promises";


const COUNT_SHOW_ITEM = 4;

class InviteUsers {
    constructor(data) {
        this.id       = data.id;
        this.agencyId =  data.agencyId;
        this.getData(data.id, data.agencyId);
    }

    view() {
        return m.fragment({}, [
            <div className="modal__header bg-blue color-white">
                <div className="font-icon cancel modal-close"/>
                <span>
                    { translate("changeRoles.titleAdd")}
                </span>
            </div>,
            <div className="modal__body">
                <label className="form-item">
                    <span className="pb10">{translate("changeRoles.ourRole")}</span>
                    <ChooseSimple
                        data-popup-key="add_scheme"
                        data={this.selectItems}
                        onChange={item => this.changeRole(item.id, "ourRole")}
                        countShowItems={COUNT_SHOW_ITEM}
                        isCanEmpty={false}
                        value={this.ourRole}
                    />
                </label>
                <label className="form-item pt15">
                    <span className="pb10">{translate("changeRoles.partnerRole")}</span>
                    <ChooseSimple
                        data-popup-key="add_scheme"
                        data={this.selectItems}
                        onChange={item => this.changeRole(item.id, "partnerRole")}
                        countShowItems={COUNT_SHOW_ITEM}
                        isCanEmpty={false}
                        value={this.partnerRole}
                    />
                </label>
            </div>,

            <div className="modal__footer bg-grey text-right">
                <Button className="turbo-button__size_md dotted-link mr20 fs14 p0 modal-close color-blue">
                    {translate("changeRoles.textButtonCancel")}
                </Button>
                <Button
                    className="turbo-button__size_md turbo-button__bg_orange turbo-button__text_white"
                    disabled={this._isDisableSave}
                    onclick={() => this._save()}
                    title={this._isDisableSave ? translate("changeRoles.warningSave") : ""}
                >
                    {translate("changeRoles.textButtonSave")}
                </Button>
            </div>
        ]);
    }

    getData(id, agencyId) {
        getRoles(id, agencyId).then(result => {
            this._data = result;
            this.selectItems = this.getSelectItems();
            this.changeRole(result.ourRole, "ourRole");
            this.changeRole(result.partnerRole, "partnerRole");
        })
    }

    getSelectItems() {
        let dta = this._data.roles;

        return Object.keys(dta).map((key) => ({id: key, name: dta[key]}));
    }

    changeRole(item, whom) {
        this[whom] = item
    }

    _save() {
        updateRole(this.id, this.agencyId, this.partnerRole, this.ourRole)
            .then(() => {
                turbo.plugins.modal.close(document.querySelector('.inviteUsers'));
            });
    }

    open() {
        turbo.plugins.modal.open({
            closeOnOverlayClick: false,
            vnode              : this,
            class              : "inviteUsers"
        });
    }
}

export default InviteUsers;
