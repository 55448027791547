import m from 'mithril';

export default class MainLevelLeft {
    view({ children }) {
        return (
            <div className="toolbar-table-row__coll toolbar__top-panel-left">
                { children }
            </div>
        )
    }
}
