import "mithril";
import {connect} from "_/lib/midux";
import Component from "_/lib/Component";
import {translate} from "_/localizations";
import Button from "_/components/plugins/Button";
import {openRelatedDataModal} from "_/service/questionnaire";

const mapStateToProps = state => ({
    question: state.questionnaire.currentQuestion,
    info    : state.currentInfo,
});

const mapActionsToProps = {};

class QuestionRelatedValue extends Component {
    view() {
        const { question }   = this.attrs;
        const { value = {}, type } = question;
        const { name = "", nodeId }  = value;

        return (
            <div className="turbo-question__item-value">
                <div className="turbo-question__part-title">
                    {translate("questionnaire.relatedValueTitle")}
                </div>
                <div className="turbo-question__item-value-elements">
                    <div className="turbo-question__item-value-element">
                        <div
                            className="form-item-row"
                            onclick={() => openRelatedDataModal(type, nodeId)}
                        >
                            <div className="form-item-row__left">
                                <div
                                    className="bordered p8 pl10 pr10 bg-white color-grey text-clipped"
                                    title={name}
                                >
                                    {name || translate("questionnaire.defaultRelatedValueText")}
                                </div>
                            </div>
                            <div className="form-item-row__right">
                                <Button className="btn_size_md btn_border btn_text_blue">
                                    •••
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapActionsToProps)(QuestionRelatedValue);
