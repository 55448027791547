import m from "mithril";
import {translate} from 'localizations';
import Button from "components/plugins/Button";


class pasteLogicalExpressionDialog {
    constructor(onSaveAction, type) {
        this._onSaveAction = onSaveAction;
        this._data         = null;
        this.type          = type || 'logicalExpression'
    }

    view() {
        let isDisableSave = !this._data;

        setTimeout(
            () =>
                document.getElementById("pasteLogicalExpressionDialog").focus(),
            500
        )

        return m.fragment({}, [
            <div className="modal__header bg-blue color-white">
                <div className="font-icon cancel modal-close"/>
                <span>
                    {
                        this.type === 'logicalExpression'
                            ? translate("pasteLogicalExpressionDialog.titleAdd")
                            : translate("pasteLogicalExpressionDialog.titleAddStep")
                    }

                </span>
            </div>,

            <div className="modal__body">
                <span className="pb10">{this.type === 'logicalExpression'
                    ? translate("pasteLogicalExpressionDialog.help")
                    : translate("pasteLogicalExpressionDialog.helpStep")
                }</span>
                <label className="form-item pt15">
                    <span className="pb10">{translate("pasteLogicalExpressionDialog.labelComment")}</span>
                    <textarea
                        id={"pasteLogicalExpressionDialog"}
                        value={this._data}
                        oninput={this._changeComment()}
                    />
                </label>
            </div>,

            <div className="modal__footer bg-grey text-right">
                <Button className="turbo-button__size_md dotted-link mr20 fs14 p0 modal-close color-blue">
                    {translate("pasteLogicalExpressionDialog.textButtonCancel")}
                </Button>
                <Button
                    className="turbo-button__size_md turbo-button__bg_orange turbo-button__text_white"
                    disabled={isDisableSave}
                    onclick={() => this._save()}
                    title={isDisableSave ? translate("pasteLogicalExpressionDialog.warningSave") : ""}
                >
                    {translate("pasteLogicalExpressionDialog.textButtonSave")}
                </Button>
            </div>
        ]);
    }

    _changeComment() {
        return m.withAttr(
            "value",
            (value) => {
                this._data = value
            },
            this
        )
    }

    _save() {
        turbo.plugins.modal.close();

        if (typeof this._onSaveAction === "function") {
            this._onSaveAction(this._data);
        }
    }

    open() {
        turbo.plugins.modal.open({
            closeOnOverlayClick: false,
            vnode              : this
        });
    }
}

export default pasteLogicalExpressionDialog;
