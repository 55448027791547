import m from "mithril";
import Component from "lib/Component";
import Navigation from "./components/navigation";
import Days from "./components/days";
import Months from "./components/months";
import Years from "./components/years";
import Buttons from "./components/buttons";
import {MODE_DATE, MODE_MONTH, MODE_YEAR} from "./constants";

class DatePicker extends Component {
    oninit() {
        let { value } = this.attrs,
            date      = this._parseDate(value);

        this._onChange = this.attrs.onChange;
        this._onSave   = this.attrs.onSave;

        this._selectedDate = date;

        this._currentDate = date || new Date();

        this._currentMode = MODE_DATE;

        this._update();
    }

    onbeforeupdate() {
        this._update();
    }

    view() {
        return (
            <div className="t-datepicker">

                <Navigation
                    currentDate={this._currentDate}
                    mode={this._currentMode}
                    changeModeAction={this._changeMode.bind(this)}
                    changeCurrentDateAction={this._changeCurrentDate.bind(this)}
                />

                <div className="t-datepicker-content">
                    <Choose>
                        <When condition={this._currentMode === MODE_DATE}>
                            <Days
                                selectDate={this._selectedDate}
                                currentDate={this._currentDate}
                                setSelectedDateAction={this._setSelectedDate.bind(this)}
                            />
                        </When>
                        <When condition={this._currentMode === MODE_MONTH}>
                            <Months
                                selectDate={this._selectedDate}
                                currentDate={this._currentDate}
                                setCurrentMonthAction={this._setCurrentMonth.bind(this)}
                            />
                        </When>
                        <When condition={this._currentMode === MODE_YEAR}>
                            <Years
                                selectDate={this._selectedDate}
                                currentDate={this._currentDate}
                                setCurrentYearAction={this._setCurrentYear.bind(this)}
                            />
                        </When>
                    </Choose>
                </div>

                <Buttons setTodayAction={this._setToday.bind(this)} clearDate={this._clearDate.bind(this)}/>
            </div>
        );
    }

    _update() {
        let { store } = this.attrs;

        if (store && !store.isUpdatePicker) {
            this._currentDate    = store.value ? this._parseDate(store.value) : new Date();
            this._selectedDate   = this._parseDate(store.value);
            store.isUpdatePicker = true;
        }
    }

    _updateInput() {
        let { store } = this.attrs;

        if (store) {
            store.value         = this._selectedDate ? this._dateToDateMask() : "";
            store.isUpdateInput = false;
        }
    }

    _parseDate(stringDate = "") {
        let dateArr = stringDate.split(".");

        if (dateArr.length === 3) {
            let tmpDate = new Date(),
                year    = parseInt(dateArr[2]),
                month   = parseInt(dateArr[1]) - 1,
                date    = parseInt(dateArr[0]);

            tmpDate.setFullYear(year, month, date);

            return tmpDate;
        }

        return null;
    }

    _dateToDateMask() {
        let date, month, year;

        if (!this._selectedDate) {
            return "";
        }

        date  = this._selectedDate.getDate().toString();
        month = (this._selectedDate.getMonth() + 1).toString();
        year  = this._selectedDate.getFullYear().toString();

        return `${date.padStart(2, "0")}.${month.padStart(2, "0")}.${year.padStart(4, "0")}`;
    }

    _dateToOutputFormat() {
        let date, month, year;

        if (!this._selectedDate) {
            return "";
        }

        date  = this._selectedDate.getDate().toString();
        month = (this._selectedDate.getMonth() + 1).toString();
        year  = this._selectedDate.getFullYear().toString();

        return `${year.padStart(4, "0")}-${month.padStart(2, "0")}-${date.padStart(2, "0")}`;
    }

    _changeMode(mode) {
        this._currentMode = mode;
    }

    _changeCurrentDate(delta) {
        if (this._currentMode === MODE_DATE) {
            this._currentDate.setMonth(this._currentDate.getMonth() + delta);
        } else if (this._currentMode === MODE_MONTH) {
            this._currentDate.setFullYear(this._currentDate.getFullYear() + delta);
        } else if (this._currentMode === MODE_YEAR) {
            this._currentDate.setFullYear(this._currentDate.getFullYear() + delta * 10);
        }

        if (this._currentDate.getFullYear() < 0) {
            this._currentDate.setFullYear(this._currentDate.getFullYear() + 10000);
        } else if (this._currentDate.getFullYear() > 9999) {
            this._currentDate.setFullYear(this._currentDate.getFullYear() - 10000);
        }
    }

    _setSelectedDate(date, month, year) {
        if (!this._selectedDate) {
            this._selectedDate = new Date();
        }

        this._currentDate.setFullYear(year, month, date);
        this._selectedDate.setFullYear(year, month, date);

        if (typeof this._onChange === "function") {
            this._onChange(this._dateToOutputFormat());
        }

        if (typeof this._onSave === "function") {
            this._onSave();
        }

        this._updateInput();
    }

    _setCurrentMonth(month, year) {
        this._currentDate.setFullYear(year, month);

        this._currentMode = MODE_DATE;
    }

    _setCurrentYear(year) {
        this._currentDate.setFullYear(year);

        this._currentMode = MODE_MONTH;
    }

    _setToday() {
        let today      = new Date(),
            yearToday  = today.getFullYear(),
            monthToday = today.getMonth(),
            dateToday  = today.getDate();

        if (!this._selectedDate) {
            this._selectedDate = new Date();
        }

        this._currentDate.setFullYear(yearToday, monthToday, dateToday);
        this._selectedDate.setFullYear(yearToday, monthToday, dateToday);

        this._currentMode = MODE_DATE;

        if (typeof this._onChange === "function") {
            this._onChange(this._dateToOutputFormat());
        }

        if (typeof this._onSave === "function") {
            this._onSave();
        }

        this._updateInput();
    }

    _clearDate() {
        this._selectedDate = null;

        if (typeof this._onChange === "function") {
            this._onChange(this._dateToOutputFormat());
        }

        if (typeof this._onSave === "function") {
            this._onSave();
        }

        this._updateInput();
    }
}

export default DatePicker;
