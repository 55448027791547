import "mithril";
import Component from "lib/Component";
import {translate} from "localizations";
import ChooseSimple from "_/components/common/ChooseSimple";
import {SEPARATOR} from "_/constants/typesOfView";
import {OWN} from "_/constants/separators";
import {getElementsOfSimpleList} from "../promises";

//Корень для локализации
const ROOT = "listObjectOfRegistryByLink";

class SimpleListFields extends Component {
    oninit() {
        this.types      = this.attrs.typesOfView;
        this.separators = this.attrs.separators;
        this.formats    = [];
        this.elements   = [];
        this.docType    = this.attrs.docType;


        getElementsOfSimpleList(this.attrs.listId, this.docType).then(result => {
            let elementId = this.attrs.element;

            this.elements = result.data || [];

            if (elementId) {
                let element = this.elements.find(item => item.id === elementId);

                if (element) {
                    this.formats = element.formats || [];
                }
            }
        });

        if (typeof this.attrs.updateValidateInfo === "function") {
            let isValidate      = this.isValidate(this.attrs),
                validateMessage = isValidate ? "" : translate(`${ROOT}.warning.notValidateScheme`);

            this.attrs.updateValidateInfo(isValidate, validateMessage)
        }
    }

    view() {
        const { type, element, typeSeparator, separator, modalClass, format } = this.attrs;

        let types       = this.types,
            elements    = this.elements || [],
            separators  = this.separators,
            exampleName = translate(`${ROOT}.addEditScheme.example`),
            formats     = this.formats.map(format => ({ ...format, data: { [exampleName]: format.example } }));

        return (
            <div>
                <div className="form-item mt10">
                    <span>{translate(`${ROOT}.addEditScheme.elementOfSimpleList`)}</span>
                    <ChooseSimple
                        data={elements}
                        data-popup-key="AddEditSchemeElementsOfSimpleList"
                        modal={`.${modalClass}`}
                        onChange={item => {
                            this.changeValue("element", item.id);
                            this.changeValue("format", "");

                            this.formats = item.formats || [];
                        }}
                        isCanEmpty={false}
                        value={element}
                    />
                </div>

                <Choose>
                    <When condition={formats.length > 0}>
                        <div className="form-item mt10">
                            <span>{translate(`${ROOT}.addEditScheme.format`)}</span>
                            <ChooseSimple
                                data={formats}
                                data-popup-key="AddEditSchemeFormatsOfElement"
                                modal={`.${modalClass}`}
                                onChange={item => this.changeValue("format", item.id)}
                                isCanEmpty={false}
                                value={format}
                            />
                        </div>
                    </When>
                </Choose>

                <div className="form-item mt10">
                    <span>{translate(`${ROOT}.addEditScheme.typeOfSimpleList`)}</span>
                    <ChooseSimple
                        data={types}
                        data-popup-key="AddEditSchemeTypesOfSimpleList"
                        modal={`.${modalClass}`}
                        onChange={item => this.changeValue("type", item.id)}
                        isCanEmpty={false}
                        value={type}
                    />
                </div>

                <Choose>
                    <When condition={+type === SEPARATOR}>
                        <div className="form-item mt10">
                            <span>{translate(`${ROOT}.addEditScheme.separator`)}</span>
                            <ChooseSimple
                                data={separators}
                                data-popup-key="AddEditSchemeSeparators"
                                modal={`.${modalClass}`}
                                onChange={item => this.changeValue("typeSeparator", item.id)}
                                isCanEmpty={false}
                                value={typeSeparator}
                            />
                        </div>

                        <Choose>
                            <When condition={+typeSeparator === OWN}>
                                <div className="form-item mt10">
                                    <span>{translate(`${ROOT}.addEditScheme.ownSeparator`)}</span>
                                    <input
                                        className="wl100"
                                        type="text"
                                        value={separator}
                                        oninput={e => this.changeValue("separator", e.target.value)}
                                    />
                                </div>
                            </When>
                        </Choose>
                    </When>
                </Choose>
            </div>
        );
    }

    changeValue(field, value) {
        const { updateOptions, updateDescription, updateValidateInfo } = this.attrs;

        let description, isValidate, validateMessage, options;

        options = updateOptions({ [field]: value });

        description     = this.getDescription(options);
        isValidate      = this.isValidate(options);
        validateMessage = isValidate ? "" : translate(`${ROOT}.warning.notValidateScheme`);

        updateDescription(description);
        updateValidateInfo(isValidate, validateMessage);
    }

    getDescription(options) {
        const { type, element, typeSeparator, separator = "", format } = options;

        let typeTitle         = translate(`${ROOT}.addEditScheme.typeOfSimpleList`),
            elementTitle      = translate(`${ROOT}.addEditScheme.elementOfSimpleList`),
            separatorTitle    = translate(`${ROOT}.addEditScheme.separator`),
            separatorOwnTitle = translate(`${ROOT}.addEditScheme.ownSeparator`),
            formatTitle       = translate(`${ROOT}.addEditScheme.format`),
            typeName          = this.getNameOfElement(type, this.types),
            elementName       = this.getNameOfElement(element, this.elements),
            separatorName     = this.getNameOfElement(typeSeparator, this.separators),
            formatName        = this.getNameOfElement(format, this.formats),
            description       = `${elementTitle}: ${elementName}`,
            trimSeparator     = separator.replace(/\s{2,}/g, " ");

        if (format && this.formats.length > 0) {
            description = `${description}; ${formatTitle}: ${formatName}`;
        }

        description = `${description}; ${typeTitle}: ${typeName}`;

        if (+type === SEPARATOR) {
            description = `${description}; ${separatorTitle}: ${separatorName}`;

            if (+typeSeparator === OWN) {
                description = `${description}; ${separatorOwnTitle}: ${trimSeparator}`;
            }
        }

        return description;
    }

    getNameOfElement(elementId, collection) {
        let elementName = "";

        if (Array.isArray(collection)) {
            let element = collection.find(item => item.id === elementId);

            elementName = element ? element.name : "";
        }

        return elementName;
    }

    isValidate(options) {
        const { type, element, typeSeparator, separator, format } = options;

        let isValidate;

        isValidate = type !== undefined && type !== null;
        isValidate = isValidate && element !== undefined && element !== null;

        if (this.formats.length > 0) {
            isValidate = isValidate && format !== undefined && format !== null;
        }

        if (+type === SEPARATOR) {
            isValidate = isValidate && typeSeparator !== undefined && typeSeparator !== null;

            if (+typeSeparator === OWN) {
                isValidate = isValidate && separator !== undefined && separator !== null;
            }
        }

        return isValidate;
    }
}

export default SimpleListFields;
