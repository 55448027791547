export const updateRole = function(id, currentAgencyId, role, roleI) {
    return m.request({
        method: 'POST',
        // eslint-disable-next-line camelcase
        url   : Routing.generate('turbo_document_roles', {id, current_agency_id: currentAgencyId}),
        data : {roleI, role},
        config(xhr) {
            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
            xhr.setRequestHeader('Content-Type', 'application/json');
        }
    })
};

export const getRoles = function(id, currentAgencyId) {
    return m.request({
        method: 'GET',
        // eslint-disable-next-line camelcase
        url   : Routing.generate('turbo_document_roles', {id, current_agency_id: currentAgencyId}),
        config(xhr) {
            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
            xhr.setRequestHeader('Content-Type', 'application/json');
        }
    })
};
