import m from 'mithril';
import {getMonth} from "components/plugins/DatePicker/config";
import {MODE_DATE, MODE_MONTH, MODE_YEAR} from "components/plugins/DatePicker/constants";

class Navigation {
    view({ attrs }) {
        let {
                currentDate,
                mode,
                changeModeAction,
                changeCurrentDateAction
            }                 = attrs,
            currentMonth      = currentDate.getMonth(),
            currentYear       = currentDate.getFullYear(),
            textMonth         = getMonth(currentMonth),
            firstYearInDecade = Math.floor(currentYear / 10) * 10,
            lastYearInDecade  = firstYearInDecade + 9;

        return (
            <nav className="t-datepicker-nav">
                <div
                    className="t-datepicker-nav-action"
                    onclick={() => changeCurrentDateAction(-1)}
                >
                    <svg>
                        <path d="M 17,12 l -5,5 l 5,5"/>
                    </svg>
                </div>
                <Choose>
                    <When condition={mode === MODE_DATE}>
                        <div
                            className="t-datepicker-nav-title"
                            onclick={() => changeModeAction(MODE_MONTH)}
                        >
                            {`${textMonth}, `} <i>{currentYear}</i>
                        </div>
                    </When>
                    <When condition={mode === MODE_MONTH}>
                        <div
                            className="t-datepicker-nav-title"
                            onclick={() => changeModeAction(MODE_YEAR)}
                        >
                            {currentYear}
                        </div>
                    </When>
                    <When condition={mode === MODE_YEAR}>
                        <div className="t-datepicker-nav-title no-active">
                            {`${firstYearInDecade} - ${lastYearInDecade}`}
                        </div>
                    </When>
                </Choose>
                <div
                    className="t-datepicker-nav-action"
                    onclick={() => changeCurrentDateAction(1)}
                >
                    <svg>
                        <path d="M 14,12 l 5,5 l -5,5"/>
                    </svg>
                </div>
            </nav>
        );
    }
}

export default Navigation;
