import "mithril";
import Component from "lib/Component";
import {translate} from "localizations";
import {getListTextBlock} from "../promises";
import ChooseSimple from "_/components/common/ChooseSimple";
import {SEPARATOR} from "_/constants/typesOfView";
import {OWN} from "_/constants/separators";

//Корень для локализации
const ROOT = "listObjectOfRegistryByLink";

class ListTextBlockOfRegistryFields extends Component {
    oninit() {
        this.outputMethods = this.attrs.typesOfView;
        this.separators    = this.attrs.separators;
        this.textBlocks    = [];

        getListTextBlock(this.attrs.listId, this.attrs.docType).then(result => {
            this.textBlocks = result.data;
        });

        if (typeof this.attrs.updateValidateInfo === "function") {
            let isValidate      = this.isValidate(this.attrs),
                validateMessage = isValidate ? "" : translate(`${ROOT}.warning.notValidateScheme`);

            this.attrs.updateValidateInfo(isValidate, validateMessage)
        }
    }

    view() {
        const { textBlock, method, typeSeparator, separator, modalClass } = this.attrs;

        let textBlocks = this.textBlocks,
            methods    = this.outputMethods,
            separators = this.separators;

        return (
            <div>
                <div className="form-item mt10">
                    <span>{translate(`${ROOT}.addEditScheme.textBlockOfRegistry`)}</span>
                    <ChooseSimple
                        data={textBlocks}
                        data-popup-key="AddEditSchemeTextBlock"
                        modal={`.${modalClass}`}
                        onChange={item => this.changeValue("textBlock", item.id)}
                        isCanEmpty={false}
                        value={textBlock}
                    />
                </div>

                <div className="form-item mt10">
                    <span>{translate(`${ROOT}.addEditScheme.outputMethod`)}</span>
                    <ChooseSimple
                        data={methods}
                        data-popup-key="AddEditSchemeOutputMethods"
                        modal={`.${modalClass}`}
                        onChange={item => this.changeValue("method", item.id)}
                        isCanEmpty={false}
                        value={method}
                    />
                </div>

                <Choose>
                    <When condition={method === SEPARATOR}>
                        <div className="form-item mt10">
                            <span>{translate(`${ROOT}.addEditScheme.separator`)}</span>
                            <ChooseSimple
                                data={separators}
                                data-popup-key="AddEditSchemeSeparators"
                                modal={`.${modalClass}`}
                                onChange={item => this.changeValue("typeSeparator", item.id)}
                                isCanEmpty={false}
                                value={typeSeparator}
                            />
                        </div>

                        <Choose>
                            <When condition={+typeSeparator === OWN}>
                                <div className="form-item mt10">
                                    <span>{translate(`${ROOT}.addEditScheme.ownSeparator`)}</span>
                                    <input
                                        className="wl100"
                                        type="text"
                                        value={separator}
                                        oninput={e => this.changeValue("separator", e.target.value)}
                                    />
                                </div>
                            </When>
                        </Choose>
                    </When>
                </Choose>
            </div>
        );
    }

    changeValue(field, value) {
        const { updateOptions, updateDescription, updateValidateInfo } = this.attrs;

        let description, isValidate, validateMessage, options;

        options = updateOptions({ [field]: value });

        description     = this.getDescription(options);
        isValidate      = this.isValidate(options);
        validateMessage = isValidate ? "" : translate(`${ROOT}.warning.notValidateScheme`);

        updateDescription(description);
        updateValidateInfo(isValidate, validateMessage);
    }

    getDescription(options) {
        const { textBlock, method, typeSeparator, separator = "" } = options;

        let textBlockTitle    = translate(`${ROOT}.addEditScheme.textBlockOfRegistry`),
            methodTitle       = translate(`${ROOT}.addEditScheme.outputMethod`),
            separatorTitle    = translate(`${ROOT}.addEditScheme.separator`),
            separatorOwnTitle = translate(`${ROOT}.addEditScheme.ownSeparator`),
            methodName        = this.getNameOfElement(method, this.outputMethods),
            textBlockName     = this.getNameOfElement(textBlock, this.textBlocks),
            separatorName     = this.getNameOfElement(typeSeparator, this.separators),
            description       = `${textBlockTitle}: ${textBlockName}; ${methodTitle}: ${methodName}`,
            trimSeparator     = separator.replace(/\s{2,}/g, " ");

        if (+method === SEPARATOR) {
            description = `${description}; ${separatorTitle}: ${separatorName}`;

            if (+typeSeparator === OWN) {
                description = `${description}; ${separatorOwnTitle}: ${trimSeparator}`;
            }
        }

        return description;
    }

    getNameOfElement(elementId, collection) {
        let elementName = "";

        if (Array.isArray(collection)) {
            let element = collection.find(item => item.id === elementId);

            elementName = element ? element.name : "";
        }

        return elementName;
    }

    isValidate(options) {
        const { textBlock, method, typeSeparator, separator } = options;

        let isValidate;

        isValidate = textBlock !== undefined && textBlock !== null;
        isValidate = isValidate && method !== undefined && method !== null;

        if (+method === SEPARATOR) {
            isValidate = isValidate && typeSeparator !== undefined && typeSeparator !== null;

            if (+typeSeparator === OWN) {
                isValidate = isValidate && separator !== undefined && separator !== null;
            }
        }

        return isValidate;
    }
}

export default ListTextBlockOfRegistryFields;
