import "mithril";
import {store} from "_/lib/midux";
import Component from "_/lib/Component";
import QuestionView from "./QuestionView";
import QuestionEdit from "./QuestionEdit";

const mapActionsToProps = {};

class Question extends Component {
    view() {
        const question        = this.attrs.data;
        const currentQuestion = store().getState().questionnaire.currentQuestion;

        if (question.id === currentQuestion.id) {
            return <QuestionEdit name={question.name}/>
        }

        return <QuestionView data={question}/>;
    }
}

export default Question;
