export const createDocumentFragment = function({ name, comment, content }) {
    return mt.post({
        url        : Routing.generate("document_fragment_create"),
        data       : { name, comment, content },
        loadConfig : { isShow: true, name: "AddEditDocumentFragment: createDocumentFragment" },
        errorConfig: { isShow: true }
    });
};

export const changeDocumentFragment = function({ id, name, comment }) {
    return mt.post({
        url        : Routing.generate("document_fragment_update", { id }),
        data       : { name, comment },
        loadConfig : { isShow: true, name: "AddEditDocumentFragment: changeDocumentFragment" },
        errorConfig: { isShow: true }
    });
};

export const createDocumentFragmentFromItem = function({ documentId, itemId, name, comment }) {
    return mt.post({
        url        : Routing.generate('pattern_item_save_as_fragment', { id: documentId, nodeId: itemId }),
        data       : { name, comment },
        loadConfig : { isShow: true, name: "AddEditDocumentFragment: createDocumentFragmentFromItem" },
        errorConfig: { isShow: true }
    });
};

export const createDocumentFragmentFromTemplate = function({ name, comment, patternId }) {
    return mt.post({
        url        : Routing.generate('document_fragment_create'),
        data       : { name, comment, patternId },
        loadConfig : { isShow: true, name: "AddEditDocumentFragment: createDocumentFragmentFromTemplate" },
        errorConfig: { isShow: true }
    });
};
