export const getPatternTextBlockGroups = (id) => {
    return mt.get({
        url        : Routing.generate("pattern_text_block_registry_list", { id }),
        loadConfig : { isShow: true, name: "ListTextBlocksOfTemplate: getPatternTextBlockGroups" },
        errorConfig: { isShow: true }
    });
};

export const getPatternTextBlockGroupsFragments = (id) => {
    return mt.get({
        url        : Routing.generate("document_fragment_text_block_registry_list", { id }),
        loadConfig : { isShow: true, name: "ListTextBlocksOfTemplate: getPatternTextBlockGroups" },
        errorConfig: { isShow: true }
    });
};

export const saveTextBlockGroup = (id, data, type = "template") => {
    return mt.post({
        url        : Routing.generate("pattern_text_block_registry_save", { id, type }),
        data,
        loadConfig : { isShow: true, name: "ListTextBlocksOfTemplate: saveTextBlockGroup" },
        errorConfig: { isShow: true }
    });
};

export const deleteTextBlockGroup = (id) => {
    return mt.post({
        url        : Routing.generate("text_block_registry_delete", { id }),
        loadConfig : { isShow: true, name: "ListTextBlocksOfTemplate: deleteTextBlockGroup" },
        errorConfig: { isShow: true }
    });
};
