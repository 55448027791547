import m from 'mithril';

export default class MainLevelRight {
    view({ children }) {
        return (
            <div className="toolbar-table-row__coll toolbar__top-panel-right">
                { children }
            </div>
        )
    }
}
