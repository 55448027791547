export const SET_DOCUMENT_SECTION_STATE    = "SET_DOCUMENT_SECTION_STATE";
export const CHANGE_DOCUMENT_HISTORY_VIEW  = "CHANGE_DOCUMENT_HISTORY_VIEW";
export const CHANGE_DOCUMENT_COMMENTS_VIEW = "CHANGE_DOCUMENT_COMMENTS_VIEW";
export const CHANGE_DOCUMENT_SIDE_VIEW     = "CHANGE_DOCUMENT_SIDE_VIEW";
export const SET_DOCUMENT_SECTION_SETTINGS = "SET_DOCUMENT_SECTION_SETTINGS";
export const SET_SETTINGS                  = "DOCUMENT-SECTION/SET_SETTINGS";
export const REQUEST_DOCUMENT_STATUS       = "REQUEST_DOCUMENT_STATUS";
export const SET_DOCUMENT_STATUS           = "SET_DOCUMENT_STATUS";
export const SET_DOCUMENT_PARAMETERS       = "SET_DOCUMENT_PARAMETERS";
export const REQUEST_DATA_DOCUMENT_SIDES   = "REQUEST_DATA_DOCUMENT_SIDES";
export const SET_DATA_DOCUMENT_SIDES       = "DOCUMENT-SECTION/SET_DATA_DOCUMENT_SIDES";
