import m from 'mithril';
import TopPanel from './components/TopPanel';
import Inner from './components/Inner';
import Logo from './components/Logo';
import Info from './components/Info';
import Nav from './components/Nav';
import Menu from './components/Menu';
import Tools from './components/Tools';

class HeaderToolbar {
    view({ children }) {
        return (
            <div className="toolbar">
                { children }
            </div>
        )
    }
}

HeaderToolbar.TopPanel = TopPanel;
HeaderToolbar.Inner    = Inner;
HeaderToolbar.Logo     = Logo;
HeaderToolbar.Info     = Info;
HeaderToolbar.Nav      = Nav;
HeaderToolbar.Menu     = Menu;
HeaderToolbar.Tools    = Tools;

export default HeaderToolbar;
