import stream from "mithril/stream";

export const model = stream({});

export const Role = () => ({
    view(vnode) {
        const { id = "" } = vnode.attrs;

        if (model()[id] === false) {
            return null;
        }

        return vnode.children;
    }
});

export const isShow = id => {
    return model()[id] !== false;
}
