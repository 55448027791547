import "mithril";
import Component from "_/lib/Component";
import {translate} from "_/localizations";
import NumberInput from "_/components/dialogs/printProperties/components/NumberInput";

class PageNumbers extends Component {
    view() {
        const { printSettings, isMain } = this.attrs;
        const {
            pageNumbers = {},
            showPagesNumbers = false,
            pageNumbersStep = 1,
            pageNumbersMin = 0,
            pageNumbersMax = 999999999
        }                         = printSettings;
        const {
            alignBottom = "center",
            alignTop = "center",
            isBottom = true,
            isTop = false,
            prefix = "Стр. ",
            firstNumberOfSection = 0,
            firstNumberOfDocument = 0,
            withTotalCount = true,
            isShowNumberOnFirstPageOfSection = true,
            isShowNumberOnFirstPageOfDocument = true
        }                         = pageNumbers;

        let statusApproved = turbo.lib.checkApprovedDocumentTemplate();

        return [
            <div className="print-properties__body-row">
                <div className="print-properties__body-item wl100">
                    <label className="checkbox-radio wl100">
                        <input
                            type="checkbox"
                            checked={showPagesNumbers}
                            onchange={() => this.onChange(null, "showPagesNumbers", !showPagesNumbers)}
                            disabled={statusApproved}
                        />
                        <i/>
                        <span className="fs14 ml5">
                            {translate("printProperties.pageNum")}
                        </span>
                    </label>
                </div>
            </div>,

            <div className="print-properties__body-row">
                <div className="print-properties__body-item wl100">
                    {isMain
                        ? (
                            <label className="checkbox-radio wl100">
                                <input
                                    type="checkbox"
                                    checked={isShowNumberOnFirstPageOfDocument}
                                    onchange={() => this.onChange(
                                        "pageNumbers",
                                        "isShowNumberOnFirstPageOfDocument",
                                        !isShowNumberOnFirstPageOfDocument
                                    )}
                                    disabled={statusApproved}
                                />
                                <i/>
                                <span className="fs14 ml5">
                                    {translate("printProperties.firstPageOfDocument")}
                                </span>
                            </label>
                        ) : (
                            <label className="checkbox-radio wl100">
                                <input
                                    type="checkbox"
                                    checked={isShowNumberOnFirstPageOfSection}
                                    onchange={() => this.onChange(
                                        "pageNumbers",
                                        "isShowNumberOnFirstPageOfSection",
                                        !isShowNumberOnFirstPageOfSection
                                    )}
                                    disabled={statusApproved}
                                />
                                <i/>
                                <span className="fs14 ml5">
                                    {translate("printProperties.firstPageOfSection")}
                                </span>
                            </label>
                        )
                    }

                </div>
            </div>,

            <div className="print-properties__body-row">
                <div className="print-properties__body-item wl100">
                    <label className="checkbox-radio wl100">
                        <input
                            type="checkbox"
                            checked={isTop}
                            onchange={() => this.onChange("pageNumbers", "isTop", !isTop)}
                            disabled={statusApproved}
                        />
                        <i/>
                        <span className="fs14 ml5">
                            {translate("printProperties.showPageOnTopPage")}
                        </span>
                    </label>
                </div>
            </div>,

            <div className="print-properties__body-row">
                <div className="print-properties__body-item">
                    <label
                        className="checkbox-radio_button inline-block v-align-middle"
                        title={translate("printProperties.leftAlignment")}
                    >
                        <input
                            type="radio"
                            name="alignTop"
                            checked={alignTop === "left"}
                            onchange={() => this.onChange("pageNumbers", "alignTop", "left")}
                            disabled={statusApproved}
                        />
                        <div className="bg-blue color-blue text-bold font-fira">
                            <i className="font-icon icon-text-left block fs18"/>
                        </div>
                    </label>
                </div>

                <div className="print-properties__body-item">
                    <label
                        className="checkbox-radio_button inline-block v-align-middle"
                        title={translate("printProperties.centerAlignment")}
                    >
                        <input
                            type="radio"
                            name="alignTop"
                            checked={alignTop === "center"}
                            onchange={() => this.onChange("pageNumbers", "alignTop", "center")}
                            disabled={statusApproved}
                        />
                        <div className="bg-blue color-blue text-bold font-fira">
                            <i className="font-icon icon-text-left block fs18"/>
                        </div>
                    </label>
                </div>

                <div className="print-properties__body-item">
                    <label
                        className="checkbox-radio_button inline-block v-align-middle"
                        title={translate("printProperties.rightAlignment")}
                    >
                        <input
                            type="radio"
                            name="alignTop"
                            checked={alignTop === "right"}
                            onchange={() => this.onChange("pageNumbers", "alignTop", "right")}
                            disabled={statusApproved}
                        />
                        <div className="bg-blue color-blue text-bold font-fira">
                            <i className="font-icon icon-text-left block fs18"/>
                        </div>
                    </label>
                </div>
            </div>,

            <div className="print-properties__body-row">
                <div className="print-properties__body-item wl100">
                    <label className="checkbox-radio wl100">
                        <input
                            type="checkbox"
                            checked={isBottom}
                            onchange={() => this.onChange("pageNumbers", "isBottom", !isBottom)}
                            disabled={statusApproved}
                        />
                        <i/>
                        <span className="fs14 ml5">
                            {translate("printProperties.showPageOnBottomPage")}
                        </span>
                    </label>
                </div>
            </div>,

            <div className="print-properties__body-row">
                <div className="print-properties__body-item">
                    <label
                        className="checkbox-radio_button inline-block v-align-middle"
                        title={translate("printProperties.leftAlignment")}
                    >
                        <input
                            type="radio"
                            name="alignBottom"
                            checked={alignBottom === "left"}
                            onchange={() => this.onChange("pageNumbers", "alignBottom", "left")}
                            disabled={statusApproved}
                        />
                        <div className="bg-blue color-blue text-bold font-fira">
                            <i className="font-icon icon-text-left block fs18"/>
                        </div>
                    </label>
                </div>

                <div className="print-properties__body-item">
                    <label
                        className="checkbox-radio_button inline-block v-align-middle"
                        title={translate("printProperties.centerAlignment")}
                    >
                        <input
                            type="radio"
                            name="alignBottom"
                            checked={alignBottom === "center"}
                            onchange={() => this.onChange("pageNumbers", "alignBottom", "center")}
                            disabled={statusApproved}
                        />
                        <div className="bg-blue color-blue text-bold font-fira">
                            <i className="font-icon icon-text-left block fs18"/>
                        </div>
                    </label>
                </div>

                <div className="print-properties__body-item">
                    <label
                        className="checkbox-radio_button inline-block v-align-middle"
                        title={translate("printProperties.rightAlignment")}
                    >
                        <input
                            type="radio"
                            name="alignBottom"
                            checked={alignBottom === "right"}
                            onchange={() => this.onChange("pageNumbers", "alignBottom", "right")}
                            disabled={statusApproved}
                        />
                        <div className="bg-blue color-blue text-bold font-fira">
                            <i className="font-icon icon-text-left block fs18"/>
                        </div>
                    </label>
                </div>
            </div>,

            <div className="print-properties__body-row">
                <div className="print-properties__body-item">
                    <label className="print-properties__interval-item">
                        <span className="pb5 fs14">
                            {translate("printProperties.prefix")}
                        </span>

                        <input
                            type="text"
                            value={prefix}
                            onchange={event => this.onChange("pageNumbers", "prefix", event.target.value)}
                            disabled={statusApproved}
                        />
                    </label>
                </div>
            </div>,

            <div className="print-properties__body-row">
                <div className="print-properties__body-item wl100">
                    <label className="checkbox-radio wl100">
                        <input
                            type="checkbox"
                            checked={withTotalCount}
                            onchange={() => this.onChange("pageNumbers", "withTotalCount", !withTotalCount)}
                            disabled={statusApproved}
                        />
                        <i/>
                        <span className="fs14 ml5">
                            {translate("printProperties.withTotalCount")}
                        </span>
                    </label>
                </div>
            </div>,

            <div className="print-properties__body-row">
                {
                    isMain
                        ? [
                            <span className="pb5 pr10 fs14">
                                {translate("printProperties.firstNumberOfDocument")}
                            </span>,
                            <label className="print-properties__page-num-item">
                                <NumberInput
                                    step={pageNumbersStep}
                                    min={pageNumbersMin}
                                    max={pageNumbersMax}
                                    small={true}
                                    prefix={''}
                                    value={firstNumberOfDocument}
                                    onChange={value => this.onChange("pageNumbers", "firstNumberOfDocument", value)}
                                    disabled={statusApproved}
                                />
                            </label>
                        ]
                        : [
                            <span className="pb5 pr25 fs14">
                                {translate("printProperties.firstNumberOfSection")}
                            </span>,
                            <label className="print-properties__page-num-item">
                                <NumberInput
                                    step={pageNumbersStep}
                                    min={pageNumbersMin}
                                    max={pageNumbersMax}
                                    small={true}
                                    prefix={''}
                                    value={firstNumberOfSection}
                                    onChange={value => this.onChange("pageNumbers", "firstNumberOfSection", value)}
                                    disabled={statusApproved}
                                />
                            </label>
                        ]
                }
            </div>
        ];
    }

    onChange(parent, name, value) {
        const { onChange } = this.attrs;

        if (typeof onChange === "function") {
            onChange(parent, name, value);
        }
    }
}

export default PageNumbers;
