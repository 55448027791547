export const getDocumentInfo = function(id, currentAgencyId) {
    return m.request({
        method: 'GET',
        // eslint-disable-next-line camelcase
        url   : Routing.generate('turbo_document_category', {id, current_agency_id: currentAgencyId }),
        config(xhr) {
            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
            xhr.setRequestHeader('Content-Type', 'application/json');
        }
    })
};

export const setDocumentMain = function(id, currentAgencyId) {
    return m.request({
        method: 'GET',
        // eslint-disable-next-line camelcase
        url   : Routing.generate('turbo_document_category', {id, current_agency_id: currentAgencyId, main: 1 }),
        config(xhr) {
            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
            xhr.setRequestHeader('Content-Type', 'application/json');
        }
    })
};

export const saveDocumentCategory = function(id, currentAgencyId, category, docTitle, docNumber) {
    return m.request({
        method: 'POST',
        // eslint-disable-next-line camelcase
        url   : Routing.generate('turbo_document_category', {id, current_agency_id: currentAgencyId}),
        // eslint-disable-next-line camelcase
        data  : {doc_category: category, title: docTitle, number: docNumber},
        config(xhr) {
            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
            xhr.setRequestHeader('Content-Type', 'application/json');
        }
    })
}
