import m from "mithril";
import globalState from 'lib/globalState';
import Component from "lib/Component";
import classNames from "classnames";
import {connect} from "_/lib/midux";
import Button from "components/plugins/Button";
import SearchEngine from "lib/searchEngine";
import {translate} from "localizations";
import {showProcess} from 'lib/progressbar';
import {
    changeSearchValue,
    changeSearchSysValue,
    clearSearchValue,
    clearSearchSysValue
} from "actions/common/searchInput"
import Filters from "./components/filters"

const mapStateToProps = state => ({
    state: state.searchInput
});

const mapActionsToProps = {
    changeSearchValue,
    changeSearchSysValue,
    clearSearchValue,
    clearSearchSysValue
};

class SearchInput extends Component {
    oninit({ attrs }) {
        let eventName;

        this._oldStore     = attrs.oldStore;
        this._oldActions   = attrs.oldActions;
        this._type         = attrs["data-type"];
        this._isFilter     = attrs["isFilter"];
        this.showFilters   = false;
        this.strictFilters = {};
        this.showTags      = false;
        this._component    = attrs.component || 'documentFragment';
        this.saveXlsx      = (attrs["saveXlsx"] && attrs["saveXlsx"] === 'false') ? false : true;

        eventName = this._type === "sys" ? "sysLoadDataForSearch" : "loadDataForSearch";
        document.addEventListener(eventName, () => {
            this._search()
        });
    }

    view({ attrs }) {
        let value       = this._type === "sys" ? this.attrs.state.sysValue : this.attrs.state.value,
            filters     = this.attrs.filters,
            disableXlsx = this.checkForData();

        let TurboTree;

        if (this.turboTree) {
            TurboTree = this.turboTree.getElement();
        }
        const inputDivClasses = classNames({ wl95: this.saveXlsx === true });
        const searchButton    = classNames({
            "turbo-search__button turbo-search__submit_filter": this._isFilter === true,
            "turbo-search__button turbo-search__submit"       : this._isFilter !== true,
            "turbo-search__button turbo-search__submit right0": (this._isFilter !== true && this.saveXlsx !== true)
        });
        const clearButton     = classNames({
            "turbo-search__button turbo-search__clear_filter" : this._isFilter === true,
            "turbo-search__button turbo-search__clear"        : this._isFilter !== true,
            "turbo-search__button turbo-search__clear right30": (this._isFilter !== true && this.saveXlsx !== true)
        });

        return (
            <div className="mb15">
                <div className={`turbo-search ${attrs.className || ""}`}>
                    <div className={inputDivClasses}>
                        <input
                            type="text"
                            className="turbo-search__input"
                            placeholder={translate("inputSearch.placeholder")}
                            value={value}
                            oninput={e => (this._changeSearchValue(e.target.value))}
                            onkeyup={e => (this._onKeyUp(e))}
                        />

                        <Button
                            className={searchButton}
                            onclick={() => (this._search())}
                        >
                            <Button.Icon className="zoom"/>
                        </Button>

                        <Choose>
                            <When condition={value}>
                                <Button
                                    className={clearButton}
                                    onclick={() => this._clearValue()}
                                >
                                    <Button.Icon className="circle-close"/>
                                </Button>
                            </When>
                        </Choose>

                        <Choose>
                            <When condition={this._isFilter}>
                                <Button
                                    className="turbo-search__button turbo-search__filter"
                                    onclick={() => this._showFilters(!this.showFilters)}
                                >
                                    <Button.Icon className={this.showFilters ? "filter-icon-selected" : "filter-icon"}/>
                                </Button>
                            </When>
                        </Choose>
                    </div>
                    <Choose>
                        <When condition={this.saveXlsx}>
                            <div>
                                <Button
                                    className="turbo-search__button turbo-search__xls"
                                    onclick={() => (this.toXls(disableXlsx))}
                                    title={translate("inputSearch.exportToXls")}
                                >
                                    <Button.Icon
                                        className={disableXlsx ? "xls-icon color-grey" : "xls-icon color-orange"}/>
                                </Button>
                            </div>
                        </When>
                    </Choose>
                </div>
                <Choose>
                    <When condition={this.showFilters}>
                        <Filters
                            filters={filters}
                            strictFilters={this.strictFilters}
                            onChangeTagValue={this.onChangeTagValue.bind(this)}
                            onChangeFilterValue={this.onChangeFilterValue.bind(this)}
                            onChangeFilterMultiValue={this.onChangeFilterMultiValue.bind(this)}
                            onChangeDateValue={this.onChangeDateValue.bind(this)}
                            isShowTags={this._showTags.bind(this)}
                            showTags={this.showTags}
                        />
                    </When>
                </Choose>
            </div>
        );
    }

    _onKeyUp(e) {
        if (e.which === 27) {
            this._clearValue();
        } else if (e.which === 13) {
            this._search();
        }
    }

    _changeSearchValue(value) {
        if (this._oldStore) {
            this._oldStore.dispatch(this._oldActions.changeSearchValue(value));
        }

        if (this._type === "sys") {
            this.attrs.actions.changeSearchSysValue(value);
        } else {
            this.attrs.actions.changeSearchValue(value);
        }
    }

    onChangeFilterValue(type, value) {
        this.strictFilters[type]           = value;
        this.strictFilters[type].fieldName = type;

        this._search();
    }

    onChangeFilterMultiValue(type, value) {
        let val;

        if (value.some(val => val === "-1")) {
            val = { name: "Все", id: "-1", fieldName: type }

            this.onChangeFilterValue(type, val)
        } else {
            val                                = { name: "multi", id: value, fieldName: type }
            this.strictFilters[type]           = val;
            this.strictFilters[type].fieldName = type;

            this._search();
        }
    }

    onChangeTagValue(actionIds, self) {
        let actions,
            type = 'tags';

        if (actionIds.length < 1) {
            actions = {
                name: "Все",
                id  : '-1'
            }
        } else {
            actions = [];
            actionIds.forEach(id => {
                const node = self.turboTree.getNode(id);

                actions.push({ id: node.id, name: node.name });
            });
        }


        this.strictFilters[type]           = actions;
        this.strictFilters[type].fieldName = type;

        this._search();
    }

    onChangeDateValue(type, value, subField, isPeriod) {
        let data = this.strictFilters[type] || {};

        if (this.strictFilters[type]) {
            if (isPeriod) {
                this.strictFilters[type].date = '';
            } else {
                this.strictFilters[type].dateFrom = '';
                this.strictFilters[type].dateTo   = '';
            }
        }

        data[subField]                     = value;
        this.strictFilters[type]           = data;
        this.strictFilters[type].fieldName = type;
        this.strictFilters[type].isPeriod  = isPeriod;

        this._search();
    }

    _showFilters(show) {
        this.showFilters = show;

        if (!show) {
            this.strictFilters = {};

            this._search();
        }
    }

    _showTags(show) {
        this.showTags = show;
    }


    _clearValue() {
        let nameOfLoadProcessName = this._type === "sys" ? "sysLoadProcessName" : "loadProcessName",
            loadProcessName       = globalState.get(nameOfLoadProcessName),
            event, eventName;

        if (loadProcessName) {
            showProcess(loadProcessName);
        }

        if (this._oldStore) {
            this._oldStore.dispatch(this._oldActions.changeSearchValue(""));
        }

        if (this._type === "sys") {
            this.attrs.actions.clearSearchSysValue();
        } else {
            this.attrs.actions.clearSearchValue();
        }

        this._clearStorage();

        //TODO временное решение пока есть списки не умеющие работать с хранилищем
        eventName = this._type === "sys" ? "sysSearch" : "search";
        event     = new CustomEvent(eventName);
        document.dispatchEvent(event);
    }

    _clearStorage() {
        if (typeof this.attrs.onSetSearchData === "function") {
            this.attrs.onSetSearchData({ searchData: [], relevanceData: [], isSearch: false });
        } else if (this._oldStore) {
            this._oldStore.dispatch(this._oldActions.clearSearchData());
        } else {
            let searchConfigStorage = this._type === "sys" ? "sysSearchConfig" : "searchConfig",
                searchConfig        = globalState.get(searchConfigStorage) || [];

            searchConfig.forEach(config => {
                globalState.set({ [config.searchData]: undefined });
                globalState.set({ [config.relevanceData]: undefined });
            });
        }

        if (this.showFilters && Object.keys(this.strictFilters).length > 0) {
            setTimeout(() => {
                this._search()
            }, 0)
        }
    }

    _search() {
        let value                 = this._type === "sys" ? this.attrs.state.sysValue : this.attrs.state.value,
            searchConfigStorage   = this._type === "sys" ? "sysSearchConfig" : "searchConfig",
            nameOfLoadProcessName = this._type === "sys" ? "sysLoadProcessName" : "loadProcessName",
            loadProcessName       = globalState.get(nameOfLoadProcessName),
            searchConfig, event, eventName;

        if (loadProcessName) {
            showProcess(loadProcessName);
        }

        if (!value && this.showFilters) {
            value = '';
        }

        if (value === undefined || (!this.showFilters && value === '')) {
            this._clearStorage();

            //TODO временное решение пока есть списки не умеющие работать с хранилищем
            eventName = this._type === "sys" ? "sysSearch" : "search";
            event     = new CustomEvent(eventName);
            document.dispatchEvent(event);

            return;
        }

        if (this._oldStore) {
            this._oldStore.dispatch(this._oldActions.search());
            return;
        }

        searchConfig = this.attrs.searchConfig || globalState.get(searchConfigStorage) || [];

        searchConfig.forEach(config => {
            let data, searchEngine, searchData, records, relevanceData;

            data = this.attrs.data || globalState.get(config.data) || [];

            searchEngine = new SearchEngine({
                filterFragment  : value,
                data,
                filterConditions: {
                    fields      : config.fields || [],
                    linkField   : config.linkField,
                    subField    : config.subField,
                    subFields   : config.subFields,
                    subLinkField: config.subLinkField
                },
                strictFilters   : this.strictFilters
            });

            searchData    = searchEngine.search();
            records       = searchData.records;
            relevanceData = searchData.relevanceData;

            if (relevanceData) {
                relevanceData.setHighLight = value === '' ? false : true;
            }

            if (typeof this.attrs.onSetSearchData === "function") {
                this.attrs.onSetSearchData({ searchData: records, relevanceData, isSearch: true });
            }

            if (config.searchData) {
                globalState.set({ [config.searchData]: records });
            }

            if (config.relevanceData) {
                globalState.set({ [config.relevanceData]: relevanceData });
            }
        });

        //TODO временное решение пока есть списки не умеющие работать с хранилищем
        eventName = this._type === "sys" ? "sysSearch" : "search";
        event     = new CustomEvent(eventName);

        document.dispatchEvent(event);
    }

    checkForData() {
        let value               = this._type === "sys" ? this.attrs.state.sysValue : this.attrs.state.value,
            searchConfigStorage = this._type === "sys" ? "sysSearchConfig" : "searchConfig",
            searchConfig        = this.attrs.searchConfig || globalState.get(searchConfigStorage) || [],
            data, searchData, state;

        if (this._oldStore) {
            state = this._oldStore.getState();
            data = state[this._component].list;
            if (!data && !state[this._component].search) {
                return true
            }
            searchData = state[this._component].search.searchData;
        } else {
            data = [];
            searchData = [];

            searchConfig.forEach(config => {
                data = data.concat(this.attrs.data || globalState.get(config.data) || []);
                if (this.attrs.searchData || globalState.get(config.searchData)) {
                    searchData = searchData.concat(this.attrs.searchData || globalState.get(config.searchData))
                }
            });
        }

        if (searchData && searchData.length > 0) {
            data = searchData;
        }

        if ((!value || value === '') && (data && data.length > 0)) {
            return false
        } else {
            return true
        }
    }

    toXls(disable) {
        if (disable) {
            return
        }
        let searchConfigStorage = this._type === "sys" ? "sysSearchConfig" : "searchConfig",
            searchConfig        = this.attrs.searchConfig || globalState.get(searchConfigStorage) || [],
            options             = this.attrs.options || {},
            filter              = [],
            referenceId         = '',
            data, searchData, state,
            entity, filename;

        entity = this._component.split('').map((character) => {
            if (character === character.toUpperCase()) {
                return '-' + character.toLowerCase();
            } else {
                return character;
            }
        }).join('');

        if (this._component === 'groupTextBlock') {
            entity = 'text-block-registry'
        }

        if (this._oldStore) {
            state      = this._oldStore.getState();
            data       = state[this._component].list;
            searchData = state[this._component].search.searchData;

            if (entity === 'text-block') {
                referenceId = "&referenceId=" + state[this._component].groupId
            }
        } else {
            data       = [];
            searchData = [];

            searchConfig.forEach(config => {
                data = data.concat(this.attrs.data || globalState.get(config.data) || []);
                if (this.attrs.searchData || globalState.get(config.searchData)) {
                    searchData = searchData.concat(this.attrs.searchData || globalState.get(config.searchData))
                }
            });
        }

        if (searchData && searchData.length > 0) {
            data = searchData;
        }

        data.forEach(item => {
            filter.push(item.id);
        });

        entity      = options.entity || entity;
        referenceId = options.groupId || referenceId;

        m.request({
            method : "post",
            url    : Routing.generate("turbo_export", { entity, type: "xls", referenceId }),
            data   : { ids: filter },
            config : xhr => {
                xhr.responseType = "blob";
            },
            headers: { Accept: "application/force-download" },
            extract: xhr => {
                let disposition = xhr.getResponseHeader("Content-Disposition");

                if (disposition && disposition.indexOf("attachment") !== -1) {
                    let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
                        matches       = filenameRegex.exec(disposition);

                    if (matches !== null && matches[1]) {
                        filename = decodeURI(matches[1].replace(/['"]/g, ''));
                    }
                }

                return xhr.response;
            }
        }).then(function(resp) {
            if (resp.type === "application/json") {
                const fileReader = new FileReader();

                fileReader.onload = function() {
                    const result  = JSON.parse(this.result);
                    const message = result.error ? [result.error] : [translate("common.serverError")];

                    turbo.components.alerts.error(message);
                };

                fileReader.readAsText(resp);
            } else if (resp.type === "application/force-download") {
                if (navigator.msSaveOrOpenBlob) {
                    navigator.msSaveOrOpenBlob(resp, filename || "report.xls");
                } else {
                    let downloadLink = document.createElement("a");

                    downloadLink.classList.add("download-list-report");
                    downloadLink.style.display = "none";
                    document.body.appendChild(downloadLink);

                    downloadLink.href     = window.URL.createObjectURL(resp);
                    downloadLink.download = filename || "report.xls";

                    downloadLink.click();
                    document.body.removeChild(downloadLink);

                    setTimeout(function() {
                        URL.revokeObjectURL(downloadLink.href);
                    }, 1500);
                }
            } else {
                turbo.components.alerts.error([translate("common.serverError")]);
            }
        });
    }
}

export default connect(mapStateToProps, mapActionsToProps)(SearchInput);
