import "mithril";
import Component from "_/lib/Component";
import {translate} from "_/localizations";

class Header extends Component {
    view() {
        return (
            <div className="modal__header bg-blue color-white">
                <div className="modal-close font-icon cancel"/>
                <span>{translate("relatedData.title")}</span>
            </div>
        );
    }
}

export default Header;
