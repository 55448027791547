import m from "mithril";
import classNames from "classnames";
import Component from 'lib/Component';
import {translate} from 'localizations';
import Button from "components/plugins/Button";
import {SINGLE_MODE, MULTI_MODE} from "./constants";
import ValuesListTop from "./components/ValuesListTop"
import ValuesListBody from "./components/ValuesListBody"

class SettingsValuesList extends Component {
    oninit() {
        this.data     = this.attrs.data || [];
        this.addMode  = SINGLE_MODE;
        this.editMode = false;
    }

    view() {
        let isDisableSave = this.data.length === 0 || this.editMode,
            noSaveTitle   = "";

        if (this.data.length === 0) {
            noSaveTitle = translate("settingValuesList.isDisableSaveEmptyTitle");
        } else if (this.editMode) {
            noSaveTitle = translate("settingValuesList.isDisableSaveEditTitle");
        }

        return (
            <div>
                <div className="modal__header bg-blue color-white">
                    <div className="modal-close font-icon cancel"/>
                    <span>{translate("settingValuesList.title")}</span>
                </div>

                <div className="modal__body">
                    <div className="values-list form-item">
                        <ValuesListTop
                            addMode={this.addMode}
                            onChangeAddMode={this.changeAddMode.bind(this)}
                            onAdd={this.onAddValues.bind(this)}
                            onEdit={this.onEditValue.bind(this)}
                            onCancelEdit={this.onCancelEdit.bind(this)}
                            editMode={this.editMode}
                            editValue={this.editValue}
                        />

                        <ValuesListBody
                            data={this.data}
                            editMode={this.editMode}
                            onEditMode={this.onEditMode.bind(this)}
                            onMoveBefore={this.onMoveBefore.bind(this)}
                            onMoveAfter={this.onMoveAfter.bind(this)}
                            onDelete={this.onDelete.bind(this)}
                        />
                    </div>
                </div>

                <div className="modal__footer bg-grey text-right">
                    <Button className="turbo-button__size_md dashed-link color-blue p0 mr10 modal-close">
                        {translate("buttons.cancel")}
                    </Button>
                    <Button
                        className="turbo-button__size_md turbo-button__bg_orange turbo-button__text_white modal-close"
                        onclick={() => (this.onSave())}
                        disabled={isDisableSave}
                        title={noSaveTitle}
                    >
                        {translate("buttons.save")}
                    </Button>
                </div>
            </div>
        );
    }

    changeAddMode() {
        this.addMode = this.addMode === SINGLE_MODE ? MULTI_MODE : SINGLE_MODE;
    }

    onAddValues(values = "") {
        let arrValue = values.split("\n"),
            uniqueValues;

        if (this.checkDuplicate(arrValue)) {
            return false;
        }

        uniqueValues = this.getNotDuplicateValues(arrValue);

        uniqueValues.forEach(value => {
            if (this.data.indexOf(value) === -1 && value.trim() !== "") {
                this.data.push(value.trim());
            }
        });

        if (uniqueValues.length < arrValue.length) {
            turbo.plugins.modal.alert({
                title      : translate("settingValuesList.warningPartDuplicates"),
                iconClasses: "warning fs24 mr10 color-red"
            });
        }

        return true;
    }

    onEditValue(value) {
        if (this.checkDuplicate([value])) {
            return false;
        }

        this.data[this.editIndex] = value;
        this.editMode             = false;
        this.editValue            = "";
        this.editIndex            = -1;

        return true;
    }

    onEditMode(index) {
        this.editMode  = true;
        this.editIndex = index;
        this.editValue = this.data[index];
        this.addMode   = SINGLE_MODE;
    }

    onCancelEdit() {
        this.editMode  = false;
        this.editValue = "";
        this.editIndex = -1;
    }

    onMoveBefore(index, targetIndex) {
        let shift = index < targetIndex ? 0 : 1;

        this.data.splice(targetIndex, 0, this.data[index]);
        this.data.splice(index + shift, 1);
    }

    onMoveAfter(index, targetIndex) {
        let shift = index < (targetIndex + 1) ? 0 : 1;

        this.data.splice(targetIndex + 1, 0, this.data[index]);
        this.data.splice(index + shift, 1);
    }

    onDelete(index) {
        this.data.splice(index, 1);
    }

    onSave() {
        const { onSave } = this.attrs;

        if (typeof onSave === "function") {
            onSave([...this.data]);
        }
    }

    checkDuplicate(values = []) {
        if (this.isAllDuplicate(values)) {
            turbo.plugins.modal.alert({
                title      : values.length > 1
                    ? translate("settingValuesList.warningDuplicates")
                    : translate("settingValuesList.warningDuplicate"),
                iconClasses: "warning fs24 mr5 color-red"
            });

            return true;
        }

        return false;
    }

    getNotDuplicateValues(values = []) {
        let uniqueValues = [],
            index, value;

        for (index = 0; index < values.length; index++) {
            value = values[index];

            if (this.data.indexOf(value) === -1) {
                uniqueValues.push(value);
            }
        }

        return uniqueValues;
    }

    isAllDuplicate(values = []) {
        let index, value;

        for (index = 0; index < values.length; index++) {
            value = values[index];

            if (this.data.indexOf(value) === -1) {
                return false;
            }
        }

        return true;
    }
}

export default SettingsValuesList;
