import "mithril";
import Component from "lib/Component";
import Button from "components/plugins/Button";
import ChooseSimple from "_/components/common/ChooseSimple";
import SimpleListFields from "./SimpleListFields";
import ListTextBlockOfRegistryFields from "./ListTextBlockOfRegistryFields";
import {translate} from "localizations";
import {SIMPLE_LIST, LIST_TEXT_BLOCK_OF_REGISTRY} from "_/constants/typesOfScheme";

//Корень для локализации
const ROOT = "listObjectOfRegistryByLink";

class AddEditScheme extends Component {
    oninit() {
        const data = this.attrs.data || {};

        this.docType = this.attrs.type;

        this.data = {
            name   : "",
            type   : "",
            ...data,
            options: { ...data.options }
        };

        this.title = data.id
            ? `${translate(`${ROOT}.addEditScheme.titleEdit`)} "${data.name}"`
            : translate(`${ROOT}.addEditScheme.titleAdd`);

        this.validateInfo = {
            isValidate       : false,
            isValidateOptions: false,
            message          : translate(`${ROOT}.warning.notValidateScheme`)
        };
    }

    view() {
        const { listId, typesOfScheme = [], typesOfView = [], separators = [] } = this.attrs;

        let modalClass = this.attrs.modalClass,
            data       = this.data,
            titleSaveButton, isValidate, validateMessage,
            updateOptions, updateDescription, updateValidateInfo;

        titleSaveButton = data.id ? translate("buttons.save") : translate("buttons.create");

        isValidate      = this.validateInfo.isValidate;
        validateMessage = isValidate ? "" : this.validateInfo.message;

        updateOptions      = options => this.updateOptions(options);
        updateDescription  = description => this.updateDescription(description);
        updateValidateInfo = (isValidate, message) => this.updateValidateInfo(isValidate, true, message);

        return (
            <div>
                <div className="modal__header bg-blue color-white">
                    <div className="modal-close font-icon cancel"/>
                    <span>{this.title}</span>
                </div>
                <div className="modal__body">
                    <div className="form-item">
                        <span>{translate(`${ROOT}.addEditScheme.nameScheme`)}</span>
                        <input
                            type="text"
                            oninput={e => this.changeName(e.target.value)}
                            value={data.name}
                        />
                    </div>
                    <Choose>
                        <When condition={!data.id}>
                            <div className="form-item mt10">
                                <span>{translate(`${ROOT}.addEditScheme.typeScheme`)}</span>
                                <ChooseSimple
                                    data-popup-key="add_scheme"
                                    data={typesOfScheme}
                                    modal={`.${modalClass}`}
                                    onChange={item => (data.type = item.id)}
                                    isCanEmpty={false}
                                    value={data.type}
                                />
                            </div>
                        </When>
                    </Choose>

                    <Choose>
                        <When condition={+data.type === SIMPLE_LIST}>
                            <SimpleListFields
                                listId={listId}
                                type={data.options.type}
                                docType={this.docType}
                                element={data.options.element}
                                typeSeparator={data.options.typeSeparator}
                                separator={data.options.separator}
                                format={data.options.format}
                                typesOfView={typesOfView}
                                separators={separators}
                                modalClass={modalClass}
                                updateOptions={updateOptions}
                                updateDescription={updateDescription}
                                updateValidateInfo={updateValidateInfo}
                            />
                        </When>
                        <When condition={+data.type === LIST_TEXT_BLOCK_OF_REGISTRY}>
                            <ListTextBlockOfRegistryFields
                                listId={listId}
                                docType={this.docType}
                                textBlock={data.options.textBlock}
                                method={data.options.method}
                                typeSeparator={data.options.typeSeparator}
                                separator={data.options.separator}
                                typesOfView={typesOfView}
                                separators={separators}
                                modalClass={modalClass}
                                updateOptions={updateOptions}
                                updateDescription={updateDescription}
                                updateValidateInfo={updateValidateInfo}
                            />
                        </When>
                    </Choose>
                </div>
                <div className="modal__footer bg-grey text-right">
                    <Button className="turbo-button__size_md dashed-link color-blue p0 mr10 modal-close">
                        {translate("buttons.cancel")}
                    </Button>
                    <Button
                        disabled={!isValidate}
                        title={validateMessage}
                        className="turbo-button__size_md turbo-button__bg_orange turbo-button__text_white"
                        onclick={() => this.save()}
                    >
                        {titleSaveButton}
                    </Button>
                </div>
            </div>
        );
    }

    save() {
        const
            { onSave, onValidate, modalClass } = this.attrs,
            { separator = "" }                 = this.data.options;

        if (typeof onValidate === "function") {
            if (!onValidate(this.data.name.trim(), this.data.type, this.data.id)) {
                return;
            }
        }

        this.data.options.separator = separator.replace(/\s{2,}/g, " ");

        if (typeof onSave === "function") {
            onSave(this.data);
        }

        turbo.plugins.modal.close(document.querySelector(`.${modalClass}`));
    }

    changeName(value) {
        let isValidate, validateMessage;

        this.data.name = value;

        isValidate      = this.isValidateFn();
        validateMessage = isValidate ? "" : translate(`${ROOT}.warning.notValidateScheme`);

        this.updateValidateInfo(isValidate, false, validateMessage);
    }

    updateOptions(options) {
        this.data.options = { ...this.data.options, ...options };

        return { ...this.data.options };
    }

    updateDescription(description) {
        this.data.description = description;
    }

    updateValidateInfo(isValidate, isOptions, message) {
        if (isOptions) {
            let isValidateLocal = this.isValidateFn();

            this.validateInfo.isValidateOptions = isValidate;
            this.validateInfo.isValidate        = isValidateLocal && this.validateInfo.isValidateOptions;
        } else {
            this.validateInfo.isValidate = isValidate && this.validateInfo.isValidateOptions;
        }

        this.validateInfo.message = message;
    }

    isValidateFn() {
        return this.data.name.trim() !== "" && this.data.type !== undefined && this.data.type !== null;
    }
}

export default AddEditScheme;
