import m from "mithril";
import classNames from "classnames";
import turbo from "_/lib/turbo";
import Component from "_/lib/Component";
import {translate} from 'localizations';
import Button from "components/plugins/Button";
import {Container} from "_/components/plugins/ScrollBar";
import {getPrintProperties, saveProperties} from "./promises";
import BaseSettings from "_/components/dialogs/printProperties/components/BaseSettings";
import PaperSettings from "_/components/dialogs/printProperties/components/PaperSettings";
import LevelsSettings from "_/components/dialogs/printProperties/components/LevelsSettings";
import PageNumbers from "_/components/dialogs/printProperties/components/PageNumbers";

const BASE         = "base";
const PAGE         = "page";
const LEVELS       = "levels";
const PAGE_NUMBERS = "pageNumbers";

class PrintProperties extends Component {
    oninit() {
        this.currentTab = BASE;

        this.controlsSettings = { fontFamilyNames: {} };
        this.printSettings    = { levels: [] };

        this.fonts = [];

        getPrintProperties(this.attrs.urlGet)
            .then(result => {
                let fontFamilyNames = {};

                this.printSettings    = result.print_settings || this.printSettings;
                this.controlsSettings = result.controls_settings || this.controlsSettings;

                fontFamilyNames = this.controlsSettings.fontFamilyNames;

                this.fonts = Object.keys(fontFamilyNames).map(fontKey => {
                    return { id: fontFamilyNames[fontKey], name: fontFamilyNames[fontKey] };
                });
            });
    }

    view() {
        const tabs = [BASE, PAGE, LEVELS, PAGE_NUMBERS];

        let statusApproved = turbo.lib.checkApprovedDocumentTemplate();

        return m.fragment({}, [
            <div className="modal__header bg-blue color-white">
                <div className="font-icon cancel modal-close"/>
                <span>{translate("printProperties.title")}</span>
            </div>,

            <div className="modal__body p0 print-properties print-properties__body">
                <div className="print-properties__body-wrapper">
                    <div className="tabs tabs_bordered fs16 contoured">
                        <div className="ml10 mr10 turbo-tabs__group-flex pt5 pb5 mb15">
                            {
                                tabs.map(tab => {
                                    const classes = classNames(
                                        "tabs__button text-nowrap",
                                        { active: this.currentTab === tab }
                                    );

                                    return (
                                        <div
                                            className={classes}
                                            onclick={() => this.onChangeTab(tab)}
                                        >
                                            <span>{translate(`printProperties.parts.${tab}`)}</span>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>

                    <Container isScrollHasY={true} isScrollHasX={false}>
                        <div className="p15">
                            {this.renderPageOfTab(this.currentTab)}
                        </div>
                    </Container>
                </div>
            </div>,

            <div className="modal__footer bg-grey row row_table">
                <div className="col-6 v-align-middle">
                    <label className="checkbox-radio">
                        <input
                            type="checkbox"
                            checked={this.printSettings.apply_to_attachments}
                            onchange={() => this.onChangeRadioDefaultValue("apply_to_attachments")}
                            disabled={statusApproved}
                        />
                        <i/>
                        <span className="fs14 ml5">{translate("printProperties.toAttachments")}</span>
                    </label>
                </div>

                <div className="col-6 v-align-middle text-right">
                    <Button
                        className="turbo-button__size_md dotted-link mr20 fs14 p0 modal-close color-blue"
                        disabled={statusApproved}>
                        {translate("printProperties.textButtonCancel")}
                    </Button>

                    <Button
                        className="turbo-button__size_md turbo-button__bg_orange turbo-button__text_white"
                        onclick={() => statusApproved ? null : this.save()}
                        disabled={statusApproved}
                    >
                        {translate("printProperties.textButtonSave")}
                    </Button>
                </div>
            </div>
        ]);
    }

    renderPageOfTab(tab) {
        switch (tab) {
            case BASE:
                return (
                    <BaseSettings
                        printSettings={this.printSettings}
                        controlsSettings={this.controlsSettings}
                        fonts={this.fonts}
                        onChangeValue={(val, type) => this.onChangeDefaultValue(val, type)}
                        onChangeRadioValue={type => this.onChangeRadioDefaultValue(type)}
                    />
                );
            case PAGE:
                return (
                    <PaperSettings
                        printSettings={this.printSettings}
                        controlsSettings={this.controlsSettings}
                        onChangeValue={(val, type) => this.onChangeDefaultValue(val, type)}
                        onChangeRadioValue={type => this.onChangeRadioDefaultValue(type)}
                    />
                );
            case LEVELS:
                return (
                    <div>
                        <LevelsSettings
                            printSettings={this.printSettings}
                            onChangeRadioValue={(index, type, value) => {
                                return this.onChangeRadioDefaultLevelsValue(index, type, value)
                            }}
                        />
                    </div>
                );
            case PAGE_NUMBERS:
                return (
                    <PageNumbers
                        printSettings={this.printSettings}
                        onChange={this.onChange.bind(this)}
                        isMain={this.attrs.isMain}
                    />
                );
            default:
                return null;
        }
    }

    onChangeDefaultValue(val, type) {
        this.printSettings[type] = val;
    }

    onChangeRadioDefaultValue(type) {
        this.printSettings[type] = !this.printSettings[type];
    }

    onChangeRadioDefaultLevelsValue(index, type, val) {
        if (val === undefined || val === null) {
            this.printSettings.levels[index][type] = !this.printSettings.levels[index][type];
        } else {
            this.printSettings.levels[index][type] = val;
        }
    }

    onChange(parent, name, value) {
        if (parent) {
            if (!this.printSettings[parent]) {
                this.printSettings[parent] = {};
            }

            this.printSettings[parent][name] = value;
        } else {
            this.printSettings[name] = value;
        }
    }

    onChangeTab(tab) {
        this.currentTab = tab;
    }

    save() {
        let data = {
            // eslint-disable-next-line camelcase
            apply_to_attachments: this.printSettings.apply_to_attachments || false,
            settings            : this.printSettings
        };

        if (this.attrs.entityId) {
            // eslint-disable-next-line camelcase
            data.entity_id = this.attrs.entityId;
        }

        saveProperties(this.attrs.urlSave, data)
            .then(() => {
                turbo.plugins.modal.close(document.querySelector('.printProperties'));

                turbo.plugins.modal.alert({
                    title       : translate("printProperties.messageSaveSettings"),
                    iconClasses : "success color-green fs24 mr5",
                    closeTimeout: 2000
                });
            });
    }
}

export default PrintProperties;
