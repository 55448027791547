import "mithril";
import {connect} from "_/lib/midux";
import Component from "_/lib/Component";
import {translate} from "_/localizations";
import Button from "_/components/plugins/Button";
import QuestionName from "./QuestionName";
import QuestionType from "./QuestionType";
import QuestionTypeField from "./QuestionTypeField";
import QuestionDefaultValue from "./QuestionDefaultValue";
import QuestionRelatedValue from "_/containers/questionnaire/components/QuestionRelatedValue";
import {removeCurrentQuestion, deleteTmpQuestion} from "_/actions/questionnaire";
import {FIELD_OF_OBJECT_OR_OWN_REGISTRY, OBJECT_OF_REGISTRY} from "_/constants/questionsOfRelatedData";
import {saveQuestion} from "_/service/questionnaire";

const mapStateToProps = state => ({
    question: state.questionnaire.currentQuestion,
    info    : state.currentInfo
});

const mapActionsToProps = {
    removeCurrentQuestion,
    deleteTmpQuestion
};

class QuestionEdit extends Component {
    oninit() {
        document.addEventListener('customSaveQuestion', () => saveQuestion());
    }

    view() {
        const { name, question } = this.attrs;

        return (
            <div className="turbo-question__item tile-list__item">
                <div className="turbo-question__item-title">
                    <span>{name}</span>
                    <div className="group-h-button">
                        <Button
                            onclick={() => saveQuestion()}
                        >
                            <Button.Text className="dashed-link color-blue fs13 text-normal">
                                {translate("buttons.save")}
                            </Button.Text>
                        </Button>
                        <Button
                            title={translate("buttons.cancel")}
                            onclick={() => this.onCancel()}
                        >
                            <Button.Icon className="block close-icon pl5 fs12 color-blue"/>
                        </Button>
                    </div>
                </div>
                <div className="turbo-question__item-form form-item">
                    <QuestionName/>
                    <QuestionType/>
                    {
                        +question.type === FIELD_OF_OBJECT_OR_OWN_REGISTRY
                            ? (<QuestionTypeField/>)
                            : (<QuestionRelatedValue/>)
                    }
                    {
                        [FIELD_OF_OBJECT_OR_OWN_REGISTRY, OBJECT_OF_REGISTRY].includes(+question.type)
                            ? (<QuestionDefaultValue/>)
                            : null
                    }
                </div>
            </div>
        );
    }

    onCancel() {
        this.attrs.actions.removeCurrentQuestion();
        this.attrs.actions.deleteTmpQuestion();
    }
}

export default connect(mapStateToProps, mapActionsToProps)(QuestionEdit);
