import m from 'mithril';
import TextEllipsis from 'components/plugins/TextEllipsis';
import {Role} from "_/lib/roleModel";

export default class Nav {
    view({ attrs }) {
        let { main = {}, items = [], title = '', subTitle = '', comment, typeDocument } = attrs,
            breadCrumbsCode, titleCode;

        if (typeDocument === "document-section") {
            breadCrumbsCode = "1-2-3-0-1";
            titleCode       = "1-2-3-0-2";
        } else if (typeDocument === "document-template") {
            breadCrumbsCode = "1-3-0-4-0-1";
            titleCode       = "1-3-0-4-0-2";
        }

        return (
            <div className="toolbar-nav g-clearfix">
                <Role id={breadCrumbsCode}>
                    <div className="toolbar-nav__item">
                        <div className="toolbar__trigger">
                            <a href={main.route} onclick={e => this.onClick(e, attrs.checkConditions(main.route))}>
                                <span className="solid-link">
                                    {main.label}
                                </span>
                            </a>
                            <div className="toolbar__menu">
                                <For each="item" of={items}>
                                    {
                                        item.isHide ? "" : (
                                            <a href={item.route} className="toolbar__menu-item color-black" onclick={e => this.onClick(e, attrs.checkConditions(item.route))}>
                                                <i className={`toolbar__menu-icon font-icon ${item.icon || ""}`}/>
                                                {item.label}
                                            </a>
                                        )
                                    }
                                </For>
                            </div>
                        </div>
                        <span>→</span>
                    </div>
                </Role>
                <Role id={titleCode}>
                    <div className="toolbar-nav__title">
                        <TextEllipsis>
                            <span title={comment ? comment : title}>{title}</span>
                            <span className="fs12 pl10 color-grey" title={subTitle}>{subTitle}</span>
                        </TextEllipsis>
                    </div>
                </Role>
            </div>
        )
    }

    onClick(event, checkConditions) {
        event.preventDefault();
        if (typeof checkConditions === "function") {
            checkConditions()
        }
    }
}
