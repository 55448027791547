import m from "mithril";
import Component from "lib/Component";
import PopUp from "components/plugins/PopUp"

class PopUpTooltip extends Component {
    view({ children }) {
        let modal        = this.attrs.modal,
            dataPopupKey = this.attrs["data-popup-key"];

        return (
            <PopUp
                modal={modal}
                data-popup-key={dataPopupKey}
                trigger={{side: "SIDE_LEFT", position: "POSITION_START"}}
            >
                <div className="popup-tooltip__inner color-blue">
                    {children}
                </div>
            </PopUp>
        );
    }
}

export default PopUpTooltip;
