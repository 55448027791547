import m from 'mithril';

export default class RowRight {
    view({ children }) {
        return (
            <div className="col-2 v-align-middle">
                { children }
            </div>
        )
    }
}
