import {
    REQUEST_BINDING_WORK_GROUP_TO_USER,
    REQUEST_CREATE_WORK_GROUP,
    REQUEST_LIST_WORK_GROUPS,
    REQUEST_REMOVE_WORK_GROUP,
    REQUEST_UPDATE_WORK_GROUP,
    SET_LIST_WORK_GROUPS
} from "_/types/work-groups";

export const requestListWorkGroups = () => ({
    type: REQUEST_LIST_WORK_GROUPS
});

export const setListWorkGroups = payload => ({
    type: SET_LIST_WORK_GROUPS,
    payload
});

export const requestCreateWorkGroup = payload => ({
    type: REQUEST_CREATE_WORK_GROUP,
    payload
});

export const requestUpdateWorkGroup = payload => ({
    type: REQUEST_UPDATE_WORK_GROUP,
    payload
});

export const requestRemoveWorkGroup = payload => ({
    type: REQUEST_REMOVE_WORK_GROUP,
    payload
});

export const requestBindingWorkGroupToUser = payload => ({
    type: REQUEST_BINDING_WORK_GROUP_TO_USER,
    payload
});
