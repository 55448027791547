import "mithril";
import globalState from "lib/globalState";
import Dropdown from "components/plugins/Dropdown";
import TextEllipsis from "components/plugins/TextEllipsis";
import {translate} from "localizations";
import {isShow} from "_/lib/roleModel";

export default class UserMenu {
    view({ attrs }) {
        let user         = globalState.get('userName') || globalState.get('userEmail'),
            currentRoute = globalState.get('currentRoute');

        let profileEnable = globalState.get('accountOptions')
            ? globalState.get('accountOptions').sections.profile.isVisible
            : true;

        let items = [
            {
                isRender : profileEnable && isShow("0-4-0"),
                route    : "turbo_user_profile_edit",
                isCurrent: currentRoute === "turbo_user_profile_edit",
                className: "color-blue",
                label    : translate('links.userEdit'),
                icon     : "pencil",
                onclick  : (e) => this.onClick(e, attrs.checkConditions(Routing.generate("turbo_user_profile_edit")))
            },
            {
                isRender : profileEnable && isShow("0-4-1"),
                route    : "turbo_front_messages_settings",
                isCurrent: currentRoute === "turbo_front_messages_settings",
                className: "color-blue",
                label    : translate('links.noticeSettings'),
                icon     : "bell-circuit text-bold",
                onclick  : (e) => this.onClick(e, attrs.checkConditions(Routing.generate("turbo_front_messages_settings")))
            },
            {
                isRender : isShow("0-4-2"),
                route    : "turbo.fos_user.security.logout",
                className: "color-orange",
                isCurrent: false,
                label    : translate('links.exit'),
                icon     : "exit",
                onclick  : (e) => this.onClick(e, attrs.checkConditions(Routing.generate("turbo.fos_user.security.logout")))
            }
        ];

        return (
            <Dropdown right={true} className="block">
                <Dropdown.Label indicate={true} className="turbo-text-icon color-blue">
                    <i
                        title={translate('helpers.yourAccount')}
                        className="font-icon font-icon_circle user fs20 text-bold inline-block"
                    />
                    <TextEllipsis className="solid-link v-align-middle fs14 maxw200">
                        {user}
                    </TextEllipsis>
                </Dropdown.Label>
                <Dropdown.Inner>
                    <For each="item" of={items}>
                        <Dropdown.Item
                            render={item.isRender}
                            href={Routing.generate(item.route)}
                            disabled={item.isCurrent}
                            rel="nofollow"
                            className={item.className}
                            label={item.label}
                            icon={item.icon}
                            onclick={item.onclick}
                        />
                    </For>
                </Dropdown.Inner>
            </Dropdown>
        );
    }

    onClick(event, checkConditions) {
        event.preventDefault();
        if (typeof checkConditions === "function") {
            checkConditions()
        }
    }
}
