import {
    SET_DOCUMENT_SECTION_STATE,
    CHANGE_DOCUMENT_HISTORY_VIEW,
    CHANGE_DOCUMENT_COMMENTS_VIEW,
    CHANGE_DOCUMENT_SIDE_VIEW,
    SET_DOCUMENT_SECTION_SETTINGS,
    SET_DOCUMENT_STATUS, SET_DOCUMENT_PARAMETERS, REQUEST_DATA_DOCUMENT_SIDES, SET_DATA_DOCUMENT_SIDES
} from 'types/document-section';
import {setDataContentsAndRevisions} from "./contentsAndRevisions";

const getLoadDataDocumentSectionSettingsAsync = (documentId, agencyId) => (
    mt.s.get({
        url        : Routing.generate(
            "turbo_document_properties",
            {
                id: documentId,
                // eslint-disable-next-line camelcase
                current_agency_id: agencyId
            }
        ),
        loadConfig : { isShow: true, name: "DocumentSection: getLoadDataDocumentSectionSettingsAsync" },
        errorConfig: { isShow: true }
    })
);

const getDocumentStatusAsync = (documentId) => (
    mt.s.get({
        url        : Routing.generate("document_workflow_document_current_status", { id: documentId }),
        errorConfig: { isShow: true }
    })
);

const getDocumentParametersAsync = id => (
    mt.s.get({
        url        : Routing.generate("document_parameters", { id }),
        errorConfig: { isShow: true }
    })
);

export const setState = state => ({
    type: SET_DOCUMENT_SECTION_STATE,
    state
});

export const setStatus = data => ({
    type: SET_DOCUMENT_STATUS,
    data
});

export const setParameters = data => ({
    type: SET_DOCUMENT_PARAMETERS,
    data
});


export const changeDocumentHistoryView = value => ({
    type: CHANGE_DOCUMENT_HISTORY_VIEW,
    value
});

export const changeDocumentCommentsView = value => ({
    type: CHANGE_DOCUMENT_COMMENTS_VIEW,
    value
});

export const changeDocumentSideView = value => ({
    type: CHANGE_DOCUMENT_SIDE_VIEW,
    value
});

export const setDocumentSectionSettings = data => ({
    type: SET_DOCUMENT_SECTION_SETTINGS,
    data
});

export const loadDataDocumentSectionSettingsAsync = (documentId, agencyId) => dispatch => {
    return getLoadDataDocumentSectionSettingsAsync(documentId, agencyId)
        .then(result => {
            let resultData = result.data,
                data       = {};

            // eslint-disable-next-line camelcase
            data.notificationChange              = resultData.immediately_changes;
            data.disagreementProtocol            = resultData.protocol;
            // eslint-disable-next-line camelcase
            data.formationOfHeader               = resultData.header_enabled;
            // eslint-disable-next-line camelcase
            data.formationOfPreamble             = resultData.preamble_enabled;
            // eslint-disable-next-line camelcase
            data.formationOfRequisites           = resultData.requisites_enabled;
            // eslint-disable-next-line camelcase
            data.availableChangeFormation        = resultData.available_change_formation;
            data.documentStatusBarEnabled        = resultData.documentStatusBarEnabled;
            data.documentPartnershipBlockEnabled = resultData.documentPartnershipBlockEnabled;

            dispatch(setDocumentSectionSettings(data));
        });
};

export const loadDocumentStatusAsync = (documentId) => dispatch => {
    return getDocumentStatusAsync(documentId)
        .then(result => {
            let resultData = result.data,
                data       = {};

            data.statusId   = resultData.id;
            data.statusName = resultData.name;

            dispatch(setStatus(data));
        });
};

export const loadDocumentParametersAsync = (documentId, callback) => dispatch => {
    return getDocumentParametersAsync(documentId)
        .then(result => {
            dispatch(setParameters(result.data || {}));
            setTimeout(() => callback(), 800);
        });
};

export const requestDataDocumentSides = payload => ({
    type: REQUEST_DATA_DOCUMENT_SIDES,
    payload
})

export const setDataDocumentSides = payload => ({
    type: SET_DATA_DOCUMENT_SIDES,
    payload
})
