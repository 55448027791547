import m from 'mithril';

export default class Row {
    view({ children }) {
        return (
            <div className="toolbar-table-row__coll toolbar__top-panel-main">
                <div className="row row_table">
                    { children }
                </div>
            </div>
        )
    }
}
