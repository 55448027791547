export const FIELD_TEXT              = 1;
export const FIELD_TEXT_AREA         = 2;
export const FIELD_FINANCE           = 3;
export const FIELD_NUMBER            = 4;
export const FIELD_BOOLEAN           = 5;
export const FIELD_DATE              = 6;
export const FIELD_SELECT            = 7;
export const FIELD_SELECT_EDIT       = 8;
export const FIELD_TITLE             = 9;
export const FIELD_TEXT_BLOCK        = 10;
export const FIELD_MULTI_SELECT      = 11;
export const FIELD_MULTI_SELECT_EDIT = 12;
export const FIELD_PERCENTAGE        = 13;
export const FIELD_LINK              = 14;
export const FIELD_FIO               = 15;
export const FIELD_CALC              = 16;
export const FIELD_TEXT_BLOCK_EDIT   = 17;
