import m from "mithril";
import Component from "lib/Component";

class FIO extends Component {
    oninit() {
        this.value  = this.attrs.value;
        this.data   = {
            name :      '',
            secondName: '',
            surname:    ''
        };

        this.getData();
    }

    view() {
        return (
            <div>
                <span>Фамилия</span>
                <input
                    type="text"
                    placeholder={"Фамилия"}
                    oninput={e => this.changeValue(e)}
                    value={this.data.surname}
                    name= "surname"
                    onblur={() => this.blurFunc()}
                    disabled={this.attrs.disabled || false}
                />
                <span>Имя</span>
                <input
                    type="text"
                    placeholder={"Имя"}
                    oninput={e => this.changeValue(e)}
                    value={this.data.name}
                    name= "name"
                    onblur={() => this.blurFunc()}
                    disabled={this.attrs.disabled || false}
                />
                <span>Отчество</span>
                <input
                    type="text"
                    placeholder={"Отчество"}
                    oninput={e => this.changeValue(e)}
                    value={this.data.secondName}
                    name= "secondName"
                    onblur={() => this.blurFunc()}
                    disabled={this.attrs.disabled || false}
                />
            </div>
        );
    }

    getData() {
        let tmp;

        if (this.value.length > 0) {
            tmp = this.value.split(" ");
            this.data  = {
                name :      tmp[1],
                secondName: tmp[2] || '',
                surname:    tmp[0] || ''
            };
        }
    }

    changeValue(e) {
        let { onChange } = this.attrs,
            value;

        this.data[e.target.name] = e.target.value;

        value = `${this.data.surname} ${this.data.name} ${this.data.secondName}`

        if (typeof onChange === "function") {
            onChange(value);
        }
    }

    blurFunc() {
        let { onBlur } = this.attrs;

        if (typeof onBlur === "function") {
            onBlur();
        }
    }
}

export default FIO;
