import m from "mithril";
import Component from "_/lib/Component";
import {translate} from 'localizations';
import Button from "_/components/plugins/Button";
import NumberInput from "_/components/dialogs/printProperties/components/NumberInput";
import ChooseSimple from "_/components/common/ChooseSimple";

class SettingsIndent extends Component {
    oninit() {
        const { indentLeft, indentRight, indentFirstLine, tab } = this.attrs;

        this.typesIndent = [
            { id: "indent", name: translate("printProperties.indent") },
            { id: "ledge", name: translate("printProperties.ledge") }
        ];

        this.options = {
            indentLeft,
            indentRight,
            typeFirstLine  : indentFirstLine >= 0 ? "indent" : "ledge",
            indentFirstLine: Math.abs(indentFirstLine),
            tab
        };
    }

    view() {
        const { indentLeft, indentRight, typeFirstLine, indentFirstLine, tab } = this.options;

        let statusApproved = turbo.lib.checkApprovedDocumentTemplate();

        return m.fragment({}, [
            <div className="modal__header bg-blue color-white">
                <div className="font-icon cancel modal-close"/>
                <span>{translate("printProperties.settingsIdentTitle")}</span>
            </div>,

            <div className="modal__body p0">
                <div className="p15">
                    <div className="mb10">
                        <div className="row row_table">
                            <div className="col-6 v-align-middle pr10">
                                <span className="pb5 fs14">
                                    {translate("printProperties.indentLeft")}
                                </span>
                                <NumberInput
                                    step={0.1}
                                    min={0}
                                    max={10}
                                    prefix={"см"}
                                    value={indentLeft}
                                    onChange={value => this.onChangeOption(value, "indentLeft")}
                                    disabled={statusApproved}
                                />
                            </div>
                            <div className="col-6 v-align-middle pr10">
                                <span className="pb5 fs14">
                                    {translate("printProperties.indentRight")}
                                </span>
                                <NumberInput
                                    step={0.1}
                                    min={0}
                                    max={10}
                                    prefix={"см"}
                                    value={indentRight}
                                    onChange={value => this.onChangeOption(value, "indentRight")}
                                    disabled={statusApproved}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <span className="pb5 fs14">
                            {translate("printProperties.firstLine")}
                        </span>
                    </div>
                    <div className="row row_table">
                        <div className="col-6 v-align-middle pr10">
                            <ChooseSimple
                                data-popup-key="typeFirstLine"
                                data={this.typesIndent}
                                onChange={item => this.onChangeOption(item.id, "typeFirstLine")}
                                isCanEmpty={false}
                                isButton={false}
                                countShowItems={5}
                                value={typeFirstLine}
                                disabled={statusApproved}
                            />
                        </div>
                        <div className="col-6 v-align-middle pr10">
                            <NumberInput
                                step={0.1}
                                min={0}
                                max={10}
                                prefix={"см"}
                                value={indentFirstLine}
                                onChange={value => this.onChangeOption(value, "indentFirstLine")}
                                disabled={statusApproved}
                            />
                        </div>
                    </div>
                    <div className="row row_table">
                        <div className="col-6 v-align-middle pr10">
                            <span className="pb5 fs14">
                                {translate("printProperties.tab")}
                            </span>
                            <NumberInput
                                step={0.1}
                                min={0}
                                max={10}
                                prefix={"см"}
                                value={tab}
                                onChange={value => this.onChangeOption(value, "tab")}
                                disabled={statusApproved}
                            />
                        </div>
                    </div>
                </div>
            </div>,
            <div className="modal__footer bg-grey text-right">
                <Button className="turbo-button__size_md dotted-link mr20 fs14 p0 modal-close color-blue">
                    {translate("buttons.cancel")}
                </Button>
                <Button
                    className="turbo-button__size_md turbo-button__bg_orange turbo-button__text_white"
                    onclick={() => this.save()}
                    disabled={statusApproved}
                >
                    {translate("buttons.save")}
                </Button>
            </div>
        ]);
    }

    onChangeOption(value, name) {
        this.options[name] = value;
    }

    save() {
        const onSave                                                           = this.attrs.onSave;
        const { indentLeft, indentRight, typeFirstLine, indentFirstLine, tab } = this.options;

        if (typeof onSave === "function") {
            let totalIndentFirstLine = typeFirstLine === "indent" ? indentFirstLine : -indentFirstLine;

            onSave(indentLeft, indentRight, totalIndentFirstLine, tab);
        }
    }
}

export default SettingsIndent;
