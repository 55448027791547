import "mithril";
import Head from "./components/head";
import Title from "./components/title";
import Body from "./components/body";

class BodyPageLayer {
    view({ attrs, children }) {
        return (
            <main className="page-layer">
                <div className={`page-layer__wrapper ${attrs.className || ''}`}>
                    {children}
                </div>
            </main>
        );
    }
}

BodyPageLayer.Head  = Head;
BodyPageLayer.Title = Title;
BodyPageLayer.Body  = Body;

export default BodyPageLayer;
