import m from 'mithril';
import Component from "lib/Component";
import {getShortMonths} from "components/plugins/DatePicker/config";

class Months extends Component {
    view() {
        let { setCurrentMonthAction } = this.attrs,
            monthsModel               = this._getMonthsModel();

        return (
            <div className="t-datepicker-months t-datepicker-body">
                <div className="t-datepicker-cells t-datepicker-cells-months">
                    <For each="month" of={monthsModel}>
                        <div
                            className={
                                "t-datepicker-cell t-datepicker-cell-month"
                                + (month.isToday ? " current" : "")
                                + (month.isSelected ? " selected" : "")
                            }
                            onclick={() => setCurrentMonthAction(month.month, month.year)}
                        >
                            {month.shortName}
                        </div>
                    </For>
                </div>
            </div>
        );
    }

    _getMonthsModel() {
        let { selectDate, currentDate } = this.attrs,
            models                      = [],
            currentYear                 = currentDate.getFullYear(),
            selectMonth                 = selectDate ? selectDate.getMonth() : null,
            selectYear                  = selectDate ? selectDate.getFullYear() : null,
            shortMonths                 = getShortMonths(),
            today                       = new Date();

        shortMonths.forEach((shortMonth, index) => {
            models.push({
                month     : index,
                year      : currentYear,
                shortName : shortMonth,
                isToday   : today.getMonth() === index && today.getFullYear() === currentYear,
                isSelected: selectMonth === index && currentYear === selectYear
            });
        });

        return models;
    }
}

export default Months;
