import "mithril";
import Component from "_/lib/Component";
import {translate} from "_/localizations";
import turbo from "_/lib/turbo";
import SettingsIndent from "_/components/dialogs/printProperties/components/SettingsIndent";

class LevelsSettings extends Component {
    view() {
        const { printSettings } = this.attrs;
        const numLevels         = { 0: "1", 1: "1.1", 2: "1.1.1" };
        const { levels }        = printSettings;

        let statusApproved = turbo.lib.checkApprovedDocumentTemplate();

        return (
            <div className="tile-list tile-list_bordered mb10">
                <div className="tile-list__head fs14 pl0 pr0 row row_table">
                    <div className="col-1">{translate("printProperties.level")}</div>
                    <div className="col-2">{translate("printProperties.levelNum")}</div>
                    <div className="col-4">{translate("printProperties.levelTitle")}</div>
                    <div className="col-5">{translate("printProperties.levelText")}</div>
                </div>

                {
                    levels.map((item, index) => (
                        <div className="tile-list__item row row_table pl0 pr0">
                            <div className="col-1 v-align-middle">{numLevels[index]}</div>

                            <div className="col-2 v-align-middle">
                                <div className="inline-block v-align-middle">
                                    <label
                                        className="checkbox-radio_button inline-block v-align-middle mr5"
                                        title={translate("printProperties.highlightBold")}
                                    >
                                        <input
                                            type="checkbox"
                                            checked={item.isNumberBold}
                                            onchange={() => this.onChangeRadioDefaultLevelsValue(index, "isNumberBold")}
                                            disabled={statusApproved}
                                        />
                                        <div className="bg-blue color-blue text-bold font-fira">
                                            {translate("printProperties.bold")}
                                        </div>
                                    </label>
                                </div>
                            </div>

                            <div className="col-4 row v-align-middle">
                                <div className="inline-block v-align-middle mr5">
                                    <label
                                        className="checkbox-radio_button inline-block v-align-middle mr5"
                                        title={translate("printProperties.highlightBold")}
                                    >
                                        <input
                                            type="checkbox"
                                            checked={item.isTitleBold}
                                            onchange={() => this.onChangeRadioDefaultLevelsValue(index, "isTitleBold")}
                                            disabled={statusApproved}
                                        />
                                        <div className="bg-blue color-blue text-bold font-fira">
                                            {translate("printProperties.bold")}
                                        </div>
                                    </label>
                                </div>

                                <div className="inline-block v-align-middle mr5">
                                    <div>
                                        <label
                                            className="checkbox-radio_button inline-block v-align-middle mr5"
                                            title={translate("printProperties.leftAlignment")}>
                                            <input
                                                type="radio"
                                                checked={+item.titleAlignment === 0}
                                                onchange={() => this.onChangeRadioDefaultLevelsValue(index, "titleAlignment", 0)}
                                                disabled={statusApproved}
                                            />
                                            <div className="bg-blue color-blue text-bold font-fira">
                                                <i className="font-icon icon-text-left block fs18"/>
                                            </div>
                                        </label>

                                        <label
                                            className="checkbox-radio_button inline-block v-align-middle mr5"
                                            title={translate("printProperties.centerAlignment")}
                                        >
                                            <input
                                                type="radio"
                                                checked={+item.titleAlignment === 2}
                                                onchange={() => this.onChangeRadioDefaultLevelsValue(index, "titleAlignment", 2)}
                                                disabled={statusApproved}
                                            />
                                            <div className="bg-blue color-blue text-bold font-fira">
                                                <i className="font-icon icon-text-center block fs18"/>
                                            </div>
                                        </label>
                                    </div>
                                </div>

                                <div className="inline-block v-align-middle mr5">
                                    <label
                                        className="checkbox-radio_button inline-block v-align-middle mr5"
                                        title={translate("printProperties.settingIndent")}
                                    >
                                        <button
                                            type="button"
                                            className="turbo-button color-blue fs20 text-bold font-fira btn_size_xsm border-no-color"
                                            onclick={() => statusApproved
                                                ? null
                                                : this.openSettingsTextIndent(index, item)}
                                            disabled={statusApproved}
                                        >
                                            <i className="font-icon icon-text-indent-left block fs18"/>
                                        </button>
                                    </label>
                                </div>

                                <div className="inline-block v-align-middle">
                                    <div className="fs12">
                                        <div>
                                            {`${translate("printProperties.indentLeft")}: `}
                                            <span className="text-bold">{item.indentLeft}</span>
                                        </div>
                                        <div>
                                            {`${translate("printProperties.indentRight")}: `}
                                            <span className="text-bold">{item.indentRight}</span>
                                        </div>
                                        <div>
                                            {`${translate("printProperties.indentFirstLine")}: `}
                                            <span className="text-bold">{item.indentFirstLine}</span>
                                        </div>
                                        <div>
                                            {`${translate("printProperties.tab")}: `}
                                            <span className="text-bold">{item.tabTitle}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-5 v-align-middle">
                                <div className="inline-block v-align-middle mr5">
                                    <div>
                                        <label
                                            className="checkbox-radio_button inline-block v-align-middle mr5"
                                            title={translate("printProperties.leftAlignment")}
                                        >
                                            <input
                                                type="radio"
                                                checked={item.textAlignment === "left"}
                                                onchange={() => this.onChangeRadioDefaultLevelsValue(index, "textAlignment", "left")}
                                                disabled={statusApproved}
                                            />
                                            <div className="bg-blue color-blue text-bold font-fira">
                                                <i className="font-icon icon-text-left block fs18"/>
                                            </div>
                                        </label>

                                        <label
                                            className="checkbox-radio_button inline-block v-align-middle mr5"
                                            title={translate("printProperties.centerAlignment")}
                                        >
                                            <input
                                                type="radio"
                                                checked={item.textAlignment === "center"}
                                                onchange={() => this.onChangeRadioDefaultLevelsValue(index, "textAlignment", "center")}
                                                disabled={statusApproved}
                                            />
                                            <div className="bg-blue color-blue text-bold font-fira">
                                                <i className="font-icon icon-text-center block fs18"/>
                                            </div>
                                        </label>

                                        <label
                                            className="checkbox-radio_button inline-block v-align-middle mr5"
                                            title={translate("printProperties.rightAlignment")}
                                        >
                                            <input
                                                type="radio"
                                                checked={item.textAlignment === "right"}
                                                onchange={() => this.onChangeRadioDefaultLevelsValue(index, "textAlignment", "right")}
                                                disabled={statusApproved}
                                            />
                                            <div className="bg-blue color-blue text-bold font-fira">
                                                <i className="font-icon icon-text-right block fs18"/>
                                            </div>
                                        </label>

                                        <label
                                            className="checkbox-radio_button inline-block v-align-middle"
                                            title={translate("printProperties.justifyAlignment")}
                                        >
                                            <input
                                                type="radio"
                                                checked={item.textAlignment === "justify"}
                                                onchange={() => this.onChangeRadioDefaultLevelsValue(index, "textAlignment", "justify")}
                                                disabled={statusApproved}
                                            />
                                            <div className="bg-blue color-blue text-bold font-fira">
                                                <i className="font-icon icon-text-justify block fs18"/>
                                            </div>
                                        </label>
                                    </div>
                                </div>

                                <div className="inline-block v-align-middle mr5">
                                    <label
                                        className="checkbox-radio_button inline-block v-align-middle mr5"
                                        title={translate("printProperties.settingIndent")}
                                    >
                                        <button
                                            type="button"
                                            className="turbo-button color-blue fs20 text-bold font-fira btn_size_xsm border-no-color"
                                            onclick={() => statusApproved
                                                ? null
                                                : this.openSettingsTextIndent(index, item)}
                                            disabled={statusApproved}
                                        >
                                            <i className="font-icon icon-text-indent-left block fs18"/>
                                        </button>
                                    </label>
                                </div>

                                <div className="inline-block v-align-middle">
                                    <div className="fs12">
                                        <div>
                                            {`${translate("printProperties.indentLeft")}: `}
                                            <span className="text-bold">{item.indentLeftText}</span>
                                        </div>
                                        <div>
                                            {`${translate("printProperties.indentRight")}: `}
                                            <span className="text-bold">{item.indentRightText}</span>
                                        </div>
                                        <div>
                                            {`${translate("printProperties.indentFirstLine")}: `}
                                            <span className="text-bold">{item.indentFirstLineText}</span>
                                        </div>
                                        <div>
                                            {`${translate("printProperties.tab")}: `}
                                            <span className="text-bold">{item.tabText}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        );
    }

    onChangeRadioDefaultLevelsValue(index, type, val) {
        const { onChangeRadioValue } = this.attrs;

        if (typeof onChangeRadioValue === "function") {
            onChangeRadioValue(index, type, val);
        }
    }

    openSettingsTitleIndent(index, item) {
        const { indentLeft, indentRight, indentFirstLine, tabTitle } = item;

        const onSave = (indentLeft, indentRight, indentFirstLine, tab) => {
            this.onChangeRadioDefaultLevelsValue(index, "indentLeft", indentLeft);
            this.onChangeRadioDefaultLevelsValue(index, "indentRight", indentRight);
            this.onChangeRadioDefaultLevelsValue(index, "indentFirstLine", indentFirstLine);
            this.onChangeRadioDefaultLevelsValue(index, "tabTitle", tab);

            turbo.plugins.modal.close(document.querySelector(".settingsIndent"));
        }

        turbo.plugins.modal.open({
            closeOnOverlayClick: false,
            class              : "settingsIndent",
            vnode              : {
                view() {
                    return (
                        <SettingsIndent
                            indentLeft={indentLeft}
                            indentRight={indentRight}
                            indentFirstLine={indentFirstLine}
                            tab={tabTitle}
                            onSave={onSave}
                        />
                    )
                }
            }
        });
    }

    openSettingsTextIndent(index, item) {
        const { indentLeftText, indentRightText, indentFirstLineText, tabText } = item;

        const onSave = (indentLeft, indentRight, indentFirstLine, tab) => {
            this.onChangeRadioDefaultLevelsValue(index, "indentLeftText", indentLeft);
            this.onChangeRadioDefaultLevelsValue(index, "indentRightText", indentRight);
            this.onChangeRadioDefaultLevelsValue(index, "indentFirstLineText", indentFirstLine);
            this.onChangeRadioDefaultLevelsValue(index, "tabText", tab);

            turbo.plugins.modal.close(document.querySelector(".settingsIndent"));
        }

        turbo.plugins.modal.open({
            closeOnOverlayClick: false,
            class              : "settingsIndent",
            vnode              : {
                view() {
                    return (
                        <SettingsIndent
                            indentLeft={indentLeftText}
                            indentRight={indentRightText}
                            indentFirstLine={indentFirstLineText}
                            tab={tabText}
                            onSave={onSave}
                        />
                    )
                }
            }
        });
    }
}

export default LevelsSettings;
