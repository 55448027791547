import {getTableModelWithId, getAssociativeModel, getTableModel} from "_/lib/turboeditor/table/model";

/**
 * Метод получает объект атрибутов на основе параметрических данных, требуется для преобразования модели в html
 *
 * @param {object} parameters
 *
 * @returns {object}
 */
const getAttrsParametricCell = parameters => {
    let attrs = {};

    Object.keys(parameters).forEach(parameter => {
        let snakeCaseName = parameter.split(/(?=[A-Z])/).join('-').toLowerCase();

        snakeCaseName = `data-${snakeCaseName}`;

        attrs[snakeCaseName] = parameters[parameter];
    });

    attrs.contenteditable = "false";

    return attrs;
};

/**
 * метод устанвливает атрибуты и признак парамтерического для ячеек контейнера формулировки
 *
 * @param {object} model - ассоциативная модель контейнера
 * @param {object} attrs - атрибуты ячеек
 */
const seParametersForAddingCells = (model, attrs) => {
    Object.keys(model.cells).forEach(cellId => {
        model.cells[cellId].attrs = attrs;
        model.cells[cellId].type  = "parametric";
    });
};

/**
 * Метод устанавливает источник для каждой добавляемой ячейки
 *
 * @param {object} model    - ассоциативная модель контейнера
 * @param {object} goalCell - заменяемая ячека
 */
const setSourceCell = (model, goalCell) => {
    model.table.rows.forEach((rowId, indexRow) => {
        const row = model.rows[rowId];

        row.cells.forEach((cellId, indexCell) => {
            if (indexRow === 0 && indexCell === 0) {
                model.cells[cellId].source = "base";

                model.cells[cellId].rowSpanBase = goalCell.rowSpanBase || goalCell.rowSpan;
                model.cells[cellId].colSpanBase = goalCell.colSpanBase || goalCell.colSpan;
            } else {
                model.cells[cellId].source = "add";
            }
        })
    });
};

/**
 * Установка дополнительных строк для целевой модели в зависимости от размеров вствляемого контейнера
 *
 * @param {object} model      - ассоциативная модель контейнера
 * @param {object} baseModel  - базовая модель таблицы
 * @param {object} goalCell   - базовая ячейка модели таблицы в которая замещается контейнером
 */
const setAdditionalRows = (model, baseModel, goalCell) => {
    let lastRowId        = goalCell.rows[goalCell.rows.length - 1],
        indexLastBaseRow = baseModel.table.rows.indexOf(lastRowId),
        countRows        = model.table.rows.length,
        diffRows         = countRows - goalCell.rows.length;

    if (diffRows > 0) {
        setAdditionalSpansForCells(baseModel, goalCell, diffRows, "next");
        setAdditionalSpansForCells(baseModel, goalCell, diffRows, "prev");
    } else {
        let lastRowModelId = model.table.rows[model.table.rows.length - 1],
            lastRowModel   = model.rows[lastRowModelId],
            addRowSpan     = -diffRows;

        lastRowModel.cells.forEach(cellId => {
            if (!model.cells[cellId].rowSpanBase) {
                model.cells[cellId].rowSpanBase = model.cells[cellId].rowSpan;
            }

            model.cells[cellId].rowSpan = model.cells[cellId].rowSpan + addRowSpan;
        });
    }

    model.table.rows.forEach((rowId, index) => {
        const row = model.rows[rowId];

        if (index === 0) {
            let baseRowId     = goalCell.rows[index],
                baseRow       = baseModel.rows[baseRowId],
                indexBaseCell = baseRow.cells.indexOf(goalCell.id);

            baseRow.cells.splice(indexBaseCell, 1, ...row.cells);
        } else if (index < goalCell.rows.length) {
            let baseRowId      = goalCell.rows[index],
                baseRow        = baseModel.rows[baseRowId],
                rightCellId    = goalCell.next[index],
                indexRightCell = rightCellId ? baseRow.cells.indexOf(rightCellId) : baseRow.cells.length;

            baseRow.cells.splice(indexRightCell, 0, ...row.cells);
        } else {
            baseModel.table.rows.splice(++indexLastBaseRow, 0, rowId);
            baseModel.rows[rowId] = row;
        }
    });
};

/**
 * Установка дополнительных colSpan или rowSpan для ячеек базовой модели
 *
 * @param {object} baseModel - модель базовой таблицы
 * @param {object} goalCell  - замещаемая чейка
 * @param {number} diff      - разница размера ячейки и контейнера
 * @param {string} direction - направления вставки дополнительных размеров
 */
const setAdditionalSpansForCells = (baseModel, goalCell, diff, direction) => {
    let typeSpan   = ["up", "down"].includes(direction) ? "colSpan" : "rowSpan",
        nextCellId = goalCell[direction][goalCell[direction].length - 1],
        nextCell   = baseModel.cells[nextCellId];

    while (nextCell) {
        if (!nextCell[`${typeSpan}Base`]) {
            nextCell[`${typeSpan}Base`] = nextCell[typeSpan];
        }

        nextCell[typeSpan] = +nextCell[typeSpan] + diff;

        nextCellId = nextCell[direction][0];
        nextCell   = baseModel.cells[nextCellId];
    }
};

/**
 * Установка дополнительных столбцов для целевой модели в зависимости от размеров вствляемого контейнера
 *
 * @param {object} model
 * @param {object} baseModel
 * @param {object} goalCell
 */
const setAdditionalCols = (model, baseModel, goalCell) => {
    let countCols = model.table.cols.length;

    if (countCols > goalCell.colSpan) {
        let diffCol         = countCols - goalCell.colSpan,
            allSumWidthCols = 0,
            avgWidth, rightIndexCol, currentTime;

        setAdditionalSpansForCells(baseModel, goalCell, diffCol, "up");
        setAdditionalSpansForCells(baseModel, goalCell, diffCol, "down");

        goalCell.cols.forEach(colId => {
            allSumWidthCols = allSumWidthCols + parseFloat(baseModel.cols[colId].width);
        });

        avgWidth      = (allSumWidthCols / countCols).toFixed(2);
        rightIndexCol = goalCell.rightIndexCol;

        currentTime = Date.now();

        for (let index = 0; index < diffCol; index++) {
            const addCol = { id: `add_${currentTime}_col_${index}`, width: `${avgWidth}%`, source: "add" };

            baseModel.table.cols.splice(++rightIndexCol, 0, addCol.id);
            baseModel.cols[addCol.id] = addCol;
        }

        goalCell.cols.forEach(colId => {
            if (!baseModel.cols[colId].baseWidth) {
                baseModel.cols[colId].baseWidth = baseModel.cols[colId].width;
            }

            baseModel.cols[colId].width = `${avgWidth}%`;
        });

        if (avgWidth * countCols < allSumWidthCols) {
            let diffWidth = allSumWidthCols - avgWidth * countCols;

            baseModel.cols[`add_${currentTime}_col_${diffCol - 1}`].width = `${avgWidth + diffWidth}%`;
        }
    } else {
        let diffCol = +goalCell.colSpan - countCols;

        model.table.rows.forEach(rowId => {
            let row        = model.rows[rowId],
                lastCellId = row.cells[row.cells.length - 1];

            model.cells[lastCellId].colSpan = +model.cells[lastCellId].colSpan + diffCol;
        });
    }
};

/**
 * Метод заменяет ячекй таблицы с параметрическим значением (Формулировка типа ячейка)
 * на таблицу которая описывает параметрическое, соотвественно происходит влияние на на другие ячейки исходной таблицы
 * может увеличится количество строк и столбцов
 *
 * @param {object} baseModel - модель исходной таблицы
 * @param {object} goalCell  - целевая ячейка которая заменяется
 * @param {object} model     - модель таблицы параметрического
 * @param {object} attrs     - атрибуты каждой ячейки вставляемой таблицы
 *
 * @returns {object}
 */
const replaceCellByModel = (baseModel, goalCell, model, attrs) => {
    let modelWithId      = getTableModelWithId(model, `new_${Date.now()}`),
        associativeModel = getAssociativeModel(modelWithId);

    seParametersForAddingCells(associativeModel, attrs);
    setSourceCell(associativeModel, goalCell);
    setAdditionalRows(associativeModel, baseModel, goalCell);
    setAdditionalCols(associativeModel, baseModel, goalCell);

    baseModel.cells = { ...baseModel.cells, ...associativeModel.cells };

    return getAssociativeModel(getTableModel(baseModel));
};

const normalizeModelCols = model => {
    let countCols = model.cols.length,
        matrix    = [];

    model.rows.forEach((row, index) => {
        let cells = row.cells;

        if (matrix[index] === undefined) {
            matrix[index] = 0;
        }

        cells.forEach(cell => {
            let rowSpan = +cell.colSpan,
                colSpan = +cell.colSpan;

            while (colSpan > 0) {
                let currentIndex = index + colSpan - 1;

                if (matrix[currentIndex] === undefined) {
                    matrix[currentIndex] = 0;
                }

                matrix[currentIndex] += rowSpan;

                colSpan--;
            }
        });

        if (matrix[index] < countCols) {
            let lastCell = cells[cells.length - 1];

            lastCell.colSpan = +lastCell.colSpan + (countCols - matrix[index]);
        }
    });
};

const unionContainers = containers => {
    let unionContainer = { element: "table", cols: [], rows: [] };

    containers.forEach(container => {
        if (container.cols.length > unionContainer.cols.length) {
            unionContainer.cols = [...container.cols];
        }

        unionContainer.rows = [...unionContainer.rows, ...container.rows];
    });

    normalizeModelCols(unionContainer);

    return unionContainer;
};

export const injectParametric = tableModel => {
    let modelWithId      = getTableModelWithId(tableModel),
        associativeModel = getAssociativeModel(modelWithId),
        addModels        = {},
        modelIds, associativeModelIds;

    associativeModelIds = Object.keys(associativeModel.cells);

    associativeModelIds.forEach(cellId => {
        let cell = associativeModel.cells[cellId];

        if (cell.type === "parametric" && cell.parametric) {
            let parametric = cell.parametric,
                value      = parametric.value,
                parameters = parametric.parameters || {},
                attrs      = getAttrsParametricCell(parameters);

            if (value) {
                if (Array.isArray(value)) {
                    let containers = [],
                        unionContainer;

                    value.forEach(item => {
                        if (item.container) {
                            containers.push(item.container);
                        }

                        if (item.model) {
                            addModels = { ...addModels, ...item.model };
                        }
                    });

                    unionContainer = unionContainers(containers);

                    const { updateModel, models } = injectParametric(unionContainer);

                    addModels = { ...addModels, ...models };

                    associativeModel = replaceCellByModel(associativeModel, cell, updateModel, attrs);
                } else if (value.container) {
                    addModels        = { ...addModels, ...value.model };
                    associativeModel = replaceCellByModel(associativeModel, cell, value.container, attrs);
                } else {
                    cell.content = value.content;
                    addModels    = { ...addModels, ...value.model };
                }
            } else {
                cell.content = parametric.placeholder;
            }

            cell.attrs = attrs;
            cell.type  = "parametric";
        }
    });

    modelIds = Object.keys(addModels);

    modelIds.forEach(modelId => {
        const model                   = addModels[modelId];
        const { updateModel, models } = injectParametric(model);

        addModels[modelId] = updateModel;
        addModels          = { ...addModels, ...models };
    });

    return { updateModel: getTableModel(associativeModel), models: addModels };
};
