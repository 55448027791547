import {
    CHANGE_SEARCH_VALUE,
    SYS_CHANGE_SEARCH_VALUE,
    CLEAR_SEARCH_VALUE,
    SYS_CLEAR_SEARCH_VALUE
} from "types/common/searchInput";

export default function(state = {}, action = {}) {
    switch (action.type) {
        case CHANGE_SEARCH_VALUE:
            return {...state, value: action.value};
        case SYS_CHANGE_SEARCH_VALUE:
            return {...state, sysValue: action.value};
        case CLEAR_SEARCH_VALUE:
            return {...state, value: ""};
        case SYS_CLEAR_SEARCH_VALUE:
            return {...state, sysValue: ""};
        default:
            return state;
    }
}
