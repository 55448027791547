import turbo from "_/lib/turbo";
import {translate} from "_/localizations";

const MAX_LENGTH_CONTENT = 100;

export const getContentForView = (content, type, model) => {
    let viewContent, itemHtml;

    itemHtml = new turbo.lib.EditorItemHtml(content, model);

    viewContent = itemHtml.getHtml();
    viewContent = turbo.stripTags(viewContent);
    viewContent = viewContent.trim();

    if (viewContent.length > MAX_LENGTH_CONTENT) {
        viewContent = viewContent.substr(0, MAX_LENGTH_CONTENT);
        viewContent = viewContent + "...";
    }

    if (viewContent === "") {
        viewContent = turbo.stripTags(content, "<img><table>");
        viewContent = viewContent.trim();

        if (viewContent.indexOf("<img") === 0) {
            viewContent = "<" + translate("documentSection.contentOnlyImage") + ">";
        } else if (viewContent.indexOf("<table") === 0) {
            viewContent = "<" + translate("documentSection.contentOnlyTable") + ">";
        } else if (type === "pageBreak") {
            viewContent = "<" + translate("documentSection.contentPageBreak") + ">";
        } else {
            viewContent = "<" + translate("documentSection.contentEmpty") + ">"
        }
    }

    return viewContent;
};
