export default class HandlerQueue {
    constructor(scope) {
        this._scope = scope || window;

        this.clear();
    }

    add(fn) {
        let fnLength, index;

        if (Array.isArray(fn)) {
            fnLength = fn.length;

            for (index = 0; index < fnLength; index++) {
                this.add(fn[index]);
            }
        } else {
            this._fns.push(fn);
        }

        return this;
    }

    remove(fn) {
        let copy      = [],
            fns       = this._fns,
            fnsLength = fns.length,
            index, currentFn;

        for (index = 0; index < fnsLength; index++) {
            currentFn = fns[index];

            if (currentFn !== fn) {
                copy.push(currentFn);
            }
        }

        this._fns = copy;

        return this;
    }

    clear() {
        this._fns = [];

        return this;
    }

    dispatch() {
        let scope     = this._scope,
            fns       = this._fns,
            fnsLength = fns.length,
            index, currentFn;

        for (index = 0; index < fnsLength; index++) {
            currentFn = fns[index];

            if (currentFn.apply(scope, arguments) === false) {
                break;
            }
        }

        return this;
    }
}
