import m from 'mithril';
import { translate } from 'localizations';

export default class LinkToPage {
    view() {
        return (
            <a
                href={ Routing.generate('turbo_front_messages') }
                className="dashed-link color-white text-nowrap"
            >
                { translate('notifications.readAll') }
            </a>
        )
    }
}
