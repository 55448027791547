import {REQUEST_USER_VISA, REQUEST_VISA_DATA, SET_START_LOAD_DATA, SET_VISA_DATA} from "_/types/visa";

export const requestVisaData = payload => ({
    type: REQUEST_VISA_DATA,
    payload
});

export const setStartLoadData = () => ({
    type: SET_START_LOAD_DATA
});

export const setVisaData = payload => ({
    type: SET_VISA_DATA,
    payload
});

export const requestUserVisa = payload => ({
    type: REQUEST_USER_VISA,
    payload
});
