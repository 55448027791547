import "mithril";
import classNames from "classnames";
import {connect} from "_/lib/midux";
import Component from "_/lib/Component";
import {translate} from "_/localizations";
import Button from "_/components/plugins/Button";
import SettingsValuesList from "_/components/dialogs/SettingValuesList";
import {
    FIELD_MULTI_SELECT,
    FIELD_MULTI_SELECT_EDIT,
    FIELD_SELECT,
    FIELD_SELECT_EDIT,
    FIELD_TEXT_BLOCK,
    FIELD_TEXT_BLOCK_EDIT
} from "_/constants/typesOfRegistryFIelds";
import {
    setSettingsCurrentQuestion,
    setValueCurrentQuestion,
    requestListGroupTextBlock,
    requestListTextBlock,
    setOptionsCurrentQuestion
} from "_/actions/questionnaire";
import Checkbox from "_/components/plugins/Checkbox";

const mapStateToProps = state => ({
    question                 : state.questionnaire.currentQuestion,
    typesOfFields            : state.referenceBooks.typesOfRegistryFieldsForQuestionnaire,
    textBlockGroups          : state.questionnaire.textBlockGroups,
    statusLoadTextBlockGroups: state.questionnaire.statusLoadTextBlockGroups
});

const mapActionsToProps = {
    setValueCurrentQuestion,
    setSettingsCurrentQuestion,
    requestListGroupTextBlock,
    requestListTextBlock,
    setOptionsCurrentQuestion
};

class QuestionTypeField extends Component {
    oninit() {
        const { question }        = this.attrs;
        const { value = {} }      = question;
        const { typeOfField = 0 } = value;

        if (
            +typeOfField === FIELD_TEXT_BLOCK
            || +typeOfField === FIELD_TEXT_BLOCK_EDIT
        ) {
            this.attrs.actions.requestListGroupTextBlock();
        }
    }

    view() {
        const { question, textBlockGroups }      = this.attrs;
        const { value = {} }                     = question;
        const { typeOfField = 0, settings = {} } = value;
        const settingsId                         = settings.id;
        const typesOfFields                      = this.attrs.typesOfFields;
        const statusLoadTextBlockGroups          = this.attrs.statusLoadTextBlockGroups;

        const isSelectField = [
            FIELD_SELECT,
            FIELD_SELECT_EDIT,
            FIELD_MULTI_SELECT,
            FIELD_MULTI_SELECT_EDIT
        ].includes(+typeOfField);

        const selectTypeFieldClasses = classNames(
            "turbo-question__item-value-element",
            { "form-item form-item_with-button": isSelectField }
        );

        if (
            +typeOfField === FIELD_TEXT_BLOCK
            || +typeOfField === FIELD_TEXT_BLOCK_EDIT
        ) {
            if (!settingsId && textBlockGroups.length > 0) {
                this.onChangeGroupTextBlock(textBlockGroups[0].id);
            }
        }

        return (
            <div className="turbo-question__item-value">
                <div className="turbo-question__part-title">
                    {translate("questionnaire.typeFieldQuestionTitle")}
                </div>
                <div className="turbo-question__item-value-elements">
                    <div className={selectTypeFieldClasses}>
                        <select value={+typeOfField} onchange={event => this.onChangeTypeOfField(event)}>
                            {typesOfFields.map(type => (<option value={type.id}>{type.name}</option>))}
                        </select>
                        {
                            isSelectField
                                ? (
                                    <Button
                                        className="form-item__button"
                                        title={translate("questionnaire.settingValueOfList")}
                                        onclick={() => this.openSettingsFieldList()}
                                    >
                                        <Button.Icon className="block settings color-blue"/>
                                    </Button>
                                )
                                : null
                        }
                    </div>
                    {
                        (+typeOfField === FIELD_TEXT_BLOCK || +typeOfField === FIELD_TEXT_BLOCK_EDIT)
                        && statusLoadTextBlockGroups
                            ? (
                                <div className="turbo-question__item-value-element">
                                    <select
                                        value={settingsId}
                                        onchange={event => this.onChangeGroupTextBlock(event.target.value)}
                                    >
                                        {textBlockGroups.map(group => (
                                            <option key={group.id} value={group.id}>{group.name}</option>))}
                                    </select>
                                </div>
                            )
                            : null
                    }
                    <div>
                        {
                            +typeOfField === FIELD_TEXT_BLOCK_EDIT
                                ? (
                                    <div className="turbo-question__item-value-element">
                                        <Checkbox
                                            value={question.options && question.options.isShowAllTextBlocks}
                                            text={translate("questionnaire.showAllTextBlocks")}
                                            onchange={value => this.setOptions("isShowAllTextBlocks", value)}
                                        />
                                    </div>
                                )
                                : null
                        }
                    </div>
                </div>
            </div>
        );
    }

    onChangeTypeOfField(event) {
        const typeOfField = +event.target.value;

        this.attrs.actions.setValueCurrentQuestion({ typeOfField, defaultValue: "" });

        switch (typeOfField) {
            case FIELD_SELECT:
            case FIELD_SELECT_EDIT:
            case FIELD_MULTI_SELECT:
            case FIELD_MULTI_SELECT_EDIT:
                this.openSettingsFieldList();
                break;
            case FIELD_TEXT_BLOCK:
            case FIELD_TEXT_BLOCK_EDIT:
                this.attrs.actions.requestListGroupTextBlock();
                break;
        }
    }

    onChangeGroupTextBlock(groupId) {
        this.attrs.actions.setSettingsCurrentQuestion({ id: groupId });
        this.attrs.actions.requestListTextBlock({ groupId });
    }

    openSettingsFieldList() {
        const { value = {} }    = this.attrs.question;
        const { settings = {} } = value;
        const { values = [] }   = settings;
        const initSettings      = { data: [...values], onSave: this.saveSettingsFieldList.bind(this) };

        turbo.plugins.modal.open({
            width              : 600,
            closeOnOverlayClick: false,
            vnode              : {
                view() {
                    return m(SettingsValuesList, initSettings)
                }
            }
        });
    }

    saveSettingsFieldList(values) {
        this.attrs.actions.setSettingsCurrentQuestion({ values });
    }

    setOptions(optionName, value) {
        this.attrs.actions.setOptionsCurrentQuestion({[optionName]: value});
    }
}

export default connect(mapStateToProps, mapActionsToProps)(QuestionTypeField);
