import {translate} from 'localizations';

let days        = [],
    shortDays   = [],
    months      = [],
    shortMonths = [];

const initDays = () => {
    if (days.length === 0) {
        days = [
            translate("date.days.monday"),
            translate("date.days.tuesday"),
            translate("date.days.wednesday"),
            translate("date.days.thursday"),
            translate("date.days.friday"),
            translate("date.days.saturday"),
            translate("date.days.sunday")
        ];
    }
};

const initShortDays = () => {
    if (shortDays.length === 0) {
        shortDays = [
            translate("date.days.short.monday"),
            translate("date.days.short.tuesday"),
            translate("date.days.short.wednesday"),
            translate("date.days.short.thursday"),
            translate("date.days.short.friday"),
            translate("date.days.short.saturday"),
            translate("date.days.short.sunday")
        ];
    }
};

const initMonths = () => {
    if (months.length === 0) {
        months = [
            translate("date.month.january"),
            translate("date.month.february"),
            translate("date.month.march"),
            translate("date.month.april"),
            translate("date.month.may"),
            translate("date.month.june"),
            translate("date.month.july"),
            translate("date.month.august"),
            translate("date.month.september"),
            translate("date.month.october"),
            translate("date.month.november"),
            translate("date.month.december")
        ];
    }
};

const initShortMonths = () => {
    if (shortMonths.length === 0) {
        shortMonths = [
            translate("date.month.short.january"),
            translate("date.month.short.february"),
            translate("date.month.short.march"),
            translate("date.month.short.april"),
            translate("date.month.short.may"),
            translate("date.month.short.june"),
            translate("date.month.short.july"),
            translate("date.month.short.august"),
            translate("date.month.short.september"),
            translate("date.month.short.october"),
            translate("date.month.short.november"),
            translate("date.month.short.december")
        ];
    }
};

export const getDay = (index) => {
    initDays();

    return days[index];
};

export const getShortDay = (index) => {
    initShortDays();

    return shortDays[index];
};

export const getShortDays = () => {
    initShortDays();

    return [...shortDays];
};

export const getMonth = (index) => {
    initMonths();

    return months[index];
};

export const getShortMonth = (index) => {
    initShortMonths();

    return shortMonths[index];
};

export const getShortMonths = () => {
    initShortMonths();

    return [...shortMonths];
};
