export const getPrintProperties = function(url) {
    return mt.get({
        url,
        loadConfig : { isShow: true, name: "PrintProperties: getPrintProperties" },
        errorConfig: { isShow: true }
    })
}

export const saveProperties = function(url, data) {
    return mt.post({
        url,
        data,
        loadConfig : { isShow: true, name: "PrintProperties: saveProperties" },
        errorConfig: { isShow: true }
    });
};
