import m from 'mithril';

export default class Level {
    view({ attrs, children }) {
        return (
            <div className={ `toolbar__level ${attrs.className || ""}` }>
                { children }
            </div>
        )
    }
}
