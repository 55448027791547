import debounce from "lib/debounce";

let instance = null;

export default class CollectionDebounce {
    constructor() {
        if (!instance) {
            instance = this;
        }

        this._mapDebounce = {};

        return instance;
    }

    static getInstance() {
        if (!instance) {
            instance = new CollectionDebounce();
        }

        return instance;
    }

    add(name, func, ms, context) {
        this._mapDebounce[name] = {
            debounce: debounce(func, ms, context)
        };
    }

    run(name, ...args) {
        if (!this._mapDebounce[name]) {
            return;
        }

        this._mapDebounce[name].debounce(args);
    }

    runNow(name, ...args) {
        if (!this._mapDebounce[name]) {
            return;
        }

        this._mapDebounce[name].debounce.run(args);
    }

    cancel(name) {
        if (!this._mapDebounce[name]) {
            return;
        }

        this._mapDebounce[name].debounce.cancel();
    }
}
