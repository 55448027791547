import m from 'mithril';
import Component from "lib/Component";
import {getShortDays} from "components/plugins/DatePicker/config";

class Days extends Component {
    view() {
        let { setSelectedDateAction } = this.attrs,
            shortDays                 = getShortDays(),
            daysModel                 = this._getDaysModel();

        return (
            <div className="t-datepicker-days t-datepicker-body">
                <div className="t-datepicker-days-names">
                    <For each="day" of={shortDays}>
                        <div className="t-datepicker-day-name">{day}</div>
                    </For>
                </div>
                <div className="t-datepicker-cells t-datepicker-cells-days">
                    <For each="day" of={daysModel}>
                        <div
                            className={
                                "t-datepicker-cell t-datepicker-cell-day"
                                + (day.isOtherMonth ? " other-month" : "")
                                + (day.isToday ? " current" : "")
                                + (day.isSelected ? " selected" : "")
                            }
                            onclick={() => setSelectedDateAction(day.date, day.month, day.year)}
                        >
                            {day.date}
                        </div>
                    </For>
                </div>
            </div>
        );
    }

    _getDaysModel() {
        let { selectDate, currentDate } = this.attrs,
            model                       = [],
            currentMonth                = currentDate.getMonth(),
            currentYear                 = currentDate.getFullYear(),
            selectDDate                 = selectDate ? selectDate.getDate() : null,
            selectMonth                 = selectDate ? selectDate.getMonth() : null,
            selectYear                  = selectDate ? selectDate.getFullYear() : null,
            countDaysOfMonth            = this._getCountDaysOfMonth(currentYear, currentMonth),
            firstDayOfMonth             = this._getDay(currentYear, currentMonth, 1),
            lastDayOfMonth              = this._getDay(currentYear, currentMonth, countDaysOfMonth),
            countDaysFromPrevMonth      = firstDayOfMonth - 1,
            countDaysFromNextMonth      = 7 - lastDayOfMonth,
            startDateIndex, finishDateIndex;

        countDaysFromPrevMonth = countDaysFromPrevMonth < 0 ? (countDaysFromPrevMonth + 7) : countDaysFromPrevMonth;
        countDaysFromNextMonth = countDaysFromNextMonth > 6 ? (countDaysFromNextMonth - 7) : countDaysFromNextMonth;

        startDateIndex  = 1 - countDaysFromPrevMonth;
        finishDateIndex = countDaysOfMonth + countDaysFromNextMonth;

        for (let index = startDateIndex; index <= finishDateIndex; index++) {
            let tmpDate = new Date(),
                today   = new Date(),
                isToday = today.getFullYear() === currentYear
                          && today.getMonth() === currentMonth
                          && today.getDate() === index,
                isSelected;

            tmpDate.setFullYear(currentYear, currentMonth, index);

            isSelected = selectYear === tmpDate.getFullYear()
                         && selectMonth === tmpDate.getMonth()
                         && selectDDate === tmpDate.getDate();

            model.push({
                date        : tmpDate.getDate(),
                month       : tmpDate.getMonth(),
                year        : tmpDate.getFullYear(),
                isOtherMonth: tmpDate.getMonth() !== currentMonth,
                isToday,
                isSelected
            });
        }

        return model;
    }

    _getCountDaysOfMonth(year, month) {
        let tmpDate = new Date();

        tmpDate.setFullYear(year, month + 1, 0);

        return tmpDate.getDate();
    }

    _getDay(year, month, date) {
        let tmpDate = new Date();

        tmpDate.setFullYear(year, month, date);

        return tmpDate.getDay();
    }
}

export default Days;
