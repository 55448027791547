export const getListPartner = (regExps = [], excludeIds = [], includeIds = [], id) => {
    return mt.post({
        // eslint-disable-next-line camelcase
        url        : Routing.generate("turbo_partner_list", {agencyId: id}),
        data       : { regExps, excludeIds, includeIds },
        loadConfig : { isShow: false },
        errorConfig: { isShow: true }
    });
};

export const cloneDocument = function(documentId, data, basedOn) {
    let url = basedOn ? 'turbo_document_create_from' : 'turbo_pattern_matching_new_from_contract'

    return m.request({
        method: 'POST',
        // eslint-disable-next-line camelcase
        url  : Routing.generate(url, {id: documentId}),
        data,
        config(xhr) {
            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
            xhr.setRequestHeader('Content-Type', 'application/json');
        }
    })
};


