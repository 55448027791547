import WampClient from "./wampClient";

const SCHEME            = window.location.protocol === 'http:' ? 'ws' : 'wss';
const URI               = SCHEME + '://' + document.domain + '/ws/';
const RECONNECT_DELAY   = 1000;
const WARNING_THRESHOLD = 3;

let queue    = [],
    handlers = {},
    config, connection, callQueue;

callQueue = (topic, data) => {
    queue.forEach(({ fn, context }) => {
        fn.call(context, data);
    });
};

handlers['connect'] = function() {
    let warnElement = document.getElementById("warning_offline");

    if (warnElement) {
        warnElement.classList.add("hidden");
    }

    connection.subscribe("documents", callQueue);
};

handlers['disconnect'] = function() {
    let warnElement = document.getElementById("warning_offline");

    if (warnElement && this.failures > WARNING_THRESHOLD) {
        warnElement.classList.remove("hidden");
    }
};

config = {
    uri      : URI,
    reconnect: {
        delay     : RECONNECT_DELAY,
        cumulative: true
    },
    listeners: {
        connect   : handlers["connect"],
        disconnect: handlers["disconnect"]
    }
};

export function connectWampClient() {
    connection = new WampClient(config);
}

export const queueWS = {
    add(fn, context) {
        queue.push({ fn, context });
    }
};
