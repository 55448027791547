import "mithril";
import classNames from "classnames";
import {translate} from "localizations";
import Component from "lib/Component";
import Button from "components/plugins/Button";
import {Container} from "components/plugins/ScrollBar";
import TextEllipsis from "components/plugins/TextEllipsis";

//Корень для локализации
const ROOT = "listObjectOfRegistryByLink";

class Lists extends Component {
    view() {
        const { data, isEditMode, selectedList } = this.attrs;

        return (
            <Container
                className="tile-list tile-list_flex tile-list_striped tile-list_bordered tile-list_hovered"
                style="max-height: calc(100vh - 244px);"
            >
                <Choose>
                    <When condition={data.length === 0}>
                        <div className="fs16 color-grey">
                            {translate(`${ROOT}.emptyList`)}
                        </div>
                    </When>
                    <Otherwise>
                        {
                            data.map(list => {
                                let listClasses;

                                listClasses = classNames(
                                    "tile-list__item-flex tile-list__item p0 pl5",
                                    { "tile-list__active": selectedList && (selectedList.id === list.id) }
                                );

                                return (
                                    <div className={listClasses}>
                                        <Choose>
                                            <When condition={isEditMode && list.isEditable}>
                                                <div className="form-item wl100">
                                                    <input
                                                        type="text"
                                                        oninput={e => (list.tmpName = e.target.value)}
                                                        value={list.tmpName}
                                                    />
                                                </div>
                                            </When>
                                            <Otherwise>
                                                <div
                                                    className="content-flex cursor-pointer w500"
                                                    onclick={() => this.onSelectList(list)}
                                                >
                                                    <TextEllipsis>{list.name}</TextEllipsis>
                                                    <TextEllipsis className="content-flex__description">
                                                        {list.description}
                                                    </TextEllipsis>
                                                </div>
                                            </Otherwise>
                                        </Choose>

                                        <div className="buttons-flex">
                                            <Choose>
                                                <When condition={isEditMode && list.isEditable}>
                                                    <Button
                                                        className="turbo-button__size_md color-blue fs20"
                                                        title={translate("buttons.save")}
                                                        disabled={list.name.trim() === list.tmpName.trim()}
                                                        onclick={() => this.onSave(list)}
                                                    >
                                                        <Button.Icon className="save block"/>
                                                    </Button>
                                                    <Button
                                                        className="turbo-button__size_md color-red fs20"
                                                        title={translate("buttons.cancel")}
                                                        onclick={() => this.onCancel(list)}
                                                    >
                                                        <Button.Icon className="circle-close block"/>
                                                    </Button>
                                                </When>
                                                <Otherwise>
                                                    <Button
                                                        className="turbo-button__size_md color-blue fs20"
                                                        title={translate(`${ROOT}.editListNameButton`)}
                                                        disabled={isEditMode}
                                                        onclick={() => this.onEditList(list)}
                                                    >
                                                        <Button.Icon className="pencil block"/>
                                                    </Button>
                                                    <Button
                                                        className="turbo-button__size_md color-red fs20"
                                                        title={translate(`${ROOT}.deleteListButton`)}
                                                        disabled={isEditMode}
                                                        onclick={() => this.onDelete(list)}
                                                    >
                                                        <Button.Icon className="icon-trash block"/>
                                                    </Button>
                                                </Otherwise>
                                            </Choose>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </Otherwise>
                </Choose>
            </Container>
        );
    }

    onEditList(list) {
        const onChangeEditMode = this.attrs.onChangeEditMode;

        if (typeof onChangeEditMode === "function") {
            onChangeEditMode(true);
        }

        list.isEditable = true;
        list.tmpName    = list.name;
    }

    onSave(list) {
        const { onValidate, onChangeEditMode, onSave } = this.attrs;

        if (typeof onValidate === "function") {
            if (!onValidate(list.tmpName)) {
                return;
            }
        }

        list.name = list.tmpName;

        if (typeof onSave === "function") {
            onSave(list, () => {
                if (typeof onChangeEditMode === "function") {
                    onChangeEditMode(false);
                }
            });
        }
    }

    onCancel(list) {
        const onChangeEditMode = this.attrs.onChangeEditMode;

        if (typeof onChangeEditMode === "function") {
            onChangeEditMode(false);
        }

        delete list.tmpName;
        delete list.isEditable;
    }

    onDelete(list) {
        const onDelete = this.attrs.onDelete;

        let text = `${translate(`${ROOT}.warning.deleteText`)} "${list.name}" ?`;

        turbo.plugins.modal.confirm(
            {
                title        : translate(`${ROOT}.warning.deleteTitle`),
                btnCancelText: translate("buttons.cancel"),
                btnAcceptText: translate("buttons.yes"),
                text,
                iconClasses  : "font-icon warning fs24 color-orange mr5"
            },
            () => {
                if (typeof onDelete === "function") {
                    onDelete(list.id);
                }
            }
        );
    }

    onSelectList(list) {
        const onSelectList = this.attrs.onSelectList;

        if (typeof onSelectList === "function") {
            onSelectList(list);
        }
    }
}

export default Lists;
