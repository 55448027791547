import m from "mithril";
import Component from "lib/Component";

const NUMBER_REGEX = /^\d*\.?\d*$/;

class NumberInput extends Component {
    oninit() {
        let { value } = this.attrs;

        this.lastValue = { value, cursorStart: 0, cursorEnd: 0 }
    }

    view() {
        let { value, placeholder, onBlur, disabled = false, onkeyup, name} = this.attrs;

        return (
            <input
                type="text"
                name={name}
                placeholder={placeholder}
                oninput={e => this.changeValue(e)}
                value={value}
                onblur={onBlur}
                disabled={disabled}
                onkeyup={onkeyup}
            />
        );
    }

    changeValue(e) {
        let { onChange } = this.attrs,
            target       = e.target,
            value        = target.value,
            index;

        value = value.trim();
        value = value.replace(/,/, ".");
        value = value.replace(/\./g, "#");
        value = value.replace(/#/, ".");
        value = value.replace(/[^\d,.]/g, "");

        if (NUMBER_REGEX.test(value)) {
            this.lastValue = {
                value,
                cursorStart: target.selectionStart,
                cursorEnd  : target.selectionEnd
            };
        } else if (this.lastValue) {
            value        = this.lastValue.value;
            target.value = value;

            target.setSelectionRange(this.lastValue.cursorStart, this.lastValue.cursorEnd);
        }

        if (typeof onChange === "function") {
            onChange(value);
        }
    }
}

export default NumberInput;
