import 'mithril';
import turbo from 'lib/turbo';
import MessagesList from './MessagesList';

export default class WidgetItem {
    static showMessages(subject) {
        turbo.plugins.modal.open({
            width: 700,
            vnode: {
                view: () => (
                    <MessagesList subject={ subject }/>
                )
            }
        });
    }

    view({ attrs }) {
        let { item } = attrs;

        return (
            <li
                className="notice-widget__item"
                onclick={ () => WidgetItem.showMessages(item.subject) }
            >
                <div className="row">
                    <div className="notice-widget__item-label col-10 text-clipped">
                        { item.subject }
                    </div>
                    <div className="notice-widget__item-count col-2">
                        <span>{ item.messages.length }</span>
                        <i className="font-icon bell-circuit"/>
                    </div>
                </div>
            </li>
        );
    }
}
