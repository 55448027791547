import "mithril";
import Component from "_/lib/Component";
import {connect} from "_/lib/midux";
import {Container} from "_/components/plugins/ScrollBar";
import Question from "./Question";
import {
    setCurrentStep,
    setCurrentQuestion,
    addTmpQuestion
} from "_/actions/questionnaire";
import {DIRECTION_NONE, MODE_STEP_EDIT, TMP_QUESTION_ID} from "_/containers/questionnaire/constants";
import {FIELD_OF_OBJECT_OR_OWN_REGISTRY as OWN_FIELD} from "_/constants/questionsOfRelatedData";
import {FIELD_TEXT} from "_/constants/typesOfRegistryFIelds";
import ScrollBar from "_/components/plugins/ScrollBar/lib/ScrollBar";
import Button from "_/components/plugins/Button";
import {translate} from "_/localizations";

const mapStateToProps = state => ({
    currentStep    : state.questionnaire.currentStep,
    step           : state.questionnaire.currentStep,
    steps          : state.questionnaire.steps,
    ownRegistry    : state.registry.ownRegistry,
    currentQuestion: state.questionnaire.currentQuestion,
    info           : state.currentInfo
});

const mapActionsToProps = {
    setCurrentStep,
    setCurrentQuestion,
    addTmpQuestion
};

class Body extends Component {
    view() {
        const { isEdit = true }             = this.attrs.info;
        const { direction, questions = [] } = this.attrs.currentStep;
        const isEditStep                    = this.attrs.step.mode === MODE_STEP_EDIT;
        const isNoCurrentStep               = !this.attrs.step.id;

        return (
            <Container className="turbo-questionnaire__body">
                <div
                    className={`turbo-questionnaire__body-inner tile-list tile-list_bordered tile-list_hovered ${direction}`}
                    onanimationend={() => this.clearDirection()}
                >
                    {
                        questions.map(question => (
                            <Question
                                oncreate={({ dom }) => this.scrollToNewQuestion(dom, question.id)}
                                key={question.id}
                                data={question}
                            />
                        ))
                    }
                    {
                        this.attrs.currentQuestion.id || isEditStep || isNoCurrentStep || !isEdit ? null


                            : <div class="turbo-question__item-title right mt10">
                                <Button
                                    className="dashed-link color-blue mr10"
                                    onclick={() => this.addNewQuestion()}
                                >
                                    <Button.Text>
                                        {translate("questionnaire.addQuestion")}
                                    </Button.Text>
                                    <Button.Icon className="circle-plus fs15 pl5"/>
                                </Button>
                            </div>
                    }
                </div>
            </Container>
        );
    }

    clearDirection() {
        this.attrs.actions.setCurrentStep({ direction: DIRECTION_NONE });
    }

    addNewQuestion() {
        const { step, ownRegistry } = this.attrs;
        const countQuestions        = this.getCountQuestions();
        const tmpQuestion           = {
            id        : TMP_QUESTION_ID,
            comments  : null,
            expression: null,
            stepId    : step.id,
            name      : `${translate("questionnaire.prefixNewNameQuestion")} ${countQuestions + 1}`,
            type      : OWN_FIELD,
            value     : {
                bindingId   : ownRegistry.idBinding,
                registryId  : ownRegistry.id,
                typeOfField : FIELD_TEXT,
                defaultValue: "",
                view        : "700"
            }
        };


        this.attrs.actions.addTmpQuestion(tmpQuestion);
        this.attrs.actions.setCurrentQuestion(tmpQuestion);
    }

    getCountQuestions() {
        const { steps } = this.attrs;

        return steps.reduce((accumulator, step) => {
            return accumulator + (step.questions || []).length;
        }, 0);
    }

    scrollToNewQuestion(questionElement, questionId) {
        if (questionId === TMP_QUESTION_ID) {
            ScrollBar.scrollElementToYTop({
                element: questionElement, smooth: true, onEnd: () => {
                    setTimeout(() => m.redraw(), 0)
                }
            });
        }
    }
}

export default connect(mapStateToProps, mapActionsToProps)(Body);
