import {store} from '_/lib/midux';
import globalState from "_/lib/globalState";
import {translate} from "_/localizations";
import ChoiceRelatedData from "_/components/dialogs/ChoiceRelatedData";
import {
    removeParametricData,
    requestAddEditQuestion,
    setCurrentQuestion,
    setParametricData
} from "_/actions/questionnaire";
import {removeRelatedData} from "_/actions/relatedData";
import {FIELD_OF_OBJECT_OR_OWN_REGISTRY, OBJECT_OF_REGISTRY} from "_/constants/questionsOfRelatedData";
import {requestListObjects} from "_/actions/registry";
import {TMP_QUESTION_ID} from "_/containers/questionnaire/constants";
import {FIELD_CALC} from "_/constants/typesOfRegistryFIelds";

const onSelect = (type, value) => {
    store().dispatch(setCurrentQuestion({ type, value }));

    if (+type === OBJECT_OF_REGISTRY) {
        store().dispatch(requestListObjects(value.registryId));
    }
};

export const openRelatedDataModal = (type, nodeId) => {
    const { documentId } = store().getState().currentInfo;
    const userId         = globalState.get("userId");

    turbo.plugins.modal.open({
        width              : 500,
        closeOnOverlayClick: false,
        vnode              : {
            view() {
                return <ChoiceRelatedData
                    onSelect={onSelect}
                    localStorageName={`questionnaire_${documentId}_${userId}`}
                    type={type}
                    nodeId={nodeId}
                />
            }
        },
        class              : "related-data-modal",
        afterClose() {
            store().dispatch(removeRelatedData());
        }
    });
}

const getParametricByQuestion = (id, type, questionId) => {
    return mt.get({
        url        : Routing.generate("parametric_values_by_question", { id, type, questionId }),
        loadConfig : { isShow: true, name: "Questionnaire: getParametricByQuestion" },
        errorConfig: { isShow: true }
    });
};

export const processParametricData = (data, questionId) => {
    store().dispatch(setParametricData({ data }));

    if (Array.isArray(data)) {
        const dataWithId = data.map((item, index) => ({ ...item, id: index }));

        store().dispatch(setParametricData({
            questionId,
            options      : dataWithId,
            isShowOptions: true,
            typeOptions  : "parametric"
        }));
    } else if (Array.isArray(data.formats) && data.formats.length > 0) {
        store().dispatch(setParametricData({
            questionId,
            options      : data.formats,
            isShowOptions: true,
            typeOptions  : "formats"
        }));
    } else {
        insertParameterElement();
    }
};

export const insertParameterElement = (format) => {
    let { data, range } = store().getState().questionnaire.parametric,
        parameterElement, isRangeInEditItem, commonElement,
        parametricName, parametricData, selection;

    if (range) {
        commonElement = range.commonAncestorContainer;

        if (commonElement.nodeType === 3) {
            commonElement = commonElement.parentNode;
        }

        isRangeInEditItem = commonElement.closest(".edit-punct")
                            || commonElement.closest(".punct-head-edit");
    } else {
        isRangeInEditItem = false;
    }

    if (!isRangeInEditItem) {
        turbo.plugins.modal.alert({
            width      : 400,
            title      : translate("questionnaire.bindingParameterTitle"),
            text       : translate("questionnaire.bindingParameterText"),
            iconClasses: "warning color-red fs20 mr5"
        });

        store().dispatch(removeParametricData());

        return;
    }

    parametricName = data.name;
    parametricData = data.data;

    if (format) {
        parametricData["format-id"] = format.id;
        parametricName              = `${parametricName}.${format.name}`;
    }

    parameterElement = document.createElement("span");
    parameterElement.classList.add("parameter");
    parameterElement.setAttribute("contenteditable", false);
    parameterElement.innerHTML = parametricName;

    Object.keys(parametricData).forEach(attr => {
        const value = parametricData[attr];

        parameterElement.setAttribute(`data-${attr}`, value);
    });

    range.deleteContents();
    range.insertNode(parameterElement);
    range.setStartAfter(parameterElement);
    range.collapse(true);

    selection = window.getSelection();

    selection.removeAllRanges();
    selection.addRange(range);

    store().dispatch(removeParametricData());
};

export const insertParameter = question => {
    const { documentType, documentId } = store().getState().currentInfo;
    const range                        = turbo.component.turboEditor.utils.getCursor();
    const type                         = documentType === "documentFragment" ? "document-fragment" : "template";

    store().dispatch(setParametricData({ range }));

    getParametricByQuestion(documentId, type, question.id)
        .then(result => processParametricData(result.data, question.id));
};

const showWarningMessage = (title, text) => {
    turbo.plugins.modal.alert({
        width      : 300,
        title,
        text,
        iconClasses: 'warning color-red fs24 mr5'
    });
};

const getFieldObject = (text, startFieldIndex) => {
    const registry                         = store().getState().registry;
    const { ownRegistry: { fields = [] } } = registry;

    let endFieldIndex = text.indexOf("]", startFieldIndex),
        fieldName     = text.substring(startFieldIndex + 1, endFieldIndex),
        field         = fields.find(item => item.fieldName === fieldName),
        fieldId       = field ? field.id : -1;

    return { type: "field", fieldId };
};

const checkAndAddOperand = (operand, result) => {
    if (operand.text.trim() !== "") {
        result.push({ type: "operand", value: operand.text });
        operand.text = "";
    }
};

const getSettingsByFormula = text => {
    let result     = [],
        textLength = text.length,
        operand    = { text: "" };

    for (let index = 0; index < textLength; index++) {
        let char = text[index];

        switch (char) {
            case "+":
                checkAndAddOperand(operand, result);
                result.push({ type: "operator", value: char });
                break;
            case "-":
                checkAndAddOperand(operand, result);
                result.push({ type: "operator", value: char });
                break;
            case "*":
                checkAndAddOperand(operand, result);
                if (text[index + 1] === "*") {
                    result.push({ type: "operator", value: "**" });
                    index++;
                } else {
                    result.push({ type: "operator", value: char });
                }
                break;
            case "/":
                checkAndAddOperand(operand, result);
                result.push({ type: "operator", value: char });
                break;
            case "%":
                checkAndAddOperand(operand, result);
                result.push({ type: "operator", value: char });
                break;
            case "(":
                checkAndAddOperand(operand, result);
                result.push({ type: "operator", value: char });
                break;
            case ")":
                checkAndAddOperand(operand, result);
                result.push({ type: "operator", value: char });
                break;
            case "[":
                checkAndAddOperand(operand, result);
                result.push(getFieldObject(text, index));
                index = text.indexOf("]", index);
                break;
            case " ":
                checkAndAddOperand(operand, result);
                break;
            default:
                operand.text = operand.text + char;
                break;
        }
    }

    checkAndAddOperand(operand, result);

    return result;
};

export const saveQuestion = () => {
    const { documentType, documentId } = store().getState().currentInfo;
    const data                         = store().getState().questionnaire.currentQuestion;

    if (!data.name || !data.name.trim()) {
        showWarningMessage(
            translate("questionnaire.warningMessage.titleSaveQuestion"),
            translate("questionnaire.warningMessage.textEmptyQuestionName")
        );

        return;
    }

    if (!data.type || +data.type === 0) {
        showWarningMessage(
            translate("questionnaire.warningMessage.titleSaveQuestion"),
            translate("questionnaire.warningMessage.textWarningQuestionType")
        );

        return;
    }

    if (data.value && data.value.typeOfField === FIELD_CALC) {
        let terms = getSettingsByFormula(data.value.defaultValue);

        data.value.settings = { priority: 0, terms };
    }

    if (data.id === TMP_QUESTION_ID) {
        delete data.id;
    }

    store().dispatch(requestAddEditQuestion({ documentId, documentType, data }));
};
