import m from 'mithril';
import Component from 'lib/Component';
import globalState from 'lib/globalState';
import {connect} from "_/lib/midux";
import {Container} from 'components/plugins/ScrollBar';
import Dropdown from 'components/plugins/Dropdown';
import WidgetItem from './components/WidgetItem';
import LinkToPage from '../components/LinkToPage';
import {translate} from 'localizations';
import {getWidgetNotificationsAsync, setRemoteChangesFlag} from 'actions/notifications';
import {queueWS} from "lib/ws/ws";

const mapStateToProps = state => ({
    entities        : state.notifications.messages,
    count           : state.notifications.count,
    hasRemoteChanges: state.notifications.hasRemoteChanges || false,
    hasLocalChanges : state.notifications.hasLocalChanges || false
});

const mapActionsToProps = {
    getWidgetNotificationsAsync,
    setRemoteChangesFlag
};

function setCountOnTitle(value) {
    let navBar = document.querySelector('.nav-bar'),
        count  = +value ? `(${value})` : '',
        title  = document.title.split(/^\((\d+)\)/).pop().trim();

    globalState.set({ countMessage: value });

    if (navBar) {
        navBar.querySelector(".nav-message").innerHTML     = value;
        navBar.querySelector(".nav-new-message").innerHTML = value;
    }

    document.title = `${count} ${title}`;
}

class Widget extends Component {
    oninit() {
        this.attrs.actions.getWidgetNotificationsAsync().then(() => {
            let channel = globalState.get('channel'),
                userId  = globalState.get('userId'),
                jsonData;

            queueWS.add(data => {
                jsonData = JSON.parse(data);

                if (jsonData.channel === channel) {
                    if (jsonData.data && jsonData.data.includes(String(userId)) && !this.attrs.hasLocalChanges) {
                        this.attrs.actions.getWidgetNotificationsAsync().then(() => {
                            this.attrs.actions.setRemoteChangesFlag(true);
                        });
                    }
                }
            }, this);
        });
    }

    getCounterCssClasses() {
        return [
            "notice-widget__count",
            ` ${this.attrs.hasRemoteChanges ? "blinkBlueToOrange" : ""}`
        ].join('');
    }

    onDropdownClick() {
        if (this.attrs.entities.length === 1) {
            WidgetItem.showMessages(this.attrs.entities[0].subject)
        }

        if (this.attrs.hasRemoteChanges) {
            this.attrs.actions.setRemoteChangesFlag(false);
        }
    }

    view() {
        let { entities = [], count = 0 } = this.attrs;

        setCountOnTitle(count);

        return (
            <Dropdown
                className="notice-widget"
                center={true}
                disabled={entities.length === 1}
                onclick={() => this.onDropdownClick()}
            >
                <Dropdown.Label>
                    <div className="notice-widget__indicator">
                        <If condition={count}>
                            <span className={this.getCounterCssClasses()}>
                                {count}
                            </span>
                        </If>
                        <i className="notice-widget__icon font-icon bell fs20"/>
                    </div>
                </Dropdown.Label>
                <Dropdown.Inner className="p0">
                    <Choose>
                        <When condition={!!count}>
                            <Container className="notice-widget__wrapper">
                                <ul className="notice-widget__list">
                                    <For each="item" of={entities}>
                                        <If condition={item.messages.length}>
                                            <WidgetItem item={item}/>
                                        </If>
                                    </For>
                                </ul>
                            </Container>
                        </When>
                        <Otherwise>
                            <div className="p10 color-grey fs13 text-nowrap">
                                {translate('notifications.noNewMessages')}
                            </div>
                        </Otherwise>
                    </Choose>
                    <div className="bg-blue p10 fs13 text-right">
                        <LinkToPage/>
                    </div>
                </Dropdown.Inner>
            </Dropdown>
        );
    }
}

export default connect(mapStateToProps, mapActionsToProps)(Widget);
