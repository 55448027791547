export default function debounce(func, ms, context = null) {
    let timer   = null,
    debounced;

    debounced = function(...args) {
        const onComplete = () => {
            func.apply(context, args);
            timer = null;
        };

        if (timer) {
            clearTimeout(timer);
        }

        timer = setTimeout(onComplete, ms);
    };

    debounced.cancel = function() {
        clearTimeout(timer);
        timer = null;
    };

    debounced.run = function(...args) {
        clearTimeout(timer);
        timer = null;

        func.apply(context, args);
    };

    return debounced;
}
