import {
    SET_LIST_DIVISIONS,
    SET_AGENCIES,
    SET_SEARCH_DATA_DIVISIONS,
    REQUEST_LIST_DIVISIONS,
    REQUEST_CREATE_DIVISION,
    REQUEST_UPDATE_DIVISION,
    REQUEST_REMOVE_DIVISION
} from "_/types/holding-division";

export const setAgencies = payload => {
    return {
        type: SET_AGENCIES,
        payload
    };
};

export const setSearchDataDivisions = payload => ({
    type: SET_SEARCH_DATA_DIVISIONS,
    payload
});

export const requestListDivisions = () => {
    return {
        type: REQUEST_LIST_DIVISIONS

    };
};

export const setListDivisions = payload => {
    return {
        type: SET_LIST_DIVISIONS,
        payload
    };
};

export const createDivision =  payload => {
    return {
        type: REQUEST_CREATE_DIVISION,
        payload
    };
};

export const updateDivision =  payload => {
    return {
        type: REQUEST_UPDATE_DIVISION,
        payload
    };
};

export const removeDivision =  payload => {
    return {
        type: REQUEST_REMOVE_DIVISION,
        payload
    };
};
