import {
    ADD_TEMP_STEP, ADD_TMP_QUESTION,
    DELETE_TEMP_STEP,
    DELETE_TMP_QUESTION,
    INIT_QUESTIONNAIRE_DATA,
    REMOVE_CURRENT_QUESTION,
    REMOVE_PARAMETRIC_DATA,
    REQUEST_ADD_EDIT_QUESTION,
    REQUEST_ADD_EDIT_STEP,
    REQUEST_COPY_STEP,
    REQUEST_DELETE_QUESTION,
    REQUEST_DELETE_QUESTIONNAIRE,
    REQUEST_DELETE_STEP,
    REQUEST_LIST_GROUP_TEXT_BLOCK,
    REQUEST_LIST_TEXT_BLOCK,
    REQUEST_MOVE_QUESTION,
    REQUEST_MOVE_STEP,
    REQUEST_QUESTIONNAIRE_DATA,
    SET_CURRENT_QUESTION,
    SET_CURRENT_STEP,
    SET_LIST_GROUP_TEXT_BLOCK,
    SET_LIST_TEXT_BLOCK, SET_OPTIONS_CURRENT_QUESTION,
    SET_PARAMETRIC_DATA,
    SET_QUESTIONNAIRE_DATA,
    SET_SETTINGS_CURRENT_QUESTION, SET_STATUS_LOAD_GROUP_TEXT_BLOCK,
    SET_TMP_NAME_STEP,
    SET_VALUE_CURRENT_QUESTION, UPDATE_CURRENT_STEP
} from "_/types/questionnaire";

export const initQuestionnaireData = payload => ({
    type: INIT_QUESTIONNAIRE_DATA,
    payload
});

export const requestQuestionnaireData = payload => ({
    type: REQUEST_QUESTIONNAIRE_DATA,
    payload
});

export const setQuestionnaireData = payload => ({
    type: SET_QUESTIONNAIRE_DATA,
    payload
});

export const setCurrentStep = payload => ({
    type: SET_CURRENT_STEP,
    payload
});

export const addTempStep = payload => ({
    type: ADD_TEMP_STEP,
    payload
});

export const deleteTempStep = () => ({
    type: DELETE_TEMP_STEP
});

export const requestAddEditStep = payload => ({
    type: REQUEST_ADD_EDIT_STEP,
    payload
});

export const setTmpNameStep = payload => ({
    type: SET_TMP_NAME_STEP,
    payload
});

export const requestDeleteStep = payload => ({
    type: REQUEST_DELETE_STEP,
    payload
});

export const requestMoveStep = payload => ({
    type: REQUEST_MOVE_STEP,
    payload
});

export const requestAddEditQuestion = payload => ({
    type: REQUEST_ADD_EDIT_QUESTION,
    payload
});

export const setCurrentQuestion = payload => ({
    type: SET_CURRENT_QUESTION,
    payload
});

export const removeCurrentQuestion = () => ({
    type: REMOVE_CURRENT_QUESTION
});

export const setValueCurrentQuestion = payload => ({
    type: SET_VALUE_CURRENT_QUESTION,
    payload
});

export const setSettingsCurrentQuestion = payload => ({
    type: SET_SETTINGS_CURRENT_QUESTION,
    payload
});

export const requestListGroupTextBlock = () => ({
    type: REQUEST_LIST_GROUP_TEXT_BLOCK
});

export const setListGroupTextBlock = payload => ({
    type: SET_LIST_GROUP_TEXT_BLOCK,
    payload
});

export const setStatusLoadListGroupTextBlock = payload => ({
    type: SET_STATUS_LOAD_GROUP_TEXT_BLOCK,
    payload
});

export const requestListTextBlock = payload => ({
    type: REQUEST_LIST_TEXT_BLOCK,
    payload
});

export const setListTextBlock = payload => ({
    type: SET_LIST_TEXT_BLOCK,
    payload
});

export const addTmpQuestion = payload => ({
    type: ADD_TMP_QUESTION,
    payload
});

export const deleteTmpQuestion = () => ({
    type: DELETE_TMP_QUESTION
});

export const updateCurrentStep = () => ({
    type: UPDATE_CURRENT_STEP
});

export const requestDeleteQuestion = payload => ({
    type: REQUEST_DELETE_QUESTION,
    payload
});

export const setParametricData = payload => ({
    type: SET_PARAMETRIC_DATA,
    payload
});

export const removeParametricData = () => ({
    type: REMOVE_PARAMETRIC_DATA
});

export const requestDeleteQuestionnaire = payload => ({
    type: REQUEST_DELETE_QUESTIONNAIRE,
    payload
});

export const requestMoveQuestion = payload => ({
    type: REQUEST_MOVE_QUESTION,
    payload
});

export const requestCopyStep = payload => ({
    type: REQUEST_COPY_STEP,
    payload
});

export const setOptionsCurrentQuestion = payload => ({
    type: SET_OPTIONS_CURRENT_QUESTION,
    payload
});
