import m from "mithril";
import {translate} from "localizations";
import Component from "lib/Component";
import TextEllipsis from 'components/plugins/TextEllipsis';
import Button from "components/plugins/Button";
import {Container} from "_/components/plugins/ScrollBar";
import {
    getPatternTextBlockGroups,
    getPatternTextBlockGroupsFragments,
    saveTextBlockGroup,
    deleteTextBlockGroup
} from "./promises";
import AddList from "./components/AddList";

//Корневой элемент локализации
const ROOT = "listsTextBlocksOfTemplate";

class ListsTextBlocksOfTemplate extends Component {
    oninit() {
        this.data = [];

        this.loadData();
    }

    view() {
        let data           = this.data,
            statusApproved = turbo.lib.checkApprovedDocumentTemplate();

        return (
            <div>
                <div className="modal__header bg-blue color-white">
                    <div className="modal-close font-icon cancel"/>
                    <span>{translate(`${ROOT}.title`)}</span>
                </div>
                <div className="modal__body">
                    <div className="turbo-tabs__container-flex pb10 pl5 pr5">
                        <div className="turbo-tabs__group-flex">
                        </div>

                        <Button
                            className="btn color-blue turbo-link"
                            onclick={() => statusApproved ? null : this.openWindowNewEditList()}
                            disabled={statusApproved}
                        >
                            <Button.Icon className="circle-plus fs20"/>
                            <Button.Text className="dashed-link fs15">
                                {translate(`${ROOT}.buttons.createList`)}
                            </Button.Text>
                        </Button>
                    </div>

                    <Container
                        className="tile-list tile-list_flex tile-list_striped tile-list_bordered tile-list_hovered"
                        style="max-height: calc(100vh - 244px);"
                    >
                        <Choose>
                            <When condition={data.length === 0}>
                                <div className="fs16 color-grey">
                                    {translate(`${ROOT}.emptyList`)}
                                </div>
                            </When>
                            <Otherwise>
                                {
                                    data.map(list => {
                                        return (
                                            <div className="tile-list__item-flex tile-list__item p0 pl5">
                                                <div className="content-flex">
                                                    <TextEllipsis>{list.name}</TextEllipsis>
                                                    <TextEllipsis className="content-flex__description">
                                                        {list.comment}
                                                    </TextEllipsis>
                                                    <TextEllipsis className="content-flex__description">
                                                        {list.description}
                                                    </TextEllipsis>
                                                </div>

                                                <div className="buttons-flex">
                                                    <Button
                                                        className="turbo-button__size_md color-blue fs20"
                                                        onclick={() => statusApproved
                                                            ? null
                                                            : this.openGroupOfTextBlock(list.id)}
                                                        disabled={statusApproved}
                                                    >
                                                        <Button.Icon className="share-out block"/>
                                                    </Button>
                                                    <Button
                                                        className="turbo-button__size_md color-blue fs20"
                                                        onclick={() => statusApproved
                                                            ? null
                                                            : this.openWindowNewEditList(list)}
                                                        disabled={statusApproved}
                                                    >
                                                        <Button.Icon className="pencil block"/>
                                                    </Button>
                                                    <Button
                                                        className="turbo-button__size_md color-red fs20"
                                                        onclick={() => statusApproved
                                                            ? null
                                                            : this.deleteList(list)}
                                                        disabled={statusApproved}
                                                    >
                                                        <Button.Icon className="icon-trash block"/>
                                                    </Button>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </Otherwise>
                        </Choose>
                    </Container>
                </div>
                <div className="modal__footer bg-grey text-right">
                    <Button className="turbo-button__size_md dashed-link color-blue p0 mr10 modal-close">
                        {translate("buttons.close")}
                    </Button>
                </div>
            </div>
        );
    }

    loadData() {
        if (this.attrs.type === "document-fragment") {
            getPatternTextBlockGroupsFragments(this.attrs.templateId)
                .then(result => {
                    this.data = result.data || [];
                });
        } else {
            getPatternTextBlockGroups(this.attrs.templateId)
                .then(result => {
                    this.data = result.data || [];
                });
        }
    }

    openWindowNewEditList(data = {}) {
        let modalClass = "modal-create-new-edit-list",
            validate   = (name, excludeId) => this.validateListName(name, excludeId),
            save       = data => this.saveList(data);

        turbo.plugins.modal.open({
            width              : 300,
            vnode              : {
                view() {
                    return (
                        <AddList
                            data={data}
                            modalClass={modalClass}
                            onSave={save}
                            onValidate={validate}
                        />
                    )
                }
            },
            class              : modalClass,
            closeOnOverlayClick: false
        });
    }

    validateListName(name, excludeId) {
        let lists       = this.data || [],
            isDuplicate = !!lists.find(item => item.id !== excludeId && item.name === name);

        if (isDuplicate) {
            turbo.plugins.modal.alert({
                width      : 400,
                title      : translate(`${ROOT}.warning.duplicateListNameTitle`),
                text       : translate(`${ROOT}.warning.duplicateListNameText`),
                iconClasses: 'warning color-red fs24 mr5'
            });

            return false;
        }

        return true;
    }

    saveList(data) {
        saveTextBlockGroup(this.attrs.templateId, data, this.attrs.type)
            .then(() => {
                this.loadData();
            });
    }

    deleteList(list) {
        let text = `${translate(`${ROOT}.warning.deleteListText`)} "${list.name}" ?`;

        turbo.plugins.modal.confirm(
            {
                title        : translate(`${ROOT}.warning.deleteListTitle`),
                btnCancelText: translate("buttons.cancel"),
                btnAcceptText: translate("buttons.yes"),
                text,
                iconClasses  : "font-icon warning fs24 color-orange mr5"
            },
            () => {
                deleteTextBlockGroup(list.id)
                    .then(() => {
                        this.loadData();
                    });
            }
        );
    }

    openGroupOfTextBlock(groupId) {
        window.open(Routing.generate("text_block_index", { id: groupId }));
    }
}

export default ListsTextBlocksOfTemplate;
