import {
    REQUEST_LIST_ROLES,
    REQUEST_TYPES_OF_REGISTRY_FIELDS,
    SET_LIST_ROLES,
    SET_TYPES_OF_REGISTRY_FIELDS,
    SET_TYPES_OF_REGISTRY_FIELDS_FOR_QUESTIONNAIRE
} from "_/types/referenceBooks";

export const requestTypesOfRegistryFields = () => ({
    type: REQUEST_TYPES_OF_REGISTRY_FIELDS
});

export const setTypesOfRegistryFields = payload => ({
    type: SET_TYPES_OF_REGISTRY_FIELDS,
    payload
});

export const setTypesOfRegistryFieldsForQuestionnaire = payload => ({
    type: SET_TYPES_OF_REGISTRY_FIELDS_FOR_QUESTIONNAIRE,
    payload
});

export const requestListRoles = () => ({
    type: REQUEST_LIST_ROLES
});

export const setListRoles = payload => ({
    type: SET_LIST_ROLES,
    payload
});
