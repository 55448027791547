import m from 'mithril';
import Component from 'lib/Component';
import turbo from 'lib/turbo';
import { showProcess, hideProcess } from 'lib/progressbar';
import LinkToPage from '../../components/LinkToPage';
import { Container } from 'components/plugins/ScrollBar';
import MessageItem from './MessageItem';
import { translate } from 'localizations';
import { connect } from "_/lib/midux";
import { removeWidgetMessageAsync, viewWidgetMessageAsync } from 'actions/notifications';

const SYSTEM_MESSAGE_KEY = "Системные уведомления";

const mapStateToProps = (state, attrs) => ({
    entity: state.notifications.messages.find(entity => entity.subject === attrs.subject)
});

const mapActionsToProps = {
    removeWidgetMessageAsync,
    viewWidgetMessageAsync
};

function showNotification({ id, header, body }) {
    let navBar = document.querySelector('.nav-bar');

    let template = [
        '<div>',
        '   <div class="modal__header bg-blue color-white">',
        '       <div class="modal-close font-icon cancel"></div>',
        '       <div class="row row_table">',
        '           <div class="col-7 v-align-middle">{header}</div>',
        '           <div class="col-5 v-align-middle text-right">',
        '               <div class="nav-card-object inline-block v-align-top"></div>',
        '           </div>',
        '       </div>',
        '   </div>',
        '   <div class="modal__body p0 bg-grey ps-scrollable">',
        '       <div class="pr15 pl15">{body}</div>',
        '   </div>',
        '</div>'
    ].join('');

    turbo.plugins.modal.open({
        htmlString:          turbo.formatString(template, { header, body }),
        closeOnOverlayClick: false,
        width:               800,
        afterLoading() {
            let element     = this.element,
                body        = element.querySelector('.modal__body'),
                modalZindex = window.getComputedStyle(element).zIndex;

            if (navBar) {
                navBar.style.zIndex = +modalZindex + 1;

                (new turbo.quickNavigationBar.CardObject({
                    selectorParent: ".nav-card-object",
                    objectId:       +id,
                    typeObject:     "message"
                })).buildCardObject();
            }

            turbo.pages.helpers.scrollBar.init(body);
        },
        afterClose() {
            if (navBar) {
                navBar.style.zIndex = '';
            }
        }
    });
}

class MessagesList extends Component {
    onbeforeupdate({ attrs }, { dom }) {
        if (attrs.entity && attrs.entity.messages.length) {
            return true;
        } else {
            turbo.plugins.modal.close(dom.closest('.modal'));

            return false;
        }
    }

    remove(e, message) {
        e.redraw = false;

        turbo.plugins.modal.confirm({
            title:         translate('notifications.alerts.removeMessage'),
            btnCancelText: translate('buttons.cancel'),
            btnAcceptText: translate('buttons.remove')
        }, () => {
            showProcess("notifications:widget:remove");

            this.attrs.actions.removeWidgetMessageAsync(message)
                .then(() => hideProcess("notifications:widget:remove"));
        });
    }

    show(e, message) {
        e.redraw = false;

        showProcess('notifications:showMessage');

        this.attrs.actions.viewWidgetMessageAsync(message).then(response => {
            hideProcess('notifications:showMessage');

            if (response.success) {
                showNotification(response);
            }
        });
    }

    markAsRead(e, message) {
        e.redraw = false;

        showProcess("notifications:widget:markAsRead");

        this.attrs.actions.viewWidgetMessageAsync(message)
            .then(() => hideProcess("notifications:widget:markAsRead"));
    }

    view({ attrs }) {
        let { subject, messages } = attrs.entity || {};

        return (
            <If condition={ subject && messages.length }>
                <div>
                    <div className="modal__header bg-blue color-white">
                        <div className="modal-close font-icon cancel"></div>
                        <span>
                            {
                                subject === SYSTEM_MESSAGE_KEY
                                    ? translate('notifications.newSystemMessage')
                                    : `${translate('notifications.newMessageFrom')} ${subject}`
                            }
                        </span>
                    </div>
                    <Container className="modal__body p0">
                        <ul className="tile-list tile-list_hovered tile-list_bordered">
                            <For each="message" of={ messages }>
                                <MessageItem
                                    message={ message }
                                    showMessage={ e => this.show(e, message) }
                                    removeMessage={ e => this.remove(e, message) }
                                    markAsRead={ e => this.markAsRead(e, message) }
                                />
                            </For>
                        </ul>
                    </Container>
                    <div className="modal__footer bg-grey text-right">
                        <LinkToPage />
                    </div>
                </div>
            </If>
        );
    }
}

export default connect(mapStateToProps, mapActionsToProps)(MessagesList);
