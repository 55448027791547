import m from "mithril";
import Component from "_/lib/Component";
import {translate} from 'localizations';
import Button from "components/plugins/Button";
import Checkbox from "_/components/plugins/Checkbox";
import RadioButtonGroup from "_/components/plugins/RadioButtonGroup";
import {requestListRoles} from "_/actions/referenceBooks";
import {connect} from "_/lib/midux";

const mapStateToProps = state => ({
    documentStructure: state.documentTemplate.documentStructure
});

const mapActionsToProps = {
};

class RedirectSettings extends Component {
    oninit() {
        this.annexes = this.getList(this.attrs.documentStructure)
        this.isRedirect = this.attrs.redirect.isRedirect;
        this.redirectTo = this.attrs.redirect.redirectTo;
    }

    view() {
        const { name, redirect } = this.attrs;

        return m.fragment({}, [
            <div className="modal__header bg-blue color-white">
                <div className="font-icon cancel modal-close"/>
                <span>
                    {`${translate("questionnaire.dialogs.titleRedirectSettings")} "${name}"`}
                </span>
            </div>,

            <div className="modal__body">
                <Choose>
                    <When condition={this.annexes.length > 0}>
                        <div className="mb10">
                            <Checkbox
                                value={this.isRedirect}
                                text={translate("questionnaire.dialogs.isRedirect")}
                                onchange={value => this.onChangeRedirect(value)}
                            />
                        </div>
                        <div className="mb10">
                            <Choose>
                                <When condition={this.isRedirect}>
                                    <label>{translate("questionnaire.dialogs.annexDocuments")}</label>
                                    <RadioButtonGroup
                                        data={this.annexes}
                                        valueId={this.redirectTo}
                                        onSelect={value => this.onSelectAnnex(value)}
                                    />
                                </When>
                            </Choose>
                        </div>
                    </When>
                </Choose>
            </div>,

            <div className="modal__footer bg-grey text-right">
                <Button className="turbo-button__size_md dotted-link mr20 fs14 p0 modal-close color-blue">
                    {translate("buttons.cancel")}
                </Button>
                <Button
                    className="turbo-button__size_md turbo-button__bg_orange turbo-button__text_white"
                    onclick={() => this.onSave()}
                >
                    {translate("buttons.save")}
                </Button>
            </div>
        ]);
    }

    getList(data) {
        let list = []

        data.forEach(item => {
            if (!item.isMain) {
                item.name = item.title
                list.push(item)
            }
        })
        return list
    }

    onChangeRedirect(value) {
        this.isRedirect = value;
    }

    onSelectAnnex(value) {
        this.redirectTo = value.id;
    }

    onChangeViewOther(value) {
        this.isViewOther = value;
    }

    onSave() {
        const { onSave } = this.attrs,
            data     = {
                redirectTo: this.redirectTo,
                isRedirect: this.isRedirect
            };

        if (typeof onSave === "function") {
            onSave(data);
        }
    }
}

export default connect(mapStateToProps, mapActionsToProps)(RedirectSettings);
