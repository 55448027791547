import m from "mithril";
import Component from "_/lib/Component";
import {translate} from 'localizations';
import Button from "components/plugins/Button";
import Checkbox from "_/components/plugins/Checkbox";
import MultiDropDownList from "_/components/plugins/MultiDropDownList";
import {requestListRoles} from "_/actions/referenceBooks";
import {connect} from "_/lib/midux";

const mapStateToProps = state => ({
    roles: state.referenceBooks.roles
});

const mapActionsToProps = {
    requestListRoles
};

class AccessSettings extends Component {
    oninit() {
        const { values = [], isViewOther = true } = this.attrs;

        this.attrs.actions.requestListRoles();

        this.isAccessAll = values.length === 0;
        this.isViewOther = isViewOther;
        this.values      = values;
    }

    view() {
        const { name, roles } = this.attrs;

        return m.fragment({}, [
            <div className="modal__header bg-blue color-white">
                <div className="font-icon cancel modal-close"/>
                <span>
                    {`${translate("questionnaire.dialogs.titleAccessSettings")} "${name}"`}
                </span>
            </div>,

            <div className="modal__body">
                <div className="mb10">
                    <Checkbox
                        value={this.isAccessAll}
                        text={translate("questionnaire.dialogs.accessAll")}
                        onchange={value => this.onChangeAccessAll(value)}
                    />
                </div>
                <div className="mb10">
                    <label>{translate("questionnaire.dialogs.roles")}</label>
                    <MultiDropDownList
                        data={roles}
                        values={this.values}
                        onchange={values => this.onChangeCheckRoles(values)}
                        disabled={this.isAccessAll}
                    />
                </div>
                <div>
                    <Checkbox
                        value={this.isViewOther}
                        text={translate("questionnaire.dialogs.accessViewOtherRoles")}
                        onchange={value => this.onChangeViewOther(value)}
                        disabled={this.isAccessAll}
                    />
                </div>
            </div>,

            <div className="modal__footer bg-grey text-right">
                <Button className="turbo-button__size_md dotted-link mr20 fs14 p0 modal-close color-blue">
                    {translate("buttons.cancel")}
                </Button>
                <Button
                    className="turbo-button__size_md turbo-button__bg_orange turbo-button__text_white"
                    onclick={() => this.onSave()}
                >
                    {translate("buttons.save")}
                </Button>
            </div>
        ]);
    }

    onChangeAccessAll(value) {
        this.isAccessAll = value;
    }

    onChangeCheckRoles(values) {
        this.values = values;
    }

    onChangeViewOther(value) {
        this.isViewOther = value;
    }

    onSave() {
        const { onSave } = this.attrs;

        if (typeof onSave === "function") {
            onSave(this.isAccessAll ? [] : this.values, this.isViewOther);
        }
    }
}

export default connect(mapStateToProps, mapActionsToProps)(AccessSettings);
