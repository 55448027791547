import "mithril";
import {connect} from "_/lib/midux";
import Component from "_/lib/Component";
import Header from "./components/Header";
import Label from "./components/Label";
import Body from "./components/Body";
import Footer from "./components/Footer";
import {requestRelatedData, requestBindingsData} from "_/actions/relatedData";

const mapStateToProps = state => ({
    info: state.currentInfo
});

const mapActionsToProps = {
    requestRelatedData,
    requestBindingsData
};

class ChoiceRelatedData extends Component {
    oninit() {
        this.listeners = {};
    }

    oncreate() {
        const { documentType, documentId } = this.attrs.info;

        this.attrs.actions.requestRelatedData({ documentType, documentId });
        this.attrs.actions.requestBindingsData(documentId);
    }

    view() {
        const { localStorageName, onSelect, type, nodeId } = this.attrs;

        return (
            <div>
                <Header/>
                <Label localStorageName={localStorageName} listeners={this.listeners} type={type}/>
                <Body localStorageName={localStorageName} addListener={this.addListener.bind(this)} nodeId={nodeId}/>
                <Footer onSelect={onSelect}/>
            </div>
        );
    }

    addListener(name, fn) {
        this.listeners[name] = fn;
    }
}

export default connect(mapStateToProps, mapActionsToProps)(ChoiceRelatedData);
