let turbo = {};

try {
    turbo.supportLocalStorage = (
        ('localStorage' in window)
        &&
        (window['localStorage'] !== null)
    );
} catch (e) {
    turbo.supportLocalStorage = false;
}

turbo.namespace = (function() {
    let separator = '.',
        token     = '$',
        singleFn, multiFn;

    singleFn = function(specifier) {
        let parts   = specifier.split('.'),
            pLen    = parts.length,
            current = window.turbo,
            parent  = current,
            name    = 'turbo',
            p, part;

        for (p = 0; p < pLen; p++) {
            part = parts[p];
            name += separator + part;

            if (part) {
                if (current[part] === undefined) {
                    current[part] = {};

                    current[part][token] = { name, parent };
                }

                parent = current = current[part];
            }
        }

        return current;
    };

    multiFn = function(specifiers) {
        let aLen = specifiers.length,
            a, arg, current;

        for (a = 0; a < aLen; a++) {
            arg     = specifiers[a];
            current = singleFn(arg);
        }

        return current;
    };

    return function() {
        return arguments.length > 1
            ? multiFn(arguments)
            : singleFn(arguments[0]);
    };
})();

turbo.formatString = (function() {
    let sliceFn = Array.prototype.slice,
        rxIndex = /{(\d+)}/g,
        objectFn, arrayFn;

    objectFn = function(str, args) {
        let a, rx;

        for (a in args) {
            if (args.hasOwnProperty(a)) {
                rx  = new RegExp('\\{' + a + '\\}', 'gi');
                str = str.replace(rx, args[a]);
            }
        }

        return str;
    };

    arrayFn = function(str, args) {
        let replaceFn = function(match, number) {
            return args[number];
        };

        return str.replace(rxIndex, replaceFn);
    };

    return function() {
        let args = sliceFn.call(arguments, 0),
            str  = args.shift();

        if ((typeof str !== 'string') || !str) {
            return str;
        }

        return typeof args[0] === 'object'
            ? objectFn(str, args[0])
            : arrayFn(str, args);
    };
})();

/**
 * Функция для динамического изминения размеров блоков с помощью мыши
 * parameters.resizableEl - изменяемый элемент
 * parameters.resizingEl  - элемент с помощью которого происходит изминение
 * parameters.direction   - направление изминения (EW: восток-запад, NS: север-юг, EWNS: во все направления)
 * parameters.maxWidth    - максимальная ширина элемента
 * parameters.minWidth    - минимальная ширина элемента
 * parameters.callback    - функция которая будет вызывать после изминения размеров элемента.
 * parameters.onEndResize - функция которая будет вызывана после окончания изменения размеров.
 *
 * @param {object} parameters - параметры.
 */
turbo.resize = function(parameters) {
    let resizableEl = parameters.resizableEl,
        resizingEl  = parameters.resizingEl,
        startX, startY, startWidth, startHeight,
        initDrag, doDrag, stopDrag, nextSize;

    doDrag = function(e) {
        e.preventDefault();
        window.getSelection().removeAllRanges();

        if (parameters.direction === "EW" || parameters.direction === "EWNS") {
            nextSize = startWidth + e.clientX - startX;

            if (parameters.maxWidth && nextSize > parameters.maxWidth) {
                nextSize = parameters.maxWidth;
            } else if (parameters.minWidth && nextSize < parameters.minWidth) {
                nextSize = parameters.minWidth;
            }

            resizableEl.style.width = (nextSize) + 'px';
        }

        if (parameters.direction === "NS" || parameters.direction === "EWNS") {
            resizableEl.style.height = (startHeight + e.clientY - startY) + 'px';
        }

        if (parameters.callback) {
            parameters.callback(resizableEl.style.width, resizableEl.style.height);
        }
    };

    stopDrag = function(e) {
        document.documentElement.removeEventListener('mousemove', doDrag, false);
        document.documentElement.removeEventListener('mouseup', stopDrag, false);

        if (parameters.onEndResize) {
            parameters.onEndResize();
        }
    };

    initDrag = function(e) {
        startX      = e.clientX;
        startY      = e.clientY;
        startWidth  = parseInt(document.defaultView.getComputedStyle(resizableEl).width, 10);
        startHeight = parseInt(document.defaultView.getComputedStyle(resizableEl).height, 10);
        document.documentElement.addEventListener('mousemove', doDrag, false);
        document.documentElement.addEventListener('mouseup', stopDrag, false);
    };

    resizingEl.addEventListener('mousedown', initDrag, false);
};

turbo.stringToHtmlElements = function(string) {
    let div = document.createElement('div');

    div.innerHTML = string;

    return div.childNodes;
};

turbo.stringToHtmlElement = function(string) {
    let div = document.createElement('div');

    div.innerHTML = string;

    return div.firstChild;
};

turbo.stringToTableHtmlElement = function(string, typeEl) {
    let div = document.createElement('table');

    div.innerHTML = string;

    return div.querySelector(typeEl);
};

turbo.htmlElementsToString = function(elements = []) {
    let div = document.createElement('div'),
        array;

    if (elements instanceof NodeList) {
        array = turbo.copyNodeListInArray(elements);
    } else {
        array = [...elements];
    }

    array.forEach(element => {
        div.appendChild(element);
    });

    return div.innerHTML;
};

turbo.copyNodeListInArray = function(nodeList) {
    let nodeArray = [];

    [].forEach.call(nodeList, element => {
        nodeArray.push(element);
    });

    return nodeArray;
};

turbo.removeAllChildren = function(element) {
    while (element.firstChild) {
        element.removeChild(element.firstChild);
    }
};

turbo.removeAllChildrenByClass = function(element, classChildren) {
    let child = document.querySelector("." + classChildren);

    while (child !== null) {
        element.removeChild(child);
        child = document.querySelector("." + classChildren);
    }
};

turbo.saveJsonToLocalStorage = function(nameStorage, json) {
    if (turbo.supportLocalStorage) {
        let textJson = JSON.stringify(json);

        window['localStorage'].setItem(nameStorage, textJson);
    }
};

turbo.saveTextToLocalStorage = function(nameStorage, text) {
    if (turbo.supportLocalStorage) {
        window['localStorage'].setItem(nameStorage, text);
    }
};

turbo.loadJsonFromLocalStorage = function(nameStorage, isArray = false) {
    let json = isArray ? [] : {};

    if (turbo.supportLocalStorage) {
        let textJson = window['localStorage'].getItem(nameStorage);

        if (textJson !== null) {
            try {
                json = JSON.parse(textJson);
            } catch (e) {
                json = isArray ? [] : {};
            }
        }
    }

    return json;
};

turbo.loadTextFromLocalStorage = function(nameStorage) {
    let text = "";

    if (turbo.supportLocalStorage) {
        text = window['localStorage'].getItem(nameStorage);

        if (text === null) {
            text = ""
        }
    }

    return text;
};

turbo.removeItemFromLocalStorage = function(nameStorage) {
    if (turbo.supportLocalStorage) {
        window['localStorage'].removeItem(nameStorage);
    }
};

turbo.objectToQueryString = function(a) {
    let prefix, s, add, name, r20, output;

    s   = [];
    r20 = /%20/g;
    add = function(key, value) {
        // If value is a function, invoke it and return its value
        if (typeof value === 'function') {
            value = value();
        } else if (value === null) {
            value = "";
        }

        s[s.length] = encodeURIComponent(key) + "=" + encodeURIComponent(value);
    };

    if (a instanceof Array) {
        for (name in a) {
            if (a.hasOwnProperty(name)) {
                add(name, a[name]);
            }
        }
    } else {
        for (prefix in a) {
            if (a.hasOwnProperty(prefix)) {
                buildParams(prefix, a[prefix], add);
            }
        }
    }

    output = s.join("&").replace(r20, "+");

    return output;
};

turbo.ajax = function(options) {
    let xhr     = new XMLHttpRequest(),
        headers = options.headers || {},
        headerKey;

    if (options.onSuccess) {
        xhr.addEventListener('load', options.onSuccess);
    }

    if (options.onComplete) {
        xhr.addEventListener('loadend', options.onComplete);
    }

    if (options.onAbort) {
        xhr.addEventListener('abort', options.onAbort);
    }

    if (options.onError) {
        xhr.addEventListener('error', options.onError);
    }

    if (options.onStart) {
        xhr.addEventListener('loadstart', options.onStart);
    }

    xhr.open(options.method || 'POST', options.url, true);

    if (options.responseType) {
        xhr.responseType = options.responseType;
    }

    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

    for (headerKey in headers) {
        if (headers.hasOwnProperty(headerKey)) {
            xhr.setRequestHeader(headerKey, headers[headerKey]);
        }
    }

    xhr.send(options.data || null);

    return xhr;
};

function buildParams(prefix, obj, add) {
    let name, i, l, rbracket;

    rbracket = /\[\]$/;

    if (obj instanceof Array) {
        for (i = 0, l = obj.length; i < l; i++) {
            if (rbracket.test(prefix)) {
                add(prefix, obj[i]);
            } else {
                buildParams(prefix + "[" + (typeof obj[i] === "object" ? i : "") + "]", obj[i], add);
            }
        }
    } else if (typeof obj === "object") {
        // Serialize object item.
        for (name in obj) {
            if (obj.hasOwnProperty(name)) {
                buildParams(prefix + "[" + name + "]", obj[name], add);
            }
        }
    } else {
        // Serialize scalar item.
        add(prefix, obj);
    }
}

/**
 * Функция для удаления элемента DOM дерева
 * в первый параметр передается родительский элемент
 * во второй параметр передается элемент что нужно удалить
 * возращает true если было удалено или false если нет
 * Уточнение. Родитель parent может быть элемент любого уровня(даже например Body),
 * главное чтобы в нем был элемент для удаления.
 *
 * @param {object} parent - элемент.
 * @param {object} remChild - элемент.
 *
 * @returns {boolean}
 */
turbo.removeDomElement = function(parent, remChild) {
    let childs       = parent.querySelectorAll("*"),
        childsLenght = childs.length,
        child,
        i;

    for (i = 0; i < childsLenght; i++) {
        child = childs[i];

        if (child === remChild) {
            remChild.parentNode.removeChild(remChild);

            return true;
        }
    }

    return false;
};

//Транслитерация кириллицы
turbo.rusToLat = function(str) {
    let cyr2latChars = [
            /** @todo  Изменить ф-ию, дабы необходимость в таких структурах отпала. **/
            /* eslint-disable array-element-newline */
            ['а', 'a'], ['б', 'b'], ['в', 'v'], ['г', 'g'],
            ['д', 'd'], ['е', 'e'], ['ё', 'yo'], ['ж', 'zh'], ['з', 'z'],
            ['и', 'i'], ['й', 'y'], ['к', 'k'], ['л', 'l'],
            ['м', 'm'], ['н', 'n'], ['о', 'o'], ['п', 'p'], ['р', 'r'],
            ['с', 's'], ['т', 't'], ['у', 'u'], ['ф', 'f'],
            ['х', 'h'], ['ц', 'c'], ['ч', 'ch'], ['ш', 'sh'], ['щ', 'shch'],
            ['ъ', ''], ['ы', 'y'], ['ь', ''], ['э', 'e'], ['ю', 'yu'], ['я', 'ya'],

            ['А', 'A'], ['Б', 'B'], ['В', 'V'], ['Г', 'G'],
            ['Д', 'D'], ['Е', 'E'], ['Ё', 'YO'], ['Ж', 'ZH'], ['З', 'Z'],
            ['И', 'I'], ['Й', 'Y'], ['К', 'K'], ['Л', 'L'],
            ['М', 'M'], ['Н', 'N'], ['О', 'O'], ['П', 'P'], ['Р', 'R'],
            ['С', 'S'], ['Т', 'T'], ['У', 'U'], ['Ф', 'F'],
            ['Х', 'H'], ['Ц', 'C'], ['Ч', 'CH'], ['Ш', 'SH'], ['Щ', 'SHCH'],
            ['Ъ', ''], ['Ы', 'Y'],
            ['Ь', ''],
            ['Э', 'E'],
            ['Ю', 'YU'],
            ['Я', 'YA'],

            ['a', 'a'], ['b', 'b'], ['c', 'c'], ['d', 'd'], ['e', 'e'],
            ['f', 'f'], ['g', 'g'], ['h', 'h'], ['i', 'i'], ['j', 'j'],
            ['k', 'k'], ['l', 'l'], ['m', 'm'], ['n', 'n'], ['o', 'o'],
            ['p', 'p'], ['q', 'q'], ['r', 'r'], ['s', 's'], ['t', 't'],
            ['u', 'u'], ['v', 'v'], ['w', 'w'], ['x', 'x'], ['y', 'y'],
            ['z', 'z'],

            ['A', 'A'], ['B', 'B'], ['C', 'C'], ['D', 'D'], ['E', 'E'],
            ['F', 'F'], ['G', 'G'], ['H', 'H'], ['I', 'I'], ['J', 'J'], ['K', 'K'],
            ['L', 'L'], ['M', 'M'], ['N', 'N'], ['O', 'O'], ['P', 'P'],
            ['Q', 'Q'], ['R', 'R'], ['S', 'S'], ['T', 'T'], ['U', 'U'], ['V', 'V'],
            ['W', 'W'], ['X', 'X'], ['Y', 'Y'], ['Z', 'Z'],

            [' ', '_'], ['0', '0'], ['1', '1'], ['2', '2'], ['3', '3'],
            ['4', '4'], ['5', '5'], ['6', '6'], ['7', '7'], ['8', '8'], ['9', '9'],
            ['-', '-']
        ],
        newStr       = "",
        ch, newCh, i, j;

    for (i = 0; i < str.length; i++) {
        ch    = str.charAt(i);
        newCh = '';

        for (j = 0; j < cyr2latChars.length; j++) {
            if (ch === cyr2latChars[j][0]) {
                newCh = cyr2latChars[j][1];
            }
        }

        newStr += newCh;
    }

    return newStr.replace(/_{2,}/gim, '_').replace(/\n/gim, '');
};

/**
 * Функция возращает имя браузера в котором находится клиент
 * Если клиент зашел в браузер что не указан в массиве arrayNameBrowsers
 * то ф-я вернет undefined. <devel-artur>
 */
turbo.isThisWhatBrowser = (function() {
    let ua                = navigator.userAgent,
        arrayNameBrowsers = ["MSIE", "Firefox", "Opera", "Edge", "Chrome", "Safari", "rv:11.0"],
        arrayNameBrowser, nameBrowser, regexp;

    return function() {
        arrayNameBrowser = arrayNameBrowsers.filter(function(nameBrowser) {
            regexp = new RegExp(nameBrowser);

            return ua.search(regexp) > -1
        });

        nameBrowser = arrayNameBrowser[0];

        return nameBrowser;
    }
})();

/* Функция для очистки содержимого от не нужных тегов */
turbo.stripTags = function(input, allowed) {
    // making sure the allowed arg is a string containing only tags in lowercase (<a><b><c>)
    allowed = (((allowed || "") + "").toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join('');

    let tags               = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi,
        commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;

    return input.replace(commentsAndPhpTags, '').replace(tags, function($0, $1) {
        return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '';
    });
};

/**
 * Перевод даты в нужный формат
 *
 * @param {string} dateString - дата в виде строки
 * @param {string} dateFormat - формат в виде строки
 *
 * @returns {string} - отформатированная строка.
 */
turbo.getFormatDate = function(dateString, dateFormat) {
    let dateArray = dateString.split(/[-:., ]/),
        format    = dateFormat || "dd.MM.yyyy",
        months    = [
            "января",
            "февраля",
            "марта",
            "апреля",
            "мая",
            "июня",
            "июля",
            "августа",
            "сентября",
            "октября",
            "ноября",
            "декабря"
        ],
        date      = new Date(
            dateArray[0] || null,
            dateArray[1] ? (+dateArray[1] - 1) : null,
            dateArray[2] || null,
            dateArray[3] || null,
            dateArray[4] || null,
            dateArray[5] || null,
            dateArray[6] || null
        );

    if (date) {
        let tmp = format;

        tmp = tmp.replace(/dd/g, date.getDate() < 10 ? "0" + date.getDate() : date.getDate());
        tmp = tmp.replace(/MMMMM/g, months[date.getMonth()]);
        tmp = tmp.replace(/MM/g, (date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1));
        tmp = tmp.replace(/yyyy/g, date.getFullYear());
        tmp = tmp.replace(/yy/g, date.getFullYear().toString().substr(2));
        tmp = tmp.replace(/hh/g, date.getHours() < 10 ? "0" + date.getHours() : date.getHours());
        tmp = tmp.replace(/mm/g, date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes());
        tmp = tmp.replace(/ss/g, date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds());

        return tmp;
    }
};

/**
 * Рекурсивное дополнение первого JSON объекта вторым
 *
 * @param {object} json1 - первый объект
 * @param {object} json2 - второй объект
 *
 * @returns {object}
 */
turbo.jsonMergeRecursive = function(json1, json2) {
    let out = {};

    for (let k1 in json1) {
        if (json1.hasOwnProperty(k1)) {
            out[k1] = json1[k1];
        }
    }

    for (let k2 in json2) {
        if (json2.hasOwnProperty(k2)) {
            if (!out.hasOwnProperty(k2)) {
                out[k2] = json2[k2];
            } else if (
                (typeof out[k2] === 'object')
                && out[k2] !== null
                && (out[k2].constructor === Object)
                && (typeof json2[k2] === 'object')
                && (json2[k2].constructor === Object)
            ) {
                out[k2] = turbo.jsonMergeRecursive(out[k2], json2[k2]);
            }
        }
    }

    return out;
};

turbo.cloneObject = function(o) {
    let output, v, key;

    if (!o) {
        return o;
    }

    output = Array.isArray(o) ? [] : {};

    for (key in o) {
        if (o.hasOwnProperty(key)) {
            v           = o[key];
            output[key] = (typeof v === "object") ? turbo.cloneObject(v) : v;
        }
    }

    return output;
};

turbo.simulateEvent = function(element, eventName) {
    let eventMatchers  = {
            HTMLEvents : /^(?:load|unload|abort|error|select|change|submit|reset|focus|blur|resize|scroll)$/,
            MouseEvents: /^(?:click|dblclick|mouse(?:down|up|over|move|out))$/
        },
        defaultOptions = {
            clientX   : 0,
            clientY   : 0,
            screenX   : 0,
            screenY   : 0,
            button    : 0,
            ctrlKey   : false,
            altKey    : false,
            shiftKey  : false,
            metaKey   : false,
            bubbles   : true,
            cancelable: true,
            isTrusted : true
        },
        options        = Object.assign(defaultOptions, arguments[2] || {}),
        eventType      = null,
        oEvent, name, evt;

    for (name in eventMatchers) {
        if (eventMatchers[name].test(eventName)) {
            eventType = name;
            break;
        }
    }

    if (!eventType) {
        throw new SyntaxError('Only HTMLEvents and MouseEvents interfaces are supported');
    }

    if (document.createEvent) {
        oEvent = document.createEvent(eventType);

        if (eventType === "HTMLEvents") {
            oEvent.initEvent(eventName, options.bubbles, options.cancelable);
        } else {
            oEvent.initMouseEvent(
                eventName, options.bubbles, options.cancelable, document.defaultView,
                options.button, options.screenX, options.screenY, options.clientX, options.clientY,
                options.ctrlKey, options.altKey, options.shiftKey, options.metaKey, options.button, element
            );
        }

        element.dispatchEvent(oEvent);
    } else {
        options.clientX = options.pointerX;
        options.clientY = options.pointerY;
        evt             = document.createEventObject();
        oEvent          = Object.assign(evt, options);
        element.fireEvent('on' + eventName, oEvent);
    }

    return element;
};

turbo.getScrollBarSize = function() {
    let div = document.createElement('div'),
        size;

    div.style.overflow = 'scroll';

    document.body.appendChild(div);

    size = div.offsetWidth - div.clientWidth;

    document.body.removeChild(div);

    return size;
};

turbo.closestWithLimit = function(node, selector, limitNode) {
    let matches       = limitNode.querySelectorAll(selector),
        lengthMatches = matches.length,
        currentNode   = node;

    do {
        for (let index = 0; index < lengthMatches; index++) {
            if (currentNode === matches[index]) {
                return currentNode;
            }
        }

        currentNode = currentNode.parentNode;
    } while (currentNode && currentNode !== limitNode);

    return null;
};

turbo.isElementInView = function(element, scrollContainer) {
    const scroll    = scrollContainer.scrollTop || scrollContainer.scrollY || scrollContainer.pageYOffset;
    const scrollTop = scrollContainer.getBoundingClientRect ? scrollContainer.getBoundingClientRect().top : 0;
    const boundsTop = element.getBoundingClientRect().top + scroll - scrollTop;

    const viewport = {
        top   : scroll,
        bottom: scroll + (scrollContainer.clientHeight || scrollContainer.innerHeight),
    };

    const bounds = {
        top   : boundsTop,
        bottom: boundsTop + element.clientHeight,
    };

    return (bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom)
           || (bounds.top <= viewport.bottom && bounds.top >= viewport.top);
};

turbo.isEmpty = function(value) {
    let isEmpty = value === undefined || value === null;

    isEmpty = isEmpty || (typeof value === "string" && value.trim() === "");
    isEmpty = isEmpty || (Array.isArray(value) && value.length === 0);

    return isEmpty;
};

turbo.objectToArray = function(data = {}) {
    const keys  = Object.keys(data);
    const array = [];

    keys.forEach(key => {
        array.push({
            id  : key,
            name: data[key]
        });
    });

    return array;
};

export default turbo;
