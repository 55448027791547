import m from 'mithril';
import Trigger from './Trigger';
import TargetTrigger from './TargetTrigger';
import ToolsTrigger from './ToolsTrigger';
import CreateDocumentTrigger from './CreateDocumentTrigger';

class Menu {
    view({ children }) {
        return (
            <div>
                { children }
            </div>
        )
    }
}

Menu.Trigger               = Trigger;
Menu.TargetTrigger         = TargetTrigger;
Menu.ToolsTrigger          = ToolsTrigger;
Menu.CreateDocumentTrigger = CreateDocumentTrigger;

export default Menu;
