import "mithril";

class Body {
    view({ attrs, children }) {
        return (
            <div className={`page-layer__body page-layer__block ${attrs.className || ''}`}>
                {children}
            </div>
        );
    }
}

export default Body;
