import "mithril";
import turbo from "_/lib/turbo";
import Component from "_/lib/Component";
import {translate} from 'localizations';
import pasteLogicalExpressionDialog from "components/dialogs/pasteLogicalExpressionDialog";

const AND = 0;
const OR  = 1;

const ROOT = "contextMenuExpression";

class ContextMenu extends Component {
    oninit() {
        const expression = this.attrs.expression;
        const step       = this.attrs.step;

        this.model = this.attrs.model || [
            {
                isRender: expression || (step && step.id) || (step && step.questions.length > 0),
                title   : translate(`${ROOT}.items.copy`),
                handler : () => this.copy()
            },
            {
                isRender: expression,
                title   : translate(`${ROOT}.items.pasteWithReplace`),
                handler : () => this.read()
            },
            {
                isRender: expression,
                title   : translate(`${ROOT}.items.pasteWithRuleAnd`),
                handler : () => this.read(AND)
            },
            {
                isRender: expression,
                title   : translate(`${ROOT}.items.pasteWithRuleOr`),
                handler : () => this.read(OR)
            },
            {
                isRender: expression !== undefined
                    || !(step && step.type)
                    || (step && !step.type && step.questions.length > 0),
                title   : translate(`${ROOT}.items.paste`),
                handler : () => this.read()
            }
        ];
    }

    oncreate({ dom, attrs}) {
        if (attrs.position) {
            if (attrs.position.rule) {
                dom.style[attrs.position.rule.x]  = attrs.position.rule.xCount;
                dom.style[attrs.position.rule.y]  = attrs.position.rule.yCount;
            } else {
                dom.style[attrs.position.side]  = attrs.position.count;
            }
        } else {
            dom.style.right  = 0;
        }

        dom.style["z-index"] = 99999;
    }

    view() {
        return (
            <ul className="turbo-menu text-left block">
                {
                    this.model.map(item => (
                        item.isRender
                            ? (
                                <li
                                    className="turbo-menu__li"
                                    onclick={item.handler}
                                >
                                    <div className="turbo-menu__item">
                                        <span>{item.title}</span>
                                    </div>
                                </li>)
                            : ""
                    ))
                }
            </ul>
        );
    }

    copy() {
        const { expression, onHideMenu, step } = this.attrs;

        let toCopy = expression || step;

        navigator.clipboard.writeText(JSON.stringify(toCopy))
            .then(() => {
                if (typeof onHideMenu === "function") {
                    onHideMenu();
                }
            })
            .catch(() => {
                this.showError("copy");
            });
    }

    paste(res) {
        const { onOpenExpression, onHideMenu } = this.attrs;

        try {
            let data = JSON.parse(res);

            if (typeof onOpenExpression === "function") {
                onOpenExpression(data);
            }
        } catch {
            this.showError("paste");
        }

        if (typeof onHideMenu === "function") {
            onHideMenu();
        }
    }

    read(rule = 3) {
        let callBack =  rule === 3 ? (res) => this.paste(res) : (res) => this.pasteWithRule(rule, res),
            dialog   = new pasteLogicalExpressionDialog(callBack, this.attrs.type);

        dialog.open();
    }

    pasteWithRule(rule, res) {
        const { onOpenExpression, onHideMenu } = this.attrs;

        try {
            let data = JSON.parse(res);

            if (typeof onOpenExpression === "function") {
                onOpenExpression(this.sort(rule, data));
            }
        } catch {
            this.showError("paste");
        }

        if (typeof onHideMenu === "function") {
            onHideMenu();
        }
    }

    sort(type, additionalExpression) {
        const { expression } = this.attrs;

        let maxIndex                   = Object.keys(expression.conditions).length - 1,
            maxKeyExpression           = `condition_${maxIndex}`,
            additionalExpressionLength = Object.keys(additionalExpression.conditions).length;

        expression.conditions[maxKeyExpression].type = type;
        expression.conditions[maxKeyExpression].with = `condition_${maxIndex + 1}`;

        for (let i = 0; i < additionalExpressionLength; i++) {
            let expressionLength;

            additionalExpression.conditions["condition_" + i].with
                = additionalExpression.conditions["condition_" + i].with
                    ? "condition_" + (Object.keys(expression.conditions).length + 1)
                    : null;

            expressionLength                                       = Object.keys(expression.conditions).length;
            expression.conditions["condition_" + expressionLength] = additionalExpression.conditions["condition_" + i];
        }

        return expression
    }

    showError(type) {
        let modalConfig = {
            iconClasses: "warning color-red fs24 mr5",
            title      : type === "copy" ? translate(`${ROOT}.error.copy`) : translate(`${ROOT}.error.paste`)
        };

        turbo.plugins.modal.alert(modalConfig);
    }
}

export default ContextMenu;
