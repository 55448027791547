import m from "mithril";
import DropDownList from "components/plugins/DropDownList";

const COUNT_SHOW_ITEM = 3;

const template = (item) => {
    let fieldsName, fieldsElements;

    fieldsName = item.data ? Object.keys(item.data) : [];

    fieldsElements = fieldsName.map(field => {
        if (!turbo.isEmpty(item.data[field])) {
            return (
                <div className="drop-down-additional-info">
                    <span className="title">{field}</span>
                    <span>{item.data[field]}</span>
                </div>
            );
        }

        return "";
    });

    return [
        <span>{item.name}</span>,
        ...fieldsElements
    ];
};

const templateShort = (item) => {
    let name;

    if (typeof item.name === "string") {
        name = item.name.replace(/; /ig, "\n");
    } else if (Array.isArray(item.name)) {
        name = item.name.map(part => {
            if (typeof part === "string") {
                return part.replace(/; /ig, "\n");
            }

            return part;
        })
    }

    return <span style="white-space: pre-wrap;">{name}</span>;
};

class ChooseSimple {
    view({ attrs }) {
        return (
            <DropDownList
                data-popup-key={attrs["data-popup-key"]}
                data={attrs.data}
                fieldView={"name"}
                fieldsSearch={["name", ...this.getFieldsSearch(attrs.data)]}
                fieldKey={"id"}
                template={attrs.isShortView ? templateShort : template}
                countShowItem={attrs.countShowItems || COUNT_SHOW_ITEM}
                modal={attrs.modal}
                onChange={attrs.onChange}
                onRemove={attrs.onRemove}
                isCanEmpty={attrs.isCanEmpty}
                value={attrs.value}
                trigger={attrs.trigger}
                isEdit={attrs.isEdit}
                classNames={attrs.classNames || ''}
                isButton={attrs.isButton || false}
                buttonClassNames={attrs.buttonClassNames || ''}
                iconColorClass={attrs.iconColorClass}
                disabled={attrs.disabled || false}
                extraButtons={attrs.extraButtons || []}
            />
        );
    }

    getFieldsSearch(data = []) {
        let fieldsSearch = new Set(),
            result       = [];

        data.forEach(item => {
            let data       = item.data || {},
                fieldsName = Object.keys(data);

            fieldsName.forEach(field => {
                fieldsSearch.add(field);
            });
        });

        fieldsSearch.forEach(fs => {
            result.push(fs);
        });

        return result;
    }
}

export default ChooseSimple;
