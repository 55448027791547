import turbo from 'lib/turbo';

const MIN_LENGTH_SEARCH_WORDS = 4;
const MAX_REMOVE_CHAR         = 2;
const MIN_WORD_POINTS         = 10;

export default class SearchEngine {
    /**
     * фильтрация данных по настройкам
     * settings.data - данные для фильтрации
     * settings.filterFragment - фрагмент который ищется в данных
     * settings.filterConditions - условия фильтрации
     * settings.filterConditions.fields - массив полей по которым идет фильтрация
     * settings.filterConditions.linkField - поле по которому происходит связь с релевантной информацией
     * settings.filterConditions.subField - наименование дочернего поля содержащего массив для фильтрации
     * settings.filterConditions.subFields - массив полей дочернего роля по которым идет фильтрация
     * settings.filterConditions.subLinkField - поле по которому происходит связь с релевантной информацией
     *
     * @param {object} settings
     */
    constructor(settings) {
        this._settings         = settings;
        this._regExpCollection = {
            wholeFragment: "",
            words        : []
        };
        this.strictFields = [];

        this._generateRegExp();
    }

    _generateRegExp() {
        let screeningFragment = SearchEngine._screening(this._settings.filterFragment),
            words             = screeningFragment.match(/[^\s]+/gi) || [],
            countWords        = words.length;

        words.sort(SearchEngine._sortWordsByLength);

        this._regExpCollection.wholeFragment = {
            fragment: screeningFragment,
            points  : SearchEngine._getMaxPoints(countWords),
            regExp  : new RegExp(screeningFragment.replace(/[её]/ig, "[её]"), "i")
        };

        words.forEach((word, index) => {
            let regExpWord = {
                    fragment: word,
                    regExps : []
                },
                searchWord = word.replace(/[её]/ig, "[её]"),
                pointIndex = countWords - index;

            regExpWord.regExps.push({
                points: pointIndex * MIN_WORD_POINTS,
                regExp: new RegExp("(^|[\\s])" + searchWord, "i")
            });

            regExpWord.regExps.push({
                points: pointIndex * MIN_WORD_POINTS - 1,
                regExp: new RegExp(searchWord, "i")
            });

            regExpWord.regExps = regExpWord.regExps.concat(this._generateRegExpByPartWords(word, pointIndex));

            this._regExpCollection.words.push(regExpWord);
        });
    }

    static _sortWordsByLength(item1, item2) {
        if (item1.length < item2.length) {
            return 1;
        } else {
            return item1.length === item2.length ? 0 : -1;
        }
    }

    _generateRegExpByPartWords(word, num) {
        let regExpByParts = [],
            parts         = word.match(/[а-яёa-z]+/gi) || [],
            numbers       = word.match(/[0-9]+/gi) || [];

        parts.forEach(part => {
            if (part !== word) {
                regExpByParts.push({
                    points: num * MIN_WORD_POINTS - 2,
                    regExp: new RegExp(part.replace(/[её]/ig, "[её]"), "i")
                });
            }

            for (let index = 1; index <= MAX_REMOVE_CHAR; index++) {
                let partSearch = part.substr(0, part.length - index);

                if (partSearch.length >= MIN_LENGTH_SEARCH_WORDS) {
                    regExpByParts.push({
                        points: num * MIN_WORD_POINTS - 2 - index,
                        regExp: new RegExp(partSearch.replace(/[её]/ig, "[её]"), "i")
                    });
                } else {
                    break;
                }
            }
        });

        numbers.forEach(number => {
            if (number !== word) {
                regExpByParts.push({
                    points: num * MIN_WORD_POINTS - MIN_WORD_POINTS + 1,
                    regExp: new RegExp(number.replace(/[её]/ig, "[её]"), "i")
                });
            }
        });

        return regExpByParts;
    }

    static _screening(text) {
        return (text + "").replace(/[.?*+^$[\]\\(){}|-]/g, "\\$&")
    }

    static _getMaxPoints(countWords) {
        let maxPoints = 0;

        for (let index = 0; index < countWords; index++) {
            maxPoints = maxPoints + ((index + 1) * MIN_WORD_POINTS);
        }

        return maxPoints + 1;
    }

    search() {
        let data             = this._settings.data || [],
            filterConditions = this._settings.filterConditions,
            fields           = filterConditions.fields || [],
            subField         = filterConditions.subField,
            subFields        = filterConditions.subFields,
            strictFilters    = this._settings.strictFilters,
            searchData       = this._getSearchData(data, fields, subField, subFields),
            keys;

        if (strictFilters) {
            keys = Object.keys(strictFilters);

            for (let i = 0; i < keys.length; i++) {
                if (strictFilters[keys[i]].id !== '-1') {
                    this.strictFields.push(strictFilters[keys[i]])
                }
            }
        }

        if (this._settings.filterFragment === '' && this.strictFields.length === 0) {
            return searchData
        } else if (
            this._settings.filterFragment === ''
            && this.strictFields.length !== 0
        ) {
            searchData = this.filterSeachData(searchData)

            return this._getRecordsAndRelevanceDataSeparate(searchData);
        }

        if (this._settings.filterFragment !== '') {
            if (this.strictFields.length !== 0) {
                searchData = this.filterSeachData(searchData);
            }

            searchData = SearchEngine._getSearchRecordInOrder(searchData);
        }

        return this._getRecordsAndRelevanceDataSeparate(searchData);
    }

    filterSeachData(searchData) {
        if (this.strictFields.length === 0) {
            return searchData
        }

        this.strictFields.forEach(field => {
            let data = [];

            if (Array.isArray(field)) {
                field.forEach(elem => {
                    searchData.forEach(item => {
                        if (Array.isArray(field.id)) {
                            field.id.forEach(id => {
                                if ((item.record[field.fieldName] + '') === (id + '')) {
                                    data.push(item);
                                }
                            })
                        } else if (Array.isArray(item.record[field.fieldName])) {
                            if (this.equalTags(field, item.record[field.fieldName])) {
                                data.push(item)
                            }
                        } else if ((item.record[field.fieldName] + '') === (elem.id + '')) {
                            data.push(item);
                        } else if (field.fieldName === "created_at") {
                            data = this.compareDates(item, field, data)
                        }
                    })
                })
            } else {
                searchData.forEach(item => {
                    if (Array.isArray(field.id)) {
                        field.id.forEach(id => {
                            if ((item.record[field.fieldName] + '') === (id + '')) {
                                data.push(item);
                            }
                        })
                    } else if ((item.record[field.fieldName] + '') === (field.id + '')) {
                        data.push(item);
                    } else if (field.fieldName === "created_at") {
                        data = this.compareDates(item, field, data)
                    }
                })
            }

            searchData = data;
        })

        return searchData
    }

    compareDates(item, field, data) {
        let itemDate = new Date(new Date(item.record[field.fieldName]).toDateString()).getTime(),
            date     = field.date || '',
            dateFrom = field.dateFrom || '',
            dateTo   = field.dateTo || '';

        if (date.length < 1 && dateFrom.length < 1 && dateTo.length < 1) {
            data.push(item);
        }

        if (field.isPeriod) {
            data = this.compareDatePeriod(itemDate, dateFrom, dateTo, data, item)
        } else if (date.length > 0
            && itemDate === new Date(new Date(date).toDateString()).getTime()) {
            data.push(item);
        }

        return data
    }

    compareDatePeriod(itemDate, dateFrom, dateTo, data, item) {
        if (dateFrom.length > 1 && dateTo.length > 1
            // eslint-disable-next-line
            && new Date(new Date(dateTo).toDateString()).getTime()< new Date(new Date(dateFrom).toDateString()).getTime()) {
            [dateTo, dateFrom] = [dateFrom, dateTo]
        }

        if (dateFrom.length > 1 && dateTo.length > 1
            && (itemDate >= new Date(new Date(dateFrom).toDateString()).getTime()
            && itemDate <= new Date(new Date(dateTo).toDateString()).getTime())) {
            data.push(item);
        }

        if (dateFrom.length < 1 && dateTo.length > 1
            && itemDate <= new Date(new Date(dateTo).toDateString()).getTime()) {
            data.push(item);
        }

        if (dateFrom.length > 1 && dateTo.length < 1
            && itemDate >= new Date(new Date(dateFrom).toDateString()).getTime()) {
            data.push(item);
        }
        return data
    }

    equalTags(tags, tagsOfRecord) {
        let tagsOfRecordIds = tagsOfRecord.map(tag => +tag.id);

        return tags.every(tag => tagsOfRecordIds.includes(+tag.id))
    }

    _getSearchData(data, fields, subField, subFields) {
        let searchData = [],
            relevanceData;

        data.forEach(record => {
            relevanceData = this._getRelevanceDataByFields(record, fields);

            if (subField && record.hasOwnProperty(subField)) {
                let subData = record[subField],
                    subRelevanceData;

                subData.forEach(subRecord => {
                    subRelevanceData = this._getRelevanceDataByFields(subRecord, subFields);

                    relevanceData = this._unionRelevanceData(relevanceData, subRelevanceData);
                });
            }

            if (relevanceData && relevanceData.points > 0) {
                relevanceData.record = typeof record === "object" ? turbo.cloneObject(record) : record;

                searchData.push(relevanceData);
            }
        });

        return searchData;
    }

    _unionRelevanceData(relevanceData1 = {}, relevanceData2 = {}) {
        return {
            fragments: this._unionArrayWithoutDuplicate(relevanceData1.fragments, relevanceData2.fragments),
            regExps  : this._unionArrayWithoutDuplicate(relevanceData1.regExps, relevanceData2.regExps),
            points   : (relevanceData1.points || 0) + (relevanceData2.points || 0)
        };
    }

    _unionArrayWithoutDuplicate(arr1 = [], arr2 = []) {
        let arr    = arr1.concat(arr2),
            result = [];

        for (let item of arr) {
            if (!result.includes(item)) {
                result.push(item);
            }
        }

        return result;
    }

    _getRelevanceDataByFields(record, fields) {
        let relevanceData = {};

        if (fields.length > 0) {
            for (let field of fields) {
                if (record.hasOwnProperty(field)) {
                    let content = record[field],
                        localRelevanceData;

                    localRelevanceData = this._getRelevanceDataByField(content);

                    if (localRelevanceData.points > 0) {
                        relevanceData = this._unionRelevanceData(relevanceData, localRelevanceData);
                    }
                }
            }
        } else if (typeof record === "string") {
            let localRelevanceData;

            localRelevanceData = this._getRelevanceDataByField(record);

            if (localRelevanceData.points > 0) {
                relevanceData = this._unionRelevanceData(relevanceData, localRelevanceData);
            }
        }

        return relevanceData;
    }

    _getRelevanceDataByField(content) {
        let relevanceData = {
                fragments: [],
                regExps  : [],
                points   : 0
            },
            wholeFragment = this._regExpCollection.wholeFragment,
            words;

        if (wholeFragment.regExp.test(content)) {
            return {
                fragments: [wholeFragment.fragment],
                regExps  : [wholeFragment.regExp],
                points   : wholeFragment.points
            };
        }

        words = this._regExpCollection.words;

        words.forEach(word => {
            for (let regExp of word.regExps) {
                if (regExp.regExp.test(content)) {
                    relevanceData.fragments.push(word.fragment);
                    relevanceData.regExps.push(regExp.regExp);
                    relevanceData.points = relevanceData.points + regExp.points;

                    break;
                }
            }
        });

        return relevanceData;
    }

    static _getSearchRecordInOrder(searchData) {
        let sortFn = (item1, item2) => {
            if (item1.points < item2.points) {
                return 1;
            } else {
                return item1.points === item2.points ? 0 : -1;
            }
        };

        return searchData.sort(sortFn);
    }

    _getRecordsAndRelevanceDataSeparate(searchData) {
        let records       = [],
            relevanceData = { length: 0 };

        searchData.forEach((item, index) => {
            let linkField      = this._settings.filterConditions.linkField,
                valueLinkField = linkField && item.record[linkField] ? item.record[linkField] : null;

            records.push(item.record);

            delete item.record;

            if (valueLinkField) {
                relevanceData[valueLinkField] = item;
            } else {
                relevanceData[index] = item;
            }

            relevanceData.length++;
        });

        return { records, relevanceData };
    }

    getArraySourceOfRegExps() {
        let sourceOfRegExps = [],
            wholeFragment   = this._regExpCollection.wholeFragment,
            words           = this._regExpCollection.words,
            mainSource      = wholeFragment.regExp.source;

        mainSource = mainSource.replace(/\[её]/g, "(е|ё)");
        mainSource = mainSource.replace(/\[\\s]/g, "[[.space.]]");

        sourceOfRegExps.push(mainSource);

        words.forEach(word => {
            word.regExps.forEach(regExp => {
                let source = regExp.regExp.source;

                source = source.replace(/\[её]/g, "(е|ё)");
                source = source.replace(/\[\\s]/g, "[[.space.]]");

                sourceOfRegExps.push(source);
            });
        });

        return sourceOfRegExps;
    }
}
