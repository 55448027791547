import "mithril";
import {connect} from "_/lib/midux";
import Component from "_/lib/Component";
import Header from "./components/Header";
import Body from "./components/Body";
import Footer from "./components/Footer";
import {initQuestionnaireData} from "_/actions/questionnaire";
import {requestOwnRegistry} from "_/actions/registry";

const mapStateToProps = state => ({
    info: state.currentInfo
});

const mapActionsToProps = {
    initQuestionnaireData,
    requestOwnRegistry
};

class Questionnaire extends Component {
    oninit() {
        const { documentType, documentId } = this.attrs.info;

        this.attrs.actions.initQuestionnaireData({ documentType, documentId });
        this.attrs.actions.requestOwnRegistry({ documentType, documentId });
    }

    view() {
        return (
            <div className="turbo-questionnaire">
                <Header/>
                <Body/>
                <Footer/>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapActionsToProps)(Questionnaire);
