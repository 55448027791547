import "mithril";
import classNames from "classnames";
import {translate} from "localizations";
import Component from "lib/Component";
import Button from "components/plugins/Button";
import AddLink from "./components/AddLink";
import Lists from "./components/Lists";
import Schemes from "./components/Schemes";
import AddEditScheme from "./components/AddEditScheme";
import {
    getListLists,
    getListBindings,
    saveList,
    deleteList,
    getTypesOfScheme,
    getTypesOfView,
    getListSeparators,
    saveScheme,
    getListFields,
    deleteScheme
} from "./promises";

const TAB_LINKS   = "links";
const TAB_SCHEMES = "schemes";

//Корень для локализации
const ROOT = "listObjectOfRegistryByLink";

class ListObjectOfRegistryByLink extends Component {
    oninit() {
        const templateId = this.attrs.templateId;

        this.type = this.attrs.type || '';

        this.currentTabs  = TAB_LINKS;
        this.isEditMode   = false;
        this.selectedList = undefined;

        this.loadData();

        if (this.type === "fromRegistry") {
            getListFields(templateId)
                .then(result => {
                    this.linkList = result.data
                });
        } else {
            getListBindings(templateId)
                .then(result => {
                    this.bindings = result.bindings
                });
        }


        getTypesOfScheme()
            .then(result => {
                this.typesOfScheme = result.data
            });

        getTypesOfView()
            .then(result => {
                this.typesOfView = result.data;
            });

        getListSeparators()
            .then(result => {
                this.listSeparators = result.data;
            });
    }

    view() {
        let data               = this.data || [],
            validate           = name => this.validateName(name),
            changeEditMode     = isEditMode => this.onChangeEditMode(isEditMode),
            selectList         = listId => this.onSelectList(listId),
            onDelete           = listId => this.deleteList(listId),
            onSave             = (data, onSuccess) => this.saveList(data, onSuccess),
            selectedList       = this.selectedList,
            onDeleteScheme     = schemeId => this.deleteScheme(schemeId),
            onOpenWindowScheme = data => this.openWindowNewEditScheme(data),
            tabListsClasses, tabSchemesClasses, onOpenWindowNew, titleAddButton;

        tabListsClasses   = classNames("turbo-tabs__tumbler", { current: this.currentTabs === TAB_LINKS });
        tabSchemesClasses = classNames("turbo-tabs__tumbler", { current: this.currentTabs === TAB_SCHEMES });

        onOpenWindowNew = this.currentTabs === TAB_LINKS
            ? this.openWindowNewLink.bind(this)
            : this.openWindowNewEditScheme.bind(this);

        titleAddButton = this.currentTabs === TAB_LINKS
            ? translate(`${ROOT}.addListButton`)
            : translate(`${ROOT}.addSchemeButton`);

        return (
            <div>
                <div className="modal__header bg-blue color-white">
                    <div className="modal-close font-icon cancel"/>
                    <span>{translate(`${ROOT}.title`)}</span>
                </div>
                <div className="modal__body">
                    <div className="turbo-tabs__container-flex pb10 pl5 pr5">
                        <div className="turbo-tabs__group-flex">
                            <div className={tabListsClasses} onclick={() => this.onChangeTab(TAB_LINKS)}>
                                {translate(`${ROOT}.tabs.lists`)}
                            </div>
                            <Choose>
                                <When condition={!!selectedList}>
                                    <div className={tabSchemesClasses} onclick={() => this.onChangeTab(TAB_SCHEMES)}>
                                        {translate(`${ROOT}.tabs.schemes`)}
                                    </div>
                                </When>
                            </Choose>
                        </div>

                        <Button
                            className="turbo-button__size_md color-blue fs20"
                            onclick={() => turbo.lib.checkApprovedDocumentTemplate() ? null : onOpenWindowNew()}
                            title={titleAddButton}
                            disabled={this.isEditMode || turbo.lib.checkApprovedDocumentTemplate()}
                        >
                            <Button.Icon className="circle-plus block"/>
                        </Button>
                    </div>

                    <Choose>
                        <When condition={this.currentTabs === TAB_LINKS}>
                            <Lists
                                data={data}
                                isEditMode={this.isEditMode}
                                selectedList={selectedList}
                                onValidate={validate}
                                onChangeEditMode={changeEditMode}
                                onSelectList={selectList}
                                onDelete={onDelete}
                                onSave={onSave}
                            />
                        </When>
                        <Otherwise>
                            <Schemes
                                data={selectedList.schemes}
                                onDelete={onDeleteScheme}
                                onOpenWindowScheme={onOpenWindowScheme}
                            />
                        </Otherwise>
                    </Choose>
                </div>
                <div className="modal__footer bg-grey text-right">
                    <Button className="turbo-button__size_md dashed-link color-blue p0 mr10 modal-close">
                        {translate("buttons.close")}
                    </Button>
                </div>
            </div>
        );
    }

    loadData() {
        const templateId = this.attrs.templateId;

        getListLists(templateId, this.type).then(result => {
            this.data = result.data || [];

            if (this.selectedList) {
                this.selectedList = this.data.find(item => item.id === this.selectedList.id);
            }
        });
    }

    openWindowNewLink() {
        let modalClass = "modal-create-new-list",
            bindings   = this.bindings || [],
            type       = this.type,
            linkList   = this.linkList,
            save       = data => this.saveList(data),
            validate   = name => this.validateName(name);

        turbo.plugins.modal.open({
            width              : 300,
            vnode              : {
                view() {
                    return (
                        <AddLink
                            bindings={bindings}
                            linkList={linkList}
                            type={type}
                            onSave={save}
                            onValidate={validate}
                            modalClass={modalClass}
                        />
                    )
                }
            },
            class              : modalClass,
            closeOnOverlayClick: false
        });
    }

    saveList(data, onSuccess) {
        const templateId = this.attrs.templateId;

        saveList(templateId, data, this.type)
            .then(result => {
                if (typeof onSuccess === "function") {
                    onSuccess();
                }

                if (!data.id) {
                    this.selectedList = { id: result.id }
                    this.currentTabs  = TAB_SCHEMES;

                    this.openWindowNewEditScheme();
                }

                this.loadData();
            });
    }

    deleteList(listId) {
        deleteList(listId, this.type)
            .then(() => {
                this.loadData();
            });
    }

    validateName(name) {
        let isDuplicate = !!this.data.find(item => item.name === name);

        if (isDuplicate) {
            turbo.plugins.modal.alert({
                width      : 400,
                title      : translate(`${ROOT}.warning.duplicateNameTitle`),
                text       : translate(`${ROOT}.warning.duplicateNameText`),
                iconClasses: 'warning color-red fs24 mr5'
            });

            return false;
        }

        return true;
    }

    onChangeEditMode(isEditMode) {
        this.isEditMode = isEditMode;
    }

    onSelectList(list) {
        this.selectedList = list;
        this.currentTabs  = TAB_SCHEMES;
    }

    onChangeTab(tab) {
        this.currentTabs = tab;
    }

    openWindowNewEditScheme(data = {}) {
        let modalClass    = "modal-create-new-edit-scheme",
            typesOfScheme = this.typesOfScheme,
            typesOfView   = this.typesOfView,
            separators    = this.listSeparators,
            type          = this.type,
            validate      = (name, type, excludeId) => this.validateSchemeName(name, type, excludeId),
            save          = data => this.saveScheme(data),
            listId        = this.selectedList.id;

        turbo.plugins.modal.open({
            width              : 300,
            vnode              : {
                view() {
                    return (
                        <AddEditScheme
                            data={data}
                            listId={listId}
                            modalClass={modalClass}
                            typesOfScheme={typesOfScheme}
                            typesOfView={typesOfView}
                            separators={separators}
                            onSave={save}
                            onValidate={validate}
                            type={type}
                        />
                    )
                }
            },
            class              : modalClass,
            closeOnOverlayClick: false
        });
    }

    validateSchemeName(name, type, excludeId) {
        let schemes     = this.selectedList.schemes || [],
            isDuplicate = !!schemes
                .find(item => item.id !== excludeId && item.type === type && item.name === name);

        if (isDuplicate) {
            turbo.plugins.modal.alert({
                width      : 400,
                title      : translate(`${ROOT}.warning.duplicateSchemeNameTitle`),
                text       : translate(`${ROOT}.warning.duplicateSchemeNameText`),
                iconClasses: 'warning color-red fs24 mr5'
            });

            return false;
        }

        return true;
    }

    saveScheme(data, onSuccess) {
        let listId = this.selectedList.id;

        saveScheme(listId, data, this.type)
            .then(() => {
                if (typeof onSuccess === "function") {
                    onSuccess();
                }

                this.loadData();
            });
    }

    deleteScheme(schemeId) {
        deleteScheme(schemeId, this.type)
            .then(() => {
                this.loadData();
            });
    }
}

export default ListObjectOfRegistryByLink;
