import m from 'mithril';

export default class MainLevel {
    view({ children }) {
        return (
            <div className="toolbar-table-row toolbar__level toolbar__level_top">
                { children }
            </div>
        )
    }
}
