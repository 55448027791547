import m from 'mithril';
import Component from "lib/Component";

class Years extends Component {
    view() {
        let { setCurrentYearAction } = this.attrs,
            yearsModel               = this._getYearsModel();

        return (
            <div className="t-datepicker-years t-datepicker-body">
                <div className="t-datepicker-cells t-datepicker-cells-years">
                    <For each="year" of={yearsModel}>
                        <div
                            className={
                                "t-datepicker-cell t-datepicker-cell-year"
                                + (year.isOtherDecade ? " other-decade" : "")
                                + (year.isToday ? " current" : "")
                                + (year.isSelected ? " selected" : "")
                            }
                            onclick={() => setCurrentYearAction(year.year)}
                        >
                            {year.year}
                        </div>
                    </For>
                </div>
            </div>
        );
    }

    _getYearsModel() {
        let { selectDate, currentDate } = this.attrs,
            models                      = [],
            currentYear                 = currentDate.getFullYear(),
            selectYear                  = selectDate ? selectDate.getFullYear() : null,
            today                       = new Date(),
            firstYearInDecade           = Math.floor(currentYear / 10) * 10,
            lastYearInDecade            = firstYearInDecade + 9,
            index;

        for (index = firstYearInDecade - 1; index <= lastYearInDecade + 1; index++) {
            models.push({
                year         : index,
                isOtherDecade: index < firstYearInDecade || index > lastYearInDecade,
                isToday      : today.getFullYear() === index,
                isSelected   : selectYear === index
            });
        }

        return models;
    }
}

export default Years;
