import m from 'mithril';
import ChooseSimple from "_/components/common/ChooseSimple";

export default class CreateDocumentTrigger {
    isRender(item) {
        return item.hasOwnProperty('isRender') ? item.isRender : true;
    }

    setSelectedOption(item, items) {
        let value;

        for (let i = 0; i < items.length; i++) {
            if (items[i].id === item.id) {
                value = i;
            }
        }
        localStorage.setItem('OPEN_NEW_DOCUMENT_IN_NEW_WINDOW', value);
    }

    getSelectedValue(items) {
        if (localStorage.getItem('OPEN_NEW_DOCUMENT_IN_NEW_WINDOW') === undefined) {
            localStorage.setItem('OPEN_NEW_DOCUMENT_IN_NEW_WINDOW', 0);
        }
    }

    view({ attrs }) {
        let { items } = attrs;

        this.getSelectedValue()

        return (
            <ChooseSimple
                data-popup-key="create_document"
                data={items}
                onChange={item => this.setSelectedOption(item, items)}
                isCanEmpty={false}
                isButton={true}
                classNames={"toolbar__trigger border-none pr0i"}
                value={items[localStorage.getItem('OPEN_NEW_DOCUMENT_IN_NEW_WINDOW')]}
                buttonClassNames={"noHover border mr0i h24"}
                iconColorClass={"color-blue"}
            />
        )
    }
}
