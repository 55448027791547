import "mithril";
import {connect} from "_/lib/midux";
import classNames from "classnames";
import Component from "_/lib/Component";
import {translate} from "_/localizations";
import Button from "_/components/plugins/Button";
import ContextMenu from "_/components/widgets/ContextMenu";
import {MODE_STEP_EDIT, MODE_STEP_NONE, MODE_STEP_VIEW, TMP_STEP_ID} from "_/containers/questionnaire/constants";
import {requestAddEditStep, setCurrentStep, requestDeleteStep, deleteTempStep} from "_/actions/questionnaire";
import AccessSettings from "_/containers/questionnaire/components/dialogs/AccessSettings";
import RedirectSettings from "_/containers/questionnaire/components/dialogs/RedirectSettings";


const mapStateToProps = state => ({
    currentStep    : state.questionnaire.currentStep,
    steps          : state.questionnaire.steps,
    info           : state.currentInfo,
    currentQuestion: state.questionnaire.currentQuestion
});

const mapActionsToProps = {
    requestAddEditStep,
    setCurrentStep,
    requestDeleteStep,
    deleteTempStep
};

class ManagementHeaderPanel extends Component {
    oninit() {
        this.idExpressionMenu = null;
        this.position         = null;
        this.isSelectName     = false;

        document.addEventListener('customCurrentStep', () => this.onSave());
        document.addEventListener("click", () => this.hideExpressionMenu());
    }

    view() {
        const { isEdit = true }                                    = this.attrs.info;
        const { id, mode, tmpName, name, expression, access = {}, settings } = this.attrs.currentStep;

        const logicalClasses     = classNames(
            "btn_block btn_border p8",
            { "color-grey": !expression },
            { "color-purple": !!expression }
        );
        const logicalIconClasses = classNames(
            "block fs17",
            { settings: !expression },
            { "icon-settings-filled": !!expression }
        );

        const isEditQuestion = !!this.attrs.currentQuestion.id;

        return (
            <div className="turbo-questionnaire__header-management-panel">
                <div className="form-item">
                    {
                        mode === MODE_STEP_NONE
                            ? null
                            : (
                                <input
                                    onupdate={({ dom }) => this.onUpdateInput(dom)}
                                    type="text"
                                    disabled={mode !== MODE_STEP_EDIT}
                                    value={mode === MODE_STEP_EDIT ? tmpName : name}
                                    oninput={event => this.onChangeName(event)}
                                />
                            )
                    }
                </div>
                <div className="group-h-button">
                    {
                        mode === MODE_STEP_VIEW && isEdit
                            ? [
                                <Button
                                    oncontextmenu={event => this.showExpressionMenu(event)}
                                    className={logicalClasses}
                                    onclick={() => this.openLogicalExpression()}
                                    title={this.logicalExpressionTitle(expression)}
                                    disabled={isEditQuestion}
                                >
                                    <Button.Icon className={logicalIconClasses}/>
                                </Button>,
                                <Button
                                    className="btn_block btn_border color-blue p8"
                                    onclick={() => this.onEdit()}
                                    title={`${translate("buttons.edit")} «${name}»`}
                                    disabled={isEditQuestion}
                                >
                                    <Button.Icon className="block fs17 pencil"/>
                                </Button>,
                                <Button
                                    className="btn_block btn_border color-blue p8"
                                    onclick={() => this.openAccessSettings(access, name)}
                                    title={`${translate("buttons.access")} «${name}»`}
                                    disabled={isEditQuestion}
                                >
                                    <Button.Icon className="block fs17 lock"/>
                                </Button>,
                                <Button
                                    className="btn_block btn_border color-blue p8"
                                    onclick={() => this.openRedirectSettings(access, name, settings)}
                                    title={`${translate("buttons.redirect")} «${name}»`}
                                    disabled={isEditQuestion}
                                >
                                    <Button.Icon className="block fs17 link"/>
                                </Button>,
                                <Button
                                    className="btn_block btn_border color-red p8"
                                    onclick={() => this.onDelete()}
                                    title={`${translate("buttons.remove")} «${name}»`}
                                    disabled={isEditQuestion}
                                >
                                    <Button.Icon className="block fs17 icon-trash"/>
                                </Button>
                            ]
                            : null
                    }
                    {
                        mode === MODE_STEP_EDIT
                            ? [
                                <Button
                                    className="btn_block btn_border color-blue p8"
                                    onclick={() => this.onSave()}
                                    title={`${translate("buttons.save")} «${name}»`}
                                >
                                    <Button.Icon className="block fs17 save"/>
                                </Button>,
                                <Button
                                    className="btn_block btn_border color-red p8"
                                    onclick={() => this.onCancel()}
                                    title={translate("buttons.cancel")}
                                >
                                    <Button.Icon className="block fs17 circle-close"/>
                                </Button>
                            ]
                            : null
                    }
                </div>
                {
                    this.idExpressionMenu === `${id}-step` && isEdit
                        ? (
                            <ContextMenu
                                expression={expression}
                                onHideMenu={() => this.hideExpressionMenu()}
                                onOpenExpression={(expression) => this.openLogicalExpression(expression)}
                                position={this.position}
                            />
                        )
                        : null
                }
            </div>
        );
    }

    onUpdateInput(dom) {
        const { mode } = this.attrs.currentStep;

        if (mode === MODE_STEP_EDIT && !this.isSelectName) {
            dom.select();
            this.isSelectName = true;
        }
    }

    onChangeName(event) {
        this.attrs.actions.setCurrentStep({ tmpName: event.target.value });
    }

    logicalExpressionTitle(data) {
        let title = data
            ? translate("questionnaire.regularExpressionQuestionSet") + ": "
            : translate("questionnaire.regularExpressionQuestionNotSet");

        if (data) {
            for (let prop in data.conditions) {
                if (data.conditions.hasOwnProperty(prop) && data.conditions[prop].expression.parameter) {
                    title += data.conditions[prop].expression.parameter.name + "; ";
                }
            }
        }

        return title
    }

    onSave() {
        const { documentType, documentId } = this.attrs.info;
        const { id, tmpName }              = this.attrs.currentStep;

        let data = { name: tmpName };

        if (!tmpName || !tmpName.trim()) {
            this.showWarningMessage(
                translate("questionnaire.warningMessage.titleSaveStep"),
                translate("questionnaire.warningMessage.textEmptyStepName")
            );

            return;
        }

        if (id !== TMP_STEP_ID) {
            data.id = id;
        }

        this.attrs.actions.requestAddEditStep({ documentId, documentType, data });
    }

    onEdit() {
        const { name } = this.attrs.currentStep;

        this.isSelectName = false;
        this.attrs.actions.setCurrentStep({ mode: MODE_STEP_EDIT, tmpName: name });
    }

    onCancel() {
        const { id } = this.attrs.currentStep;

        if (id === TMP_STEP_ID) {
            const steps = this.attrs.steps.filter(step => step.id !== TMP_STEP_ID);

            if (steps.length > 0) {
                this.attrs.actions.setCurrentStep(steps[steps.length - 1]);
            }

            this.attrs.actions.deleteTempStep();
        }

        this.attrs.actions.setCurrentStep({ mode: MODE_STEP_VIEW, tmpName: "" });
    }

    onDelete() {
        const { documentType, documentId } = this.attrs.info;
        const { id, name }                 = this.attrs.currentStep;

        turbo.plugins.modal.confirm(
            {
                title        : translate("questionnaire.warningMessage.titleDeleteStep"),
                btnCancelText: translate("buttons.cancel"),
                btnAcceptText: translate("buttons.yes"),
                text         : `${translate("questionnaire.warningMessage.textDeleteStep")} «${name}» ?`,
                iconClasses  : "font-icon warning fs24 color-orange mr5"
            },
            () => {
                this.attrs.actions.requestDeleteStep({ documentId, documentType, stepId: id });
            }
        );
    }

    openLogicalExpression(newExpression) {
        const { documentType, documentId } = this.attrs.info;
        const { id, expression }           = this.attrs.currentStep;
        const logicalExpression            = new turbo.components.dialogs.logicalExpression.QuestionnaireItem({
            expression  : newExpression || expression,
            type        : "step",
            templateId  : documentId,
            typeDocument: documentType
        });

        logicalExpression.on("save", data => {
            const expression = data.conditions && Object.keys(data.conditions).length ? data : null;
            const saveData   = { id, expression };

            this.attrs.actions.requestAddEditStep({ documentId, documentType, data: saveData });
        });

        logicalExpression.open();
    }

    showExpressionMenu(event) {
        const { id } = this.attrs.currentStep;

        event.preventDefault();

        this.idExpressionMenu = `${id}-step`;
        this.position         = {
            rule: {
                x     : "left",
                xCount: event.layerX + "px",
                y     : "top",
                yCount: event.layerY + "px"
            }
        };
    }

    hideExpressionMenu() {
        if (this.idExpressionMenu) {
            this.idExpressionMenu = null;
            this.position         = null;
            setTimeout(() => m.redraw(), 0);
        }
    }

    showWarningMessage(title, text) {
        turbo.plugins.modal.alert({
            width      : 300,
            title,
            text,
            iconClasses: 'warning color-red fs24 mr5'
        });
    }

    openAccessSettings(access, name) {
        const { editRoles = [], isViewOther = true } = access;

        turbo.plugins.modal.open({
            closeOnOverlayClick: false,
            vnode              : {
                view: () => (
                    <AccessSettings
                        name={name}
                        values={editRoles}
                        isViewOther={isViewOther}
                        onSave={this.onSaveAccessSettings.bind(this)}
                    />
                )
            },
            class              : "access-settings-of-step-questionnaire"
        });
    }

    openRedirectSettings(access, name, settings) {
        const { editRoles = [], isViewOther = true } = access,
            {redirect} = settings;

        turbo.plugins.modal.open({
            closeOnOverlayClick: false,
            vnode              : {
                view: () => (
                    <RedirectSettings
                        name={name}
                        values={editRoles}
                        redirect={redirect}
                        isViewOther={isViewOther}
                        onSave={this.onSaveRedirectSettings.bind(this)}
                    />
                )
            },
            class              : "access-settings-of-step-questionnaire"
        });
    }

    onSaveRedirectSettings(redirectData) {
        const { documentType, documentId } = this.attrs.info;
        const { id }                       = this.attrs.currentStep;
        const data                         = { id, settings: {redirect: redirectData }};

        this.attrs.actions.requestAddEditStep({ documentId, documentType, data });

        turbo.plugins.modal.close(document.querySelector(".access-settings-of-step-questionnaire"));
    }

    onSaveAccessSettings(editRoles, isViewOther) {
        const { documentType, documentId } = this.attrs.info;
        const { id }                       = this.attrs.currentStep;
        const data                         = { id, access: { editRoles, isViewOther } };

        this.attrs.actions.requestAddEditStep({ documentId, documentType, data });

        turbo.plugins.modal.close(document.querySelector(".access-settings-of-step-questionnaire"));
    }
}

export default connect(mapStateToProps, mapActionsToProps)(ManagementHeaderPanel);
