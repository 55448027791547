import m from 'mithril';
import classNames from "classnames";

export default class Info {
    view({ attrs }) {
        let { label, content, trigger } = attrs,
            toolbarClasses = classNames({"cursor-pointer dashed-link color-blue": !!trigger, "inline-block": !trigger});

        return (
            <div className="toolbar__info">
                <span>{ label }:</span>
                <div className={toolbarClasses} onclick={trigger ? trigger.onclick : null}>{ content }</div>
            </div>
        )
    }
}
