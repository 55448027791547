import turbo from 'lib/turbo';

let templates = {},
    debug     = false,
    processes = [];

templates.loading = [
    '<div class="progressbar progressbar_loading">',
    '   <div class="progressbar__indikator">',
    '</div>'
].join('');

const element = turbo.stringToHtmlElement(templates.loading);

function debugEnable() {
    debug = true;
}

function addProcess(name) {
    processes.push({ name });
}

function removeProcess(name) {
    processes = processes.filter(process => process.name !== name);
}

function isExistElement() {
    return !!element.offsetWidth;
}

function showLoading(nameProcess) {
    if (debug) {
        console.log('"' + nameProcess + '" process started.');
    }

    addProcess(nameProcess);

    if (!isExistElement()) {
        document.body.appendChild(element);
    }
}

function hideLoading(nameProcess) {
    if (debug) {
        console.log('"' + nameProcess + '" process completed.');
    }

    removeProcess(nameProcess);

    if (isExistElement() && !processes.length) {
        document.body.removeChild(element);
    }
}

/**
 * Отображает на странице прогрессбар и добавляет в массив processes
 * элемент с названием(nameProcess).
 *
 * Пока массив processes не пустой прогрессбар будет отображаться на странице.
 *
 * @param {string} nameProcess - Название процесса.
 */
function showProcess(nameProcess) {
    showLoading(nameProcess || 'unnamed');
}

/**
 * Скрывает прогрессбар со страницы и удаляет из массива processes
 * элемент с названием(nameProcess).
 *
 * Пока массив processes не пустой прогрессбар будет отображаться на странице.
 *
 * @param {string} nameProcess - Название процесса.
 */
function hideProcess(nameProcess) {
    hideLoading(nameProcess || 'unnamed');
}

/**
 * Скрывает прогрессбар со страницы и удаляет все процессы из массива processes.
 *
 * Вызывать если требуется принудительно завершить все процессы и скрыть прогрессбар.
 * Например при возникновении ошибки.
 */
function hideAllProcesses() {
    processes.length = 0;

    hideLoading();
}

export {
    showProcess,
    hideProcess,
    hideAllProcesses,
    debugEnable,
    processes
};

export default {
    showProcess,
    hideProcess,
    hideAllProcesses,
    debugEnable,
    processes
};
