export const INIT_QUESTIONNAIRE_DATA       = "QUESTIONNAIRE/INIT_QUESTIONNAIRE_DATA";
export const REQUEST_QUESTIONNAIRE_DATA    = "QUESTIONNAIRE/REQUEST_QUESTIONNAIRE_DATA";
export const SET_QUESTIONNAIRE_DATA        = "QUESTIONNAIRE/SET_QUESTIONNAIRE_DATA";
export const SET_CURRENT_STEP              = "QUESTIONNAIRE/SET_CURRENT_STEP";
export const ADD_TEMP_STEP                 = "QUESTIONNAIRE/ADD_TEMP_STEP";
export const DELETE_TEMP_STEP              = "QUESTIONNAIRE/DELETE_TEMP_STEP";
export const REQUEST_ADD_EDIT_STEP         = "QUESTIONNAIRE/REQUEST_ADD_EDIT_STEP";
export const SET_TMP_NAME_STEP             = "QUESTIONNAIRE/SET_TMP_NAME_STEP";
export const REQUEST_DELETE_STEP           = "QUESTIONNAIRE/REQUEST_DELETE_STEP";
export const REQUEST_MOVE_STEP             = "QUESTIONNAIRE/REQUEST_MOVE_STEP";
export const REQUEST_ADD_EDIT_QUESTION     = "QUESTIONNAIRE/REQUEST_ADD_EDIT_QUESTION";
export const SET_CURRENT_QUESTION          = "QUESTIONNAIRE/SET_CURRENT_QUESTION";
export const REMOVE_CURRENT_QUESTION       = "QUESTIONNAIRE/REMOVE_CURRENT_QUESTION";
export const SET_VALUE_CURRENT_QUESTION    = "QUESTIONNAIRE/SET_VALUE_CURRENT_QUESTION";
export const SET_SETTINGS_CURRENT_QUESTION = "QUESTIONNAIRE/SET_SETTINGS_CURRENT_QUESTION";
export const ADD_TMP_QUESTION              = "QUESTIONNAIRE/ADD_TMP_QUESTION";
export const DELETE_TMP_QUESTION           = "QUESTIONNAIRE/DELETE_TMP_QUESTION";
export const UPDATE_CURRENT_STEP           = "QUESTIONNAIRE/UPDATE_CURRENT_STEP";
export const REQUEST_DELETE_QUESTION       = "QUESTIONNAIRE/REQUEST_DELETE_QUESTION";
export const SET_PARAMETRIC_DATA           = "QUESTIONNAIRE/SET_PARAMETRIC_DATA";
export const REMOVE_PARAMETRIC_DATA        = "QUESTIONNAIRE/REMOVE_PARAMETRIC_DATA";
export const REQUEST_DELETE_QUESTIONNAIRE  = "QUESTIONNAIRE/REQUEST_DELETE_QUESTIONNAIRE";
export const REQUEST_MOVE_QUESTION         = "QUESTIONNAIRE/REQUEST_MOVE_QUESTION";
export const REQUEST_COPY_STEP             = "QUESTIONNAIRE/REQUEST_COPY_STEP"
export const SET_OPTIONS_CURRENT_QUESTION  = "QUESTIONNAIRE/SET_OPTIONS_CURRENT_QUESTION"

//TODO перенести в текстовые блоки когда появится этот раздел в стейте
export const REQUEST_LIST_GROUP_TEXT_BLOCK    = "QUESTIONNAIRE/REQUEST_LIST_GROUP_TEXT_BLOCK";
export const SET_LIST_GROUP_TEXT_BLOCK        = "QUESTIONNAIRE/SET_LIST_GROUP_TEXT_BLOCK";
export const SET_STATUS_LOAD_GROUP_TEXT_BLOCK = "QUESTIONNAIRE/SET_STATUS_LOAD_GROUP_TEXT_BLOCK";
export const REQUEST_LIST_TEXT_BLOCK          = "QUESTIONNAIRE/REQUEST_LIST_TEXT_BLOCK";
export const SET_LIST_TEXT_BLOCK              = "QUESTIONNAIRE/SET_LIST_TEXT_BLOCK";

