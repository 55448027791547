import m from 'mithril';
import turbo from 'lib/turbo';
import Panel from './Panel';
import Indicator from './Indicator';

class Tools {
    oncreate({ dom }) {
        this.element = dom;

        document.addEventListener('DOMContentLoaded', () => {
            document.addEventListener('scroll', () => this.onScroll());
            window.addEventListener('resize', () => this.onScroll());
            window.addEventListener('load', () => this.onScroll());
        });
    }

    onupdate({ dom }) {
        let hasOpenPanel = !!dom.querySelector('.toolbar__left-panel_open');

        document.body.classList[hasOpenPanel ? "add" : "remove"]("has-left-panel");
    }

    onScroll() {
        let visibleFooter = turbo.utils.dom.helpers.checkFooterOnScroll();

        this.element.style.height = `calc(100vh - 100% - ${+visibleFooter}px)`;

        m.redraw();
    }

    view({ children }) {
        return (
            <div
                class="toolbar__left-panels"
                data-role="left-panels"
            >
                { children }
            </div>
        )
    }
}

Tools.Panel     = Panel;
Tools.Indicator = Indicator;

export default Tools;
