import m from "mithril";
import {translate} from 'localizations';
import Button from "components/plugins/Button";
import {CREATE_FRAGMENT, EDIT_FRAGMENT, CREATE_FRAGMENT_FROM_ITEM, CREATE_FRAGMENT_FROM_TEMPLATE} from "./constants"
import {createDocumentFragment, changeDocumentFragment, createDocumentFragmentFromItem, createDocumentFragmentFromTemplate} from "./promises";

class AddEditDocumentFragment {
    constructor(data = {}, typeAction, onSaveAction) {
        this._data             = { ...data };
        this._typeAction       = typeAction;
        this._onSaveAction     = onSaveAction;
        this._isCreateWithText = false;
    }

    view() {
        let isDisableSave =      !this._data.name,
            isDisabledCheckBox = (this._data.id || this._data.patternId) ? false : true;

        return m.fragment({}, [
            <div className="modal__header bg-blue color-white">
                <div className="font-icon cancel modal-close"/>
                <span>
                    {
                        this._data.id
                            ? translate("addEditDocumentFragment.titleEdit")
                            : translate("addEditDocumentFragment.titleAdd")
                    }
                </span>
            </div>,

            <div className="modal__body">
                <label className="form-item">
                    <span className="pb10">{translate("addEditDocumentFragment.labelName")}</span>
                    <input
                        type="text"
                        value={this._data.name}
                        oninput={this._changeName()}
                    />
                </label>
                <label className="form-item pt15">
                    <span className="pb10">{translate("addEditDocumentFragment.labelComment")}</span>
                    <textarea
                        value={this._data.comment}
                        oninput={this._changeComment()}
                    />
                </label>
                <If condition={isDisabledCheckBox}>
                    <label className="checkbox-radio pt15">
                        <input
                            type="checkbox"
                            onchange={e => (this._isCreateWithText = e.target.checked)}
                            checked={this._isCreateWithText}
                        />
                        <i/>
                        <span className="fs14 ml5">{translate("addEditDocumentFragment.isCreateWithText")}</span>
                    </label>
                    <If condition={this._isCreateWithText}>
                        <div className="form-item pt15">
                            <div
                                contentEditable="true"
                                oninput={this._changeText()}
                                onpaste={this._onPasteContent}
                                data-placeholder={translate("addEditDocumentFragment.placeholderText")}
                            />
                        </div>
                    </If>
                </If>
            </div>,

            <div className="modal__footer bg-grey text-right">
                <Button className="turbo-button__size_md dotted-link mr20 fs14 p0 modal-close color-blue">
                    {translate("addEditDocumentFragment.textButtonCancel")}
                </Button>
                <Button
                    className="turbo-button__size_md turbo-button__bg_orange turbo-button__text_white"
                    disabled={isDisableSave}
                    onclick={() => this._save()}
                    title={isDisableSave ? translate("addEditDocumentFragment.warningSave") : ""}
                >
                    {translate("addEditDocumentFragment.textButtonSave")}
                </Button>
            </div>
        ]);
    }

    _changeName() {
        return m.withAttr(
            "value",
            (value) => {
                this._data.name = value
            },
            this
        )
    }

    _changeComment() {
        return m.withAttr(
            "value",
            (value) => {
                this._data.comment = value
            },
            this
        )
    }

    _changeText() {
        return m.withAttr(
            "innerHTML",
            (value) => {
                this._data.content = value
            },
            this
        )
    }

    _onPasteContent(e) {
        let contentEl    = e.currentTarget,
            parseContent = turbo.pages.createPattern.parseContent,
            fragment;

        setTimeout(clearContentEditable, 100, contentEl);

        function clearContentEditable(contentEl) {
            fragment         = parseContent.clearContent(contentEl.innerHTML);
            contentEl.vnodes = null;
            m.render(contentEl, fragment);
        }
    }

    _save() {
        let promise;

        switch (this._typeAction) {
            case CREATE_FRAGMENT:
                promise = createDocumentFragment;
                break;
            case EDIT_FRAGMENT:
                promise = changeDocumentFragment;
                break;
            case CREATE_FRAGMENT_FROM_ITEM:
                promise = createDocumentFragmentFromItem;
                break;
            case CREATE_FRAGMENT_FROM_TEMPLATE:
                promise = createDocumentFragmentFromTemplate;
                break;
            default:
                return;
        }

        promise(this._data)
            .then(result => {
                turbo.plugins.modal.close(document.querySelector('.addEditDocumentFragmentModel'));

                if (typeof this._onSaveAction === "function") {
                    this._onSaveAction(result.data);
                }
            });
    }

    open() {
        turbo.plugins.modal.open({
            closeOnOverlayClick: false,
            vnode              : this,
            class              : "addEditDocumentFragmentModel"
        });
    }
}

export default AddEditDocumentFragment;
