import "mithril";
import {connect} from "_/lib/midux";
import turbo from "_/lib/turbo";
import Component from "_/lib/Component";
import {translate} from "_/localizations";
import {Container} from "_/components/plugins/ScrollBar";
import {
    FIELD_OF_OBJECT_OF_REGISTRY,
    OBJECT_OF_REGISTRY,
    OTHER_REQUISITES, OTHER_ROLE, OTHER_SIDE,
    OWN_REQUISITES, OWN_ROLE, OWN_SIDE, PROPERTY_OF_DOCUMENT,
    OBJECT_TREE_OF_REGISTRY
} from "_/constants/questionsOfRelatedData";
import {setCurrentData} from "_/actions/relatedData";

const TurboTree = turbo.plugins.TurboTree;

const mapStateToProps = state => ({
    documentProperties: state.relatedData.documentProperties,
    registries        : state.relatedData.registries,
    requisites        : state.relatedData.requisites,
    registriesFields  : state.relatedData.registriesFields,
    currentType       : state.relatedData.currentType,
    registriesBinding : state.relatedData.registriesBinding
});

const mapActionsToProps = {
    setCurrentData
};

class Body extends Component {
    oninit() {
        const { addListener } = this.attrs;

        this.tree = {};

        addListener("changeTypeRelatedData", (currentType) => {
            const { localStorageName } = this.attrs;

            let toggleStatusNodes = [];

            if (this.tree[+currentType]) {
                const currentTree = this.tree[+currentType];
                const nodeId      = currentTree.getChecked()[0];

                if (nodeId) {
                    const node       = currentTree.getNode(nodeId);
                    const parentNode = currentTree.getParentNode(nodeId);

                    this.attrs.actions.setCurrentData({ node, parentNode });
                } else {
                    this.attrs.actions.setCurrentData({});
                }

                toggleStatusNodes = this.tree[+currentType].getToggleStatusNodes();
            } else {
                this.attrs.actions.setCurrentData({});
            }

            turbo.saveJsonToLocalStorage(localStorageName, {
                type: currentType,
                tree: toggleStatusNodes
            });
        });
    }

    view() {
        const { currentType } = this.attrs;
        const isEmptyBody     = [OWN_SIDE, OTHER_SIDE, OWN_ROLE, OTHER_ROLE].includes(+currentType);

        return (
            <Container className="modal__body p0">
                {
                    isEmptyBody
                        ? (<div className="color-grey p15">{translate("relatedData.emptyText")}</div>)
                        : this.renderTree()
                }
            </Container>
        );
    }

    renderTree() {
        const { documentProperties, registries,
            requisites, registriesFields,
            currentType, registriesBinding } = this.attrs;

        if (!this.tree[+currentType]) {
            switch (+currentType) {
                case OBJECT_OF_REGISTRY:
                    this.tree[+currentType] = this.getTree(registries);
                    break;
                case OBJECT_TREE_OF_REGISTRY:
                    this.tree[+currentType] = this.getTree(registriesBinding);
                    break;
                case FIELD_OF_OBJECT_OF_REGISTRY:
                    this.tree[+currentType] = this.getTree(registriesFields);
                    break;
                case OWN_REQUISITES:
                case OTHER_REQUISITES:
                    this.tree[+currentType] = this.getTree(requisites);
                    break;
                case PROPERTY_OF_DOCUMENT:
                    this.tree[+currentType] = this.getTree(documentProperties);
                    break;
            }

            this.applySaveSettings();
        }

        if (this.tree[+currentType]) {
            const Tree = this.tree[+currentType].getElement();

            return <Tree/>;
        }

        return null;
    }

    getTree(data = []) {
        let tree;

        if (data.length === 0) {
            return undefined;
        }

        tree = new TurboTree({
            storage : {
                data
            },
            settings: {
                isEditMode     : false,
                markedParent   : false,
                singleSelection: true,
                isHideHeader   : true,
                emptyText      : translate("relatedData.emptyTreeText")
            }
        });

        this.bindListenersTree(tree);

        return tree;
    }

    applySaveSettings() {
        const { localStorageName, currentType, nodeId } = this.attrs;

        let saveSettings, saveType, saveToggleStatusNodes;

        if (nodeId) {
            if (this.tree[+currentType]) {
                this.tree[+currentType].check(nodeId);
                this.tree[+currentType].openAllParents(nodeId);
            }
        } else if (localStorageName) {
            saveSettings          = turbo.loadJsonFromLocalStorage(localStorageName);
            saveType              = saveSettings.type;
            saveToggleStatusNodes = saveSettings.tree;

            if (+saveType === +currentType && this.tree[+currentType]) {
                this.tree[+currentType].setToggleStatusNodes(saveToggleStatusNodes);
            }
        }
    }

    bindListenersTree(tree) {
        const { localStorageName, currentType } = this.attrs;

        tree.setListener("check_node", nodeId => {
            const node       = tree.getNode(nodeId);
            const parentNode = tree.getParentNode(nodeId);

            this.attrs.actions.setCurrentData({ node, parentNode });
        });

        if (localStorageName) {
            tree.setListener("toggle_node", () => {
                let toggleStatusNodes = tree.getToggleStatusNodes();

                turbo.saveJsonToLocalStorage(localStorageName, {
                    type: currentType,
                    tree: toggleStatusNodes
                });
            });
        }
    }
}

export default connect(mapStateToProps, mapActionsToProps)(Body);
