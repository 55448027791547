import m from 'mithril';
import Row from './Row';
import RowLeft from './RowLeft';
import RowRight from './RowRight';

class Inner {
    view({ children }) {
        return (
            <div className="toolbar-table-row">
                { children }
            </div>
        )
    }
}

Inner.Row      = Row;
Inner.RowLeft  = RowLeft;
Inner.RowRight = RowRight;

export default Inner;
