import m from 'mithril';

export default class Trigger {
    isRender(item) {
        return item.hasOwnProperty('isRender') ? item.isRender : true;
    }

    hasTriggers(item) {
        return !!item.triggers.filter(entry => this.isRender(entry)).length;
    }

    checkSubTriggers(item) {
        return item.triggers ? this.hasTriggers(item) : true;
    }

    view({ attrs }) {
        let { item } = attrs;

        return (
            <If condition={ !!item && this.isRender(item) && this.hasTriggers(item) }>
                <div className={ `toolbar__trigger ${item.className || ""}  ${item.icon ? " toolbar__trigger_target" : ""}` }>
                    <i className={ `font-icon ${item.icon || ""}` }/>
                    { item.label }
                    <ul className="toolbar__menu">
                        <For each="trigger" of={ item.triggers }>
                            <If condition={ this.isRender(trigger) && this.checkSubTriggers(trigger) }>
                                <li
                                    className={ `toolbar__menu-item ${trigger.className || ''}` }
                                    onclick={ trigger.onclick }
                                >
                                    <i className={ `toolbar__menu-icon font-icon ${trigger.icon || ""}` }/>
                                    { trigger.label }
                                    <If condition={ trigger.triggers && !!trigger.triggers.length }>
                                        <ul className="toolbar__menu">
                                            <For each="subtrigger" of={ trigger.triggers }>
                                                <If condition={ this.isRender(subtrigger) }>
                                                    <li
                                                        className={ `toolbar__menu-item ${ subtrigger.className || '' }` }
                                                        onclick={ subtrigger.onclick }
                                                    >
                                                        <i className={ `toolbar__menu-icon font-icon ${ subtrigger.icon || "" }` } />
                                                        { subtrigger.label }
                                                    </li>
                                                </If>
                                            </For>
                                        </ul>
                                    </If>
                                </li>
                            </If>
                        </For>
                    </ul>
                </div>
            </If>
        )
    }
}
