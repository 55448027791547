import m from 'mithril';
import turbo from 'lib/turbo';
import globalState from 'lib/globalState';
import {
    GET_UNREAD_LIST,
    GET_UNREAD_COUNT,
    SUBSTRACT_UNREAD_COUNT,
    REMOVE_MESSAGE,
    MARK_AS_READ_WIDGET,
    HAS_REMOTE_CHANGES,
    HAS_LOCAL_CHANGES
} from "types/notifications";
import {isShow} from "_/lib/roleModel";

function getFilter() {
    const NAV_BAR_ENABLED = globalState.get('accountOptions')
        ? globalState.get('accountOptions').components['quick_navigation_bar']
        : true;

    return {
        tags: (NAV_BAR_ENABLED && isShow("3")) ? turbo.quickNavigationBar.listObjects.getListTags() : {}
    }
}

const getNotificationsAsync = () => {
    return m.request({
        method:     "post",
        url:        Routing.generate('turbo_front_messages_list'),
        background: true,
        headers:    {
            'X-Requested-With': 'XMLHttpRequest'
        }
    });
};

const removeMessageAsync = id => {
    let params = { id, filter: getFilter() };

    return m.request({
        method:     "post",
        url:        Routing.generate('turbo_front_messages_remove', params),
        background: true,
        headers:    {
            'X-Requested-With': 'XMLHttpRequest'
        }
    });
};

const viewMessageAsync = id => {
    let params = { id, filter: getFilter() };

    return m.request({
        method:     "post",
        url:        Routing.generate('turbo_front_messages_view', params),
        background: true,
        headers:    {
            'X-Requested-With': 'XMLHttpRequest'
        }
    });
};

const getUnreadNotifications = messages => ({
    type: GET_UNREAD_LIST,
    messages
});

const getUnreadCount = count => ({
    type: GET_UNREAD_COUNT,
    count
});

const substractUnreadCount = value => ({
    type: SUBSTRACT_UNREAD_COUNT,
    value
});

const removeMessage = message => ({
    type: REMOVE_MESSAGE,
    message
});

const markAsReadWidgetMessage = message => ({
    type: MARK_AS_READ_WIDGET,
    message
});

export const setRemoteChangesFlag = value => ({
    type: HAS_REMOTE_CHANGES,
    value
});

export const setLocalChangesFlag = value => ({
    type: HAS_LOCAL_CHANGES,
    value
});

export const getWidgetNotificationsAsync = () => dispatch => {
    return getNotificationsAsync().then(response => {
        if (response.success) {
            dispatch(getUnreadNotifications(response.messages));
            dispatch(getUnreadCount(response.unreadMessagesCount));
        }

        return response;
    });
};

export const removeWidgetMessageAsync = message => dispatch => {
    dispatch(setLocalChangesFlag(true));

    return removeMessageAsync(message.id).then(response => {
        if (response.success) {
            dispatch(removeMessage(message));
            dispatch(substractUnreadCount(1));
            dispatch(setLocalChangesFlag(false));
        }

        return response;
    });
};

export const viewWidgetMessageAsync = message => dispatch => {
    dispatch(setLocalChangesFlag(true));

    return viewMessageAsync(message.id).then(response => {
        if (response.success) {
            dispatch(markAsReadWidgetMessage(message));
            dispatch(substractUnreadCount(1));
            dispatch(setLocalChangesFlag(false));
        }

        return response;
    });
};
