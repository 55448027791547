import "mithril";
import Component from "_/lib/Component";
import Button from "_/components/plugins/Button";
import {translate} from "_/localizations";

class TextAreaForm extends Component {
    oninit() {
        const { value } = this.attrs;

        this.value = value || "";
    }

    oncreate({ dom }) {
        const textField = dom.querySelector("textarea");

        if (textField) {
            setTimeout(() => textField.focus(), 500);
        }
    }

    view() {
        const { title = "", textSuccess, textCancel } = this.attrs;

        return (
            <div>
                <div className="modal__header bg-blue color-white">
                    <div className="modal-close font-icon cancel"/>
                    <span>{title}</span>
                </div>
                <div className="modal__body p15">
                    <div className="form-item">
                        <textarea
                            style={{ height: "150px" }}
                            value={this.value || ''}
                            oninput={event => this.onChangeValue(event)}
                        />
                    </div>
                </div>
                <div className="modal__footer bg-grey d-flex justify-center">
                    <Button
                        className="btn_size_md btn_bg_red btn_text_white modal-close"
                        onclick={() => this.removeComment()}
                    >
                        {translate("buttons.remove")}
                    </Button>
                    <div className="text-right">
                        <Button className="fs15 color-blue dashed-link mr15 modal-close">
                            {textCancel || translate("buttons.cancel")}
                        </Button>

                        <Button
                            className="btn_size_md btn_bg_orange btn_text_white modal-close"
                            onclick={() => this.onSave()}
                            disabled={!this.value}
                        >
                            {textSuccess || translate("buttons.save")}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    onChangeValue(event) {
        this.value = event.target.value;
    }

    removeComment() {
        const { onSave } = this.attrs;

        if (typeof onSave === "function") {
            onSave(null);
        }
    }

    onSave() {
        const { onSave } = this.attrs;

        if (typeof onSave === "function") {
            onSave(this.value);
        }
    }
}

export default TextAreaForm;
