import {
    INIT_GROUP,
    SET_LIST_GROUPS,
    SET_LIST,
    SET_TYPES,
    INIT_GROUP_REGISTRY,
    INIT_ITEM,
    SET_ITEM,
    INIT_GROUP_TEMPLATE,
    CLEAR_UPDATE_STACK,
    SET_ITEM_IN_UPDATE_STACK,
    CLEAR_ITEM_IN_UPDATE_STACK,
    INIT_GROUP_FRAGMENT,
    SET_SEARCH_DATA_GROUPS, SET_SEARCH_DATA_TEXT_BLOCK
} from "_/types/document-text-block";
import * as api from "_/api/document-text-block";

const updateItemQuery = data => {
    return api.updateItem()(data);
};

const deleteItemQuery = id => {
    return mt.s.post({
        url        : Routing.generate("text_block_delete", { id }),
        loadConfig : { isShow: true, name: "DocumentTextBlock: deleteItem" },
        errorConfig: { isShow: true }
    });
};

export const setListGroups = payload => {
    return {
        type: SET_LIST_GROUPS,
        payload
    };
};

export const initGroup = payload => {
    return {
        type: INIT_GROUP,
        payload
    };
};

export const initGroupRegistry = payload => {
    return {
        type: INIT_GROUP_REGISTRY,
        payload
    };
};

export const initGroupTemplate = payload => {
    return {
        type: INIT_GROUP_TEMPLATE,
        payload
    };
};

export const setList = payload => {
    return {
        type: SET_LIST,
        payload
    };
};

export const setTypes = payload => {
    return {
        type: SET_TYPES,
        payload
    };
};

export const initItem = payload => {
    return {
        type: INIT_ITEM,
        payload
    };
};

export const setItem = payload => {
    return {
        type: SET_ITEM,
        payload
    };
};

export const clearUpdateStack = () => {
    return { type: CLEAR_UPDATE_STACK };
};

export const setItemInUpdateStack = payload => {
    return {
        type: SET_ITEM_IN_UPDATE_STACK,
        payload
    };
};

export const clearItemInUpdateStack = payload => {
    return {
        type: CLEAR_ITEM_IN_UPDATE_STACK,
        payload
    };
};

export const initGroupFragment = payload => {
    return {
        type: INIT_GROUP_FRAGMENT,
        payload
    };
};

export const setSearchDataGroups = payload => ({
    type: SET_SEARCH_DATA_GROUPS,
    payload
});

export const setSearchDataTextBlock = payload => ({
    type: SET_SEARCH_DATA_TEXT_BLOCK,
    payload
});

export const loadListGroup = () => dispatch => {
    mt.s.get({
        url        : Routing.generate("text_block_registry_list"),
        loadConfig : { isShow: true, name: "DocumentTextBlock: loadListGroup" },
        errorConfig: { isShow: true }
    }).then(result => {
        dispatch(setListGroups(result.data || []));
    });
};

export const createGroup = data => dispatch => {
    mt.s.post({
        url        : Routing.generate("text_block_registry_create"),
        data,
        loadConfig : { isShow: true, name: "DocumentTextBlock: createGroup" },
        errorConfig: { isShow: true }
    }).then(() => {
        dispatch(loadListGroup());
    });
};

export const updateGroup = data => dispatch => {
    mt.s.post({
        url        : Routing.generate("text_block_registry_update", { id: data.id }),
        data,
        loadConfig : { isShow: true, name: "DocumentTextBlock: updateGroup" },
        errorConfig: { isShow: true }
    }).then(() => {
        dispatch(loadListGroup());
    });
};

export const deleteGroup = id => dispatch => {
    mt.s.post({
        url        : Routing.generate("text_block_registry_delete", { id }),
        loadConfig : { isShow: true, name: "DocumentTextBlock: deleteGroup" },
        errorConfig: { isShow: true }
    }).then(() => {
        dispatch(loadListGroup());
    });
};

export const loadList = (groupId, isShowAll) => dispatch => {
    mt.s.get({
        url        : Routing.generate("text_block_list", { id: groupId, all: isShowAll ? 1 : 0 }),
        loadConfig : { isShow: true, name: "DocumentTextBlock: loadList" },
        errorConfig: { isShow: true }
    }).then(result => {
        dispatch(setList(result.data || []));
    });
};

export const loadListTypes = () => dispatch => {
    mt.s.get({
        url        : Routing.generate("text_block_types"),
        loadConfig : { isShow: true, name: "DocumentTextBlock: loadListTypes" },
        errorConfig: { isShow: true }
    }).then(result => {
        dispatch(setTypes(result.data || []));
    });
};

export const createItem = (groupId, registryId, data, callBack) => dispatch => {
    api.createItem()({ groupId, registryId, data })
        .then(result => {
            if (typeof callBack === "function") {
                callBack(result.data);
            }
        });
};

export const cloneItem = (data, callBack) => dispatch => {
    api.cloneItem()(data)
        .then(result => {
            if (typeof callBack === "function") {
                callBack(result.data);
            }
        });
};

export const updateItemFromList = (groupId, data) => dispatch => {
    updateItemQuery(data).then(() => {
        dispatch(loadList(groupId));
    });
};

export const approveItemFromList = (groupId, id, approved) => dispatch => {
    updateItemQuery({ id, approved }).then(() => {
        dispatch(loadList(groupId));
    });
};

export const deleteItemFromList = (groupId, id) => dispatch => {
    deleteItemQuery(id).then(() => {
        dispatch(loadList(groupId));
    });
};

export const updateItem = (data) => dispatch => {
    updateItemQuery(data).then(result => {
        dispatch(setItem(result.data));

        if (data.content || data.container) {
            dispatch(clearUpdateStack());
        }
    });
};

export const approveItem = (id, approved) => dispatch => {
    updateItemQuery({ id, approved }).then(result => {
        dispatch(setItem(result.data));

        if (approved) {
            document.dispatchEvent(new CustomEvent("approvedTextBlock"));
        }
    });
};

export const deleteItem = (id, callBack) => dispatch => {
    deleteItemQuery(id).then(result => {
        if (typeof callBack === "function") {
            callBack(result.data);
        }
    });
};

export const saveItemContent = ({ id, content, model, container }) => dispatch => {
    updateItemQuery({ id, content, model, container }).then(result => {
        dispatch(setItem(result.data));
        dispatch(clearUpdateStack());
        document.dispatchEvent(new CustomEvent('saveTextBlock'));
    });
};

export const loadItem = id => dispatch => {
    mt.s.get({
        url        : Routing.generate("text_block_view", { id }),
        loadConfig : { isShow: true, name: "DocumentTextBlock: loadItem" },
        errorConfig: { isShow: true }
    }).then(result => {
        dispatch(initItem(result.data));
    });
};

export const moveGroups = data => dispatch => {
    mt.s.post({
        url        : Routing.generate("text_block_registry_move", { type: "other" }),
        data,
        loadConfig : { isShow: true, name: "DocumentTextBlock: moveGroups" },
        errorConfig: { isShow: true }
    }).then(result => {
        dispatch(loadListGroup());
    });
};

export const moveTextBlock = (groupId, data) => dispatch => {
    mt.s.post({
        url        : Routing.generate("text_block_move"),
        data,
        loadConfig : { isShow: true, name: "DocumentTextBlock: moveTextBlock" },
        errorConfig: { isShow: true }
    }).then(result => {
        dispatch(loadList(groupId));
    });
};
