import m from "mithril"
import turbo from "lib/turbo";
import Component from "lib/Component";
import {translate} from 'localizations';
import Button from "components/plugins/Button";
import PopUp from "components/plugins/PopUp"
import {Container} from 'components/plugins/ScrollBar';
import SearchEngine from "lib/searchEngine";
import {setHighLightVnode} from "lib/highLight";
import scrollIntoView from "scroll-into-view-if-needed";
import debounce from "lib/debounce";

const ARROW_UP   = 38;
const ARROW_DOWN = 40;
const ENTER      = 13;

const DEFAULT_WIDTH = 200;

const defaultConfig = {
    template     : (data, fieldView) => (<span>{data[fieldView]}</span>),
    countShowItem: 5
};

class DropDownList extends Component {
    oninit() {
        this._config = { ...defaultConfig, ...this.attrs };

        this._config.data         = this._config.data ? this._config.data : [];
        this._config.fieldsSearch = this._config.fieldsSearch ? this._config.fieldsSearch : [];

        this._isOpen        = false;
        this._popupKey      = this._config["data-popup-key"];
        this._currentValue  = this.attrs.value;
        this._selectedIndex = -1;
        this._selectdetItem = undefined;
        this._isLoadData    = false;
        this._isProgress    = false;
        this._isLoadAllList = false;

        this._debounceServerSearch = debounce(this._serverSearch, 500, this);

        this._heightConfig = { maxHeight: 0 };

        this._loadData();

        document.body.addEventListener("mousedown", e => this._mouseDownAction(e));
        window.addEventListener("resize", () => this._resizeAction());
        document.addEventListener("resetList", () => this._resetAction())
    }

    onbeforeupdate() {
        let value = this.attrs.value,
            data  = this.attrs.data || [],
            oldValue;

        if (this._config.value !== value) {
            oldValue           = this._config.value;
            this._config.value = value;
            this._config.data  = [...data];

            if (!this._initValue()) {
                this._config.value = oldValue;
            }
        }
    }

    view() {
        let modal        = this._config.modal,
            trigger      = this._config.trigger || {},
            viewData     = this._getData(),
            isEmpty      = viewData.length === 0,
            extraButtons = this.attrs.extraButtons || [];

        return (
            <div
                className={`form-item form-item_with-controls ${this._config.classNames}`}
                data-popup-parent-key={this._popupKey}
                oncreate={({ dom }) => (this._widthContainer = dom.getBoundingClientRect().width)}
                onupdate={({ dom }) => (this._widthContainer = dom.getBoundingClientRect().width)}
            >
                <Choose>
                    <When condition={this._config.isButton}>
                        <div
                            className={`toolbar__trigger toolbar__trigger_target ${this._currentValue.className || ""}`}
                            onclick={this._currentValue.onclick}
                        >
                            <i className={`font-icon ${this._currentValue.icon || ""}`}/>
                            {this._currentValue.name}
                        </div>
                    </When>
                    <Otherwise>
                        <input
                            type="text"
                            value={this._value}
                            onclick={(e) => this._toggle(e)}
                            oninput={(e) => this._onChange(e.target.value)}
                            onkeydown={(e) => this._onKeyDown(e)}
                            onblur={() => this.onBlur()}
                            disabled={this.attrs.disabled || false}
                        />
                    </Otherwise>
                </Choose>

                <If condition={this._isProgress}>
                    <span className="progressbar_icon item-controls fs20"/>
                </If>
                <If condition={this._config.isCanEmpty}>
                    <Button
                        className="item-controls"
                        onclick={() => this._onRemove()}
                        title={this._config.clearText}
                    >
                        <Button.Icon className="circle-close color-orange block"/>
                    </Button>
                </If>
                {
                    extraButtons.map(button => {
                        if (button.isItem && !this._currentValue) {
                            return null;
                        }

                        return (
                            <Button
                                className="item-controls"
                                onclick={
                                    () => typeof button.fn === "function"
                                        ? button.fn(this._currentValue)
                                        : undefined
                                }
                                title={button.title}
                            >
                                <Button.Icon className={`${button.iconClasses} block`}/>
                            </Button>
                        );
                    })
                }
                <Button
                    className={`item-controls ${this._config.buttonClassNames}`}
                    disabled={this.attrs.disabled || false}
                    onclick={(e) => this._toggle(e)}
                >
                    <Button.Icon className={`drop-down-arrow ${this._config.iconColorClass
                        ? this._config.iconColorClass
                        : "color-orange"} fs8`}/>
                </Button>
                <Choose>
                    <When condition={this._isOpen}>
                        <PopUp
                            data-popup-key={this._popupKey}
                            modal={modal}
                            trigger={trigger}
                        >
                            <Choose>
                                <When condition={isEmpty}>
                                    <div
                                        className="drop-down-container"
                                        style={"width: " + (this._widthContainer || DEFAULT_WIDTH) + "px;"}
                                    >
                                        <span className="drop-down-empty-block color-grey">
                                            {translate("dropDownList.emptyText")}
                                        </span>
                                    </div>
                                </When>
                                <Otherwise>
                                    <Container
                                        className="drop-down-container"
                                        style={
                                            "width: "
                                            + (this._widthContainer || DEFAULT_WIDTH)
                                            + "px; max-height: "
                                            + this._heightConfig.heightContainer + "px;"
                                        }
                                        oncreate={({ dom }) => this._onCreateScrollContainer(dom)}
                                    >
                                        {viewData.map((item, index, array) => this._renderItem(item, index, array))}
                                    </Container>
                                </Otherwise>
                            </Choose>
                        </PopUp>
                    </When>
                </Choose>
            </div>
        );
    }

    _onCreateScrollContainer(dom) {
        dom.addEventListener("wheel", () => {
            let lastItem = dom.querySelector(".drop-down-item.last-item");

            this._reLoadingData(lastItem, dom)
        });
    }

    _loadData(regExps = [], excludeIds = [], callBack) {
        let self    = this,
            promise = this._config.promise;

        if (typeof promise === "function") {
            let initValue = (this._config.value && excludeIds.length === 0) ? this._config.value : "";

            this._isProgress    = true;
            this._startLoadData = true;

            promise(regExps, excludeIds, [initValue])
                .then(result => {
                    if (excludeIds.length > 0) {
                        self._config.data = [...self._config.data, ...result.data.agencies];
                    } else {
                        self._config.data = [...result.data.agencies];
                    }

                    self._firstInit();

                    self._isLoadData = true;

                    self._isLoadAllList = result.data.agencies.length === 0;

                    if (typeof callBack === "function") {
                        callBack();
                    }

                    this._startLoadData = false;
                })
                .then(() => (this._isProgress = false));
        } else {
            self._firstInit();

            self._isLoadData    = true;
            this._startLoadData = false;
        }
    }

    _reLoadingData(element, scrollContainer) {
        if (this._config.isServerSearch && !this._isLoadAllList) {
            let isViewElement = turbo.isElementInView(element, scrollContainer);

            if (isViewElement) {
                this._debounceServerSearch(this._value, true);
                this._isLoadAllList = true;
            }
        }
    }

    _initValue() {
        let value    = this._config.value,
            data     = this._config.data,
            fieldKey = this._config.fieldKey,
            item;

        if (!value || data.length === 0) {
            this._currentValue = undefined;
            this._value        = "";

            return true;
        }

        if (data[0] && typeof data[0] === "object") {
            item = data.find(itemData => itemData[fieldKey].toString() === value.toString());

            if (item) {
                this._selectItem(item, true);

                return true;
            }
        } else {
            this._selectItem(value, true);

            return true;
        }

        return false;
    }

    _initWithFirstItem(isRedraw) {
        let data = this._config.data;

        if (data.length === 0) {
            return;
        }

        this._selectItem(data[0]);

        if (isRedraw) {
            m.redraw();
        }
    }

    _firstInit(isRedraw) {
        let value = this._config.value;

        if (!this._isLoadData) {
            if (value) {
                this._initValue();
            } else if (!this._config.isCanEmpty) {
                this._initWithFirstItem(isRedraw);
            }
        }
    }

    _getData() {
        let data = this._config.data;

        if (this._searchData) {
            return this._searchData;
        }

        if (data.length === 0 && this.attrs.data && this.attrs.data.length > 0) {
            this._config.data = this.attrs.data;
            data              = this._config.data;
            this._isLoadData  = false;

            this._firstInit(true);

            this._isLoadData = true;
        }

        return data || [];
    }

    _getCurrentValue() {
        let fieldView = this._config.fieldView;

        if (this._currentValue && fieldView && this._currentValue.hasOwnProperty(fieldView)) {
            return this._currentValue[fieldView];
        }

        return this._currentValue;
    }

    _getValueOfItem(item) {
        let fieldView = this._config.fieldView;

        if (item && fieldView && item.hasOwnProperty(fieldView)) {
            return item[fieldView];
        }

        return item;
    }

    _getCurrentKeyOfItem(defaultKey) {
        let fieldKey = this._config.fieldKey;

        if (this._currentValue && fieldKey && this._currentValue.hasOwnProperty(fieldKey)) {
            return this._currentValue[fieldKey];
        }

        return defaultKey;
    }

    _getKeyOfItem(item, defaultKey) {
        let fieldKey = this._config.fieldKey;

        if (item && fieldKey && item.hasOwnProperty(fieldKey)) {
            return item[fieldKey];
        }

        return defaultKey;
    }

    _renderItem(item, index, array) {
        let value        = this._getValueOfItem(item),
            currentValue = this._getCurrentValue(),
            key          = this._getKeyOfItem(item, value),
            currentKey   = this._getCurrentKeyOfItem(currentValue),
            isCurrent    = currentKey === key,
            isSelected   = this._selectedIndex === index,
            isLast       = (array.length - 1) === index;

        if (isSelected) {
            this._selectdetItem = item;
        }

        return (
            <div
                oncreate={({ dom }) => this._onCreateItem(dom, isCurrent)}
                onupdate={({ dom }) => this._onUpdateItem(dom, isSelected, isLast)}
                // eslint-disable-next-line
                className={"drop-down-item " + (isCurrent ? this._config.iconColorClass
                    ? this._config.iconColorClass
                    : "color-orange" : "color-black") + (isSelected
                    ? " selected"
                    : "") + (isLast ? " last-item" : "")}
                onclick={() => this._selectItem(item)}
            >
                {this._getTemplateItem(item, index)}
            </div>
        );
    }

    _getTemplateItem(item, index) {
        let template     = this._config.template,
            fieldView    = this._config.fieldView,
            fieldsSearch = [...this._config.fieldsSearch],
            data;

        if (item && typeof item === "object") {
            data = { ...item };
        } else {
            data = { value: item };
            fieldsSearch.push("value");
        }

        this._setHighLightFields(data, fieldsSearch, index);

        return template(data, fieldView || "value");
    }

    _setHighLightFields(item, fields, index) {
        let key = this._getKeyOfItem(item, index);

        if (this._relevanceData && this._relevanceData.length > 0 && this._relevanceData[key]) {
            let regExps = this._relevanceData[key].regExps;

            fields.forEach(field => {
                if (item.hasOwnProperty(field)) {
                    item[field] = setHighLightVnode(item[field], regExps);
                }
            });
        }
    }

    _onCreateItem(element, isCurrent) {
        let scrollContainer = element.closest(".drop-down-container");

        this._calcHeightContainer(element);

        if (isCurrent) {
            scrollIntoView(element, { scrollMode: 'if-needed', boundary: scrollContainer });

            scrollContainer.dispatchEvent(new CustomEvent("updatescroll"));
        }
    }

    _onUpdateItem(element, isSelected, isLast) {
        let scrollContainer = element.closest(".drop-down-container");

        if (isSelected) {
            scrollIntoView(element, {
                block     : "nearest",
                scrollMode: "if-needed",
                boundary  : scrollContainer
            });

            scrollContainer.dispatchEvent(new CustomEvent("updatescroll"));
        }

        if (isLast) {
            this._reLoadingData(element, scrollContainer);
        }
    }

    _toggle(e) {
        e.preventDefault();

        if (this._isOpen) {
            this._close();
        } else {
            this._open();
        }
    }

    _open() {
        if (!this._isOpen) {
            if (!this._config.isServerSearch && this.attrs.data) {
                this._config.data = this.attrs.data;
            }
        }

        this._isOpen = true;
    }

    _close() {
        this._isOpen        = false;
        this._selectedIndex = -1;
        this._selectdetItem = undefined;
    }

    _calcHeightContainer(itemEl) {
        let boxItemEl    = itemEl.getBoundingClientRect(),
            heightItemEl = boxItemEl.height;

        if (this._heightConfig.maxHeight < heightItemEl) {
            this._heightConfig.maxHeight       = heightItemEl;
            this._heightConfig.heightContainer = this._heightConfig.maxHeight * this._config.countShowItem;

            m.redraw();
        }
    }

    _selectItem(item, isInit) {
        this._currentValue  = item;
        this._value         = this._getValueOfItem(item);
        this._searchData    = undefined;
        this._relevanceData = undefined;

        if (!isInit && typeof this._config.onChange === "function") {
            this._config.onChange(item);
        }

        this._close();
    }

    _onChange(value) {
        this._value         = value;
        this._isLoadAllList = false;

        if (this._config.isServerSearch) {
            this._debounceServerSearch(value);
        } else {
            this._search(value);
        }

        this._open();
    }

    _onRemove() {
        if (this._currentValue !== undefined ||
            this._value !== "") {
            this._currentValue = undefined;
            this._value        = "";

            if (typeof this._config.onRemove === "function") {
                this._config.onRemove();
            }
        } else {
            this._currentValue = undefined;
            this._value        = "";
        }
    }

    _onKeyDown(e) {
        let keyCode    = e.which,
            countItems = this._getData().length;

        if (keyCode !== ARROW_UP && keyCode !== ARROW_DOWN && keyCode !== ENTER) {
            return;
        }

        e.preventDefault();

        if (keyCode === ARROW_UP) {
            if (this._selectedIndex < 1) {
                this._selectedIndex = countItems - 1;
            } else if (this._selectedIndex >= countItems) {
                this._selectedIndex = countItems - 1;
            } else {
                this._selectedIndex = this._selectedIndex - 1;
            }
        } else if (keyCode === ARROW_DOWN) {
            if (this._selectedIndex >= countItems - 1) {
                this._selectedIndex = 0;
            } else {
                this._selectedIndex = this._selectedIndex + 1;
            }
        } else if (keyCode === ENTER) {
            if (this._isOpen) {
                if (this._selectdetItem) {
                    this._selectItem(this._selectdetItem);
                }

                this._close();
            } else {
                this._open();
            }
        }
    }

    _getIdsOfData() {
        let data     = this._config.data,
            fieldKey = this._config.fieldKey,
            ids      = [];

        data.forEach(item => {
            if (item.hasOwnProperty(fieldKey)) {
                ids.push(item[fieldKey]);
            }
        });

        return ids;
    }

    _serverSearch(value, isReloading) {
        let data         = this._config.data,
            fieldsSearch = this._config.fieldsSearch,
            fieldKey     = this._config.fieldKey,
            searchEngine, sourceOfRegExps, ids, searchFn;

        if (!value || value.trim() === "" || value === this._getCurrentValue()) {
            this._searchReset(isReloading);

            return;
        }

        searchEngine = new SearchEngine({
            filterFragment  : value,
            data,
            filterConditions: {
                fields   : fieldsSearch,
                linkField: fieldKey
            }
        });

        sourceOfRegExps = searchEngine.getArraySourceOfRegExps();
        ids             = this._getIdsOfData();

        searchFn = () => {
            let updateData = this._config.data,
                searchData;

            searchEngine = new SearchEngine({
                filterFragment  : value,
                data            : updateData,
                filterConditions: {
                    fields   : fieldsSearch,
                    linkField: fieldKey
                }
            });

            searchData = searchEngine.search();

            this._config.data   = searchData.records;
            this._searchData    = searchData.records;
            this._relevanceData = searchData.relevanceData;
        };

        this._loadData(sourceOfRegExps, ids, searchFn);

        m.redraw();
    }

    _search(value) {
        let data         = this._config.data,
            fieldsSearch = this._config.fieldsSearch,
            fieldKey     = this._config.fieldKey,
            searchEngine, searchData;

        if (!value || value.trim() === "" || value === this._getCurrentValue()) {
            this._searchReset();

            return;
        }

        searchEngine = new SearchEngine({
            filterFragment  : value,
            data,
            filterConditions: {
                fields   : fieldsSearch,
                linkField: fieldKey
            }
        });

        searchData = searchEngine.search();

        this._searchData    = searchData.records;
        this._relevanceData = searchData.relevanceData;
    }

    _searchReset(isReloading) {
        this._searchData    = undefined;
        this._relevanceData = undefined;

        if (this._config.isServerSearch) {
            if (!isReloading) {
                this._config.data = [];
                this._isLoadData  = false;
            }

            if (!this._startLoadData) {
                this._loadData([], this._getIdsOfData());
            }
        }
    }

    _resetAction() {
        let isEdit = this._config.isEdit;

        this._close();

        if (!isEdit) {
            this._value = this._getCurrentValue() || "";
        }

        this._searchReset();

        m.redraw();
    }

    _resizeAction() {
        let selectorPopUp = "[data-popup-key='" + this._popupKey + "']",
            elementPopUp  = document.querySelector(selectorPopUp),
            isEdit        = this._config.isEdit;

        if (!elementPopUp) {
            return;
        }

        this._close();

        if (!isEdit) {
            this._value = this._getCurrentValue() || "";
        }

        this._searchReset();

        m.redraw();
    }

    _mouseDownAction(e) {
        let target         = e.target,
            selectorPopUp  = "[data-popup-key='" + this._popupKey + "']",
            selectorParent = "[data-popup-parent-key='" + this._popupKey + "']",
            elementPopUp   = document.querySelector(selectorPopUp),
            isEdit         = this._config.isEdit;

        if (!elementPopUp || target.closest(selectorPopUp) || target.closest(selectorParent)) {
            return;
        }

        this._close();

        if (!isEdit) {
            this._value = this._getCurrentValue() || "";
        }

        this._searchReset();

        m.redraw();
    }

    onBlur() {
        let onChange = this.attrs.onChange,
            isEdit   = this._config.isEdit;

        if (isEdit) {
            if (typeof onChange === "function") {
                onChange(this._value);
            }
        }
    }
}

export default DropDownList;
