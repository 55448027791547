import {store} from "_/lib/midux";
import {translate} from "_/localizations";
import AddEditDocumentTextBlock from "_/components/dialogs/addEditDocumentTextBlock";
import {
    approveItem,
    approveItemFromList, clearUpdateStack,
    cloneItem,
    createItem,
    deleteItem,
    deleteItemFromList, loadItem, loadList,
    updateItem,
    updateItemFromList
} from "_/actions/document-text-block";

const LANG = "documentTextBlock.list";

const TEXT_BLOCK_TYPE_TEXT  = 1;
const TEXT_BLOCK_TYPE_CELLS = 2;

const createItemSuccess = item => {
    turbo.plugins.modal.alert({
        title      : translate(`${LANG}.successMessageCreateTitle`),
        text       : translate(`${LANG}.successMessageCreateText`),
        iconClasses: 'success color-green fs20 mr5'
    });

    location.href = Routing.generate("text_block_edit", { id: item.id });
};

const cloneItemSuccess = item => {
    turbo.plugins.modal.alert({
        title      : translate(`${LANG}.successMessageCloneTitle`),
        text       : translate(`${LANG}.successMessageCloneText`),
        iconClasses: 'success color-green fs20 mr5'
    });

    location.href = Routing.generate("text_block_edit", { id: item.id });
};

const getStartTableCellContainer = (content = "") => {
    return {
        element: "table",
        cols   : [{ width: "100%" }],
        rows   : [{ cells: [{ colSpan: 1, rowSpan: 1, content }] }]
    }
};

const getStartContainer = (oldItem, newItem) => {
    if (+newItem.textBlockTypeId === TEXT_BLOCK_TYPE_CELLS) {
        if (+oldItem.textBlockTypeId === TEXT_BLOCK_TYPE_TEXT) {
            return getStartTableCellContainer(newItem.content);
        } else if (!oldItem.textBlockTypeId) {
            return getStartTableCellContainer(newItem.content);
        }
    }
};

const getStartContent = (oldItem, newItem) => {
    if (+newItem.textBlockTypeId === TEXT_BLOCK_TYPE_TEXT) {
        if (+oldItem.textBlockTypeId === TEXT_BLOCK_TYPE_CELLS) {
            return oldItem.container.rows[0].cells[0].content;
        }
    }
};

const getSaveData = ({ id, name, comment, textBlockTypeId }) => {
    return { id, name, comment, textBlockTypeId };
};

const onCreateItem = (groupId, registryId, newItem) => {
    let data, container;

    container = getStartContainer({}, newItem);
    data      = getSaveData(newItem);

    if (container) {
        data.container = container;
    }

    store().dispatch(createItem(groupId, registryId, data, createItemSuccess))
};

const onUpdateItem = (groupId, oldItem, newItem, isFromList) => {
    let data, container, content;

    container = getStartContainer(oldItem, newItem);
    content   = getStartContent(oldItem, newItem);
    data      = getSaveData(newItem);

    if (container) {
        data.container = container;
        data.content   = "";
    } else if (content) {
        data.container = {};
        data.content   = content;
    }

    if (content !== undefined) {
        turbo.plugins.modal.confirm(
            {
                title        : translate(`${LANG}.warningSetTextTypeTitle`),
                btnCancelText: translate("buttons.cancel"),
                btnAcceptText: translate("buttons.yes"),
                text         : translate(`${LANG}.warningSetTextTypeText`),
                iconClasses  : "font-icon warning fs24 color-orange mr5"
            },
            () => {
                store().dispatch(isFromList ? updateItemFromList(groupId, data) : updateItem(data));
                turbo.plugins.modal.closeAll();
            }
        )

        return false;
    }

    if (isFromList) {
        store().dispatch(updateItemFromList(groupId, data));
    } else {
        store().dispatch(updateItem(data));
    }
};

const onCloneItem = (oldItem, newItem) => {
    let data, container;

    container = getStartContainer(oldItem, newItem);
    data      = getSaveData(newItem);

    if (container) {
        data.container = container;
    }

    store().dispatch(cloneItem(data, cloneItemSuccess))
};

export const createItemControl = () => {
    const
        state        = store().getState(),
        groupId      = state.documentTextBlock.group.id,
        registryId   = state.documentTextBlock.group.registry.id,
        types        = state.documentTextBlock.types,
        fnCreateItem = data => onCreateItem(groupId, registryId, data);

    turbo.plugins.modal.open({
        closeOnOverlayClick: false,
        className          : "addEditTextBlock",
        vnode              : {
            view: () => <AddEditDocumentTextBlock types={types} onSave={fnCreateItem}/>
        }
    });
};

export const updateItemControl = (item, isFromList) => {
    const
        state        = store().getState(),
        groupId      = state.documentTextBlock.group.id,
        types        = state.documentTextBlock.types,
        fnUpdateItem = data => onUpdateItem(groupId, item, data, isFromList);

    turbo.plugins.modal.open({
        closeOnOverlayClick: false,
        className          : "addEditTextBlock",
        vnode              : {
            view: () => <AddEditDocumentTextBlock item={item} types={types} onSave={fnUpdateItem}/>
        }
    });
};

export const cloneItemControl = item => {
    const
        state       = store().getState(),
        types       = state.documentTextBlock.types,
        fnCloneItem = data => onCloneItem(item, data);

    turbo.plugins.modal.open({
        closeOnOverlayClick: false,
        className          : "addEditTextBlock",
        vnode              : {
            view: () => <AddEditDocumentTextBlock item={item} types={types} onSave={fnCloneItem}/>
        }
    });
};

export const approveItemControl = (item, approve, isFromList) => {
    const
        state   = store().getState(),
        groupId = state.documentTextBlock.group.id;

    store().dispatch(isFromList ? approveItemFromList(groupId, item.id, approve) : approveItem(item.id, approve));
};

export const deleteItemControl = (item, isFromList) => {
    const
        state      = store().getState(),
        groupId    = state.documentTextBlock.group.id,
        registryId = state.documentTextBlock.group.registry.id,
        backRoute  = registryId
            ? Routing.generate("text_block_index_registry", { id: registryId })
            : Routing.generate("text_block_index", { id: groupId }),
        fnCallBack = () => (location.href = backRoute);

    turbo.plugins.modal.confirm(
        {
            title        : translate(`${LANG}.warningDeleteTitle`),
            btnCancelText: translate("buttons.cancel"),
            btnAcceptText: translate("buttons.yes"),
            text         : translate(`${LANG}.warningDeleteText`) + ": " + item.name + "?",
            iconClasses  : "font-icon warning fs24 color-orange mr5"
        },
        () => store().dispatch(isFromList ? deleteItemFromList(groupId, item.id) : deleteItem(item.id, fnCallBack))
    )
};

export const setExpressionItemControl = (itemId, isFromList) => {
    const
        state      = store().getState(),
        groupId    = state.documentTextBlock.group.id,
        templateId = state.documentTextBlock.group.template.id,
        fragmentId = state.documentTextBlock.group.fragment.id;

    let LogicalExpression = turbo.components.dialogs.logicalExpression.TextBlockOfTemplate,
        logicalExpression = new LogicalExpression({
            textBlockId : itemId,
            typeDocument: "textBlock",
            templateId,
            fragmentId
        });

    logicalExpression.open();

    logicalExpression.on("save", () => {
        if (isFromList) {
            store().dispatch(loadList(groupId));
        } else {
            store().dispatch(loadItem(itemId));
        }
    });
};
