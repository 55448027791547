import {REQUEST_LIST_OBJECTS, REQUEST_OWN_REGISTRY, SET_LIST_OBJECTS, SET_OWN_REGISTRY} from "_/types/registry";

export const requestOwnRegistry = payload => ({
    type: REQUEST_OWN_REGISTRY,
    payload
});

export const setOwnRegistry = payload => ({
    type: SET_OWN_REGISTRY,
    payload
});

export const requestListObjects = payload => ({
    type: REQUEST_LIST_OBJECTS,
    payload
});

export const setListObjects = payload => ({
    type: SET_LIST_OBJECTS,
    payload
});
