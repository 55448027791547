export const getListLists = (templateId, type) => {
    let url

    if (type === "fromRegistry") {
        url =  Routing.generate("registry_object_list", {id:templateId});
    } else {
        url = Routing.generate("pattern_registry_object_list", {templateId})
    }
    return mt.get({
        url,
        loadConfig : { isShow: true, name: "ListObjectOfRegistryByLink: getListLists" },
        errorConfig: { isShow: true }
    });
};

export const getListBindings = (templateId) => {
    return mt.get({
        url        : Routing.generate("registry_template_binding_get_list", { templateId }),
        loadConfig : { isShow: true, name: "ListObjectOfRegistryByLink: getListBindings" },
        errorConfig: { isShow: true }
    });
};

export const getListFields = (templateId) => {
    return mt.get({
        url        : Routing.generate("registry_object_list_fields", { id:templateId }),
        loadConfig : { isShow: true, name: "ListObjectOfRegistryByLink: getListBindings" },
        errorConfig: { isShow: true }
    });
};

export const getListLinks = (bindingId) => {
    return mt.get({
        url        : Routing.generate("registry_template_binding_linked_registries", { bindingId }),
        loadConfig : { isShow: true, name: "ListObjectOfRegistryByLink: getListLinks" },
        errorConfig: { isShow: true }
    });
};

export const getElementsOfSimpleList = (listId, type) => {
    let url;

    if (type === "fromRegistry") {
        url =  Routing.generate("registry_object_list_field_options", {listId});
    } else {
        url = Routing.generate("pattern_registry_object_list_field_options", {listId})
    }
    return mt.get({
        url,
        loadConfig : { isShow: true, name: "ListObjectOfRegistryByLink: getElementsOfSimpleList" },
        errorConfig: { isShow: true }
    });
};

export const getListTextBlock = (listId, type) => {
    let url

    if (type === "fromRegistry") {
        url =  Routing.generate("registry_object_list_text_block_options", {listId});
    } else {
        url = Routing.generate("pattern_registry_object_list_text_block_options", {listId})
    }
    return mt.get({
        url,
        loadConfig : { isShow: true, name: "ListObjectOfRegistryByLink: getListTextBlock" },
        errorConfig: { isShow: true }
    });
};

export const saveList = (templateId, data, type) => {
    let url

    if (type === "fromRegistry") {
        url =  Routing.generate("registry_object_list_save", {id:templateId});
    } else {
        url = Routing.generate("pattern_registry_object_list_save", {templateId})
    }

    return mt.post({
        url,
        data,
        loadConfig : { isShow: true, name: "ListObjectOfRegistryByLink: saveList" },
        errorConfig: { isShow: true }
    });
};

export const deleteList = (listId, type) => {
    let url

    if (type === "fromRegistry") {
        url =  Routing.generate("registry_object_list_delete", {listId});
    } else {
        url = Routing.generate("pattern_registry_object_list_delete", {listId})
    }

    return mt.post({
        url,
        loadConfig : { isShow: true, name: "ListObjectOfRegistryByLink: deleteList" },
        errorConfig: { isShow: true }
    });
};

export const getTypesOfScheme = () => {
    return mt.get({
        url        : Routing.generate("pattern_registry_object_list_types"),
        loadConfig : { isShow: true, name: "ListObjectOfRegistryByLink: getTypesOfScheme" },
        errorConfig: { isShow: true }
    });
};

export const getTypesOfView = () => {
    return mt.get({
        url        : Routing.generate("pattern_registry_object_list_views"),
        loadConfig : { isShow: true, name: "ListObjectOfRegistryByLink: getTypesOfView" },
        errorConfig: { isShow: true }
    });
};

export const getListSeparators = () => {
    return mt.get({
        url        : Routing.generate("pattern_registry_object_list_separators"),
        loadConfig : { isShow: true, name: "ListObjectOfRegistryByLink: getListSeparators" },
        errorConfig: { isShow: true }
    });
};

export const saveScheme = (listId, data, type) => {
    let url

    if (type === "fromRegistry") {
        url =  Routing.generate("registry_object_list_scheme_save", {listId});
    } else {
        url = Routing.generate("pattern_registry_object_list_scheme_save", {listId})
    }

    return mt.post({
        url,
        data,
        loadConfig : { isShow: true, name: "ListObjectOfRegistryByLink: saveScheme" },
        errorConfig: { isShow: true }
    });
};

export const deleteScheme = (schemeId, type) => {
    let url

    if (type === "fromRegistry") {
        url =  Routing.generate("registry_object_list_scheme_delete", {schemeId});
    } else {
        url = Routing.generate("pattern_registry_object_list_scheme_delete", {schemeId})
    }

    return mt.post({
        url,
        loadConfig : { isShow: true, name: "ListObjectOfRegistryByLink: deleteScheme" },
        errorConfig: { isShow: true }
    });
};
