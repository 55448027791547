import "mithril";
import Component from "_/lib/Component";
import {translate} from "_/localizations";
import ChooseSimple from "_/components/common/ChooseSimple";
import NumberInput from "_/components/dialogs/printProperties/components/NumberInput";

class BaseSettings extends Component {
    view() {
        const { controlsSettings, printSettings, fonts } = this.attrs;

        let statusApproved = turbo.lib.checkApprovedDocumentTemplate();

        const
            {
                fontSizeStep        = 1,
                fontSizeMin         = 5,
                fontSizeMax         = 18,
                firstLineIndentStep = 1,
                firstLineIndentMin  = 0,
                firstLineIndentMax  = 20,
                lineSpacingStep     = 0.1,
                lineSpacingMin      = 0.5,
                lineSpacingMax      = 3
            } = controlsSettings;

        return [
            <div className="print-properties__body-row">
                <div className="print-properties__body-item wl100">
                    <label className="checkbox-radio wl100">
                        <input
                            type="checkbox"
                            checked={printSettings.isDocumentTitleBold}
                            onchange={() => this.onChangeRadioDefaultValue("isDocumentTitleBold")}
                            disabled={statusApproved}
                        />
                        <i/>
                        <span className="fs14 ml5">
                            {translate("printProperties.titleBold")}
                        </span>
                    </label>
                </div>
            </div>,

            <div className="print-properties__body-row">
                <div className="print-properties__body-item">
                    <div className="text-bold fs18">
                        {translate("printProperties.textTitle")}
                    </div>
                </div>
            </div>,

            <div className="print-properties__body-row">
                <div className="print-properties__body-item">
                    <label className="print-properties__font-item">
                        <span className="pb5 fs14">
                            {translate("printProperties.fontFamily")}
                        </span>

                        <ChooseSimple
                            data-popup-key="titleFontName"
                            data={statusApproved ? [] : fonts}
                            onChange={item => this.onChangeDefaultValue(item.id, "titleFontName")}
                            isCanEmpty={false}
                            isButton={false}
                            countShowItems={5}
                            value={printSettings.titleFontName}
                            disabled={statusApproved}
                        />
                    </label>
                </div>

                <div className="print-properties__body-item">
                    <label className="print-properties__font-size-item">
                        <span className="pb5 fs14">
                            {translate("printProperties.fontSize")}
                        </span>

                        <NumberInput
                            step={fontSizeStep}
                            min={fontSizeMin}
                            max={fontSizeMax}
                            prefix={'px'}
                            value={printSettings.titleFontSize}
                            onChange={value => this.onChangeDefaultValue(value, "titleFontSize")}
                            disabled={statusApproved}
                        />
                    </label>
                </div>
            </div>,

            <div className="print-properties__body-row">
                <div className="print-properties__body-item">
                    <div className="text-bold fs18">
                        {translate("printProperties.text")}
                    </div>
                </div>
            </div>,

            <div className="print-properties__body-row">
                <div className="print-properties__body-item">
                    <label className="print-properties__font-item">
                        <span className="pb5 fs14">
                            {translate("printProperties.fontFamily")}
                        </span>

                        <ChooseSimple
                            data-popup-key="textFontName"
                            data={statusApproved ? [] : fonts}
                            onChange={item => this.onChangeDefaultValue(item.id, "textFontName")}
                            isCanEmpty={false}
                            isButton={false}
                            countShowItems={5}
                            value={printSettings.textFontName}
                            disabled={statusApproved}
                        />
                    </label>
                </div>

                <div className="print-properties__body-item">
                    <label className="print-properties__font-size-item">
                        <span className="pb5 fs14">
                            {translate("printProperties.fontSize")}
                        </span>

                        <NumberInput
                            step={fontSizeStep}
                            min={fontSizeMin}
                            max={fontSizeMax}
                            prefix={'px'}
                            value={printSettings.textFontSize}
                            onChange={value => this.onChangeDefaultValue(value, "textFontSize")}
                            disabled={statusApproved}
                        />
                    </label>
                </div>
            </div>,

            <div className="print-properties__body-row">
                <div className="print-properties__body-item">

                </div>
            </div>,

            <div className="print-properties__body-row">
                <div className="print-properties__body-item">
                    <label className="print-properties__interval-item">
                        <span className="pb5 fs14">
                            {translate("printProperties.firstLineIndent")}
                        </span>

                        <NumberInput
                            step={firstLineIndentStep}
                            min={firstLineIndentMin}
                            max={firstLineIndentMax}
                            prefix={'pt'}
                            value={printSettings.firstLineIndent}
                            onChange={value => this.onChangeDefaultValue(value, "firstLineIndent")}
                            disabled={statusApproved}
                        />
                    </label>
                </div>

                <div className="print-properties__body-item">
                    <label className="print-properties__interval-item">
                        <span className="pb5 fs14">
                            {translate("printProperties.lineSpacing")}
                        </span>

                        <NumberInput
                            step={lineSpacingStep}
                            min={lineSpacingMin}
                            max={lineSpacingMax}
                            prefix={'pt'}
                            value={printSettings.lineSpacing}
                            onChange={value => this.onChangeDefaultValue(value, "lineSpacing")}
                            disabled={statusApproved}
                        />
                    </label>
                </div>
            </div>,

            <div className="print-properties__body-row">
                <div className="print-properties__body-item wl100">
                    <label className="checkbox-radio wl100">
                        <input
                            type="checkbox"
                            checked={printSettings.isNotBreakParagraph}
                            onchange={() => this.onChangeRadioDefaultValue("isNotBreakParagraph")}
                            disabled={statusApproved}
                        />
                        <i/>
                        <span className="fs14 ml5">
                            {translate("printProperties.isNotBreakParagraph")}
                        </span>
                    </label>
                </div>
            </div>,

            <div className="print-properties__body-row">
                <div className="print-properties__body-item wl100">
                    <label className="checkbox-radio wl100">
                        <input
                            type="checkbox"
                            checked={printSettings.isOrphanLineBan}
                            onchange={() => this.onChangeRadioDefaultValue("isOrphanLineBan")}
                            disabled={statusApproved}
                        />
                        <i/>
                        <span className="fs14 ml5">
                            {translate("printProperties.isOrphanLineBan")}
                        </span>
                    </label>
                </div>
            </div>,

            <div className="print-properties__body-row">
                <div className="print-properties__body-item wl100">
                    <label className="checkbox-radio wl100">
                        <input
                            type="checkbox"
                            checked={printSettings.showSignatories}
                            onchange={() => this.onChangeRadioDefaultValue("showSignatories")}
                            disabled={statusApproved}
                        />
                        <i/>
                        <span className="fs14 ml5">
                            {translate("printProperties.showSignatories")}
                        </span>
                    </label>
                </div>
            </div>,

            <div className="print-properties__body-row">
                <div className="print-properties__body-item wl100">
                    <label className="checkbox-radio wl100">
                        <input
                            type="checkbox"
                            checked={printSettings.preparedBy}
                            onchange={() => this.onChangeRadioDefaultValue("preparedBy")}
                            disabled={statusApproved}
                        />
                        <i/>
                        <span className="fs14 ml5">
                            {translate("printProperties.preparedBy")}
                        </span>
                    </label>
                </div>
            </div>
        ];
    }

    onChangeDefaultValue(val, type) {
        const { onChangeValue } = this.attrs;

        if (typeof onChangeValue === "function") {
            onChangeValue(val, type);
        }
    }

    onChangeRadioDefaultValue(type) {
        const { onChangeRadioValue } = this.attrs;

        if (typeof onChangeRadioValue === "function") {
            onChangeRadioValue(type);
        }
    }
}

export default BaseSettings;
