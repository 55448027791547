import m from 'mithril';

export default class Logo {
    view({ attrs }) {
        return (
            <a
                className="toolbar-table-row__coll toolbar__top-panel-logo"
                href={attrs.route}
                title={attrs.title}
                onclick={e => this.onClick(e, attrs.checkConditions)}
            >
                <img src={attrs.imgUrl} alt="logo-small"/>
            </a>
        )
    }

    onClick(event, checkConditions) {
        if (typeof checkConditions === "function") {
            event.preventDefault();
            checkConditions();
        }
    }
}
