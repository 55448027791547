import "mithril";
import {connect} from "_/lib/midux";
import className from "classnames";
import Component from "_/lib/Component";
import {translate} from "_/localizations";
import {Container} from "_/components/plugins/ScrollBar";
import Button from "_/components/plugins/Button";
import ScrollBar from "_/components/plugins/ScrollBar/lib/ScrollBar";
import ManagementHeaderPanel from "./ManagementHeaderPanel";
import ContextMenu from "_/components/widgets/ContextMenu";
import {
    setCurrentStep,
    addTempStep,
    requestMoveStep,
    requestMoveQuestion,
    requestCopyStep
} from "_/actions/questionnaire";
import {
    DIRECTION_BACKWARD,
    DIRECTION_FORWARD,
    MODE_STEP_EDIT,
    MODE_STEP_VIEW,
    TMP_STEP_ID
} from "_/containers/questionnaire/constants";

const TurboDnD = turbo.plugins.TurboDnD;

const mapStateToProps = state => ({
    steps: state.questionnaire.steps,
    currentStep: state.questionnaire.currentStep,
    info: state.currentInfo,
    currentQuestion: state.questionnaire.currentQuestion
});

const mapActionsToProps = {
    setCurrentStep,
    addTempStep,
    requestMoveStep,
    requestMoveQuestion,
    requestCopyStep
};

class Header extends Component {
    oninit() {
        this.idExpressionMenu = null;
        this.position = null;

        document.addEventListener("click", () => this.hideExpressionMenu());
    }

    view() {
        const {isEdit = true} = this.attrs.info;
        const {id: currentStepId, mode} = this.attrs.currentStep;
        const steps = this.attrs.steps;
        const isEditQuestion = !!this.attrs.currentQuestion.id;

        return (
            <div className="turbo-questionnaire__header" onmouseleave={() => this.hideExpressionMenu()}>
                <div className="turbo-tabs__container-flex">
                    <Container className="turbo-scrollbar_hovered" isScrollHasY={false} isScrollHasX={true}>
                        <div className="turbo-tabs__group-flex">
                            {
                                steps.map(step => {
                                    const isEditMode = (mode === MODE_STEP_EDIT && currentStepId !== step.id)
                                        || isEditQuestion;
                                    const tabClasses = className(
                                        "turbo-tabs__tumbler",
                                        {current: currentStepId === step.id},
                                        {disabled: isEditMode}
                                    );

                                    return (
                                        <TurboDnD.DragAndDropItem
                                            key={step.id}
                                            className="inline-block"
                                            with="step"
                                            name="step"
                                            x-axis={true}
                                            ondragstart={(event, dataTransfer) => dataTransfer.setData("step", step.id)}
                                            ondropleft={(event, dataTransfer) =>
                                                this.onStepDrop(dataTransfer, step.id, 0)}
                                            ondropright={(event, dataTransfer) =>
                                                this.onStepDrop(dataTransfer, step.id, 1)}
                                        >
                                            <TurboDnD.Droppable
                                                className="pl5 pr5"
                                                name="question"
                                                ondrop={(event, dataTransfer) =>
                                                    this.onQuestionStepDrop(dataTransfer, step.id)}
                                                data-prevent-drop={step.id === currentStepId}
                                            >
                                                <div className="text-nowrap">
                                                    <div
                                                        oncreate={({dom}) => this.scrollToNewStep(dom, step.id)}
                                                        className={tabClasses}
                                                        onclick={() => this.onChangeStep(step)}
                                                        onmouseenter={event => this.showExpressionMenu(step, event)}
                                                    >
                                                        {step.name}
                                                    </div>
                                                </div>
                                            </TurboDnD.Droppable>
                                            <div style={{"position": "fixed", "z-index": "999999"}}>
                                                {
                                                    this.idExpressionMenu === `${step.id}-copyStep` && isEdit
                                                        ? (
                                                            <ContextMenu
                                                                step={step}
                                                                type="step"
                                                                onHideMenu={() => this.hideExpressionMenu()}
                                                                onOpenExpression={(src) => this.pasteStep(src, step.id)}
                                                                position={this.position}
                                                            />
                                                        )
                                                        : null
                                                }
                                            </div>
                                        </TurboDnD.DragAndDropItem>
                                    );
                                })
                            }
                        </div>
                    </Container>
                    <Button
                        className="turbo-button__size_md color-blue fs20"
                        title={translate("questionnaire.addStep")}
                        disabled={mode === MODE_STEP_EDIT || isEditQuestion || !isEdit}
                        onclick={() => this.addNewStep()}
                    >
                        <Button.Icon className="circle-plus block"/>
                    </Button>
                </div>
                <ManagementHeaderPanel/>
            </div>
        );
    }

    onChangeStep(nextStep) {
        const {id: prevId, mode} = this.attrs.currentStep;
        const {documentType, documentId} = this.attrs.info;
        const isEditQuestion = !!this.attrs.currentQuestion.id;

        if (mode === MODE_STEP_VIEW && prevId !== nextStep.id && !isEditQuestion) {
            const prevIndex = this.attrs.currentStep.index;
            const nextIndex = nextStep.index;
            const direction = nextIndex > prevIndex ? DIRECTION_FORWARD : DIRECTION_BACKWARD;
            const keyStorage = `CURRENT_STEP_${documentType}_${documentId}`;

            turbo.saveTextToLocalStorage(keyStorage, nextStep.id);

            this.attrs.actions.setCurrentStep({...nextStep, direction});
        }
    }

    showExpressionMenu(step, event) {
        const {id} = step;

        event.preventDefault();

        this.idExpressionMenu = `${id}-copyStep`;
        this.position = {
            rule: {
                x: "left",
                xCount: "0px",
                y: "top",
                yCount: "0px"
            }
        };
    }

    hideExpressionMenu() {
        if (this.idExpressionMenu) {
            this.idExpressionMenu = null;
            this.position = null;
            setTimeout(() => m.redraw(), 0);
        }
    }

    addNewStep() {
        const countSteps = this.attrs.steps.length;
        const tmpName = `${translate("questionnaire.prefixNewNameStep")} ${countSteps + 1}`;
        const newStep = {
            id: TMP_STEP_ID,
            mode: MODE_STEP_EDIT,
            direction: DIRECTION_FORWARD,
            tmpName,
            name: "",
            questions: [],
            expression: null
        };

        this.attrs.actions.addTempStep(tmpName);
        this.attrs.actions.setCurrentStep(newStep);
    }

    pasteStep(src, dst) {
        const {documentType, documentId} = this.attrs.info;

        let srcId = src.id,
            questionId;

        if (src.questionStepId) {
            srcId = src.questionStepId;
            questionId = src.id;
            this.attrs.actions.requestCopyStep({srcId, dst, documentType, documentId, questionId});
        } else {
            this.attrs.actions.requestCopyStep({srcId, dst, documentType, documentId});
        }
    }

    scrollToNewStep(stepElement, stepId) {
        if (stepId === TMP_STEP_ID) {
            ScrollBar.scrollElementToXCenter({element: stepElement, smooth: true});
        }
    }

    onStepDrop(dataTransfer, stepId, shift) {
        const {documentType, documentId} = this.attrs.info;
        const dragStepId = dataTransfer.getData("step");

        let nextStepId = stepId;

        if (shift === 1) {
            const steps = this.attrs.steps;
            const indexDropStep = steps.findIndex(step => step.id === stepId)

            nextStepId = (steps[indexDropStep + shift] || {}).id;
        }

        this.attrs.actions.requestMoveStep({documentType, documentId, stepId: dragStepId, nextStepId});
    }

    onQuestionStepDrop(dataTransfer, stepId) {
        const {documentType, documentId} = this.attrs.info;
        const dragQuestionId = dataTransfer.getData("question");

        this.attrs.actions.requestMoveQuestion({
            documentType,
            documentId,
            data: {id: dragQuestionId, stepId}
        });
    }
}

export default connect(mapStateToProps, mapActionsToProps)(Header);
