import "mithril";
import classNames from "classnames";
import Component from "lib/Component";
import {Container as Scroll} from 'components/plugins/ScrollBar';

class MultiDropDownList extends Component {
    oninit() {
        this.model = [];
    }

    view() {
        const { disabled = false } = this.attrs;
        const containerClasses     = classNames("bordered", { disabled });

        this.model = this.generateModel();

        return (
            <div className={containerClasses}>
                <Scroll isDisableOutScroll={true} className="form-item-checkbox-group">
                    {this.model.map(item => (this.renderItem(item)))}
                </Scroll>
            </div>
        );
    }

    renderItem(item) {
        const { disabled = false }               = this.attrs;
        const { index, item: { name }, isCheck } = item;

        return (
            <div className="form-item-checkbox-group__item" key={index}>
                <label className="checkbox-radio">
                    <input
                        checked={isCheck}
                        type="checkbox"
                        onchange={(e) => this.onChange(e.target.checked, index)}
                        disabled={disabled}
                    />
                    <i className="mr5"/>
                    <span>{name}</span>
                </label>
            </div>
        );
    }

    generateModel() {
        let { values = [], data = [] } = this.attrs,
            model                      = [],
            currentIndex               = 0;

        values = values ? values : [];

        data.forEach(item => {
            let itemId    = item.id || item.name || item,
                itemName  = item.name || item,
                modelItem = {
                    index  : currentIndex,
                    item   : { id: itemId, name: itemName },
                    isCheck: values.includes(itemId)
                };

            model.push(modelItem);

            currentIndex++;
        });

        return model;
    }

    onChange(isAdd, index) {
        let { onchange } = this.attrs,
            newValues    = [];

        this.model[index].isCheck = isAdd;

        this.model.forEach(item => {
            if (item.isCheck) {
                newValues.push(item.item.id);
            }
        });

        if (typeof onchange === "function") {
            onchange(newValues);
        }
    }
}

export default MultiDropDownList;
