import turbo from "_/lib/turbo";

/**
 * Метод преобразует простую табличную модель в модель с идентификаторами
 *
 * @param {object} tableModel - изначальная табличная модель (она модифицироваться не будет)
 * @param {string} prefix     - префикс для идентификаторов
 *
 * @returns {object}
 */
export const getTableModelWithId = (tableModel, prefix = "") => {
    let model = turbo.cloneObject(tableModel);

    model.cols.forEach((col, index) => {
        col.id = `${prefix}col_${index}`;
    });

    model.rows.forEach((row, indexRow) => {
        row.id = `${prefix}row_${indexRow}`;

        row.cells.forEach((cell, indexCol) => {
            cell.id = `${prefix}cell_${indexRow}_${indexCol}`;
        });
    });

    return model;
};

/**
 * Метод возвращает следующий индекс колонки после текущей ячейки
 *
 * @param {object} matrixRow       - матрица строки
 * @param {number} currentIndexCol - текущий индекс колонки
 *
 * @returns {number}
 */
const getNextIndexCol = (matrixRow, currentIndexCol) => {
    let nextIndexCol = currentIndexCol;

    if (matrixRow) {
        while (matrixRow.cells[nextIndexCol]) {
            nextIndexCol++;
        }
    }

    return nextIndexCol;
};

/**
 * Метод получает массив идентификаторов строк которые входят в состав ячейки
 *
 * @param {Array} rows       - массив строк
 * @param {number} indexRow  - начальный идентификатор строки
 * @param {number} deltaRow  - смещение по индексу строк
 *
 * @returns {Array}
 */
const getRowsByCell = (rows, indexRow, deltaRow) => {
    let rowsByCell = [];

    for (let index = indexRow; index <= indexRow + deltaRow; index++) {
        rowsByCell.push(rows[index].id);
    }

    return rowsByCell;
};

/**
 * Метод получает массив идентификаторов колонок которые входят в состав ячейки
 *
 * @param {Array} cols      - массив колонок
 * @param {number} indexCol - начальный индекс колонки
 * @param {number} deltaCol - смещение по индексу колонок
 *
 * @returns {Array}
 */
const getColsByCell = (cols, indexCol, deltaCol) => {
    let colsByCell = [];

    for (let index = indexCol; index <= indexCol + deltaCol; index++) {
        colsByCell.push(cols[index].id);
    }

    return colsByCell;
};

/**
 * Метод формирует частичную матрицу по колонкам
 *
 * @param {object} matrix         - хранилище матрицы
 * @param {number} indexRow       - индекс строки
 * @param {number} startIndexCol  - начальный индекс колонки
 * @param {number} endIndexCol    - конечный индекс колонки
 * @param {object} cellModel      - модель ячейки
 */
const setMatrixByCol = (matrix, indexRow, startIndexCol, endIndexCol, cellModel) => {
    for (let index = startIndexCol; index <= endIndexCol; index++) {
        matrix[indexRow].cells[index] = cellModel;
    }
};

/**
 * Метод формирует частичную матрицу по строкам
 *
 * @param {object} matrix         - хранилище матрицы
 * @param {number} startIndexRow  - начальный индекс строки
 * @param {number} endIndexRow    - конечный индекс строки
 * @param {number} startIndexCol  - начальный индекс колонки
 * @param {number} endIndexCol    - конечный индекс колонки
 * @param {object} cellModel      - модель ячейки
 */
const setMatrixByRow = (matrix, startIndexRow, endIndexRow, startIndexCol, endIndexCol, cellModel) => {
    for (let index = startIndexRow; index <= endIndexRow; index++) {
        if (!matrix[index]) {
            matrix[index] = { cells: [] };
        }

        setMatrixByCol(matrix, index, startIndexCol, endIndexCol, cellModel);
    }
}

/**
 * Метод получает матрицу ячеек таблицы, матрица помогает пониать какие ячейки соседствуют с другими
 *
 * @param {object} tableModel - модель таблицы с идентификаторами
 *
 * @returns {Array}
 */
export const getMatrixCells = tableModel => {
    let matrix = [],
        rows   = tableModel.rows,
        cols   = tableModel.cols;

    for (let indexRow = 0; indexRow < rows.length; indexRow++) {
        let row      = rows[indexRow],
            indexCol = 0;

        if (!matrix[indexRow]) {
            matrix[indexRow] = { cells: [] };
        }

        if (!matrix[indexRow].id) {
            matrix[indexRow].id = row.id;
        }

        for (let indexCell = 0; indexCell < row.cells.length; indexCell++) {
            let cell     = row.cells[indexCell],
                deltaCol = cell.colSpan - 1,
                deltaRow = cell.rowSpan - 1,
                rowsByCell, cellModel, colsByCell;

            indexCol   = getNextIndexCol(matrix[indexRow], indexCol);
            rowsByCell = getRowsByCell(rows, indexRow, deltaRow);
            colsByCell = getColsByCell(cols, indexCol, deltaCol);

            cellModel = {
                ...cell,
                leftCol       : cols[indexCol].id,
                rightCol      : cols[indexCol + deltaCol].id,
                leftIndexCol  : indexCol,
                rightIndexCol : indexCol + deltaCol,
                topRow        : row.id,
                bottomRow     : rows[indexRow + deltaRow].id,
                topIndexRow   : indexRow,
                bottomIndexRow: indexRow + deltaRow,
                rows          : rowsByCell,
                cols          : colsByCell
            };

            setMatrixByRow(
                matrix,
                indexRow,
                indexRow + deltaRow,
                indexCol,
                indexCol + deltaCol,
                cellModel
            );

            indexCol = indexCol + deltaCol;
            indexCol++;
        }
    }

    return matrix;
};

/**
 * Метод получает ассоциативную модель таблицы на основе матрицы
 *
 * @param {object} tableModel - простая модель таблицы с идентификаторами
 *
 * @returns {object}
 */
export const getAssociativeModel = tableModel => {
    let model  = { cols: {}, cells: {}, rows: {}, table: { rows: [], cols: [] } },
        matrix = getMatrixCells(tableModel),
        height, tmp;

    tableModel.cols.forEach(col => {
        model.table.cols.push(col.id);

        model.cols[col.id] = turbo.cloneObject(col);
    });

    for (let indexRow = 0; indexRow < matrix.length; indexRow++) {
        let row   = matrix[indexRow],
            cells = row.cells;

        model.table.rows.push(row.id);

        tmp = {};
        tmp.cells = [];
        if (tableModel.rows[indexRow].height) {
            tmp.height = tableModel.rows[indexRow].height;
        }

        model.rows[row.id] = { ...tmp };

        for (let indexCell = 0; indexCell < cells.length; indexCell++) {
            let cell = cells[indexCell];

            if (!model.cells[cell.id]) {
                let cellModel = {
                    ...cell,
                    next: [],
                    prev: [],
                    up  : [],
                    down: []
                };

                model.rows[row.id].cells.push(cell.id);

                model.cells[cell.id] = cellModel;
            }

            if (indexCell < (cells.length - 1) && cells[indexCell + 1].id !== cell.id) {
                model.cells[cell.id].next.push(cells[indexCell + 1].id);
            }

            if (indexCell > 0 && cells[indexCell - 1].id !== cell.id) {
                model.cells[cell.id].prev.push(cells[indexCell - 1].id);
            }

            if (indexRow > 0 && matrix[indexRow - 1].cells[indexCell].id !== cell.id) {
                model.cells[cell.id].up.push(matrix[indexRow - 1].cells[indexCell].id);
            }

            if (indexRow < (matrix.length - 1) && matrix[indexRow + 1].cells[indexCell].id !== cell.id) {
                model.cells[cell.id].down.push(matrix[indexRow + 1].cells[indexCell].id);
            }
        }
    }

    return model;
};

/**
 * Метод получает обычную табличную модель из ассоциативной
 *
 * @param {object} associativeModel - ассоциативная модель таблицы
 *
 * @returns {object}
 */
export const getTableModel = associativeModel => {
    let tableModel = { cols: [], rows: [], element: "table" };

    associativeModel.table.cols.forEach(coldId => {
        let col = associativeModel.cols[coldId];

        tableModel.cols.push(turbo.cloneObject(col));
    });

    associativeModel.table.rows.forEach(rowId => {
        let row      = associativeModel.rows[rowId],
            tmp      = {},
            cellIds  = [...row.cells],
            tableRow;

        if (row.height) {
            tmp.height = row.height;
        }

        tmp.id = rowId;
        tableRow = { ...tmp };

        tableRow.cells = [];

        cellIds.forEach(cellId => {
            let cell = associativeModel.cells[cellId];

            tableRow.cells.push(turbo.cloneObject(cell));
        })

        tableModel.rows.push(turbo.cloneObject(tableRow));
    });

    return tableModel;
};
