import m from 'mithril';

export default class ToolsTrigger {
    isRender(item) {
        return item.hasOwnProperty('isRender') ? item.isRender : true;
    }

    hasTriggers(item) {
        return !!item.triggers.filter(entry => this.isRender(entry)).length;
    }

    getTriggerClassName(item) {
        return [
            "toolbar__trigger toolbar__trigger_target",
            ` ${item.className || ""}`
        ].join('');
    }

    getMenuItemClassName(item, isActiveFn, prefix) {
        return [
            "toolbar__trigger toolbar__trigger-border mr10",
            ` ${item.className || ''}`
        ].join('');
    }

    view({ attrs }) {
        let { item, isActiveFn, prefix } = attrs;

        return (
            <If condition={!!item && this.isRender(item) && this.hasTriggers(item)}>
                <div className={`toolbar__triggers-left-panel ${attrs.className || ""}`}>
                    <For each="trigger" of={item.triggers}>
                        <If condition={this.isRender(trigger)}>
                            <If condition={!isActiveFn(trigger.name, prefix)}>
                                <div
                                    className={this.getMenuItemClassName(trigger, isActiveFn, prefix)}
                                    onclick={() => trigger.onclick(trigger)}
                                    title={trigger.label}
                                >
                                    <i className={`font-icon toolbar__trigger-icon ${trigger.icon || ""}`}/>
                                </div>
                            </If>
                            <If condition={isActiveFn(trigger.name, prefix)}>
                                <div
                                    className="toolbar__trigger toolbar__left-panel-indicator"
                                    onclick={() => trigger.onclick(trigger)}
                                >
                                    <i className={`font-icon toolbar__trigger-icon pr5 ${trigger.icon || ""}`}/>
                                    {trigger.label}
                                    <i className="font-icon cancel toolbar__left-panel-close"/>
                                </div>
                            </If>
                        </If>
                    </For>
                </div>

            </If>
        )
    }
}
