import {translate} from "../../../localizations";


const noSaveContentConfirm = ({ accept, extra }) => {
    turbo.plugins.modal.confirm(
        {
            title        : 'Внимание',
            btnCancelText: translate("buttons.cancel"),
            btnAcceptText: translate("buttons.yes"),
            btnExtraText : translate("buttons.no"),
            text         : translate("common.noSaveContentConfirm"),
            iconClasses  : "font-icon warning fs24 color-orange mr5",
            class        : "noSaveContentConfirm",
            beforeClose  : () => {
                turbo.plugins.modal.close(document.querySelector(".noSaveContentConfirm"), true);

                return false;
            }
        },
        () => {
            turbo.plugins.modal.close(document.querySelector(".noSaveContentConfirm"), true);
            accept();
        },
        null,
        () => {
            turbo.plugins.modal.close(document.querySelector(".noSaveContentConfirm"), true);
            extra();
        },
    )
}

export default noSaveContentConfirm;
