import "mithril";
import {connect} from "_/lib/midux";
import Component from "_/lib/Component";
import {translate} from "_/localizations";
import {setCurrentQuestion} from "_/actions/questionnaire";
import {
    FIELD_OF_OBJECT_OR_OWN_REGISTRY as OWN_FIELD,
    NONE,
    REQIURED
} from "_/constants/questionsOfRelatedData";
import {FIELD_TEXT} from "_/constants/typesOfRegistryFIelds";
import {openRelatedDataModal} from "_/service/questionnaire";

const mapStateToProps = state => ({
    question   : state.questionnaire.currentQuestion,
    ownRegistry: state.registry.ownRegistry,
});

const mapActionsToProps = {
    setCurrentQuestion
};

class QuestionType extends Component {
    view() {
        const { question } = this.attrs;
        const { type, required }     = question;

        return (
            <div className="turbo-question__item-types">
                <div className="turbo-question__part-title">
                    {translate("questionnaire.typeQuestionTitle")}
                </div>
                <div className="turbo-question__item-type">
                    <label className="checkbox-radio">
                        <input
                            type="checkbox"
                            value={question.required}
                            name={question.required ? "required" : "regular"}
                            checked={required === true}
                            onchange={() => this.onChangeRequred(!required)}
                        />
                        <i className="mr5" />
                        <span>{translate("questionnaire.reqiredFiledType")}</span>
                    </label>
                </div>
                <div className="turbo-question__item-type">
                    <label className="checkbox-radio">
                        <input
                            type="radio"
                            value={OWN_FIELD}
                            name={`question-type-${question.id}`}
                            checked={+type === OWN_FIELD}
                            onchange={event => this.onChangeType(event)}
                        />
                        <i className="mr5"/>
                        <span>{translate("questionnaire.ownFiledType")}</span>
                    </label>
                </div>
                <div className="turbo-question__item-type">
                    <label className="checkbox-radio">
                        <input
                            type="radio"
                            value={NONE}
                            name={`question-type-${question.id}`}
                            checked={+type !== OWN_FIELD}
                            onchange={event => this.onChangeType(event)}
                        />
                        <i className="mr5"/>
                        <span>{translate("questionnaire.relatedFiledType")}</span>
                    </label>
                </div>
            </div>
        );
    }

    onChangeType(event) {
        const value       = event.target.value;
        const settings    = { type: value };
        const ownRegistry = this.attrs.ownRegistry;

        if (+value === OWN_FIELD) {
            settings.view  = "700";
            settings.value = {
                bindingId   : ownRegistry.idBinding,
                registryId  : ownRegistry.id,
                typeOfField : FIELD_TEXT,
                defaultValue: ""
            };
        } else {
            settings.value = {};
            openRelatedDataModal();
        }

        this.attrs.actions.setCurrentQuestion(settings);
    }

    onChangeRequred(value) {
        const settings    = { required: value };

        this.attrs.actions.setCurrentQuestion(settings);
    }
}

export default connect(mapStateToProps, mapActionsToProps)(QuestionType);
