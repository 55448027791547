import turbo from 'lib/turbo';
import globalState from 'lib/globalState';
import {showProcess, hideProcess} from 'lib/progressbar';
import {translate} from 'localizations';
import {closeLeftPanel, openLeftPanel} from 'actions/common/headerToolbar';
import {store} from "_/lib/midux";
import {
    checkLeftPanelOnActive,
    setLeftPanelStateToLocalStorage
} from 'service/common/headerToolbar';
import ListObjectOfRegistryByLink from "components/dialogs/listObjectsOfRegistryByLink";
import {CREATE_FRAGMENT_FROM_TEMPLATE} from "components/dialogs/addEditDocumentFragment/constants"
import ListsTextBlocksOfTemplate from "_/components/dialogs/listsTextBlocksOfTemplate";
import PrintProperties from "_/components/dialogs/printProperties";
import NoSaveContentConfirmAll from "../../components/confirms/NoSaveContentConfirmAll";

function fileDownload(id, type, hasAttachments, isProtect) {
    let route  = "turbo_pattern_generate_file",
        params = { id, type, isContract: 0, isProtect };

    if (hasAttachments) {
        turbo.plugins.modal.confirm(
            {
                title        : translate('alerts.documentHasAttachments'),
                text         : translate('alerts.choiceDownloadDocument'),
                btnAcceptText: translate('common.withAttachments'),
                btnCancelText: translate('common.currentDocument')
            },
            function() {
                params.all = 1;

                ajaxFileDownload({ route, params });
            },
            function() {
                ajaxFileDownload({ route, params });
            }
        );
    } else {
        ajaxFileDownload({ route, params });
    }
}

function getDocumentState() {
    return store().getState().documentTemplate.base;
}

function getSettings() {
    return store().getState().documentTemplate.settings;
}

function getDocumentStructureState() {
    return store().getState().documentTemplate.documentStructure;
}

const controls = {
    templateDownloadPdf(isProtect) {
        let structureState = getDocumentStructureState(),
            documentState  = getDocumentState();

        fileDownload(documentState.id, 'pdf', structureState.length > 1, isProtect);
    },

    templateDownloadDocx(isProtect) {
        let structureState = getDocumentStructureState(),
            documentState  = getDocumentState();

        fileDownload(documentState.id, 'docx', structureState.length > 1, isProtect);
    },

    templateDownloadFile() {
        let state  = getDocumentState(),
            route  = 'turbo_pattern_file_attachment',
            params = { id: state.id, load: 1 };

        window.location.href = Routing.generate(route, params);
    },

    toggleStatus() {
        let state = getDocumentState(),
            route = 'turbo_pattern_toggleStatus';

        m.request({
            method     : 'POST',
            url        : Routing.generate(route, { id: state.id }),
            headers    : { 'X-Requested-With': 'XMLHttpRequest' },
            deserialize: value => value
        }).then(() => {
            window.location.reload();

            turbo.plugins.modal.alert({
                title      : translate('documentTemplate.statusWasChanged'),
                text       : translate('alerts.waitPageReload'),
                iconClasses: 'warning color-orange fs24 mr5'
            });
        });
    },

    fragmentCreate() {
        let item = store().getState().documentTemplate.base,
            callBack, data, dialog;

        callBack = (res) => {
            turbo.plugins.modal.confirm(
                {
                    title        : translate('documentFragment.fragmentCreated'),
                    btnCancelText: translate('buttons.cancel'),
                    btnAcceptText: translate('buttons.yes'),
                    text         : translate('documentFragment.createdFragmentEdit'),
                    iconClasses  : "font-icon success fs24 color-green mr5"
                },
                function() {
                    location.href = Routing.generate("document_fragment_item_index", { id: res.id })
                }
            );
        }

        data   = {
            name     : item.title,
            comment  : translate('documentFragment.createdFrom') + item.title,
            patternId: item.id
        };
        dialog = new turbo.modals.AddEditDocumentFragment(data, CREATE_FRAGMENT_FROM_TEMPLATE, callBack);

        if (globalState.get('license')) {
            dialog.open();
        } else {
            t.components.alerts.license();
        }
    },

    getAgenciesRequest() {
        return mt.post({
            url        : Routing.generate("turbo_agency_list"),
            loadConfig : { isShow: true, name: "Template: getAgencies" },
            errorConfig: { isShow: true }
        });
    },

    createDocumentRequest(data) {
        return mt.post({
            url        : Routing.generate("turbo_create_document"),
            data,
            loadConfig : { isShow: true, name: "Template: documentCreate" },
            errorConfig: { isShow: true }
        });
    },

    redirectToDocument(data, agencyId, isNewWindow) {
        let redirectRoute = 'turbo_document_matching_item',
            paramsRoute   = {
                documentId: data.documentId,
                // eslint-disable-next-line camelcase
                agency_id : agencyId
            };

        if (isNewWindow) {
            window.open(Routing.generate(redirectRoute, paramsRoute))
        } else {
            turbo.plugins.modal.alert({
                title      : translate("documentTemplate.alerts.successCreateTitle"),
                text       : translate("documentTemplate.alerts.successCreateText"),
                iconClasses: 'success color-green fs20 mr5'
            });

            window.location.href = Routing.generate(redirectRoute, paramsRoute);
        }
    },

    documentCreate(isNewWindow = false, noSaveContentConfirm) {
        let state    = getDocumentState(),
            settings = getSettings(),
            data;

        data = {
            categoryId   : state.categoryId,
            templateId   : state.id,
            templateType : "custom",
            templateTitle: state.title
        };

        if (typeof noSaveContentConfirm === "function") {
            noSaveContentConfirm(
                null,
                () => controls.documentCreateWithData(data, !settings.documentCreatePartnerEnabled, isNewWindow)
            )
        }
    },

    documentCreateWithData(data, isSimpleCreate, isNewWindow) {
        if (!globalState.get('license')) {
            turbo.components.alerts.license();

            return;
        }

        if (isSimpleCreate) {
            let agency;

            controls.getAgenciesRequest()
                .then(result => {
                    agency         = result.data.agencies[0];
                    data.subjectId = agency.id;

                    return data;
                })
                .then(controls.createDocumentRequest)
                .then(result => controls.redirectToDocument(result, agency.id, isNewWindow));
        } else {
            let dialog = new turbo.components.dialogs.CreateDocument(data, isNewWindow);

            dialog.open();
        }
    },

    addAttachment() {
        let state = getDocumentState(),
            route = 'turbo_pattern_create',
            id    = state.isMain ? state.id : state.parentId;

        window.location.href = Routing.generate(route, { parent: id });
    },

    addAttachmentFile() {
        let state = getDocumentState();

        if (state.canAddAttachments) {
            let route = "turbo_pattern_add_file",
                id    = state.isMain ? state.id : state.parentId;

            window.location.href = Routing.generate(route, { id });
        } else {
            turbo.components.alerts.preventFileDownload();
        }
    },

    delete() {
        let state       = getDocumentState(),
            processName = "delete template",
            route       = 'turbo_pattern_delete_pattern',
            id          = state.id;

        turbo.plugins.modal.confirm(
            {
                width        : 560,
                title        : state.isMain
                    ? translate('alerts.confirmRemoveTemplate')
                    : translate('alerts.confirmRemoveAttachment'),
                iconClasses  : translate('alerts.waitPageReload'),
                btnAcceptText: translate('buttons.remove'),
                btnCancelText: translate('buttons.cancel'),
            },
            () => {
                showProcess(processName);

                m.request({
                    method     : 'POST',
                    url        : Routing.generate(route, { id }),
                    headers    : { 'X-Requested-With': 'XMLHttpRequest' },
                    deserialize: value => value
                }).then(() => {
                    hideProcess(processName);

                    window.location.href = state.backRoute;

                    turbo.plugins.modal.alert({
                        title      : state.isMain
                            ? translate('documentTemplate.templateWasRemoved')
                            : translate('documentTemplate.attachmentWasRemoved'),
                        text       : translate('alerts.waitPageReload'),
                        iconClasses: 'success color-green fs24 mr5'
                    });
                });
            }
        );
    },

    templatePrint() {
        let state       = getDocumentState(),
            processName = "prepare template print",
            route       = 'turbo_pattern_print',
            routeData   = { id: state.id, type: 'pdf' };

        showProcess(processName);

        m.request({
            method     : 'POST',
            url        : Routing.generate(route, routeData),
            headers    : { 'X-Requested-With': 'XMLHttpRequest' },
            deserialize: value => value
        }).then((response) => {
            hideProcess(processName);

            turbo.plugins.modal.open({
                htmlString: response,
                width     : 1000,
                afterLoading() {
                    let element = this.element,
                        wrapper = element.querySelector('.modal__wrapper');

                    wrapper.classList.add('bg-white');
                }
            });
        });
    },

    templateProperties() {
        let state  = store().getState().documentTemplate.base,
            dialog = new turbo.components.modals.PatternProperties({
                settings : { patternId: state.id },
                listeners: {
                    afterSave() {
                        window.location.reload();
                    }
                }
            });

        dialog.open();
    },

    templatePrintProperties() {
        let state     = getDocumentState(),
            id        = state.id,
            routeGet  = "turbo_front_pattern_get_print_settings",
            routeSave = "turbo_front_pattern_save_print_settings",
            urlGet    = Routing.generate(routeGet, { id }),
            urlSave   = Routing.generate(routeSave, { id }),
            isMain    = state.isMain;

        turbo.plugins.modal.open({
            width              : 1000,
            closeOnOverlayClick: false,
            class              : "printProperties",
            vnode              : {
                view() {
                    return (
                        <PrintProperties urlGet={urlGet} urlSave={urlSave} isMain={isMain}/>
                    )
                }
            }
        });
    },

    templateRegistryProperties() {
        let state             = getDocumentState(),
            BindingRegistries = turbo.ui.modules.registries.BindingRegistries,
            bindingRegistries = new BindingRegistries({ templateId: state.id });

        bindingRegistries.openModalWindow();
    },

    templateOwnRegistryEdit() {
        let SettingFieldsRegistry = turbo.components.modals.SettingFieldsRegistry;

        SettingFieldsRegistry.open();
    },

    templateFileReplace() {
        let state  = getDocumentState(),
            route  = 'turbo_pattern_update_file',
            params = { id: state.id },
            form   = document.createElement('form'),
            input  = document.createElement('input');

        form.action  = Routing.generate(route, params);
        form.method  = 'post';
        form.enctype = "multipart/form-data";
        form.hidden  = true;

        input.type = 'file';
        input.name = 'matching_file';

        form.appendChild(input);
        document.body.appendChild(form);

        input.addEventListener('change', function() {
            form.submit();
            form.remove();
        });

        input.click();
    },

    toggleLeftPanel(leftPanel) {
        let state      = getDocumentState(),
            isFileType = state.isFileType,
            prefix     = isFileType ? state.filePrefix : state.prefix;

        if (checkLeftPanelOnActive(leftPanel.name, prefix)) {
            store().dispatch(closeLeftPanel(leftPanel));

            setLeftPanelStateToLocalStorage(leftPanel.name, false, prefix);
        } else {
            store().dispatch(openLeftPanel(leftPanel));

            setLeftPanelStateToLocalStorage(leftPanel.name, true, prefix);
        }
    },

    listObjectsOfRegistryByLink() {
        let state      = getDocumentState(),
            templateId = state.id;

        turbo.plugins.modal.open({
            width              : 620,
            closeOnOverlayClick: false,
            vnode              : {
                view() {
                    return (
                        <ListObjectOfRegistryByLink templateId={templateId}/>
                    )
                }
            }
        });
    },

    listsTextBlocksOfTemplate() {
        let state      = getDocumentState(),
            templateId = state.id;

        turbo.plugins.modal.open({
            width              : 570,
            closeOnOverlayClick: false,
            vnode              : {
                view() {
                    return (
                        <ListsTextBlocksOfTemplate templateId={templateId}/>
                    )
                }
            }
        });
    }
}

export default controls;
