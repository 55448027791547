import EventListener from "lib/listeners/eventListener";

/**
 * Определяет величину по умолчанию для восстановления подключения.
 * Величина в миллисекундах.
 *
 * @type {number}
 * @const
 */
const DEFAULT_RECONNECT_DELAY = 1000;

let handlers = {};

/**
 * Исполняется в момент, когда нижестоящая веб-сокетная сессия
 * успешно установила подключение к удаленному серверу.
 *
 * Диспетчеризует событие "подключение".
 *
 * @param {object} session
 */
handlers["connect"] = function(session) {
    let data = {
        instance: this,
        session
    };

    this.failures = 0;

    this.listeners.dispatch("connect", data);
};

/**
 * Исполняется в момент, когда нижестоящая веб-сокетная сессия
 * потеряла подключение к удаленному серверу.
 *
 * Если в настройке был передан параметр автовосстановления подключения,
 * то попытается черз заданный (или вычисляемый интервал) подключиться
 * к веб-сокетному серверу повторно.
 *
 * Диспетчеризует событие "отключение".
 *
 * @param {number} code
 * @param {string} reason
 */
handlers["disconnect"] = function(code, reason) {
    let reconnectConfig = this.config.reconnect,
        failures        = (this.failures || 0) + 1,
        data            = {
            instance: this,
            code,
            reason
        },
        delay;

    this.session  = null;
    this.failures = failures;

    this.listeners.dispatch("disconnect", data);

    if (reconnectConfig) {
        delay = reconnectConfig.delay || DEFAULT_RECONNECT_DELAY;

        if (reconnectConfig.cumulative) {
            delay *= failures;
        } else if (reconnectConfig.strategy) {
            delay = reconnectConfig.strategy(delay, failures)
        }

        window.setTimeout(reconnect.bind(this), delay);
    }
};

/**
 * Вспомогательная функция подключения.
 * Используется как шаблон для клонирования и привязки.
 */
function reconnect() {
    this.connect();
}

/**
 * Вспомогательная функция, создающая и конфигрурирующая стэк слушателей
 * для подвязки обработчиков событий.
 *
 * @param {WampClient} instance
 *
 * @returns {EventListener}
 */
function createEventListeners(instance) {
    let listeners = new EventListener(instance);

    listeners.register("connect", "disconnect", "subscribe", "unsubscribe");

    return listeners;
}

/**
 * Перевязывает конфигурацию слушателей для на слушатели переданного
 * экземпляра WampClient.
 *
 * @param  {WampClient} instance
 * @param  {object}     listeners
 */
function bindListeners(instance, listeners) {
    let event, handler;

    for (event in listeners) {
        if (listeners.hasOwnProperty(event)) {
            handler = listeners[event];

            instance.listeners.on(event, handler);
        }
    }
}

/**
 * Выбрасывает исключение с указанным сообщением,
 * если указанное условие правдиво/выполняется.
 *
 * @param  {boolean} condition
 * @param  {string}  message
 *
 * @throws {Error}
 */
function throwIf(condition, message) {
    if (condition) {
        throw new Error(message);
    }
}

/**
 * Выбрасывает типовое исключение, если у указанного экземпляра WampClient
 * отсутствует активаня сессия подключения к серверу.
 *
 * @param  {WampClient} instance
 *
 * @throws {Error}
 */
function throwIfSessionNotExists(instance) {
    throwIf(!instance.session, "Session not exists");
}

export default class WampClient {
    // eslint-disable-next-line check-indentation
    /**
     * Конструктор.
     *
     * Принимает объект конфигурации со следующей структурой:
     * + {string} config.uri
     *     -> Адрес подключения веб-сокетной сессии. Обязательно.
     *
     * + {Object} config.reconnect
     *     -> Объект настройки переподключения. Опционально.
     *
     * + {number} config.reconnect.delay
     *     -> Интервал переподключения. Опционально. Если не указан,
     *        то компонент будет использовать значение
     *        из consts.DEFAULT_RECONNECT_DELAY.
     *
     * + {boolean} config.reconnect.cumulative
     *     -> Интервал переподключения будет умножаться на число безрезультатных
     *        попыток подключения к веб-сокетному серверу, чтобы не спамить
     *        запросами. Пример: если веб-сокет не смог подключиться 5 раз при
     *        интервале в 1 секунду, то 6 попытка подключения будет выполнена
     *        не через 1 секунду, а через 6 (6 * 1) секунд. Опционально.
     *
     * + {Function} config.reconnect.strategy
     *     -> Замыкание, при помощи которого можно задать собственную схему
     *        высчитывания времени переподключения. На вход поступят
     *        интервал переподключения (delay) и число безуспешных попыток
     *        подключения. Опционально.
     *
     * + {Object} config.listeners
     *     -> Объект, который в качестве ключей содержит имена событий,
     *        а в качестве значений содержит замыкания-обработчики событий.
     *        Поддерживаются массивы замыканий. Опционально.
     *
     * Пример:
     * {
     *     uri: 'ws://tempuri.org',
     *     reconnect: {
     *         delay:      500,
     *         cumulative: true
     *     },
     *     listeners: {
     *         connect:    function() { console.log('connect') },
     *         disconnect: [
     *             function() {
     *                 console.log('disconnect 1')
     *             },
     *             function() {
     *                 console.log('disconnect 2')
     *             }
     *         ]
     *     }
     * }
     *
     * @param {object} config
     */
    constructor(config) {
        this.config    = config || {};
        this.listeners = createEventListeners(this);

        if (config.listeners) {
            bindListeners(this, config.listeners);
        }

        this.connect();
    }

    /**
     * Осуществляет подключение веб-сокетной сессии.
     */
    connect() {
        let config = this.config;

        throwIf(!config.uri, "URI wasn't defined in configuration");

        this.session = new ab.Session(
            config.uri,
            handlers["connect"].bind(this),
            handlers["disconnect"].bind(this),
            { skipSubprotocolCheck: true }
        );
    }

    /**
     * Осуществляет отключение веб-сокетной сессии.
     */
    disconnect() {
        throwIfSessionNotExists(this);

        this.session.close();
    }

    /**
     * Осуществляет подключение канала и привязку выполнение замыкания
     * при поступлении данных по каналу.
     *
     * Диспетчеризует событие "подписка".
     *
     * @param  {string}   topic
     * @param  {Function} callback
     */
    subscribe(topic, callback) {
        throwIfSessionNotExists(this);

        this.session.subscribe(topic, callback);

        this.listeners.dispatch("subscribe", topic, callback);
    }

    /**
     * Осуществляет отвязку выполнение замыкания при поступлении данных
     * по каналу и если замыканий нет, то отключает канал.
     *
     * Диспетчеризует событие "отписка".
     *
     * @param  {string}   topic
     * @param  {Function} callback
     */
    unsubscribe(topic, callback) {
        throwIfSessionNotExists(this);

        this.session.unsubscribe(topic, callback);

        this.listeners.dispatch("unsubscribe", topic, callback);
    }
}
