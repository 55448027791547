import m from 'mithril';
import TextEllipsis from 'components/plugins/TextEllipsis';

export default ({ attrs, children, toggleAction, itemAction }) => (
    <div className={ `turbo-component-menu ${attrs.className || ''}` }>
        <div
            className="turbo-component-menu__indicator"
            onclick={ () => toggleAction() }
        >
            <i className="turbo-component-menu__icon-menu font-icon icon-menu"></i>
            <i className="turbo-component-menu__icon-cancel font-icon cancel"></i>
        </div>
        <div className="turbo-component-menu__wrapper">
            <ul className="turbo-component-menu__list">
                <For each="item" of={ children }>
                    <If condition={ item.attrs.isRender }>
                        <li className={ `turbo-component-menu__item ${ item.attrs.className || '' }` }>
                            <div
                                className="turbo-component-menu__row"
                                onclick={ e => itemAction(e, item) }
                            >
                                <div className="turbo-component-menu__icon">
                                    <i className={ `font-icon ${ item.attrs.icon || '' }` }></i>
                                </div>
                                <div className="turbo-component-menu__label">
                                    <TextEllipsis>
                                        { item.attrs.label }
                                    </TextEllipsis>
                                </div>
                            </div>

                            <Choose>
                                <When condition={ item.children && item.children.length }>
                                    <div className="turbo-component-menu__buttons">
                                        <For each="button" of={ item.children }>
                                            <If condition={ button.attrs.isRender }>
                                                <button
                                                    className={ button.attrs.className || '' }
                                                    title={ button.attrs.label }
                                                    onclick={ e => itemAction(e, button) }
                                                >
                                                    <i className={ `font-icon ${ button.attrs.icon || '' }` }></i>
                                                </button>
                                            </If>
                                        </For>
                                    </div>
                                </When>
                            </Choose>
                        </li>
                    </If>
                </For>
            </ul>
        </div>
    </div>
)
