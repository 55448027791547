import "mithril";
import Component from "_/lib/Component";

class Checkbox extends Component {
    view() {
        const { value, text, disabled = false } = this.attrs;

        return (
            <label className="checkbox-radio">
                <input
                    type="checkbox"
                    checked={value}
                    onchange={event => this.onChange(event.target.checked)}
                    disabled={disabled}
                />
                <i/>
                <span className="fs12 ml5">{text}</span>
            </label>
        );
    }

    onChange(value) {
        const { onchange } = this.attrs;

        if (typeof onchange === "function") {
            onchange(value);
        }
    }
}

export default Checkbox;
