import m from 'mithril';

export default class TextEllipsis {
    showTitle(e) {
        let target = e.target;

        e.redraw = false;

        if (target.offsetWidth < target.scrollWidth) {
            if (!target.getAttribute('title')) {
                target.setAttribute('title', target.innerText);
            }
        } else if (target.getAttribute('title')) {
            target.removeAttribute('title');
        }
    }

    view({ attrs, children }) {
        return (
            <div
                className={ `text-clipped ${ attrs.className || "" }` }
                onmouseenter={ (e) => this.showTitle(e) }
            >
                { children }
            </div>
        );
    }
}
