import {
    SET_TEMPLATE_TOOLBAR_STATE,
    OPEN_LEFT_PANEL,
    CLOSE_LEFT_PANEL
} from 'types/common/headerToolbar';

export const setState = state => ({
    type: SET_TEMPLATE_TOOLBAR_STATE,
    state
});

export const openLeftPanel = entity => ({
    type: OPEN_LEFT_PANEL,
    entity
});

export const closeLeftPanel = entity => ({
    type: CLOSE_LEFT_PANEL,
    entity
});
