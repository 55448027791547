import m from 'mithril';

export default class Indicator {
    isRender(item) {
        return item.hasOwnProperty('isRender') ? item.isRender : true;
    }

    view({ attrs }) {
        let { label, onclick, icon } = attrs;

        return (
            <If condition={ this.isRender(attrs) }>
                <div className={`toolbar__triggers-left-panel ${attrs.className || ""}`}>
                    <div
                        className="toolbar__trigger toolbar__left-panel-indicator"
                        onclick={ onclick }
                    >
                        <i className={ `font-icon pr5 ${icon || ""}` }/>
                        { label }
                        <i className="font-icon cancel toolbar__left-panel-close"/>
                    </div>
                </div>
            </If>
        )
    }
}
