import "mithril";
import classNames from "classnames";
import Component from "lib/Component";
import {Container} from 'components/plugins/ScrollBar';
import Button from "components/plugins/Button";
import PopUp from "components/plugins/PopUp";
import {translate} from "localizations";
import SearchEngine from "_/lib/searchEngine";
import {setHighLightVnode} from "_/lib/highLight";


const DEFAULT_WIDTH = 200;

class SelectDropDownList extends Component {
    oninit() {
        this._config = { ...this.attrs };

        this.model         = [];
        this._isOpen       = false;
        this._popupKey     = this._config["data-popup-key"];
        this._heightConfig = { maxHeight: 0 };
        this.values        = this.attrs.values
        this.name          = ''

        this.countShowItem = this.attrs.countShowItem || 2;

        document.body.addEventListener("mousedown", e => this._mouseDownAction(e));
        window.addEventListener("resize", () => this._resizeAction());
        document.addEventListener("resetList", () => this._resetAction())

        this.model = this.generateModel();
        this.onChange(true, 0, true)
    }

    view() {
        const { disabled = false } = this.attrs;
        const containerClasses     = classNames("bordered", { disabled });

        let modal   = this._config.modal,
            trigger = this._config.trigger || {};

        this.model = this.generateModel();

        return (<div
            className={`form-item form-item_with-controls`}
            data-popup-parent-key={this._popupKey}
            oncreate={({ dom }) => (this._widthContainer = dom.getBoundingClientRect().width)}
            onupdate={({ dom }) => (this._widthContainer = dom.getBoundingClientRect().width)}
        >
            <input
                type="text"
                value={this._value}
                onclick={(e) => this._toggle(e)}
                oninput={(e) => this._onChange(e.target.value)}
                disabled={this.attrs.disabled || false}
            />

            <Button
                className={`item-controls`}
                onclick={(e) => this._toggle(e)}
            >
                <Button.Icon className={`drop-down-arrow ${this._config.iconColorClass
                    ? this._config.iconColorClass
                    : "color-orange"} fs8`}/>
            </Button>
            <Choose>
                <When condition={this._isOpen}>
                    <PopUp
                        data-popup-key={this._popupKey}
                        modal={modal}
                        trigger={trigger}
                    >
                        <Choose>
                            <When condition={false}>
                                <div
                                    className="drop-down-container"
                                    style={"width: " + (this._widthContainer || DEFAULT_WIDTH) + "px;"}
                                >
                                    <span className="drop-down-empty-block color-grey">
                                        {translate("dropDownList.emptyText")}
                                    </span>
                                </div>
                            </When>
                            <Otherwise>
                                <Container
                                    className="drop-down-container"
                                    style={
                                        "width: "
                                            + (this._widthContainer || DEFAULT_WIDTH)
                                            + "px; max-height: "
                                            + this._heightConfig.heightContainer + "px;"
                                    }
                                >
                                    {this.model.map((item, index, array) => (this.renderItem(item, index, array)))}
                                </Container>
                            </Otherwise>
                        </Choose>
                    </PopUp>
                </When>
            </Choose>
        </div>
        );
    }

    _onCreateItem(element, isCurrent) {
        let scrollContainer = element.closest(".drop-down-container");

        this._calcHeightContainer(element);

        if (isCurrent) {
            scrollIntoView(element, { scrollMode: 'if-needed', boundary: scrollContainer });

            scrollContainer.dispatchEvent(new CustomEvent("updatescroll"));
        }
    }

    _onUpdateItem(element, isSelected, isLast) {
        let scrollContainer = element.closest(".drop-down-container");

        if (isSelected) {
            scrollIntoView(element, {
                block     : "nearest",
                scrollMode: "if-needed",
                boundary  : scrollContainer
            });

            scrollContainer.dispatchEvent(new CustomEvent("updatescroll"));
        }
    }

    _calcHeightContainer(itemEl) {
        let boxItemEl    = itemEl.getBoundingClientRect(),
            heightItemEl = boxItemEl.height;

        if (this._heightConfig.maxHeight < heightItemEl) {
            this._heightConfig.maxHeight       = heightItemEl;
            this._heightConfig.heightContainer = heightItemEl * this.countShowItem;

            m.redraw();
        }
    }

    _getData() {
        let data = this._config.data;

        if (this._searchData) {
            return this._searchData;
        }

        return data || [];
    }

    _toggle(e) {
        e.preventDefault();

        if (this._isOpen) {
            this._close();
        } else {
            this._open();
        }
    }

    _open() {
        this._isOpen = true;
    }

    _close() {
        let isEdit = this._config.isEdit;

        this._isOpen = false;

        if (this.values.length < 1) {
            this.onChange(true, 0)
        }

        if (typeof this.attrs.onChange === "function") {
            this.attrs.onChange(this.values);
        }

        if (!isEdit) {
            this._value = this.name.toString() || "";
        }
    }

    _getValueOfItem(item) {
        let fieldView = this._config.fieldView;

        if (item && fieldView && item.hasOwnProperty(fieldView)) {
            return item[fieldView];
        }

        return item;
    }

    _getKeyOfItem(item, defaultKey) {
        let fieldKey = this._config.fieldKey;

        if (item && fieldKey && item.hasOwnProperty(fieldKey)) {
            return item[fieldKey];
        }

        return defaultKey;
    }

    _getCurrentKeyOfItem(defaultKey) {
        let fieldKey = this._config.fieldKey;

        if (this._currentValue && fieldKey && this._currentValue.hasOwnProperty(fieldKey)) {
            return this._currentValue[fieldKey];
        }

        return defaultKey;
    }

    renderItem(item, ind, array) {
        const { disabled = false }               = this.attrs;
        const { index, item: itemData, isCheck } = item;

        let value        = this._getValueOfItem(item),
            currentValue = this.name.toString(),
            key          = this._getKeyOfItem(item, value),
            currentKey   = this._getCurrentKeyOfItem(currentValue),
            isCurrent    = currentKey === key,
            isSelected   = this._selectedIndex === ind,
            isLast       = (array.length - 1) === ind,
            fieldsSearch = [...this._config.fieldsSearch],
            data         = { ...itemData };

        this._setHighLightFields(data, fieldsSearch, index)

        if (isSelected) {
            this._selectdetItem = item;
        }

        return (
            <div className="drop-down-item max-col-11 js-ellipsis" key={ind}
                oncreate={({ dom }) => this._onCreateItem(dom, isCurrent)}
                onupdate={({ dom }) => this._onUpdateItem(dom, isSelected, isLast)}>
                <label className="checkbox-radio">
                    <input
                        checked={isCheck}
                        type="checkbox"
                        onchange={(e) => this.onChange(e.target.checked, index)}
                        disabled={disabled}
                    />
                    <i className="mr5"/>
                    <span class="text-clipped">{data.name}</span>
                </label>
            </div>
        );
    }

    _setHighLightFields(item, fields, index) {
        let key = this._getKeyOfItem(item, index);

        if (this._relevanceData && this._relevanceData.length > 0 && this._relevanceData[key]) {
            let regExps = this._relevanceData[key].regExps;

            fields.forEach(field => {
                if (item.hasOwnProperty(field)) {
                    item[field] = setHighLightVnode(item[field], regExps);
                }
            });
        }
    }

    generateModel() {
        let data         = this._getData(),
            values       = this.values,
            model        = [],
            currentIndex = 0;

        values = values ? values : [];

        data.forEach(item => {
            let itemId    = item.id || item.name || item,
                itemName  = item.name || item,
                modelItem = {
                    index  : currentIndex,
                    item   : { id: itemId, name: itemName, active: item.active },
                    isCheck: values.includes(itemId)
                };

            model.push(modelItem);

            currentIndex++;
        });

        return model;
    }

    onChange(isAdd, index, init = false) {
        let newValues = [],
            values    = [],
            acc       = 0;

        this.model[index].isCheck = isAdd;

        if (init) {
            values.push(this.model[index].item.name);

            this.model.forEach(item => {
                if (item.item.active || item.item.active === undefined) {
                    item.isCheck = true
                    newValues.push(item.item.id);
                } else {
                    newValues.splice(0, 1);
                    item.isCheck = false
                }
            });

            if (!newValues.some(elem => elem === '-1')) {
                values = [];
                this.model.forEach(item => {
                    if (item.item.id === "-1") {
                        item.isCheck = false
                    }
                    if (item.isCheck) {
                        values.push(item.item.name);
                    }
                });
            }
        } else if (this.model[index].isCheck && this.model[index].item.id === "-1") {
            values.push(this.model[index].item.name);

            this.model.forEach(item => {
                item.isCheck = true
                newValues.push(item.item.id);
            });
        } else if (!this.model[index].isCheck && this.model[index].item.id === "-1") {
            values.push(this.model[index].item.name);

            this.model.forEach(item => {
                item.isCheck = false
            });
        } else {
            this.model.forEach(item => {
                if (item.isCheck && item.item.id !== "-1") {
                    newValues.push(item.item.id);
                    values.push(item.item.name);
                }
            });
        }

        this.model.forEach(item => {
            if (item.isCheck) {
                acc += 1
            }
        });

        if (acc === this.model.length - 1 && isAdd && !init) {
            values = [this.model[this.model.findIndex(i => i.item.id === "-1")].item.name];

            this.model.forEach(item => {
                item.isCheck = true
                newValues.push(item.item.id);
            });
        }

        this.values = newValues
        this.name   = values;
        this._value = values.toString();
    }

    _resetAction() {
        this._close();

        this._searchReset();

        m.redraw();
    }

    _resizeAction() {
        let selectorPopUp = "[data-popup-key='" + this._popupKey + "']",
            elementPopUp  = document.querySelector(selectorPopUp);

        if (!elementPopUp) {
            return;
        }

        this._close();

        this._searchReset();

        m.redraw();
    }

    _mouseDownAction(e) {
        let target         = e.target,
            selectorPopUp  = "[data-popup-key='" + this._popupKey + "']",
            selectorParent = "[data-popup-parent-key='" + this._popupKey + "']",
            elementPopUp   = document.querySelector(selectorPopUp);

        if (!elementPopUp || target.closest(selectorPopUp) || target.closest(selectorParent)) {
            return;
        }

        this._close();

        this._searchReset();

        m.redraw();
    }

    _onChange(value) {
        this._value = value;

        this._search(value);
        this._open();
    }

    _search(value) {
        let data         = this._config.data,
            fieldsSearch = this._config.fieldsSearch,
            fieldKey     = this._config.fieldKey,
            searchEngine, searchData;

        if (!value || value.trim() === "" || value === this.name.toString()) {
            this._searchReset();

            return;
        }

        searchEngine = new SearchEngine({
            filterFragment  : value,
            data,
            filterConditions: {
                fields   : fieldsSearch,
                linkField: fieldKey
            }
        });

        searchData = searchEngine.search();

        this._searchData    = searchData.records;
        this._relevanceData = searchData.relevanceData;
    }

    _searchReset() {
        this._searchData    = undefined;
        this._relevanceData = undefined;
    }
}

export default SelectDropDownList;
