import m from 'mithril';
import { translate } from 'localizations';

export default class MessageItem {
    showMessage(e, attrs) {
        if (!e.target.closest('[data-controll]')) {
            attrs.showMessage(e);
        }
    }

    view({ attrs }) {
        let { message } = attrs;

        return (
            <li
                className="tile-list__item tile-list__item_bullet-blue cursor-pointer"
                onclick={ e => this.showMessage(e, attrs) }
            >
                <div className="row row_table">
                    <div className="col-10">
                        <div className="color-grey fs13">
                            { message['sent_date'] }
                        </div>
                        <div className="text-bold">
                            { message['type'] }
                        </div>
                    </div>
                    <div className="col-2 v-align-middle text-right">
                        <button
                            data-controll
                            className="btn fs18 p5 color-blue"
                            title={ translate('notifications.buttons.markAsRead') }
                            onclick={ e => attrs.markAsRead(e) }
                        >
                            <i className="block font-icon success"></i>
                        </button>
                        <button
                            data-controll
                            className="btn fs20 p5 color-red"
                            title={ translate('buttons.remove') }
                            onclick={ e => attrs.removeMessage(e) }
                        >
                            <i className="block font-icon icon-trash"></i>
                        </button>
                    </div>
                </div>
            </li>
        );
    }
}
