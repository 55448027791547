import m from "mithril";
import Component from "lib/Component";
import {translate} from 'localizations';
import Button from "components/plugins/Button";
import DatePicker from "components/plugins/DatePicker";
import PopUp from "components/plugins/PopUp"

const ARROW_LEFT  = 37;
const ARROW_RIGHT = 39;
const ARROW_UP    = 38;
const ARROW_DOWN  = 40;
const DELETE      = 46;
const BACKSPACE   = 8;

const START_DATE_POSITION  = 0;
const END_DATE_POSITION    = 2;
const START_MONTH_POSITION = 3;
const END_MONTH_POSITION   = 5;
const START_YEAR_POSITION  = 6;
const END_YEAR_POSITION    = 10;

const LENGTH_DATE  = 2;
const LENGTH_MONTH = 2;

const MAX_TIME_BETWEEN_CLICK = 500;

const EMPTY_CHAR = " ";

const IN_DATE_REGEX = /^\d\d\d\d-\d\d-\d\d$/;

class DateInput extends Component {
    oninit() {
        let { value = "" } = this.attrs;

        if (typeof value !== "string") {
            value = "";
        }

        this._date  = "";
        this._month = "";
        this._year  = "";

        this._popupKey = this.attrs["data-popup-key"];

        this._currentPositionStart = value.length;
        this._currentPositionEnd   = value.length;
        this._isUpdatePosition     = true;
        this._isValid              = true;

        this._timeStampClick = 0;

        this._isShowDatePicker = false;

        this._initValue();

        this._localStore = {
            isUpdateInput : true,
            isUpdatePicker: true,
            value         : this._isValidDate() ? this._dateToDateMask() : undefined
        };

        document.body.addEventListener("mousedown", e => this._mouseDownAction(e));
        window.addEventListener("resize", () => this._resizeAction());
    }

    onbeforeupdate() {
        let { value = "" }   = this.attrs,
            isWasUpdateInput = false;

        value = this._convertToMaskFormat(value);

        if (!this._localStore.isUpdateInput) {
            this._convertToDate(this._localStore.value);

            this._localStore.isUpdateInput = true;
            isWasUpdateInput               = true;
            this._isShowDatePicker         = false;
        }

        if ((this._isFillDate() && this._isValidDate()) || this._isEmptyDate()) {
            let dateMask = this._dateToDateMask();

            this._isValid = true;

            if (!isWasUpdateInput && dateMask !== this._localStore.value) {
                this._localStore.value          = dateMask;
                this._localStore.isUpdatePicker = false;
            }
        } else {
            this._isValid = false;
        }

        if (value !== this._localStore.value && this._dateToDateMask() === this._localStore.value) {
            this._initValue();
        }
    }

    onupdate({ dom }) {
        if (!this._isUpdatePosition) {
            let inputEl = dom.querySelector("input");

            inputEl.setSelectionRange(this._currentPositionStart, this._currentPositionEnd);

            this._isUpdatePosition = true;
        }
    }

    onbeforeremove() {
        this._isShowDatePicker = false;
    }

    view() {
        let isEmpty    = this._isEmptyDate(),
            fnOnChange = this.attrs.onChange,
            fnOnBlur   = this.attrs.onBlur,
            trigger    = this.attrs.trigger || {},
            classInput = this.attrs.classInput || "",
            disabled   = this.attrs.disabled || false;

        return (
            <div className="form-item form-item_with-controls" data-popup-parent-key={this._popupKey}>
                <input
                    type="text"
                    placeholder="ДД.ММ.ГГГГ"
                    className={(this._isValid ? "" : "error") + classInput}
                    value={this._dateToDateMask()}
                    onkeypress={e => this._handlerKeyPress(e)}
                    onkeydown={e => this._handlerKeyDown(e)}
                    oninput={e => this._handlerInput(e)}
                    ondblclick={e => this._handlerDblClick(e)}
                    onmousedown={e => this._handlerMouseDown(e)}
                    onblur={e => this._onBlur()}
                    disabled={disabled}
                />
                <If condition={!isEmpty}>
                    <Button
                        className="item-controls"
                        onclick={() => this._onRemove()}
                        title={translate("date.clear")}
                        disabled={disabled}
                    >
                        <Button.Icon className="circle-close color-orange block"/>
                    </Button>
                </If>

                <Button
                    className="item-controls calendar"
                    onclick={() => this._openDatePicker()}
                    disabled={disabled}
                >
                    <Button.Icon className="icon-date color-blue"/>
                </Button>
                <Choose>
                    <When condition={this._isShowDatePicker}>
                        <PopUp
                            data-popup-key={this._popupKey}
                            modal={this.attrs.modal}
                            trigger={trigger}
                        >
                            <DatePicker
                                onChange={fnOnChange}
                                onSave={fnOnBlur}
                                store={this._localStore}
                            />
                        </PopUp>
                    </When>
                </Choose>
            </div>
        );
    }

    _onBlur() {
        let fnOnBlur = this.attrs.onBlur;

        this._initValue();

        if (typeof fnOnBlur === "function") {
            fnOnBlur();
        }
    }

    _initValue() {
        let { value = "" } = this.attrs;

        if (typeof value !== "string") {
            value = "";
        }

        if (IN_DATE_REGEX.test(value)) {
            this._convertByFormat(value);
        } else {
            this._convertToDate(value);
        }
    }

    _convertToMaskFormat(value) {
        if (IN_DATE_REGEX.test(value)) {
            let dateArr = value.split("-");

            return `${dateArr[2]}.${dateArr[1]}.${dateArr[0]}`;
        }

        return value;
    }

    _convertToDate(value) {
        let textDate = value;

        if (textDate.trim() === "") {
            this._date  = "";
            this._month = "";
            this._year  = "";

            return;
        }

        textDate = textDate.trim();
        textDate = textDate.replace(/\D/g, "");
        textDate = textDate.substr(0, 8);

        if (textDate.length > 0) {
            this._date  = "";
            this._month = "";
            this._year  = "";
        }

        for (let index = 0; index < textDate.length; index++) {
            if (index < LENGTH_DATE) {
                this._date = this._date.concat(textDate[index]);
            } else if (index < (LENGTH_DATE + LENGTH_MONTH)) {
                this._month = this._month.concat(textDate[index]);
            } else {
                this._year = this._year.concat(textDate[index]);
            }
        }
    }

    _convertByFormat(value) {
        let dateArr = value.split("-");

        this._date  = dateArr[2];
        this._month = dateArr[1];
        this._year  = dateArr[0];
    }

    _dateToDateMask() {
        let maskValue = "";

        if (this._trimEmptyChar(this._year).length > 0) {
            maskValue = this._date.padEnd(2, EMPTY_CHAR) + "." + this._month.padEnd(2, EMPTY_CHAR) + "." + this._year;
        } else if (this._trimEmptyChar(this._month).length > 0) {
            maskValue = this._date.padEnd(2, EMPTY_CHAR) + "." + this._month;
        } else if (this._trimEmptyChar(this._date).length > 0) {
            maskValue = this._date;
        }

        return maskValue;
    }

    _dateToOutputFormat() {
        let outputFormat = "";

        if (
            this._trimEmptyChar(this._year).length === 4
            && this._trimEmptyChar(this._month).length === 2
            && this._trimEmptyChar(this._date).length === 2) {
            outputFormat = `${this._year}-${this._month}-${this._date}`;
        }

        return outputFormat;
    }

    _handlerInput(e) {
        this._convertToDate(e.target.value);

        this._onChange();
    }

    _handlerKeyPress(e) {
        let inputEl = e.target,
            char, positionLeft, positionRight, startPositionEdit;

        if (e.altKey || e.ctrlKey || e.metaKey || !e.which) {
            return;
        }

        e.preventDefault();

        char = String.fromCharCode(e.which);

        if (!/\d/.test(char)) {
            return;
        }

        positionLeft  = this._getLeftPosition(inputEl);
        positionRight = this._getRightPosition(inputEl);

        if (positionLeft === END_YEAR_POSITION) {
            return;
        }

        if (positionLeft < END_DATE_POSITION) {
            startPositionEdit = positionLeft;
            this._date        = this._textSplice(this._date, startPositionEdit, 1, char);
        } else if (positionLeft < END_MONTH_POSITION) {
            startPositionEdit = positionLeft - (positionLeft === END_DATE_POSITION ? 2 : 3);
            this._month       = this._textSplice(this._month, startPositionEdit, 1, char);
        } else {
            startPositionEdit = positionLeft - (positionLeft === END_MONTH_POSITION ? 5 : 6);
            this._year        = this._textSplice(this._year, startPositionEdit, 1, char);
        }

        if (positionLeft === END_DATE_POSITION || positionLeft === END_MONTH_POSITION) {
            positionLeft = positionLeft + 2;
        } else {
            positionLeft = positionLeft + 1;
        }

        if (positionLeft < positionRight) {
            this._deleteCharRange(positionLeft, positionRight);
        }

        this._onChange();

        this._currentPositionStart = positionLeft;
        this._currentPositionEnd   = positionLeft;
        this._isUpdatePosition     = false;
    }

    _textSplice(text, start, deleteCount, ...additionalChars) {
        let arrText = text.split("");

        arrText.splice(start, deleteCount, ...additionalChars);

        return arrText.join("");
    }

    _handlerKeyDown(e) {
        let inputEl       = e.target,
            keyCode       = e.which,
            positionLeft  = this._getLeftPosition(inputEl),
            positionRight = this._getRightPosition(inputEl),
            positions;

        if (![ARROW_LEFT, ARROW_RIGHT, ARROW_UP, ARROW_DOWN, DELETE, BACKSPACE].includes(keyCode)) {
            return;
        }

        e.preventDefault();

        switch (keyCode) {
            case ARROW_LEFT:
                positions = this._moveToLeft(positionLeft);
                break;
            case ARROW_RIGHT:
                positions = this._moveToRight(positionRight);
                break;
            case ARROW_UP:
                positions = this._incrementParts(positionLeft, positionRight);
                break;
            case ARROW_DOWN:
                positions = this._decrementParts(positionLeft, positionRight);
                break;
            case DELETE:
                if (positionLeft === positionRight) {
                    positions = this._deleteCharToTheRight(positionLeft);
                } else {
                    this._deleteCharRange(positionLeft, positionRight);

                    positions = {
                        positionStart: positionRight,
                        positionEnd  : positionRight
                    };
                }
                break;
            case BACKSPACE:
                if (positionLeft === positionRight) {
                    positions = this._deleteCharToTheLeft(positionLeft);
                } else {
                    this._deleteCharRange(positionLeft, positionRight);

                    positions = {
                        positionStart: positionLeft,
                        positionEnd  : positionLeft
                    };
                }
                break;
            default:
                return;
        }

        this._onChange();

        this._currentPositionStart = positions.positionStart;
        this._currentPositionEnd   = positions.positionEnd;
        this._isUpdatePosition     = false;
    }

    _getLeftPosition(inputElement) {
        let startPosition = inputElement.selectionStart,
            endPosition   = inputElement.selectionEnd;

        return startPosition < endPosition ? startPosition : endPosition;
    }

    _getRightPosition(inputElement) {
        let startPosition = inputElement.selectionStart,
            endPosition   = inputElement.selectionEnd;

        return startPosition < endPosition ? endPosition : startPosition;
    }

    _moveToLeft(positionLeft) {
        let positionStart, positionEnd;

        if (positionLeft > START_YEAR_POSITION) {
            positionStart = START_YEAR_POSITION;
            positionEnd   = END_YEAR_POSITION;
        } else if (positionLeft > START_MONTH_POSITION) {
            positionStart = START_MONTH_POSITION;
            positionEnd   = END_MONTH_POSITION;
        } else {
            positionStart = START_DATE_POSITION;
            positionEnd   = END_DATE_POSITION;
        }

        return { positionStart, positionEnd }
    }

    _moveToRight(positionRight) {
        let positionStart, positionEnd;

        if (positionRight < END_DATE_POSITION) {
            positionStart = START_DATE_POSITION;
            positionEnd   = END_DATE_POSITION;
        } else if (positionRight < END_MONTH_POSITION) {
            positionStart = START_MONTH_POSITION;
            positionEnd   = END_MONTH_POSITION;
        } else {
            positionStart = START_YEAR_POSITION;
            positionEnd   = END_YEAR_POSITION;
        }

        return { positionStart, positionEnd }
    }

    _deleteCharToTheRight(positionLeft) {
        let shift = 0,
            startPositionEdit;

        if (positionLeft < END_DATE_POSITION) {
            startPositionEdit = positionLeft;
            this._date        = this._textSplice(this._date, startPositionEdit, 1, EMPTY_CHAR);
            shift             = 1;
        } else if (positionLeft < END_MONTH_POSITION) {
            startPositionEdit = positionLeft - (positionLeft === END_DATE_POSITION ? 2 : 3);
            this._month       = this._textSplice(this._month, startPositionEdit, 1, EMPTY_CHAR);
            shift             = 1;
        } else if (positionLeft < END_YEAR_POSITION) {
            startPositionEdit = positionLeft - (positionLeft === END_MONTH_POSITION ? 5 : 6);
            this._year        = this._textSplice(this._year, startPositionEdit, 1, EMPTY_CHAR);
            shift             = 1;
        }

        if (positionLeft === END_DATE_POSITION || positionLeft === END_MONTH_POSITION) {
            shift++;
        }

        return {
            positionStart: positionLeft + shift,
            positionEnd  : positionLeft + shift
        };
    }

    _deleteCharToTheLeft(positionLeft) {
        let shift = 0,
            startPositionEdit;

        if (positionLeft > START_YEAR_POSITION) {
            startPositionEdit = positionLeft - 7;
            this._year        = this._textSplice(this._year, startPositionEdit, 1, EMPTY_CHAR);
            shift             = 1;
        } else if (positionLeft > START_MONTH_POSITION) {
            startPositionEdit = positionLeft - (positionLeft === START_YEAR_POSITION ? 5 : 4);
            this._month       = this._textSplice(this._month, startPositionEdit, 1, EMPTY_CHAR);
            shift             = 1;
        } else if (positionLeft > START_DATE_POSITION) {
            startPositionEdit = positionLeft - (positionLeft === START_MONTH_POSITION ? 2 : 1);
            this._date        = this._textSplice(this._date, startPositionEdit, 1, EMPTY_CHAR);
            shift             = 1;
        }

        if (positionLeft === START_MONTH_POSITION || positionLeft === START_YEAR_POSITION) {
            shift++;
        }

        return {
            positionStart: positionLeft - shift,
            positionEnd  : positionLeft - shift
        };
    }

    _deleteCharRange(positionLeft, positionRight) {
        if (positionLeft < END_DATE_POSITION) {
            this._date = this._deleteCharRangeInPart(
                this._date,
                positionLeft,
                positionRight,
                START_DATE_POSITION,
                END_DATE_POSITION
            );
        }

        if (positionRight > START_MONTH_POSITION && positionLeft < END_MONTH_POSITION) {
            this._month = this._deleteCharRangeInPart(
                this._month,
                positionLeft,
                positionRight,
                START_MONTH_POSITION,
                END_MONTH_POSITION
            );
        }

        if (positionRight > START_YEAR_POSITION && positionLeft < END_YEAR_POSITION) {
            this._year = this._deleteCharRangeInPart(
                this._year,
                positionLeft,
                positionRight,
                START_YEAR_POSITION,
                END_YEAR_POSITION
            );
        }
    }

    _deleteCharRangeInPart(text, positionLeft, positionRight, startPartPosition, endPartPosition) {
        let leftBorder, rightBorder, spaces, startPositionEdit, countDeleteChars;

        leftBorder        = positionLeft >= startPartPosition ? positionLeft : startPartPosition;
        rightBorder       = positionRight <= endPartPosition ? positionRight : endPartPosition;
        countDeleteChars  = rightBorder - leftBorder;
        spaces            = Array.from({ length: countDeleteChars }, () => EMPTY_CHAR);
        startPositionEdit = leftBorder - startPartPosition;

        return this._textSplice(text, startPositionEdit, countDeleteChars, ...spaces);
    }

    _incrementParts(positionLeft, positionRight) {
        if (this._isFillDate() && this._isValid) {
            if (positionLeft === START_DATE_POSITION && positionRight === END_DATE_POSITION) {
                this._incrementDate()
            } else if (positionLeft === START_MONTH_POSITION && positionRight === END_MONTH_POSITION) {
                this._incrementMonth()
            } else if (positionLeft === START_YEAR_POSITION && positionRight === END_YEAR_POSITION) {
                this._incrementYear();
            }
        }

        return {
            positionStart: positionLeft,
            positionEnd  : positionRight
        };
    }

    _incrementDate() {
        let date     = parseInt(this._date),
            lastDate = this._getLastDate();

        this._date = date === lastDate ? "01" : (date + 1).toString().padStart(2, "0");
    }

    _incrementMonth() {
        let month = parseInt(this._month);

        this._month = month === 12 ? "01" : (month + 1).toString().padStart(2, "0");

        if (!this._isValidDate()) {
            this._date = this._getLastDate().toString().padStart(2, "0");
        }
    }

    _incrementYear() {
        let year = parseInt(this._year);

        this._year = year === 9999 ? "0000" : (year + 1).toString().padStart(4, "0");

        if (!this._isValidDate()) {
            this._date = this._getLastDate().toString().padStart(2, "0");
        }
    }

    _decrementParts(positionLeft, positionRight) {
        if (this._isFillDate() && this._isValid) {
            if (positionLeft === START_DATE_POSITION && positionRight === END_DATE_POSITION) {
                this._decrementDate()
            } else if (positionLeft === START_MONTH_POSITION && positionRight === END_MONTH_POSITION) {
                this._decrementMonth()
            } else if (positionLeft === START_YEAR_POSITION && positionRight === END_YEAR_POSITION) {
                this._decrementYear();
            }
        }

        return {
            positionStart: positionLeft,
            positionEnd  : positionRight
        };
    }

    _decrementDate() {
        let date     = parseInt(this._date),
            lastDate = this._getLastDate();

        this._date = date === 1 ? lastDate.toString().padStart(2, "0") : (date - 1).toString().padStart(2, "0");
    }

    _decrementMonth() {
        let month = parseInt(this._month);

        this._month = month === 1 ? "12" : (month - 1).toString().padStart(2, "0");

        if (!this._isValidDate()) {
            this._date = this._getLastDate().toString().padStart(2, "0");
        }
    }

    _decrementYear() {
        let year = parseInt(this._year);

        this._year = year === 0 ? "9999" : (year - 1).toString().padStart(4, "0");

        if (!this._isValidDate()) {
            this._date = this._getLastDate().toString().padStart(2, "0");
        }
    }

    _isFillDate() {
        return this._trimEmptyChar(this._year).length === 4
               && this._trimEmptyChar(this._month).length === 2
               && this._trimEmptyChar(this._date).length === 2;
    }

    _isEmptyDate() {
        return this._trimEmptyChar(this._year).length === 0
               && this._trimEmptyChar(this._month).length === 0
               && this._trimEmptyChar(this._date).length === 0;
    }

    _isValidDate() {
        let checkDate = new Date(),
            year      = parseInt(this._year),
            //в JS нумерация месяцев идет с 0 до 11, для пользователей отображаем с 1 до 12
            month     = parseInt(this._month) - 1,
            date      = parseInt(this._date);

        checkDate.setFullYear(year, month, date);

        return checkDate.getFullYear() === year && checkDate.getMonth() === month && checkDate.getDate() === date;
    }

    _trimEmptyChar(text) {
        return text.replace(new RegExp(EMPTY_CHAR, "g"), "");
    }

    _getLastDate() {
        let checkDate = new Date(),
            year      = parseInt(this._year),
            month     = parseInt(this._month),
            date      = 0;

        checkDate.setFullYear(year, month, date);

        return checkDate.getDate();
    }

    _handlerMouseDown(e) {
        let inputElement   = e.target,
            selectionStart = inputElement.selectionStart,
            selectionEnd   = inputElement.selectionEnd;

        if (e.timeStamp - this._timeStampClick <= MAX_TIME_BETWEEN_CLICK) {
            e.preventDefault();
        }

        this._timeStampClick = e.timeStamp;

        if (selectionStart === selectionEnd) {
            this._startDblClickPOsition = selectionStart;
        }
    }

    _handlerDblClick(e) {
        let position = this._startDblClickPOsition,
            positionStart, positionEnd;

        if (position === undefined || position === null) {
            return;
        }

        e.preventDefault();

        if (position >= START_DATE_POSITION && position <= END_DATE_POSITION) {
            positionStart = START_DATE_POSITION;
            positionEnd   = END_DATE_POSITION;
        } else if (position >= START_MONTH_POSITION && position <= END_MONTH_POSITION) {
            positionStart = START_MONTH_POSITION;
            positionEnd   = END_MONTH_POSITION;
        } else {
            positionStart = START_YEAR_POSITION;
            positionEnd   = END_YEAR_POSITION;
        }

        this._currentPositionStart = positionStart;
        this._currentPositionEnd   = positionEnd;
        this._isUpdatePosition     = false;
    }

    _openDatePicker() {
        this._isShowDatePicker          = !this._isShowDatePicker;
        this._localStore.isUpdatePicker = false;
    }

    _closeDatePicker() {
        this._isShowDatePicker          = false;
        this._localStore.isUpdatePicker = false;
    }

    _mouseDownAction(e) {
        let target         = e.target,
            selectorPopUp  = "[data-popup-key='" + this._popupKey + "']",
            selectorParent = "[data-popup-parent-key='" + this._popupKey + "']",
            elementPopUp   = document.querySelector(selectorPopUp);

        if (!elementPopUp || target.closest(selectorPopUp) || target.closest(selectorParent)) {
            return;
        }

        this._closeDatePicker();

        m.redraw();
    }

    _resizeAction() {
        let selectorPopUp = "[data-popup-key='" + this._popupKey + "']",
            elementPopUp  = document.querySelector(selectorPopUp);

        if (!elementPopUp) {
            return;
        }

        this._closeDatePicker();

        m.redraw();
    }

    _onChange() {
        if ((this._isFillDate() && this._isValidDate()) || this._isEmptyDate()) {
            let outputFormat = this._dateToOutputFormat();

            if (outputFormat !== this.attrs.value) {
                let fnOnChange = this.attrs.onChange;

                if (typeof fnOnChange === "function") {
                    fnOnChange(outputFormat);
                }
            }
        }
    }

    _onRemove() {
        let fnOnBlur = this.attrs.onBlur;

        this._date  = "";
        this._month = "";
        this._year  = "";

        this._onChange();

        this._currentPositionStart = 0;
        this._currentPositionEnd   = 0;
        this._isUpdatePosition     = false;

        if (typeof fnOnBlur === "function") {
            fnOnBlur();
        }
    }
}

export default DateInput;
