export const NONE                            = 0;
export const OWN_SIDE                        = 1;
export const OTHER_SIDE                      = 2;
export const OWN_REQUISITES                  = 3;
export const OTHER_REQUISITES                = 4;
export const OWN_ROLE                        = 5;
export const OTHER_ROLE                      = 6;
export const PROPERTY_OF_DOCUMENT            = 7;
export const OBJECT_OF_REGISTRY              = 8;
export const FIELD_OF_OBJECT_OF_REGISTRY     = 9;
export const FIELD_OF_OBJECT_OR_OWN_REGISTRY = 10;
export const SIGNER_OF_OWN_SIDE              = 12;
export const SIGNER_OF_OTHER_SIDE            = 13;
export const REQIURED                        = "REQIURED";
export const OBJECT_TREE_OF_REGISTRY         = 14;
export const OWN_BANK_REQUISITES             = 15;
export const OTHER_BANK_REQUISITES           = 16;
