import m from "mithril";
import classNames from "classnames";
import Component from "lib/Component";

const CLASS_CONTAIN = "contain-popup";

const SIDE_TOP    = "SIDE_TOP";
const SIDE_RIGHT  = "SIDE_RIGHT";
const SIDE_BOTTOM = "SIDE_BOTTOM";
const SIDE_LEFT   = "SIDE_LEFT";

const POSITION_START  = "POSITION_START";
const POSITION_END    = "POSITION_END";
const POSITION_CENTER = "POSITION_CENTER";

const DEFAULT_TRIGGER_CONFIG = { side: SIDE_TOP, position: POSITION_END };

class PopUp extends Component {
    oninit() {
        this.triggerConfig = { ...DEFAULT_TRIGGER_CONFIG, ...this.attrs.trigger };
    }

    oncreate(vnode) {
        let selectorModal = this.attrs.modal,
            modal         = selectorModal ? document.querySelector(selectorModal) : null;

        this._isModal = !!modal;
        this._parent  = modal || document.body;

        this._parent.appendChild(vnode.dom);
        this._parent.classList.add(CLASS_CONTAIN);

        this.calcPosition(vnode.dom, this._isModal);

        this.onScroll = () => this.calcPosition(vnode.dom, this._isModal);

        window.addEventListener("scroll", this.onScroll);
    }

    onremove() {
        window.removeEventListener("scroll", this.onScroll);
        this.onScroll = undefined;
    }

    onbeforeremove() {
        this._parent.classList.remove(CLASS_CONTAIN);
    }

    onupdate(vnode) {
        this.calcPosition(vnode.dom, this._isModal);
    }

    view({ children, attrs }) {
        let classesContainer = classNames(
            "popup-container",
            { "side-top": this.triggerConfig.side === SIDE_TOP },
            { "side-right": this.triggerConfig.side === SIDE_RIGHT },
            { "side-bottom": this.triggerConfig.side === SIDE_BOTTOM },
            { "side-left": this.triggerConfig.side === SIDE_LEFT },
            { start: this.triggerConfig.position === POSITION_START },
            { end: this.triggerConfig.position === POSITION_END },
            { center: this.triggerConfig.position === POSITION_CENTER }
        );

        return (
            <div
                className={classesContainer}
                data-popup-key={attrs["data-popup-key"]}
            >
                {children}
            </div>
        );
    }

    calcPosition(popUpEl, isModal) {
        let selectorParent, parents, parent, boxParent, boxPopUpEl, boxModal, top, left,
            topParentByWindow, leftParentByWindow, diffWidth, index;

        selectorParent = "[data-popup-parent-key='" + this.attrs["data-popup-key"] + "']";
        parents        = document.querySelectorAll(selectorParent);

        for (index = 0; index < parents.length; index++) {
            if (parents[index].offsetParent) {
                parent = parents[index];
                break;
            }
        }

        if (!parent) {
            return;
        }

        boxParent  = parent.getBoundingClientRect();
        boxPopUpEl = popUpEl.getBoundingClientRect();
        boxModal   = this._parent.getBoundingClientRect();

        topParentByWindow  = boxParent.top + (isModal ? this._parent.scrollTop : window.pageYOffset);
        leftParentByWindow = boxParent.left + (isModal ? this._parent.scrollLeft : window.pageXOffset);

        if (isModal) {
            topParentByWindow  = topParentByWindow - boxModal.top;
            leftParentByWindow = leftParentByWindow - boxModal.left;
        }

        diffWidth = boxParent.width - boxPopUpEl.width;

        if (this.triggerConfig.side === SIDE_TOP || this.triggerConfig.side === SIDE_BOTTOM) {
            left = leftParentByWindow + (diffWidth > 0 ? diffWidth : 0);

            if (this.triggerConfig.side === SIDE_TOP) {
                top = topParentByWindow + boxParent.height;
            } else {
                top = topParentByWindow - boxModal.height;
            }
        } else if (this.triggerConfig.side === SIDE_LEFT || this.triggerConfig.side === SIDE_RIGHT) {
            top = topParentByWindow;

            if (this.triggerConfig.side === SIDE_LEFT) {
                left = leftParentByWindow + boxParent.width + 6;
            } else {
                left = leftParentByWindow - boxModal.width;
            }

            if (this.triggerConfig.position === POSITION_CENTER) {
                top = top - (boxPopUpEl.height / 2);
            }
        }

        popUpEl.style.top  = top + "px";
        popUpEl.style.left = left + "px";
    }
}

export default PopUp;
