import "mithril";
import Component from "lib/Component";
import {translate} from "localizations";
import Button from "components/plugins/Button";
import ChooseSimple from "_/components/common/ChooseSimple";
import {NUMBERED, MARKED, PARAGRAPH, SEPARATOR, NUMBERED_BRACKET} from "_/constants/typesOfView";
import {COMMA, DOT, SEMICOLON, SPACE, OWN} from "_/constants/separators";

//Корневой элемент локализации
const ROOT = "listsTextBlocksOfTemplate";

class AddList extends Component {
    oninit() {
        const data = this.attrs.data || {};

        this.data  = { name: "", ...data };
        this.title = data.id
            ? `${translate(`${ROOT}.addList.titleEdit`)} "${data.name}"`
            : translate(`${ROOT}.addList.titleAdd`);

        this.types = [
            { id: NUMBERED, name: "Нумерованный список" },
            { id: NUMBERED_BRACKET, name: "Нумерованный список со скобками" },
            { id: MARKED, name: "Маркированный список" },
            { id: PARAGRAPH, name: "Абзац" },
            { id: SEPARATOR, name: "Разделитель" }
        ];

        this.separators = [
            { id: COMMA, name: "Запятая" },
            { id: DOT, name: "Точка" },
            { id: SEMICOLON, name: "Точка с запятой" },
            { id: SPACE, name: "Пробел" },
            { id: OWN, name: "Свой вариант" }
        ];
    }

    view() {
        const
            { modalClass }                           = this.attrs,
            { name, comment, options = {} }          = this.data,
            { typeOfView, typeSeparator, separator } = options;

        let isValidate = this.isValidate();

        return (
            <div>
                <div className="modal__header bg-blue color-white">
                    <div className="modal-close font-icon cancel"/>
                    <span>{this.title}</span>
                </div>
                <div className="modal__body">
                    <div className="form-item">
                        <span>{translate(`${ROOT}.addList.name`)}</span>
                        <input
                            type="text"
                            oninput={e => this.changeValue("name", e.target.value)}
                            value={name}
                        />
                    </div>

                    <div className="form-item mt10">
                        <span>{translate(`${ROOT}.addList.comment`)}</span>
                        <textarea
                            oninput={e => this.changeValue("comment", e.target.value)}
                            value={comment === null ? "" : comment}
                        />
                    </div>

                    <div className="form-item mt10">
                        <span>{translate(`${ROOT}.addList.typeOfView`)}</span>
                        <ChooseSimple
                            data={this.types}
                            data-popup-key="AddEditListOfTextBlockOfTemplateName"
                            modal={`.${modalClass}`}
                            onChange={item => this.changeOption("typeOfView", item.id)}
                            isCanEmpty={false}
                            value={typeOfView}
                        />
                    </div>

                    <Choose>
                        <When condition={+typeOfView === SEPARATOR}>
                            <div className="form-item mt10">
                                <span>{translate(`${ROOT}.addList.separator`)}</span>
                                <ChooseSimple
                                    data={this.separators}
                                    data-popup-key="AddEditListOfTextBlockOfTemplateSeparators"
                                    modal={`.${modalClass}`}
                                    onChange={item => this.changeOption("typeSeparator", item.id)}
                                    isCanEmpty={false}
                                    value={typeSeparator}
                                />
                            </div>

                            <Choose>
                                <When condition={+typeSeparator === OWN}>
                                    <div className="form-item mt10">
                                        <span>{translate(`${ROOT}.addList.ownSeparator`)}</span>
                                        <input
                                            className="wl100"
                                            type="text"
                                            value={separator}
                                            oninput={e => this.changeOption("separator", e.target.value)}
                                        />
                                    </div>
                                </When>
                            </Choose>
                        </When>
                    </Choose>
                </div>
                <div className="modal__footer bg-grey text-right">
                    <Button className="turbo-button__size_md dashed-link color-blue p0 mr10 modal-close">
                        {translate("buttons.cancel")}
                    </Button>
                    <Button
                        disabled={!isValidate}
                        title={isValidate ? "" : translate(`${ROOT}.warning.notValidateForm`)}
                        className="turbo-button__size_md turbo-button__bg_orange turbo-button__text_white"
                        onclick={() => this.save()}
                    >
                        {this.data.id ? translate("buttons.save") : translate("buttons.create")}
                    </Button>
                </div>
            </div>
        );
    }

    changeValue(nameField, value) {
        this.data[nameField] = value;
    }

    changeOption(nameField, value) {
        if (!this.data.options) {
            this.data.options = {};
        }

        this.data.options[nameField] = value;
    }

    isValidate() {
        let
            { name, options = {} }                   = this.data,
            { typeOfView, typeSeparator, separator } = options,
            isValidate                               = name && name.trim();

        isValidate = isValidate && typeOfView !== undefined && typeOfView !== null;

        if (+typeOfView === SEPARATOR) {
            isValidate = isValidate && typeSeparator !== undefined && typeSeparator !== null;

            if (+typeSeparator === OWN) {
                isValidate = isValidate && separator !== undefined && separator !== null;
            }
        }

        return isValidate;
    }

    getDescription(options = {}) {
        const { typeOfView, typeSeparator, separator = "" } = options;

        let typeTitle         = translate(`${ROOT}.addList.typeOfView`),
            separatorTitle    = translate(`${ROOT}.addList.separator`),
            separatorOwnTitle = translate(`${ROOT}.addList.ownSeparator`),
            typeName          = this.getNameOfElement(typeOfView, this.types),
            separatorName     = this.getNameOfElement(typeSeparator, this.separators),
            trimSeparator     = separator.replace(/\s{2,}/g, " "),
            description;

        description = `${typeTitle}: ${typeName}`;

        if (+typeOfView === SEPARATOR) {
            description = `${description}; ${separatorTitle}: ${separatorName}`;

            if (+typeSeparator === OWN) {
                description = `${description}; ${separatorOwnTitle}: ${trimSeparator}`;
            }
        }

        return description;
    }

    getNameOfElement(elementId, collection) {
        let elementName = "";

        if (Array.isArray(collection)) {
            let element = collection.find(item => item.id === elementId);

            elementName = element ? element.name : "";
        }

        return elementName;
    }

    save() {
        const
            { onSave, onValidate, modalClass } = this.attrs,
            { separator = "" }                 = this.data.options;

        this.data.name              = this.data.name.trim();
        this.data.options.separator = separator.replace(/\s{2,}/g, " ");
        this.data.description       = this.getDescription(this.data.options);

        if (typeof onValidate === "function") {
            if (!onValidate(this.data.name, this.data.id)) {
                return;
            }
        }

        if (typeof onSave === "function") {
            onSave(this.data);
        }

        turbo.plugins.modal.close(document.querySelector(`.${modalClass}`));
    }
}

export default AddList;
