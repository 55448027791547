export const SET_LIST_GROUPS            = "TEXT-BLOCK/SET_LIST_GROUPS";
export const INIT_GROUP                 = "TEXT-BLOCK/INIT_GROUP";
export const INIT_GROUP_REGISTRY        = "TEXT-BLOCK/INIT_GROUP_REGISTRY";
export const SET_LIST                   = "TEXT-BLOCK/SET_LIST";
export const SET_TYPES                  = "TEXT-BLOCK/SET_TYPES";
export const INIT_ITEM                  = "TEXT-BLOCK/INIT_ITEM";
export const SET_ITEM                   = "TEXT-BLOCK/SET_ITEM";
export const INIT_GROUP_TEMPLATE        = "TEXT-BLOCK/INIT_GROUP_TEMPLATE";
export const CLEAR_UPDATE_STACK         = "TEXT-BLOCK/CLEAR_UPDATE_STACK";
export const SET_ITEM_IN_UPDATE_STACK   = "TEXT-BLOCK/SET_ITEM_IN_UPDATE_STACK";
export const CLEAR_ITEM_IN_UPDATE_STACK = "TEXT-BLOCK/CLEAR_ITEM_IN_UPDATE_STACK";
export const INIT_GROUP_FRAGMENT        = "TEXT-BLOCK/INIT_GROUP_FRAGMENT";
export const SET_SEARCH_DATA_GROUPS     = "TEXT-BLOCK/SET_SEARCH_DATA_GROUPS";
export const SET_SEARCH_DATA_TEXT_BLOCK = "TEXT-BLOCK/SET_SEARCH_DATA_TEXT_BLOCK";
